import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, message, Input, Spin, Upload } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import commonService from '@/service/common';
import * as monaco from 'monaco-editor';
let monacoEditor = null;
const RemakeRecognition = (props) => {
    const { selectData, modeType } = props;
    const [form] = Form.useForm();

    const [modalLoding, setModalLoding] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    useEffect(() => {
        monacoEditor = monaco.editor['create'](
            document.querySelector('#containerId'),
            {
                value:'',
                language: 'json',
                theme: 'vs',
            },
        );
    }, []);
    useEffect(() => {
        console.log(selectData, '----selectData');
        // selectData && form.setFieldValue('url', selectData.url)
    }, [selectData]);
    useEffect(() => {
        form.setFieldValue(
            'url',
            selectData && selectData.url ? selectData.url : '',
        );
        setTimeout(() => {
            monacoEditor.setValue('')
        }, 200);   
    }, [modeType]);

    const fileChange = (e) => {
        console.log(e.target.files[0]); //文件对象到手
        let file = e.target.files[0];
        const objectURL = URL.createObjectURL(file);
        setThumbnail(objectURL);
        form.setFieldValue('file', file);
    };
    const remakeSubmit = () => {
        form.validateFields().then((data) => {
            const formData = new FormData();
            formData.append('url', data.url);
            formData.append('file', data.file);
            formData.append('mode', modeType);
            let params;
            if (modeType == 'waterBill') {
                params = {
                    imgUrl: data.url,
                };
            } else {
                params = formData;
            }
            setModalLoding(true);
            commonService[
                modeType == 'waterBill' ? 'WaterBillSubmit' : 'ReMakeSubmit'
            ](params).then((res) => {
            //     console.log(res);

                setTimeout(() => {
                    monacoEditor.setValue(JSON.stringify(res.content))
                    
                    setTimeout(() => {
                        monacoEditor.trigger(
                            'editor',
                            'editor.action.formatDocument',
                        );
                    }, 200);
                }, 200);
                setModalLoding(false);
                // setRecognitionResault(JSON.stringify(res.content))
            });
        });
    };
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    return (
        <>
            <div className="remakeRecognitionMain">
                <div
                    style={{ width: collapsed ? '70%' : '92%' }}
                    className="remakeRecognitionRightBox"
                >
                    <Form
                        form={form}
                        name="advanced_search"
                        className="gallery-search-form"
                    >
                        <Form.Item label="图片URL" name="url">
                            <Input />
                        </Form.Item>
                        {modeType == 'retake-recognition' && (
                            <Form.Item label="本地上传" name="file">
                                <input
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={(e) => {
                                        fileChange(e);
                                    }}
                                />
                                {thumbnail && (
                                    <img
                                        style={{
                                            width: '140px',
                                            height: 'auto',
                                            marginRight: '10px',
                                        }}
                                        alt=""
                                        src={thumbnail}
                                    />
                                )}
                                <div
                                    className="uploadBox"
                                    onClick={() => {
                                        console.log('click');
                                        document
                                            .getElementById('fileInput')
                                            .click();
                                    }}
                                >
                                    <PlusOutlined />
                                    <p className="upladeTxt">上传</p>
                                </div>
                            </Form.Item>
                        )}

                        <div style={{ marginBottom: 0, textAlign: 'right' }}>
                            <Button
                                loading={modalLoding}
                                type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    remakeSubmit();
                                }}
                            >
                                识别
                            </Button>
                        </div>
                    </Form>
                    {modeType == 'retake-recognition' && (
                        <div className="tips">
                            <p>
                                1, 建议翻拍判定方法
                                设定一个判定为翻拍图片的阈值，即如果recapture的score大于这个值，则认为这张图片是翻拍。通常有两种对应的业务模式：
                                注：以下数值均为建议值，实际应用的阈值请结合业务实际情况和实测结果进行设定
                            </p>

                            <p>
                                2,
                                业务里查翻拍的原则是宁可错杀一千，也不愿错放一个的，那么可以把认为是翻拍的阈值放在0.8~0.95。
                            </p>
                            <p>
                                3,
                                业务里查翻拍的原则是允许错放过一些翻拍的图片，但是查到的一定要对，那么可以把认为是翻拍的阈值放在0.98甚至0.99。
                            </p>
                        </div>
                    )}

                    <div className="remakeRecognitionResault">
                        <div className="remakeRecognitionResultTitle">
                            识别结果
                        </div>
                        <div>
                            {modalLoding && <Spin tip="识别中..."></Spin>}
                            <div
                                id="containerId"
                                style={{ height: '200px' }}
                            ></div>
                            {/* {recognitionResault} */}
                        </div>
                    </div>
                </div>
                <div
                    className={
                        collapsed
                            ? 'remakeRecognitionLeftBox'
                            : 'remakeRecognitionLeftBoxCollapsed'
                    }
                >
                    {/* <Tooltip placement="rightTop" title='查看体验历史' color="black">  */}
                    <Button
                        type="primary"
                        onClick={toggleCollapsed}
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        {collapsed ? (
                            <MenuUnfoldOutlined />
                        ) : (
                            <MenuFoldOutlined />
                        )}
                    </Button>
                    {/* </Tooltip> */}
                    {
                        // collapsed &&
                        <div className="remakeRecognitionLeftBoxTitle">
                            体验历史
                        </div>
                    }
                </div>
            </div>
        </>
    );
};
export default RemakeRecognition;
