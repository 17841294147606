import React from 'react';
import styles from './channel.module.less';

const ChannelInfoCard = (props) => {
    const { moduleItem, requestVal } = props;
    return (
        <>
            <div className={styles.title}>{moduleItem?.title}</div>
            <div className={styles.content}>
                {moduleItem?.infoJson?.map((item, index) => {
                    return (
                        <div className={styles.contentItem} key={index}>
                            <div className={styles.contentItemLabel}>
                                {item?.label}
                            </div>
                            <div className={styles.contentItemValue}>
                                {requestVal[item?.value]}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ChannelInfoCard;
