/* eslint-disable no-unused-vars */
import React, {
    forwardRef,
    useImperativeHandle,
    useState
} from "react";
import { Modal, Form, Radio, Input, Divider, message, Checkbox } from "antd";
import { UploadFiles } from "smart-filer-react";
import commonService from "@/service/common";
import "./index.less";
const { TextArea } = Input;
const TripOrderGcar = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [uploadProps, setUploadProps] = useState();
    const [selectData, setselectData] = useState();
    const [selectBtn, setSelectBtn] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [customerServiceModalVisible, setCustomerServiceModalVisible] = useState(false);
    const [serviceRemarkModalVisible, setServiceRemarkModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [description, setDescription] = useState('');
    const [isSupreedback, setIsSupreedback] = useState(false);
    const [isAcceptance, setIsAcceptance] = useState(true);
    const [type, setType] = useState();

    const setMappingData = (btn, data, fileLists) => {
        let mappingData = {
            "catalog": "支持文件",
            "processId": data.proposalId,
            "relatedId": data.tripId,
            "relatedType": "trip_supportfile"
        };
        console.log(mappingData);
        let updata = {
            accept:
                ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            defaultFileList: fileLists,
            showUploadList: {
                showDownloadIcon: true,
                showPreviewIcon: true,
                showRemoveIcon: true,
            },
            isMapping: true,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                setFileList(fileList);
                console.log("App uploadCallback:", file, fileList);
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                arr.push(file.fileKey)
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    setFileList(fileList);
                }


                console.log("App removeCallback:", file, fileList);
            },
        };
        console.log(updata);
        setUploadProps(updata);
        setModalVisible(true)
    };
    const uploadTripFileM = async (btn, rowKeys, selectedRows, data) => {
        console.log(btn);
        let params = {
            "filterData": {
                "catalog": "支持文件",
                "processId": data.proposalId,
                "relatedId": data.tripId,
                "relatedType": "trip_supportfile"
            }
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileList = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            setMappingData(btn, data, fileList);
        }
    }
    useImperativeHandle(ref, () => ({
        handle: (btn, rowKeys, selectedRows, data) => {
            console.log(data);
            setselectData(data)
            setSelectBtn(btn)
            switch (btn.actionCode) {
                case "uploadTripFile":
                    uploadTripFileM(btn, rowKeys, selectedRows, data)
                    break;
                case "customerService":
                    let formData = {
                        diverTrain: data.diverTrain && Number(data.diverTrain),
                        tripServiceConfirm: data.tripServiceConfirm && Number(data.tripServiceConfirm),
                        tripType: data.tripType && Number(data.tripType),
                        clientRemark: data.clientRemark
                    }
                    form.setFieldsValue(formData);
                    setCustomerServiceModalVisible(true)
                    break;
                case "serviceAccept":
                case "serviceRemark":
                    let obj = JSON.parse(data.supplierRepTime ? data.supplierChkBox : data.serviceChkBox);
                    console.log(obj);
                    if (obj && data?.acptTime) {
                        setIsAcceptance(obj.isAcceptance);
                        setIsSupreedback(obj.isSupreedback);
                        setDescription(btn.actionCode == 'serviceAccept' ? data.supplierRepContent : data.serviceRemark);
                        setType(btn.actionCode === 'serviceRemark'? '客服备注' : '');
                    }else {
                        console.log(11);
                        // 给个默认值
                        setIsAcceptance(false)
                        setIsSupreedback(false);
                        obj = {'isAcceptance': false, 'isSupreedback': false}
                    }
                    setServiceRemarkModalVisible(true)
                    break;
                default:
                    break
            }
        }
    })
    )

    const uploadTripFileHandleOk = () => {
        setModalVisible(false);
    };
    const customerServiceHandleOk = () => {
        form.validateFields().then(async (values) => {
            console.log("表单验证项： ", values);
            let params = values;
            params.tripId = selectData.tripId
            const res = await commonService.changeTripType(params);
            if (res && res.success) {
                form.resetFields();
                setCustomerServiceModalVisible(false);
                props.reloadData();
            }
        });

    }
    const customerServiceHandleCancel = () => {
        setCustomerServiceModalVisible(false)
    }
    const comPHandleOk = async () => {
        if (selectData?.supplierRepTime && selectData?.acptTime && selectBtn.actionCode == 'serviceAccept') {
            comPHandleCancel();
        } else {
            if (isSupreedback && !description) {
                message.info("请填写反馈");
                return;
            }
            if(selectBtn.actionCode != 'serviceAccept'&&!description){
                message.info("请填写备注");
                return;
            }
            let obj = {
                isAcceptance: isAcceptance,
                isSupreedback: isSupreedback
            }
            let params = {
                type: type,
                tripId: selectData.tripId,
                description: description,
                items: JSON.stringify(obj),
                itemId: selectData.itemId,
                miceId: selectData.miceId
            }
            console.log(params)
            const res = await commonService.replyComp(params);
            if (res && res.success) {
                message.success("操作成功！");
                comPHandleCancel();
                props.reloadData()
            }
        }
    }
    const comPHandleCancel = () => {
        setIsAcceptance(false)
        setIsSupreedback(false)
        setDescription('')
        setServiceRemarkModalVisible(false)
    }
    const itemOnChange = (e, type) => {
        setType(type);
        type == '客服受理' ? setIsAcceptance(e.target.checked) : setIsSupreedback(e.target.checked)
    }
    return (
        <div>
            <Modal
                title="上传支持文件"
                destroyOnClose
                visible={modalVisible}
                width={1000}
                onOk={uploadTripFileHandleOk}
                onCancel={uploadTripFileHandleOk}
            >
                <UploadFiles {...uploadProps}>
                    {/* <Button type="primary" icon={<UploadOutlined />}>
              导入
            </Button> */}
                </UploadFiles>
            </Modal>
            <Modal
                title="客服操作"
                destroyOnClose
                visible={customerServiceModalVisible}
                width={800}
                onOk={customerServiceHandleOk}
                onCancel={customerServiceHandleCancel}
            >
                <Form layout="horizontal" form={form} labelCol={{
                    span: 6,
                }}
                    wrapperCol={{
                        span: 12,
                    }}>
                    <Form.Item
                        label="确认行程地址"
                        name="tripServiceConfirm"
                        rules={[{ required: true, message: "请确认行程地址!" }]}
                    >
                        <Radio.Group optionType="button"
                            buttonStyle="solid">
                            <Radio value={1}>已确认</Radio>
                            <Radio value={0}>未确认</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="司机培训"
                        name="diverTrain"
                        rules={[{ required: true, message: "请选择司机培训!" }]}
                    >
                        <Radio.Group optionType="button"
                            buttonStyle="solid">
                            <Radio value={0}>确认培训</Radio>
                            <Radio value={1}>确认收到回执</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="特殊订单"
                        name="tripType"
                        rules={[{ required: true, message: "请选择特殊订单!" }]}
                    >
                        <Radio.Group optionType="button"
                            buttonStyle="solid">
                            <Radio value={0}>转专车</Radio>
                            <Radio value={1}>转网约车</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="客服备注（内部）"
                        name="clientRemark"
                    >
                        <TextArea rows={4} autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={selectBtn?.actionName}
                destroyOnClose
                visible={serviceRemarkModalVisible}
                width={800}
                onOk={comPHandleOk}
                onCancel={comPHandleCancel}
            >
                {selectBtn?.actionCode == 'serviceAccept' ?
                    <div className="compBox">
                        <div>
                            <p>投诉类型：{selectData.type}</p>
                            <p>投诉内容：{selectData.content}</p>
                            <p>其它备注：{selectData.otherRemark}</p>
                        </div>
                        <Divider dashed />
                        <p><span className="labelTxt">客服受理：</span><Checkbox checked={isAcceptance} disabled={selectData?.acptTime ? true : false} onChange={(e) => { itemOnChange(e, "客服受理") }}>受理</Checkbox></p>
                        {selectData?.acptTime ? <p><span className="labelTxt">供应商反馈：</span><Checkbox checked={isSupreedback} disabled={selectData?.supplierRepTime ? true : false} onChange={(e) => { itemOnChange(e, '供应商反馈') }}>供应商反馈</Checkbox></p> : null}
                        {
                            isSupreedback && <TextArea value={description} disabled={selectData?.supplierRepTime ? true : false} rows={6} onChange={(e) => { setDescription(e.target.value) }} placeholder="请输入回复" autoSize={{
                                minRows: 2,
                                maxRows: 8,
                            }} />
                        }
                    </div>
                    : <div className="compBox">
                        <p>客服备注(内部)：</p>
                        <TextArea rows={6} value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder="请输入回复" autoSize={{
                            minRows: 2,
                            maxRows: 8,
                        }} />
                    </div>
                }
            </Modal>
        </div>
    );
})

export default TripOrderGcar;
