import { request } from "./request";

// 支付宝订单表头获取
export  const getHeaderList = (params)=> request.post("/api/ndata/XJGrid/GetJGridSettings", params)
// 获支付宝订单会议数据
export const getMeetingList =(params) => request.post("/api/ndata/XJGrid/GetJGridData",params)
// 获取支付宝会议详细
export const getMeetingInfo =(params) => request.get("rpc/pay/Alipay/GetOrderDetail",{params: params})
//导出数据
export const exportJGridData =(params)=>request.post("api/ndata/XJGrid/ExportJGridData",params)
