import React, { useEffect, forwardRef, useState } from 'react';
import { Button, Descriptions, Badge, message, Modal } from 'antd';
// eslint-disable-next-line no-unused-vars
import { getCookie } from 'tiny-cookie';
import { LinkOutlined } from '@ant-design/icons';
// eslint-disable-next-line no-unused-vars
import commonService from '@/service/common';
const SmartPayButton = forwardRef((props, ref) => {
    const [visitorsList, setVisitorsList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        queryPayVisitors();
    }, []);
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const queryPayVisitors = () => {
        let params = {
            status: 0,
        };
        commonService.QueryPayVisitors(params).then((res) => {
            if (res && res.success) {
                setVisitorsList(res.content);
            }
        });
    };
    const handleBtn = (data) => {
        let params = {
            id: data.id,
            expenseId: data.expenseId,
        };
        commonService.CancelPayVisitor(params).then((res) => {
            if (res && res.success) {
                queryPayVisitors();
                message.success('作废成功');
            }
        });
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const content = (
        <div className="payDetail">
            {visitorsList.length > 0 &&
                visitorsList.map((ele) => {
                    return (
                        <div className="detailsBox">
                            <Descriptions
                                bordered
                                column={2}
                                style={{ width: '90%' }}
                            >
                                <Descriptions.Item label="取号时间">
                                    {ele.dtRequest}
                                </Descriptions.Item>
                                <Descriptions.Item label="下单人">
                                    {ele.createdBy}
                                </Descriptions.Item>
                                <Descriptions.Item label="供应商">
                                    {ele.supplierName}
                                </Descriptions.Item>
                                <Descriptions.Item label="会议编号">
                                    {ele.poNum}
                                </Descriptions.Item>
                            </Descriptions>
                            <div>
                                <Button
                                    type="primary"
                                    onClick={(e) => handleBtn(ele)}
                                >
                                    作废
                                </Button>
                            </div>
                        </div>
                    );
                })}
            {!visitorsList.length && <div>暂无数据</div>}
        </div>
    );
    return (
        <div>
            <Badge count={visitorsList.length}>
                <Button
                    type="link"
                    onClick={() => {
                        showModal();
                    }}
                >
                    排队详情
                    <LinkOutlined />
                </Button>
            </Badge>

            <Modal
                title="排队情况"
                width={800}
                onCancel={handleCancel}
                maskClosable={false}
                open={isModalOpen}
                footer={null}
            >
                {content}
            </Modal>
        </div>
    );
});
export default SmartPayButton;
