import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { Button, Form, Input, Popconfirm, Table, InputNumber } from 'antd';
import {EditOutlined} from "@ant-design/icons";
import {column2, mockData2} from "@/pages/HomePage/ConfirmSettlementCode/dataSet";
import "./index.less"
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    // 保存修改的内容
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                    padding: 0
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `请填写${title}`,
                    },
                ]}
            >
                <InputNumber min={0} size="small" controls={false} ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <h1
                className="editable-cell-value-wrap"
                onClick={toggleEdit}
            >
                {children} <EditOutlined className="editIcon"/>
            </h1>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
// 编辑的表格
const EditTable = ({tableData2, calculateFee}, ref) => {
    const [dataSource, setDataSource] = useState([]);
    // 直接父组件调用
    useImperativeHandle(ref, ()=> ({
        handleSet
    }))
    const handleSet = (arr) => {
        setDataSource(arr)
    }
    const handleSave = (row) => {
        console.log(row);
        const newData = [...tableData2];
        const index = newData.findIndex((item) => row.key === item.key);
        console.log(index);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
        calculateFee(newData);
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = column2.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });
    return (
        <div>
            <Table  components={components} className="settlement_table"  columns={columns} bordered size="small"
                   style={{maxWidth: "100%", wordBreak: "break-word"}}
                   pagination={false} dataSource={tableData2}/>
        </div>
    );
};
export default forwardRef(EditTable);
