/* eslint-disable no-eval */
import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {Modal, Cascader, Form, Input, Radio, Select, InputNumber, message, DatePicker, Button} from "antd";
import { UploadFiles } from "smart-filer-react";
import commonService from "@/service/common";
import moment from 'moment';

const Agreement = forwardRef((props, ref) => {
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [form] = Form.useForm();
    const [selectedData, setSelectedData] = useState({});
    const [uploadProps, setUploadProps] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [brandList, setBrandList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [prePayStatus, setPrePayStatus] = useState([]);
    // eslint-disable-next-line
    const [originProtocolFiles, setOriginProtocolFiles] = useState([]);
    useEffect(() => {
        getAuditOptions()
        getBrandList()
        getRegions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getAuditOptions = () => {
        let params = {
            collection: 'cfg-supplier-options',
            useCaching: false,
            cachingKey: '',
            filter: {
                tenantId: { '$in': [props.defaultParams.tenantId] },
                type: 'payMode'
            },
            projection: {
                options: 1
            }
        };
        commonService.GetSettings(params).then(res => {
            if (res && res.success) {
                setPrePayStatus(res.content?.options || [])
            }
        })
    }
    const getBrandList = () => {
        commonService.getBrandList({}).then(res => {
            if (res && res.success) {
                let dataList = Array.from(new Set(res.content))
                let data = dataList.map((item, index) => ({ label: item, value: item }))
                setBrandList(data)
            }
        })
    }
    const getRegions = () => {
        let params = {
            'keycode': 'CPC-Province,CPC-City,CPC-Region'
        };
        commonService.queryTreeAsync(params).then(res => {
            if (res && res.success) {
                setRegionList(res.content)
            }
        })
    }
    const queryFile = async (rowData) => {
        let params = {
            filterData: {
                'processId': props.defaultParams.miceId,
                'tenantId': props.defaultParams.tenantId,
                'supplierId': rowData.supplierId,
                "relatedType": 'Protocol.Signed',
                "catalog": "供应商协议"
            }
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileItem = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            setFileList(fileItem);
            setMappingData(params.filterData, fileItem)
        }
    }
    const setMappingData = (mappingData, fileItem) => {
        let updata = {
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            defaultFileList: fileItem,
            showUploadList: {
                showPreviewIcon: true,
                showRemoveIcon: true,
            },
            isMapping: true,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                setFileList(fileList);
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    setFileList(fileList);
                }
            },
        };
        setUploadProps(updata);
        setIsModalOpen(true);
    };
    const showModal = (btn, rowData) => {
        rowData.originProtocolArrayFiles = typeof rowData.originProtocolArrayFiles == 'string' ? JSON.parse(rowData.originProtocolArrayFiles) : rowData.originProtocolArrayFiles
        setSelectedData(rowData)
        queryFile(rowData)
        form.setFieldsValue({
            amount: Number(rowData.prepayment),
            payMode: rowData.payMode,
            deadline: rowData.deadline ? moment(rowData.deadline) : null,
            region: rowData.region ? rowData.region.split(',') : '',
            accountBank: rowData.accountBank,
            bankBranch: rowData.bankBranch,
            accountNum: rowData.accountNum || 0,
            accountName: rowData.accountName,
            yonyouCode: rowData.yonyouCode || 0,
        });
        setOriginProtocolFiles(JSON.parse(rowData.originProtocolFiles || '[]'))
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleOk = () => {
        form.validateFields().then((values) => {
            values.deadline = values.deadline ? moment(values.deadline).format('YYYY-MM-DD') : null
            values.region = values.region.join()
            values.supplierName = selectedData.name
            values.supplierId = selectedData.supplierId
            values = {...values, ...props.defaultParams}
            commonService.saveAdvance(values).then(res => {
                if (res && res.success) {
                    message.success("保存成功")
                    props.updateChild()
                    setIsModalOpen(false);
                }
            })
        })
    };
    const doSign = () => {
        let params = {
            fileIds: fileList.map(ele => ele.fileKey)
        };
        commonService.DoSign(params).then(res => {
            if (res && res.success) {
                ProtocolSigned(res.content)
            }
        })
    }
    const ProtocolSigned = (files) => {
        let params = {
            protocolId: selectedData.protocolId,
            signFiles: files
        };
        commonService.signProtocol(params).then(res => {
            if (res && res.success) {
                message.success('协议盖章成功')
            }
        })
    }
    const handleAccountNumChange = (e) => {
        const value = e.target.value;
        // 使用正则表达式确保只有数字被保留
        const numericValue = value.replace(/\D/g, '');
        form.setFieldValue('accountNum', numericValue);
    };
    const openFile = (ele) => {
        window.open(ele.fileUrl)
    }
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    return (
        <Modal title="协议及预付款" width={700} maskClosable={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form form={form} component={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left">
                <Form.Item label="酒店名称" style={{ marginBottom: '12px' }} rules={[ { required: false, message: 'message', }, ]}>
                    {selectedData.name}
                </Form.Item>
                <Form.Item label="供应商上传协议" style={{ marginBottom: '12px' }} rules={[ { required: false, message: 'message', }, ]}>
                    <div>
                        {selectedData.originProtocolArrayFiles && selectedData.originProtocolArrayFiles.length > 0 && selectedData.originProtocolArrayFiles.map((ele, index) => (
                            <p key={index} style={{color: '#1890ff', cursor: "pointer",marginBottom: 'auto'}}><span onClick={() => openFile(ele)}>{ele.fileName}</span></p>
                        ))}
                    </div>
                </Form.Item>
                <Form.Item label="上传盖章协议" style={{ marginBottom: '12px' }} rules={[ { required: false, message: 'message', }, ]}>
                    <div>
                        <UploadFiles {...uploadProps} />
                    </div>
                    <Button type="primary" onClick={() => doSign()}>{'盖章'}</Button>
                </Form.Item>
                <Form.Item label="酒店名称" style={{ marginBottom: '12px' }} rules={[ { required: false, message: 'message', }, ]}>
                    {selectedData.name}
                </Form.Item>
                <Form.Item name="amount" label="预付款" style={{ marginBottom: '12px' }} rules={[ { required: true, message: '请填写预付款', }, ]}>
                    <InputNumber style={{width:'300px'}} step={0.01} min={0}/>
                </Form.Item>
                <Form.Item name="deadline" label="要求付款时间" style={{ marginBottom: '12px' }} rules={[ { required: true, message: '请选择要求付款时间', }, ]}>
                    <DatePicker style={{width:'300px'}}/>
                </Form.Item>
                <Form.Item name="region" label="开户地区" style={{ marginBottom: '12px' }} rules={[ { required: true, message: '请选择开户地区', }, ]}>
                    <Cascader style={{width:'300px'}} options={regionList}/>
                </Form.Item>
                <Form.Item name="accountBank" label="开户银行" style={{ marginBottom: '12px' }} rules={[ { required: true, message: '请选择开户银行', }, ]}>
                    <Select showSearch style={{width:'300px'}} options={brandList}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }/>
                </Form.Item>
                <Form.Item name="bankBranch" label="开户支行" style={{ marginBottom: '12px' }} rules={[ { required: true, message: '请输入开户支行', }, ]}>
                    <Input style={{width:'300px'}}/>
                </Form.Item>
                <Form.Item name="accountNum" label="开户账号" style={{ marginBottom: '12px' }} rules={[ { required: true, message: '请输入开户账号', }, ]}>
                    <Input style={{width:'300px'}} onChange={handleAccountNumChange}/>
                </Form.Item>
                <Form.Item name="accountName" label="开户名称" style={{ marginBottom: '12px' }} rules={[ { required: true, message: '请输入开户名称', }, ]}>
                    <Input style={{width:'300px'}}/>
                </Form.Item>
                <Form.Item name="yonyouCode" label="用友编号" style={{ marginBottom: '12px' }} rules={[ { required: true, message: '请输入用友编号', }, ]}>
                    <InputNumber style={{width:'300px'}} min={0} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || 0)}/>
                </Form.Item>
                <p style={{color: 'red',marginBottom:0,fontSize: '14px'}}>当前仅辉瑞、协办会支持企业码支付；仅罗氏支持代付费支付</p>
                <Form.Item name="payMode" label="重新配置尾款支付" style={{ margin: 0 }} rules={[ { required: true, message: '请重新配置尾款支付', }, ]}>
                    <Radio.Group >
                        { prePayStatus.length > 0 && prePayStatus.map((ele, index) => {
                            return <Radio key={index} value={ele.value.toString()}> {ele.txt} </Radio>
                        })}
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default Agreement;
