import React, { useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { getCookie } from "tiny-cookie";
import { useLocation } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import SmartStorage from "@/utils/storage/index.js";
import SmartPayButton from "./SmartPayButton";
const ActionBtns = (props) => {
  let location = useLocation();

  const componentObj = {
    SmartPayButton: SmartPayButton,
  };
  useEffect(() => {}, [location]);

  return (
    <>
      {React.createElement(componentObj[props.headerPortals], {
        reloadData: props.reloadData,
      })}
    </>
  );
};

export default ActionBtns;
