import React, { useState } from 'react';
import SmartQueryReact from 'smart-iquery-react';
import { Button } from 'antd';
import styles from './meetingList.module.less';

const MeetingList = (props) => {
    const { activeData, fieldsValue } = props;
    console.log(activeData, 'activeData');

    const iSignalr = process.env.REACT_APP_SIGNALER;
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [queryData] = useState([
        {
            queryId: 'aba6132e-bb39-47ca-8423-1a02d103cec1',
            queryId4Count: '8aad254e-ca8a-4c6a-bcd9-37c5e4f99b83',
            cluster: 'default',
            additional: {
                _supplierId: activeData?.activeSupplier?.value,
                _category: activeData?.activeCategory?.category,
                _feeType: fieldsValue.feeType || '?', // 费用类型
            },
        },
    ]);
    const returnSelectedRows = (rowsId, rows) => {
        props.callBackSelectData(rowsId, rows);
    };
    const rowMenuHandleClick = (e, record, item) => {};
    const headerBtnHandleClick = (btn, item, items) => {
        console.log('headerBtnHandleClick');
    };
    const handleClick = () => {
        props.handleCancelMeetingModal();
    };
    return (
        <>
            <div className={styles.title}>会议列表</div>
            <SmartQueryReact
                gatewayPath={gatewayPath}
                iSignalr={iSignalr}
                getQueryData={queryData}
                returnSelectedRows={returnSelectedRows}
                rowMenuHandleClick={rowMenuHandleClick}
                headerBtnHandleClick={headerBtnHandleClick}
            ></SmartQueryReact>
            <div className={styles.foooterBtn}>
                <Button
                    type="primary"
                    className={styles.btnItem}
                    onClick={handleClick}
                >
                    确定
                </Button>
            </div>
        </>
    );
};

export default MeetingList;
