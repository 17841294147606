import { alipayList,alipayHeader,tableLoading} from "../actions/actionsType";

const initialState = {
  jGridData: {},
  headerData:{},
  tbLoading:true
};
const JGridReducer = (prevState = initialState, action) => {
  let { type, payload } = action;
  let newState = { ...prevState };
  switch (type) {
    case alipayList:
      newState.jGridData = payload;
      return newState;
    case alipayHeader:
      newState.headerData = payload;
      return newState
    case tableLoading:
    newState.tbLoading =payload;
    return newState
    default:
      return prevState;
  }
};

export default JGridReducer;
