import React from "react";
import SmartQueryReact from "smart-iquery-react";
const FinanceTaskDetail = (props) => {
    const iSignalr = process.env.REACT_APP_SIGNALER;
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const getQueryDataDetail = [{ "queryId": props.queryObject.variables.queryId4Detail,  "cluster": "default", tableType: 'tableDetails',noTotal:true }]
    const getQueryDataInvoice = [{ "queryId": props.queryObject.variables.queryId4Invoice, "cluster": "default",unUsePaging:true }]

    return (
        <div>
            <p>开票信息</p>
            <SmartQueryReact
                gatewayPath={gatewayPath}
                iSignalr={iSignalr}
                getQueryData={getQueryDataDetail}
            ></SmartQueryReact>
            <div>
            <p>发票信息</p>
            <SmartQueryReact
                gatewayPath={gatewayPath}
                iSignalr={iSignalr}
                getQueryData={getQueryDataInvoice}
            ></SmartQueryReact>
            </div>
            
        </div>
    )
}

export default FinanceTaskDetail;