import React, {useEffect, useState, useRef} from 'react';
import {Table, Input, InputNumber, Popconfirm, Form, Select, Typography, message, Checkbox, DatePicker , Radio} from 'antd';
import commonService from "@/service/common";
import moment from 'moment';

const { RangePicker } = DatePicker;
const HotelDetailsItem = (props) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('空');
    const [datasource, setDatasource] = useState([]);
    const [editItem, setEditItem] = useState({});
    const dateFormat = 'YYYY/MM/DD';
    const dateTimeFormat = 'YYYY/MM/DD HH:mm';
    const prevProps = useRef(props);
    const isEditing = (record) => {
        return record[props.dataKey] === editingKey
    };
    const handleChange = (operate) => (val) => {
        if (operate && operate.length) {
            operate.forEach(ele => {
                const operateTxt = ele.operate ? ele.operate.replace(/"/g, "") : null
                // eslint-disable-next-line no-eval
                const result = ele.operate ? eval(operateTxt) : 0
                form.setFieldsValue({ [ele.result]: result });
            })
        }
    };
    const handleSelectChange = (operate, options) => (val) => {
        if (operate && operate.length) {
            operate.forEach(ele => {
                const operateTxt = ele.operate ? ele.operate.replace(/"/g, "") : null
                // eslint-disable-next-line no-eval
                const result = ele.operate ? eval(operateTxt) : 0
                form.setFieldsValue({ [ele.result]: result });
            })
        }
    };
    const EditableCell = ({ editing, dataIndex, title, required, isEdit, dataType, record, options, index, children, operate, message, ...restProps }) => {
        let inputNode = <Input />
        switch (dataType) {
            case 'taxRate' :
                inputNode = <InputNumber size="large" min={0} max={100} onChange={handleChange(operate)} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || 0)}/>
                break;
            case 'number':
                inputNode = operate ? <InputNumber size="large" onChange={handleChange(operate)}/> : <InputNumber size="large" />
                break;
            case 'select':
                inputNode = <Select size="large" style={{width: '140px'}} options={options} onChange={handleSelectChange(operate, options)}/>
                break;
            case 'checkbox':
                inputNode = <Checkbox.Group size="large" options={options} />
                break;
            case 'date':
                inputNode = <DatePicker size="large" format={dateFormat}/>
                break;
            case 'dateTime':
                inputNode = <RangePicker size="large" style={{width: '140px'}} showTime format={dateTimeFormat}/>
                break;
            case 'radio':
                inputNode = (<Radio.Group size="large">
                    { options.length > 0 && options.map((ele, index) => {
                        return <Radio key={index} value={ele.value}> {ele.label} </Radio>
                    })}
                </Radio.Group>)
                break
            default:
                inputNode = <Input size="large" style={{ width: '120px' }} />
                break;
        }
        return (
            <td {...restProps}>
                {editing ? (
                        isEdit ? (
                            <Form.Item
                                name={dataIndex}
                                style={{ margin: 0 }}
                                rules={[ { required: required, message: message, }, ]}
                            >
                                {inputNode}
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name={dataIndex}
                                style={{ margin: 0 }}
                                rules={[ { required: required, message: message, }, ]}
                            >
                                <Input size="large" disabled={true} style={{width: '80px'}}/>
                            </Form.Item>
                        )
                ) : (
                    <div>
                        {dataType === 'checkbox' ? (<>{options.length> 0 && options.map(o => (
                            children[1] && children[1].includes(o.value) ? (
                                <span style={{marginRight: '10px'}} key={o.value}>{o.label}</span>
                            ) : null
                        ))}</>) : dataType === 'radio' ? (<>{options.length> 0 && options.map(o => (
                            children[1] && [children[1]].includes(o.value) ? (
                                <span key={o.value}>{o.label}</span>
                            ) : null
                        ))}</>) : dataType === 'select' ? (<>{typeof children[1] === 'boolean' ? children[1] ? '是' : '否' : children}</>) 
                        : children}
                    </div>
                )}
            </td>
        );
    };
    const column = [{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'left',
        render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
                <span>
                        <Typography.Link onClick={() => save(record)} style={{ marginRight: 8 }}>
                            保存
                        </Typography.Link>
                        <Popconfirm title="是否取消保存?" onConfirm={() => cancel(record)}>
                            <span style={{color: '#1890ff', cursor: 'pointer'}}>取消</span>
                        </Popconfirm>
                    </span>
            ) : (
                <span>
                        <Typography.Link disabled={editingKey !== '空'} onClick={() => edit(record)} style={{ marginRight: 8 }}>
                            编辑
                        </Typography.Link>
                        <Popconfirm title="是否确认删除?" onConfirm={() => removeItem(record)}>
                            <span style={{color: '#1890ff', cursor: 'pointer'}}>删除</span>
                        </Popconfirm>
                    </span>
            );
        },
    }]
    props.venueData.column.forEach(ele => {
        column.push(ele)
    })
    useEffect(() => {
        let data = {}
        props.venueData.column.forEach(ele => {
            data[ele.key] = ''
        })
        setEditItem(data)
        setDatasource(props.venueData.datasource)
        prevProps.current = props;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setDatasource(props.venueData.datasource)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.venueData]);
    useEffect(() => {
        if (JSON.stringify(prevProps.current.venueData.datasource) !== JSON.stringify(props.venueData.datasource)) {
            // someProp 的值发生了改变
            // 在这里执行你的逻辑，比较旧值和新值
            setDatasource(props.venueData.datasource)
            props.venueData.datasource.forEach(ele => {
                if (['新增'].includes(ele[props.dataKey])) {
                    form.setFieldsValue({ ...editItem, ...ele });
                    setEditingKey(ele[props.dataKey]);
                }
            })
            // 更新 prevProps，以便下一次比较
            prevProps.current = props;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.venueData]);
    const edit = (record) => {
        if (record.venueDate && typeof record.venueDate === 'string') {
            let data = record.venueDate.split('-')
            record.venueDate = [moment(data[0]), moment(data[1])]
        }
        if (record.roomDtStart && typeof record.roomDtStart === 'string') {
            record.roomDtStart = moment(record.roomDtStart)
            record.roomDtEnd = moment(record.roomDtEnd)
        }
        form.setFieldsValue({ ...editItem, ...record });
        setEditingKey(record[props.dataKey]);
    };
    const cancel = (record) => {
        if (['新增'].includes(record[props.dataKey])) {
            props.getDetail()
        }
        setEditingKey('空');
    };
    const removeItem = (record) => {
        if (['新增'].includes(record[props.dataKey])) {
            props.getDetail()
            return
        }
        let params = {}
        params[props.dataKey] = record[props.dataKey]
        commonService[props.venueData.deleteApiName](params).then(res => {
            if (res && res.success) {
                message.success("操作成功！");
                props.getDetail()
            }
        })
    }
    const save = (record) => {
        form.validateFields().then((values) => {
            let params = {...record, ...values, ...props.defaultParams}
            if (['新增'].includes(record[props.dataKey])) {
                delete params[props.dataKey]
            }
            if (['酒店餐费'].includes(params.costType)) {
                let expenseData = {
                    mealType: params.mealType,
                    mealDtStart: params.venueDate ? moment(params.venueDate[0]).format(dateTimeFormat) : '',
                    mealDtEnd: params.venueDate ? moment(params.venueDate[1]).format(dateTimeFormat) : '',
                }
                params.expenseData = JSON.stringify(expenseData);
            }
            if (['茶歇'].includes(params.costType)) {
                let expenseData = {
                    venueName: params.venueName,
                    teaDtStart: params.venueDate ? moment(params.venueDate[0]).format(dateTimeFormat) : '',
                    teaDtEnd: params.venueDate ? moment(params.venueDate[1]).format(dateTimeFormat) : '',
                }
                params.expenseData = JSON.stringify(expenseData);
            }
            if (['场租'].includes(params.costType)) {
                let expenseData = {
                    venueName: params.venueName,
                    venueDtStart: params.venueDate ? moment(params.venueDate[0]).format(dateTimeFormat) : '',
                    venueDtEnd: params.venueDate ? moment(params.venueDate[1]).format(dateTimeFormat) : '',
                }
                params.expenseData = JSON.stringify(expenseData);
            }
            if (['房费'].includes(params.costType)) {
                params.roomDtStart = params.roomDtStart ? moment(params.roomDtStart).format(dateFormat) : ''
                params.roomDtEnd = params.roomDtEnd ? moment(params.roomDtEnd).format(dateFormat) : ''
                let expenseData = {
                    roomType: params.roomType,
                    roomDtStart: params.roomDtStart,
                    roomDtEnd: params.roomDtEnd,
                }
                params.expenseData = JSON.stringify(expenseData);
            }
            commonService[props.venueData.editApiName](params).then(res => {
                if (res && res.success) {
                    form.resetFields()
                    message.success("操作成功！");
                    setEditingKey('空');
                    props.getDetail()
                }
            })
        })
    }
    const mergedColumns = column.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            title: (
                <span>
                    {col.required && <span style={{ color: 'red' }}>*</span>} {col.title}
                </span>
            ),
            onCell: (record) => ({
                record,
                key: col.key,
                dataIndex: col.dataIndex,
                required: col.required,
                dataType: col.dataType,
                options: col.options || [],
                title: col.title,
                isEdit: col.isEdit,
                message: col.message || '',
                operate: col.operate || null,
                editing: isEditing(record),
            }),
        };
    });
    return(
        <>
            {datasource.length > 0 && (
                <>
                    {props.venueData && <div>{props.venueData.typeName}</div>}
                    <Form form={form} component={false}>
                        <Table
                            className="hotel-details"
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            columns={mergedColumns}
                            dataSource={datasource}
                            pagination={false}
                            style={{ maxWidth: "100%", wordBreak: "break-word" }}
                            scroll={{ x: "max-content" }}/>
                    </Form>
                </>
            )}
        </>
    )
}

export default HotelDetailsItem;
