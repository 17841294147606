import React from 'react';
import { Form, Col, DatePicker } from 'antd';
import defaultSettings from './defaultSettings';

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const xRangePicker = (props) => {
    const item = props.item;

    function defaultChangeClick(e, item, sData) {
        item.callBack && props.callback(e, item, sData);
    }

    return (
        <>
            <Col span={item.span}>
                <Form.Item
                    name={item.name}
                    rules={item.rules}
                    labelCol={{ span: item.lcol || defaultLabelSpan }}
                    wrapperCol={{ span: item.wcol || defaultWrapperSpan }}
                    label={item.label}
                    labelAlign={item.labelAlign || 'right'}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        showTime={item.showTime}
                        format={item.format}
                        placeholder={item.placeholder}
                        onChange={(e) => defaultChangeClick(e, item)}
                    />
                </Form.Item>
            </Col>
        </>
    );
};

export default xRangePicker;
