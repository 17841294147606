/* eslint-disable no-eval */
import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from "react";
import {Modal, Row, Form, Col, Select, Input, InputNumber, DatePicker, Button, Divider, message} from "antd";
import { EnvironmentOutlined , MinusCircleOutlined} from '@ant-design/icons';
import { UploadFiles } from "smart-filer-react";
import commonService from "@/service/common";
import { SmartStorage } from "@/utils";
import moment from 'moment';
import MapEditor from "@/components/DetailDrawer/compments/CarSupplierModules/MapEditor";
import _ from "lodash";
const ItinerarySettlement = forwardRef((props, ref) => {
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [form] = Form.useForm();
    const mapEditor = useRef(null);
    const [uploadProps, setUploadProps] = useState();
    const [fileList, setFileList] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [carModusList, setCarModusList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [autoMapping, setAutoMapping] = useState(false);
    const [hasWheelPath, setHasWheelPath] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [amount, setAmount] = useState(0);
    const [record, setRecord] = useState({});
    // 用于装载如sCity、eCity等额外不能直接放到表单的字段
    const [ExtData, setExtData] = useState({});
    const { TextArea } = Input;
    const [passengersList, setPassengersList] = useState([{ name: '', hospital: '' }]);
    useEffect(() => {
        QueryCarModus()
        setHasWheelPath(['msd'].includes(props.miceInfo.tenantCode))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    const QueryCarModus = () => {
        let params = {
            keyCode: "CarModus"
        }
        commonService.queryBankList(params).then((res) => {
            if (res && res.success) {
                let data = []
                res.content.map(ele => {
                    data.push({label: ele.txt,value: ele.val})
                })
                setCarModusList(data)
            }
        });
    }
    const queryFile = async (rowData)=> {
        let params = {
            filterData: {
                processId: rowData.orderId,
                actionId: rowData.requestId,
                itemid: rowData.itemId,
                relatedId: rowData.expenseId,
                catalog: "行车轨迹"
            }
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileItem = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            setFileList(fileItem);
            setMappingData(params.filterData, fileItem, rowData)
        }
    }
    const setMappingData = (mappingData, fileItem, rowData) => {
        let updata = {
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            defaultFileList: fileItem,
            showUploadList: {
                showPreviewIcon: true,
                showRemoveIcon: true,
            },
            isMapping: !!rowData?.expenseId,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                setFileList(fileList);
                form.setFieldValue('fileList', fileList)
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    setFileList(fileList);
                }
            },
        };
        setUploadProps(updata);
        rowData && initForm(rowData)
    }
    const showModal = (rowData, record) => {
        console.log(rowData);
        console.log(record);
        setRecord(record);
        form.resetFields();
        setSelectedData(rowData)
        setAutoMapping(false);
        getDetailsOfEdit();
        const {sCity, eCity} = rowData;
        setExtData({sCity, eCity});
        if(rowData?.expenseId) {
            setAutoMapping(true)
            let passengersList = rowData.passengersList || [];
            setPassengersList(passengersList)
            queryFile(rowData)
        } else if (rowData) {
            form.setFieldsValue({
                carType: rowData.carModel, // 车型
                licensePlate: rowData.licensePlate, //车牌号
                departure: rowData.departure, //出发地
                destination: rowData.destination, // 目的地
            })
            setMappingData({}, [])
        }
        setIsModalOpen(true);
    }
    const getDetailsOfEdit = () => {
        const {lossFeeExpenseId, lossFeePieceId} = props.miceInfo;
        if(lossFeeExpenseId && lossFeePieceId) {
            const params = {
                expenseId: lossFeeExpenseId,
                pieceId: lossFeePieceId
            }
            console.log(params);
            setSelectedData({
                expenseId: lossFeeExpenseId,
                pieceId: lossFeePieceId
            })
            commonService.GetLossFeeDetailOfEdit(params).then(res => {
                console.log(res);
                if(res.success){
                    console.log(res);
                    initForm(res.content);
                }
            })
        }
    }
    const initForm = (rowData) => {
        form.setFieldsValue({
            carModus: rowData.carModus, // 用车形式
            carType: rowData.carType, // 车型
            licensePlate: rowData.licensePlate, //车牌号
            kilometers: rowData.kilometers, //公里数
            departure: rowData.departure, //出发地
            sCity: rowData.sCity, //出发城市
            destination: rowData.destination, // 目的地
            eCity: rowData.eCity, // 目的城市
            sDateTime: rowData.sDateTime ? moment(rowData.sDateTime) : undefined, //预计上车时间
            eDateTime: rowData.eDateTime ? moment(rowData.eDateTime) : undefined, //预计下车时间
            amount: rowData.money, //总金额
            qty: rowData.pieceQty, // 乘车人数
            descr: rowData.descr, // 备注
            passengersList: rowData.passengersList, //乘车人
            price: rowData.costDetails.price,
            overMileageFee: rowData.costDetails.overMileageFee,
            overtimeFee: rowData.costDetails.overtimeFee,
            roadFee: rowData.costDetails.roadFee,
            parkingFee: rowData.costDetails.parkingFee,
            waitingFee: rowData.costDetails.waitingFee,
            serviceFee: rowData.costDetails.serviceFee,
            otherFee: rowData.costDetails.otherFee,
        })
        setAmount(rowData.money)
        setIsModalOpen(true);
    }
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    const handleOk = () => {
        setIsEdit(true)
        form.validateFields().then((values) => {
            if(amount) {
                Modal.confirm({
                    title: '提交结算确认',
                    content: '请再次确认此订单要提交结算！',
                    onOk(){
                        savePiece(values)
                    }
                })
            }else {
                Modal.confirm({
                    title: '提示',
                    content: '费用不能为0'
                })
            }
        })
    };
    const lossFee = () => {
        setIsEdit(false)
        form.validateFields().then((values) => {
            if(amount) {
                saveLossFee(values)
            }else {
                Modal.confirm({
                    title: '提示',
                    content: '有损取消需录入损失费，并把取消原因填写在供应商备注里'
                })
            }
        }).catch(errorInfo => {
           console.log(errorInfo)
          });
    }
    const saveLossFee = (editorForm) => {
        let params = {
            proposalId: SmartStorage.get("detailRow").proposalId, // 会议id
            tenantId: SmartStorage.get("detailRow").tenantId, //租户id
            orderId: props.miceInfo.orderId, //订单id
            supplierId: props.miceInfo.supplierId, //供应商id
            amount: amount, //总金额
            descr: editorForm.descr, // 备注
            IsLossFee: true,
            itemId: props.miceInfo.itemId,
            costDetails:{
                price: editorForm.price,
                overMileageFee: editorForm.overMileageFee,
                overtimeFee: editorForm.overtimeFee,
                roadFee: editorForm.roadFee,
                parkingFee: editorForm.parkingFee,
                waitingFee: editorForm.waitingFee,
                serviceFee: editorForm.serviceFee,
                otherFee: editorForm.otherFee,
            }
        }
        if (selectedData) {
            params.expenseId = selectedData.expenseId;
            params.pieceId = selectedData.pieceId;
            params.itemId = props.miceInfo.itemId //需求id
            params.requestId = selectedData.requestId //行程id
        }
        setLoading(true)
        console.log(params);
        commonService.SavePiece(params).then((res) => {
            if (res && res.success) {

                reloadBillingDetail()
            }
        })
    }
    const savePiece = (editorForm) => {
        let params = {
            proposalId: SmartStorage.get("detailRow").proposalId, // 会议id
            tenantId: SmartStorage.get("detailRow").tenantId, //租户id
            amount: amount, //总金额
            budgetDescr: "", // 超预算说明
            carModus: editorForm.carModus, // 用车形式
            carModusPriceField: "", // 未知
            carType: editorForm.carType, // 车型
            departure: editorForm.departure, //出发地
            sCity: editorForm.sCity, //出发城市
            descr: editorForm.descr, // 备注
            destination: editorForm.destination, // 目的地
            eCity: editorForm.eCity, // 目的城市
            sDateTime: editorForm.sDateTime ? moment(editorForm.sDateTime._d).format('YYYY-MM-DD HH:mm') : '', //预计上车时间
            eDateTime: editorForm.eDateTime ? moment(editorForm.eDateTime._d).format( 'YYYY-MM-DD HH:mm') : '', //预计下车时间
            kilometers: editorForm.kilometers, //公里数
            licensePlate: editorForm.licensePlate, //车牌号
            orderId: props.miceInfo.orderId, //订单id
            itemId: props.miceInfo.itemId, //需求id
            requestId: selectedData.requestId, //行程id
            passengers: editorForm.passengersList, //乘车人
            passengersList: editorForm.passengersList, //乘车人
            pieceQty: editorForm.qty, //人数，编辑时后台返回的人数字段
            qty: editorForm.qty, //乘车人数
            supplierId: props.miceInfo.supplierId, //供应商id
            expenseId: selectedData.expenseId, // 费用id
            pieceId: selectedData.pieceId, //
            costDetails: {
                price: editorForm.price,
                overMileageFee: editorForm.overMileageFee,
                overtimeFee: editorForm.overtimeFee,
                roadFee: editorForm.roadFee,
                parkingFee: editorForm.parkingFee,
                waitingFee: editorForm.waitingFee,
                serviceFee: editorForm.serviceFee,
                otherFee: editorForm.otherFee,
            }
        }
        setLoading(true)
        commonService.SavePiece({...params, ...ExtData}).then((res) => {
            if (res && res.success) {
                if (!autoMapping) {
                    if(fileList.length !== 0) {
                        mappingssFile(res.content);
                    }else {
                        reloadBillingDetail()
                    }
                    setLoading(false);
                } else {
                    reloadBillingDetail()
                }
            }
        })
    }
    const mappingssFile = (id) => {
        let params = {
            mappingData: {
                processId: selectedData.orderId,
                actionId: selectedData.requestId,
                itemid: selectedData.itemId,
                relatedId: id,
                catalog: "行车轨迹"
            },
            fileKeys: fileList.map(item => item.fileKey),
        }
        commonService.mapping(params).then((res) => {
            if (res && res.success) {
                reloadBillingDetail()
            }
        })
    }
    const reloadBillingDetail = () => {
        message.success('保存成功')
        setIsModalOpen(false);
        setLoading(false)
        setSelectedData({})
        props.updateChild()
    }
    const disabledStartDate = (sDateTime) => {
        let eDateTime = form.getFieldValue('eDateTime')
        if(!sDateTime || !eDateTime) {
            return false;
        }
        return new Date(moment(sDateTime._d).format("YYYY-MM-DD HH:mm:ss")).getTime() > new Date(moment(eDateTime._d).format("YYYY-MM-DD HH:mm:ss")).getTime();
    }
    const disabledEndDate = (eDateTime) => {
        let sDateTime = form.getFieldValue('eDateTime')
        if(!sDateTime || !sDateTime) {
            return false;
        }
        return new Date(moment(sDateTime._d).format("YYYY-MM-DD HH:mm:ss")).getTime() > new Date(moment(eDateTime._d).format("YYYY-MM-DD HH:mm:ss")).getTime();
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const addPassenger = () => {
        setPassengersList([...passengersList, { name: '', hospital: '' }]);
    };
    const selectMap = (sourceKey, sourceCity) => {
        mapEditor.current.show({
            sourceKey:sourceKey,
            sourceCity: sourceCity
        })
    }
    const removePassenger = (index) => {
        const updatedPassengersList = passengersList.filter((_, i) => i !== index);
        setPassengersList(updatedPassengersList);
    };

    const handleInputChange = (index, field, value) => {
        const updatedPassengersList = [...passengersList];
        updatedPassengersList[index][field] = value;
        setPassengersList(updatedPassengersList);
    };
    const updateForm = (data) => {
        const copyData = _.cloneDeep(ExtData);
        for (let val in data) {
            if(Object.keys(copyData).includes(val)) {
                copyData[val] = data[val];
            }
            form.setFieldValue(val, data[val])
        }
        setExtData(copyData);
    }
    const handleUnuseFee = () => {
        console.log(props);
        const params = {
            requestId: selectedData.requestId
        }
        commonService.Unuse(params).then(res => {
            if(res.success) {
                message.success('成功');
                setIsModalOpen(false);
                props.updateChild();
            }
        })
    }
    const getPrice = (val, name) => {
        let amount = val
        const editorForm = form.getFieldsValue()
        const valName = ['price', 'overMileageFee', 'overtimeFee', 'roadFee', 'parkingFee', 'waitingFee', 'serviceFee', 'otherFee',]
        valName.forEach(ele => {
            if (![name].includes(ele)) {
                amount = amount + Number(editorForm[ele] || 0)
            }
        })
        setAmount(amount)
    }
    const footerList = () => {
        if (selectedData && record) {
            return (<>
                <Button loading={loading} onClick={handleCancel}style={{marginLeft: '10px'}}> 取消 </Button>
                <Button type="primary" loading={loading} onClick={addPassenger}style={{marginLeft: '10px'}}> 添加乘车人 </Button>
                {[90].includes(record.status) ? null : <Button type="primary" loading={loading} onClick={handleUnuseFee} style={{backgroundColor: '#ffb52a', borderColor: '#ffb52a',marginLeft: '30px'}}> 未使用 </Button>}
                <Button type="primary" loading={loading} onClick={lossFee} danger style={{marginLeft: '10px'}}> 有损取消 </Button>
                {[51, 90].includes(record.status) ? null :  <Button type="primary" loading={loading} onClick={handleOk}style={{marginLeft: '10px'}}> 保存 </Button>}
            </>)
        } else {
            return (<>
                <Button type="primary" loading={loading} onClick={lossFee} danger> 有损取消 </Button>
            </>)
        }
    }
    return (
        <Modal title="录入明细" width={700} maskClosable={false} open={isModalOpen} onCancel={handleCancel} footer={null}>
            <Row gutter={[16, 24]}>
                <Form form={form} component={false} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} labelAlign="left">
                    <Col span={12}>
                        <Form.Item name='carModus' label='用车形式' style={{ margin: 0 }} rules={[ { required: isEdit, message: '请选择用车形式', }, ]}>
                            <Select style={{ width: '100%'}} options={carModusList} placeholder="请选择用车形式"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='carType' label='车型' style={{ margin: 0 }} rules={[ { required: isEdit, message: '请填写车型', }, ]}>
                            <Input style={{width:'100%'}} placeholder="请填写车型" disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='kilometers' label='公里数' style={{ margin: 0 }} rules={[ { required: isEdit, message: '请填写公里数', }, ]}>
                            <InputNumber style={{width: '100%'}} min={0} placeholder="请填写公里数"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='qty' label='乘车人数' style={{ margin: 0 }} rules={[ { required: isEdit, message: '请填写乘车人数', }, ]}>
                            <InputNumber style={{width: '100%'}} min={0} placeholder="请填写乘车人数"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='出发地' style={{ margin: 0 }} name='departure' rules={[ { required: isEdit, message: '请选择出发地', }, ]}>
                            <Input.Group compact style={{width: '100%'}}>
                                <Form.Item style={{ margin: 0, width: 'calc(100% - 32px)' }} name='departure' rules={[ { required: isEdit, message: '请选择出发地', }, ]}>
                                    <Input style={{ width: '100%' }} placeholder="请选择出发地" disabled/>
                                </Form.Item>
                                <Button onClick={() => selectMap('departure', 'sCity')} icon={<EnvironmentOutlined />} />
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='sDateTime' label='上车时间' style={{ margin: 0 }} rules={[ { required: isEdit, message: '请选择上车时间', }, ]}>
                            <DatePicker placeholder="请选择上车时间" format="YYYY-MM-DD HH:mm:ss" disabledDate={disabledStartDate} showTime/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='目的地' style={{ margin: 0 }} name='destination' rules={[ { required: isEdit, message: '请选择目的地', }, ]}>
                            <Input.Group compact style={{width: '100%'}}>
                                <Form.Item  style={{ margin: 0, width: 'calc(100% - 32px)' }} name='destination' rules={[ { required: isEdit, message: '请选择目的地', }, ]}>
                                    <Input style={{ width: '100%' }} placeholder="请选择目的地" disabled/>
                                </Form.Item>
                                <Button onClick={() => selectMap('destination', 'eCity')} icon={<EnvironmentOutlined />} />
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='eDateTime' label='下车时间' style={{ margin: 0 }} rules={[ { required: isEdit, message: '请选择下车时间', }, ]}>
                            <DatePicker placeholder="请选择下车时间" format="YYYY-MM-DD HH:mm:ss" disabledDate={disabledEndDate} showTime/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='licensePlate' label='车牌号' style={{ margin: 0 }} rules={[ { required: isEdit, message: '请填写车牌号', }, ]}>
                            <Input style={{width:'100%'}} placeholder="请填写车牌号" disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='fileList' label='行车轨迹' style={{ margin: 0 }} labelCol={{span: 4}} wrapperCol={{span: 18}} rules={[ { required: hasWheelPath && isEdit, message: '请上传行车轨迹', }, ]}>
                            <UploadFiles {...uploadProps} />
                        </Form.Item>
                    </Col>
                    <div>费用明细</div>
                    <Col span={24}>
                        <Form.Item name='price' label='单价' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: true, message: '请填写单价', }, ]}>
                            <InputNumber style={{width: '50%'}} min={0} placeholder="请填写单价" onChange={(val) => getPrice(val, 'price')}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='overMileageFee' label='超公里费' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: true, message: '请填写超公里费', }, ]}>
                            <InputNumber style={{width: '50%'}} min={0} placeholder="请填写超公里费" onChange={(val) => getPrice(val, 'overMileageFee')}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='overtimeFee' label='超时费' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: true, message: '请填写超时费', }, ]}>
                            <InputNumber style={{width: '50%'}} min={0} placeholder="请填写超时费" onChange={(val) => getPrice(val, 'overtimeFee')}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='roadFee' label='路桥费' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: true, message: '请填写路桥费', }, ]}>
                            <InputNumber style={{width: '50%'}} min={0} placeholder="请填写路桥费" onChange={(val) => getPrice(val, 'roadFee')}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='parkingFee' label='停车费' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: true, message: '请填写停车费', }, ]}>
                            <InputNumber style={{width: '50%'}} min={0} placeholder="请填写停车费" onChange={(val) => getPrice(val, 'parkingFee')}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='waitingFee' label='等待费' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: true, message: '请填写等待费', }, ]}>
                            <InputNumber style={{width: '50%'}} min={0} placeholder="请填写等待费" onChange={(val) => getPrice(val, 'waitingFee')}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='serviceFee' label='接机/接站服务费' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: true, message: '请填写接机/接站服务费', }, ]}>
                            <InputNumber style={{width: '50%'}} min={0} placeholder="请填写接机/接站服务费" onChange={(val) => getPrice(val, 'serviceFee')}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='otherFee' label='其它费用' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: true, message: '请填写其它费用', }, ]}>
                            <InputNumber style={{width: '50%'}} min={0} placeholder="请填写其它费用" onChange={(val) => getPrice(val, 'otherFee')}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label='费用合计' style={{ margin: 0 }}>
                            {amount}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='descr' label='供应商备注' style={{ margin: 0 }} labelCol={{span: 6}} wrapperCol={{span: 16}} rules={[ { required: !isEdit, message: '请填写供应商备注', }, ]}>
                            <TextArea rows={4} style={{width: '100%'}} placeholder="请填写供应商备注"/>
                        </Form.Item>
                    </Col>
                    {passengersList.length && passengersList.map((passenger, index) => (
                        <Row key={`passenger-${index}`} style={{width: '100%'}} className="info_center">
                            <Col span={11}>
                                <Form.Item label="乘车人" name={['passengersList', index, 'name']} rules={[{ required: isEdit, message: '请填写乘车人!' }]}>
                                    <Input placeholder="请填写乘车人" value={passenger.name} onChange={(e) => handleInputChange(index, 'name', e.target.value)}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="乘车人公司" name={['passengersList', index, 'hospital']} rules={[{ required: isEdit, message: '请填写乘车人公司!' }]}>
                                    <Input placeholder="请填写乘车人公司" value={passenger.hospital} onChange={(e) => handleInputChange(index, 'hospital', e.target.value)}/>
                                </Form.Item>
                            </Col>
                            <Col span={2} className="remove-icon-box">
                                {passengersList.length > 1 && (
                                    <MinusCircleOutlined onClick={() => removePassenger(index)}/>
                                )}
                            </Col>
                        </Row>
                    ))}
                </Form>
            </Row>
            <Row className="info_center">
                <Col span={24} style={{textAlign:'right'}}>
                    {footerList()}
                </Col>
            </Row>
            <MapEditor ref={mapEditor} updateForm={updateForm}></MapEditor>
        </Modal>
    )
})
export default ItinerarySettlement
