import React, { useState, useEffect } from 'react';
import commonService from '@/service/common';
import XUpload from '@/components/Upload/XUpload';
import styles from './finance.module.less';
import { useGeneric } from '@/controllers/useGeneric';

// 财务文件列表
const FilePro = (props) => {
    const { requestVal, moduleItem } = props;
    const { replaceEvalObject } = useGeneric();
    const [fileList, setFileList] = useState(null);
    const getInvoiceQuery = () => {
        let replaceResult = replaceEvalObject(moduleItem, requestVal);
        let params = {
            filterData: replaceResult?.uploadParmas.filterData,
        };
        commonService.QueryFiles(params).then((res) => {
            if (res.success) {
                let files = res.content.map((item, index) => {
                    item.name = item.fileName;
                    item.uid = index;
                    // 非图片类型的文件需要替换成默认的图片
                    switch (item.fileType.toUpperCase()) {
                        case '.PDF':
                            item.thumbUrl =
                                'https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/public/PDF.png';
                            break;
                        case '.EXCEL':
                        case '.XlSX':
                            item.thumbUrl =
                                'https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/public/xlsx.png';
                            break;
                        case '.WORD':
                            item.thumbUrl =
                                'https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/public/doc.png';
                            break;
                        default:
                            item.thumbUrl = item.url;
                            break;
                    }
                    item.status = 'done';
                    return item;
                });
                setFileList(files);
            }
        });
    };
    const handleChangeCallback = (file) => {
        setFileList(file.fileList);
        if (file.file.status === 'done') {
            mappingFile(file);
        }
    };
    const mappingFile = (files) => {
        let replaceResult = replaceEvalObject(moduleItem, requestVal);
        let parmas = {
            fileKeys: [files.file.response.content.fileKey],
            mappingData: replaceResult?.uploadParmas.mappingData,
        };
        commonService.mapping(parmas).then((res) => {
            if (res.success) {
                getInvoiceQuery();
            }
        });
    };
    useEffect(() => {
        getInvoiceQuery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={styles.module_content}>
            <div className={styles.module_title}>{moduleItem.title}</div>
            <XUpload
                fileList={fileList}
                handleChangeCallback={handleChangeCallback}
            />
        </div>
    );
};

export default FilePro;
