const UPDATEMENUTITLE = "updateMenuTitle";
const UPDATEURL = "updateUrl";
const alipayList ="alipayList"
const alipayHeader="alipayHeader"
const enterSearch ="enterSearch"
const tableLoading="tableLoading"

export {
    UPDATEMENUTITLE,
    UPDATEURL,
    alipayList,
    alipayHeader,
    enterSearch,
    tableLoading
}