import React, { useEffect, useState } from "react";
import { Descriptions, Card, Button } from "antd";
import "./index.less";
import commonService from "@/service/common";
const SmartPay = (props) => {
  const [btnList, setBtnList] = useState([]);
  const QueryTaskActions = async () => {
    let parame = {
      taskId: props.requestVal.taskId,
    };
    const res = await commonService.QueryTaskActions(parame);
    if (res && res.success) {
      setBtnList(res.content);
    }
  };
  const handleBtn = (item) => {
    console.log(item);
  };
  useEffect(() => {
    console.log(props);
    QueryTaskActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestVal]);
  return (
    <div>
      <Descriptions title="会议消息">
        <Descriptions.Item label="Product">Cloud Database</Descriptions.Item>
        <Descriptions.Item label="Billing">Prepaid</Descriptions.Item>
        <Descriptions.Item label="time">18:00:00</Descriptions.Item>
        <Descriptions.Item label="Amount">$80.00</Descriptions.Item>
        <Descriptions.Item label="Discount">$20.00</Descriptions.Item>
        <Descriptions.Item label="Official">$60.00</Descriptions.Item>
      </Descriptions>
      <Card title="订单信息" className="smartPayOrderInfo">
        <span>提交人:{props.requestVal.event_OwnerName}</span>
        <span>提交人电话:{props.requestVal.event_OwnerMobile}</span>
        <span>商户名称:{props.requestVal.item_ShopName}</span>
        <p>商户地址：{props.requestVal.expense_PaySelect}</p>
        <p>金额:{props.requestVal.expense_OriginAmount}</p>
        <p>实际金额:</p>
        <span>支付方式:{props.requestVal.expense_PaySelect}</span>
        <span>支付模式:</span>
        <p>支付二维码:</p>
        <p>用餐账单:</p>
        <p>支持文件:</p>
      </Card>
      <div style={{ margin: "20px 0", textAlign: "right" }}>
        {btnList?.map((item, bindex) => {
          return (
            <Button
              key={bindex}
              onClick={(e) => handleBtn(item)}
              type="primary"
              style={{ marginLeft: "10px" }}
            >
              {item.actName}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default SmartPay;
