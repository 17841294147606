import React from "react";
import styles from "../FinanceWorkPanel/FinanceWorkPanel.module.less";
const ThirdReconciliation = () => {
    const microUrl = process.env.REACT_APP_FINANCE_WORK_PANEL_URL;
    return (
        <div className={styles.orderContainer}>
            <micro-app
                name="ThirdReconciliation"
                url={microUrl}
                disableScopecss='false'
                baseroute="/micro-apps"
            ></micro-app>
        </div>
    );
};

export default ThirdReconciliation;
