import React, {useEffect, useState, useImperativeHandle, forwardRef} from "react";
import {Modal, Button, message, Row, Form} from "antd";
import SmartQueryReact from "smart-iquery-react";
import LogModal from "../../LogModal";
import QrcodeModal from "../QrcodeModal";
import {SmartStorage} from "@/utils";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import commonService from "@/service/common";
import XFormItem from "@/components/FormItem";
import innerBind from "@/assets/mock/invoiceScan/innerBind.json";

const {confirm} = Modal;
const QueryModal = (props, ref) => {
    console.log(props, "genericQueryModal");
    const iSignalr = process.env.REACT_APP_SIGNALER;
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    // const [queryObject, setQueryObject] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [listData, setListData] = useState(null);
    const [logModalVisable, setLogModalVisable] = useState(false);
    const [logModalTilte, setLogModalTitle] = useState("添加沟通记录");
    const [editLogInfo, setEditLogInfo] = useState({});
    const [logType, setlogType] = useState("add");
    const [handleMethodData, setHandleMethodData] = useState(null);
    // 二维码弹窗状态
    const [qrcodeVisable, setQrcodeVisable] = useState(false);
    const [qrcodeData, setQrcodeData] = useState({});
    const [form] = Form.useForm();
    // const [bindCode,setBindCode] = useState('');
    // var bindCode = "";
    var selectedKeys = SmartStorage.get("selectedKeys");
    useImperativeHandle(ref, () => ({
        freshQuery
    }))
    const setQueryModalVisible = async () => {
        // let selectedKeys = SmartStorage.get("selectedKeys");
        switch (selectedKeys) {
            case "/report/invoice-inside-bind":
                innerConfirmBind();
                break;
            case "/report/invoice-boxed":
                platformAddBind();
                break;
            case "/report/invoice-platform-confirm":
                confirmAssociation();
                break;
            case "/report/invoice-platform-bind":
                confirmBind();
                break;
            case "/report/shop-library":
                confirmContractPush();
                break;
            default:
                props.queryModalVisibleCallback({type: "cancel"});
                break;
        }
    };
    const rowMenuHandleClick = (e, record, item) => {
        console.log(e);
        switch (item.actionCode) {
            case "addRecord":
                setLogModalTitle("编辑沟通记录");
                setLogModalVisable(true);
                setEditLogInfo(record);
                setlogType("edit");
                break;
            default:
                props.rowMenuHandleClick(e, record, item);
                break;
        }
    };
    const headerBtnHandleClick = (btn, item, items) => {
        console.log(btn, "btn");
        // console.log(item, "item");
        // console.log(items, "items");
        // console.log(props.selectQueryModalData);
        switch (btn.actionCode) {
            case "addRecord":
                setLogModalVisable(true);
                setLogModalTitle("添加沟通记录");
                setlogType("add");
                break;

            default:
                // 理论上这里不应该混合业务代码，要嘛继续加配置走其他判断
                // 兼容配置，gcar客服台-会议结算-结算明细-确认会议完成，传递proposalId进去
                if(['SendMeetingConfirmMsg', 'SendMiceFinishMessage'].some(i => btn.parameters.api.indexOf(i) > -1)){
                    btn.parameters.body.ProposalId = props.selectQueryModalData.proposalId;
                }
                props.headerBtnHandleClick(btn, item, items);
                break;
        }
    };
    // 沟通记录保存
    const saveLog = (type, obj) => {
        let requestObj = props.queryModalBtnData.Request;
        let defalutData = listData;
        let logList = [];
        if (type === "add") {
            logList = [...defalutData, obj];
        } else {
            logList = defalutData.map((item) => {
                if (item.id === obj.id) {
                    defalutData.txt = obj.txt;
                }
                return item;
            });
        }
        commonRequest("post", requestObj.api, logList);
    };
    const logModalCancel = () => {
        setLogModalVisable(false);
    };
    // 合同推送
    const confirmContractPush = () => {
        let requestObj = props.queryModalBtnData.Request;
        commonRequest("post", requestObj.api, {
            rowId: props.selectQueryModalData.rowId,
            ids: selectedRowKeys,
        });
    };
    // 平台发票查询-关联发票-确认关联
    const confirmAssociation = () => {
        let requestObj = props.queryModalBtnData.Request;
        if (selectedRows.length < 1) {
            message.info("请先勾选数据");
        } else {
            for (let key in requestObj.request) {
                if (key === "invoiceIdList") {
                    requestObj.request[key] = [props.selectQueryModalData.invoiceId];
                } else if (key === "relateType") {
                    requestObj.request[key] = props.selectQueryModalData[key];
                } else {
                    requestObj.request[key] = selectedRows[0][key];
                }
            }
            commonRequest("post", requestObj.api, requestObj.request);
        }
    };
    // 平台发票装订-装订-确认装订
    const confirmBind = () => {
        let requestObj = props.queryModalBtnData.Request;
        if (selectedRows.length < 1) {
            message.info("请先勾选数据");
        } else {
            for (let key in requestObj.request) {
                requestObj.request[key] = selectedRows[0][key];
            }
            commonRequest("post", requestObj.api, requestObj.request);
        }
    };
    // 后端参数接口地址调用
    const commonRequest = (method, api, params) => {
        commonService.common(method, api, params).then((reslut) => {
            if (reslut.success) {
                if (props.queryModalBtnData?.actionName === "查看沟通记录") {
                    setLogModalVisable(false);
                    freshQuery();
                } else {
                    message.success(reslut.msg || "操作成功");
                    if (
                        ![
                            "/report/invoice-platform-bind",
                            "/report/invoice-inside-bind",
                        ].includes(selectedKeys)
                    ) {
                        props.queryModalVisibleCallback({type: "cancel"});
                    } else {
                        setQrcodeVisable(true);
                        setQrcodeData(reslut.content);
                    }
                }
            }
        });
    };
    const freshQuery = () => {
        const rnd = new Date().getTime();
        setHandleMethodData(rnd);
    };
    // 内部发票装订-装订-确认装订
    const innerConfirmBind = () => {
        let requestObj = props.queryModalBtnData.Request;
        form
            .validateFields()
            .then((values) => {
                for (let key in requestObj.request) {
                    if (key === "bindCode") {
                        requestObj.request[key] = values.bindCode;
                    } else if (key === "projectIds") {
                        requestObj.request[key] = JSON.parse(listData[0][key]);
                    } else {
                        requestObj.request[key] = listData[0][key];
                    }
                }
                commonRequest("post", requestObj.api, requestObj.request);
            })
            .catch((errorInfo) => {
                console.log(errorInfo, "err");
            });
    };
    // 平台发票封箱-添加装订
    const platformAddBind = () => {
        console.log("111111");
        if (selectedRows.length < 1) {
            message.info("请勾选装订号");
            return;
        }
        confirm({
            title: "是否确认" + props.queryModalBtnData.actionName,
            icon: <ExclamationCircleOutlined/>,

            onOk() {
                let requestObj = props.queryModalBtnData.Request;
                for (let key in requestObj.request) {
                    if (key === "boxId") {
                        requestObj.request[key] = props.selectQueryModalData[key];
                    } else {
                        requestObj.request[key] = selectedRowKeys;
                    }
                }
                commonRequest("post", requestObj.api, requestObj.request);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };
    // 二维码关闭弹窗
    const qrcodeCancel = (status) => {
        setQrcodeVisable(status);
        props.queryModalVisibleCallback({type: "cancel"});
    };
    const getListData = (data) => {
        setListData(data);
    };
    useEffect(() => {
    }, [props.queryModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps
    // 选择行数中的id
    const returnSelectedRows = (rowsId, rows) => {
        setSelectedRowKeys(rowsId);
        setSelectedRows(rows);
    };
    // const getQueryObject = (res) => {
    //   setQueryObject(res);
    // };
    return (
        <Modal
            title={props.queryModalTitle || "数据详情"}
            centered
            destroyOnClose={true}
            visible={props.queryModalVisible}
            maskClosable
            onCancel={() => {
                props.queryModalVisibleCallback();
            }}
            footer={
                [
                    "封箱详情",
                    "添加沟通记录",
                    "快递详情",
                    "查看沟通记录",
                    "项目详情",
                ].includes(props.queryModalBtnData?.actionName)
                    ? []
                    : [
                        <Button key="back" type="primary" onClick={setQueryModalVisible}>
                            {props.queryModalBtnData?.Request?.Txt || "确定"}
                        </Button>,
                    ]
            }
            width={1200}
        >
            {props.queryModalBtnData.kind === "inner" && (
                <Row>
                    {/* <Col span={6} className={styles.bind}>
            <Input
              placeholder="请输入装订号"
              onChange={(e) => {
                bindCode = e.target.value;
              }}
            />
          </Col> */}
                    <Form form={form} layout="horizontal" autoComplete="off">
                        <XFormItem itemList={innerBind}></XFormItem>
                    </Form>
                </Row>
            )}
            {logModalVisable && (
                <LogModal
                    modalTitle={logModalTilte}
                    modalVisable={logModalVisable}
                    selectQueryModalData={props.selectQueryModalData}
                    editLogInfo={editLogInfo}
                    logType={logType}
                    saveLog={saveLog}
                    logModalCancel={logModalCancel}
                ></LogModal>
            )}
            {qrcodeVisable && (
                <QrcodeModal
                    qrcodeVisable={qrcodeVisable}
                    qrcodeData={qrcodeData}
                    qrcodeCancel={qrcodeCancel}
                ></QrcodeModal>
            )}
            <div className={props.isModalTable&&"queryModalBox"}>
            <SmartQueryReact
                gatewayPath={gatewayPath}
                rowMenuHandleClick={rowMenuHandleClick}
                handleMethod={handleMethodData}
                iSignalr={iSignalr}
                isModalTable={props.isModalTable}
                // getQueryObject={getQueryObject}
                getListData={getListData}
                headerBtnHandleClick={headerBtnHandleClick}
                getQueryData={props.queryModalData}
                returnSelectedRows={returnSelectedRows}
            ></SmartQueryReact>
            </div>
            
        </Modal>
    );
};
export default forwardRef(QueryModal);
