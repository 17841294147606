import React from 'react';
import { Col } from 'antd';

import style from './index.less'

const xSubTitle = (props) => {

    const item = props.item

    return (
        <>
            <Col span={item.span} >
                <div className={style.title}>{ item.title }</div>
            </Col>
        </>
      )
}

export default xSubTitle
