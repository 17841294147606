import React from "react";
import { Modal, Form, message } from "antd";
import XFormItem from "@/components/FormItem";
import itemList from "@/assets/mock/mopDesc.json";
import commonService from "@/service/common";

const ModalDesc = (props) => {
  const { info, modalOpen, iqueryRow } = props;
  const [form] = Form.useForm();
  const confirm = (type) => {
    form.validateFields().then((values) => {
      let parmas = {
        taskId: iqueryRow.taskId,
        actionId: info.actionId,
        ...values,
      };
      commonService.performTask(parmas).then((res) => {
        if (res && res.success) {
          message.success("操作成功" || res.msg);
          setTimeout(() => {
            props.cancelModal(type);
          }, 1000)
        }
      });
    });
  };
  const cancel = (type) => {
    props.cancelModal(type);
  };
  return (
    <Modal
      title={`${info.actName}原因`}
      visible={modalOpen}
      onOk={() => {
        confirm("ok");
      }}
      onCancel={() => {
        cancel("no");
      }}
    >
      <Form form={form}>
        <XFormItem itemList={itemList}></XFormItem>
      </Form>
    </Modal>
  );
};
export default ModalDesc;
