import React,{ useState } from "react";
import Viewer from "react-viewer";
// import "react-viewer/dist/index.css"
import "./index.less";
import { localDownloadFile } from "@/controllers/downloadFile";

const FileViewer = (props) => {
  // 支持图片文件类型(在以下类型中，即判断该文件为图片)
  const imgFileTypes = ["img", "jpg", "jpeg", "jfif", "gif", "bmp", "png"];
  // 是否显示预览图片
  const [isShowViewer, setIsShowViewer] = useState(false);

  // 组件渲染
  return (
    <div className="file-viewer">
      {props.defaultFiles.map((item, index) => (
        <div
          key={index}
          className="file-view-item"
          onClick={(e) => {

            console.log('====文件信息', item)
            //文件类型不是图片则下载文件
            if (!/.(gif|jpg|jpeg|png|gif|jpg|png)$/.test(item.name)) {
              localDownloadFile(item.url);
            } else {
              let fileTypeStr = null;
              // 兼容传入fileType时，是否传入了 点(如传入是"img"或".png")
              if (/\./.test(item.fileType)) {
                fileTypeStr = item.fileType.substr(1).toLowerCase();
              } else {
                fileTypeStr = item.fileType.toLowerCase();
              }
              if (imgFileTypes.includes(fileTypeStr)) {
                setIsShowViewer(true);
              } else {
                window.open(item.url);
              }
            }
          }}
        >
          <div
            className="item-img"
            style={{
              width: props.imgWidth || undefined,
              height: props.imgHeight || undefined,
            }}
          >
            {imgFileTypes.includes(item.fileType.substr(1).toLowerCase()) ? (
              // 图片文件
              <img src={item.url} alt={item.fileName} />
            ) : (
              // 非图片文件
              <img
                src={`https://spkg.smartmice.cn/resources/images/public/${item.fileType
                  .substr(1)
                  .toLowerCase()}.png`}
                alt=""
              />
            )}
          </div>

          {/* 其他文件，如office系列文件等 */}
          {/* {[".doc", ".docx", ".docm"].includes(item.fileType) && (
            <XIconFont className="item-icon" type={"icon-WORD"} />
          )}
          {[".xls", ".xlsx"].includes(item.fileType) && (
            <XIconFont className="item-icon" type={"icon-ECEL"} />
          )}
          {[".eml"].includes(item.fileType) && (
            <XIconFont className="item-icon" type={"icon-EML"} />
          )}
          {[".pdf"].includes(item.fileType) && (
            <XIconFont className="item-icon" type={"icon-PDF"} />
          )} */}
          <div className="item-title">{item.name}</div>
        </div>
      ))}
      {/* 注意：这种方式，要求传入组件的文件列表必须所有FileType都一致，否则会报错 */}
      {/* 图片预览组件 */}
      <Viewer
        visible={isShowViewer}
        onClose={() => {
          setIsShowViewer(false);
        }}
        images={props.defaultFiles.map((item) => {
          return {
            src: item.url,
            alt: "",
          };
        })}
      />
    </div>
  );
};

export default FileViewer;
