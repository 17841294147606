import React from 'react';
import { Form, Col } from 'antd';
import defaultSettings from './defaultSettings';
import FileViewer from '../FileViewer';

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings

const XCustomFileView = (props) => {

    const item = props.item
    // function defaultChangeClick(e, item, sData) {
    //     item.callBack && props.callback(e, item, sData);
    // }
    // 自定义表单控件
    const ImageView = ({ value, onChange }) => {
        // console.log("自定义表单组件参数：", value);
        let arr = value ? value.map(v => {
            return {
                name: "",  // 这里没有文件名，直接传空
                url: v,
                fileType: ".img",  // 图片。这里没有返回FileType，但肯定是图片文件
            }
        }) : []
        return (
            <FileViewer defaultFiles={arr} imgWidth={"36px"} imgHeight={"36px"} />
            // <ul className="custom-form-file-view">
            //     {value?.length > 0 ? value.map(item => (
            //         <li className="view-item">
            //             {/* <img src={item} alt="图片" /> */}
            //         </li>
            //     )) : (<li>暂无数据</li>)}
            // </ul>
        )
    }


    return (
        <>
            <Col span={item.span} >
                <Form.Item
                    name={item.name}
                    rules={item.rules}
                    labelCol={{ span: item.lcol || defaultLabelSpan }}
                    wrapperCol={{ span: item.wcol || defaultWrapperSpan }}
                    label={item.label}
                    hidden={item.hidden}
                    labelAlign="right"
                >
                    <ImageView />
                </Form.Item>
                {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
            </Col>
        </>
    )
}

export default XCustomFileView
