import { request } from "./request";

const LectureManageService = {
  // +会议列表表头
  getJGridSettings: (params) => {
    return request.post("/api/foundation/grid/GridSet/Get", params);
  },
  // +会议列表
  getJGridData: (params) => {
    return request.post("/api/foundation/grid/GridSet/GetGridData", params);
  },
  // +提交结算/隔离
  approval: (params) => {
    return request.post("/rpc/pay/Pay/Approval", params);
  },
   //+提交支付
   BatchTransfer: (params,headers) => {
    return request.post("rpc/pay/Pay/BatchTransfer", params,headers);
  },
  // 获取电子协议照片
  getAgreementPhoto:(params)=> {
    return request.post("foundation/aliyun/api/oss/query",params)
  }
};

export default LectureManageService;
