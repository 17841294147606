import React, {useEffect, useRef, useState} from "react";
import './CarInfoCard.less'
import {Button, Col, Descriptions, Form, Input, message, Modal, Row} from "antd";
import {SmartStorage} from "@/utils";
import moment from "moment";
import commonService from "@/service/common";
const InsurNotice = (props) => {
    const [form] = Form.useForm();
    const [taskInfo, setTaskInfo] = useState({});
    const [insurInfo, setInsurInfo] = useState({});
    const [isShowInsurInfo, setIsShowInsurInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (props?.miceInfo?.trips) {
            props?.miceInfo?.trips.map(ele => {
                if ([ele.requestId].includes(SmartStorage.get('detailRow').tripId)) {
                    setTaskInfo(ele)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    const updateChild = () => {
        props.updateChild()
    }
    const getInsuranceByTripId = () => {
        Modal['confirm']({
            title: "请确认",
            okText: "确认",
            cancelText: "我再想想",
            content: "请再次确认是否人工投保！",
            onOk() {
                let params = {
                    tripId: taskInfo.requestId
                }
                commonService.GetInsuranceByTripId(params).then((res) => {
                    if (res && res.success) {
                        setInsurInfo(res.content)
                        setIsShowInsurInfo(true)
                    }
                });
            },
            onCancel() {},
        });
    }
    const cancel = () => {
        window.close();
    }
    const handleOk = () => {
        Modal['confirm']({
            title: "已完成",
            okText: "确认",
            cancelText: "我再想想",
            content: "请再次确认该待办信息填写完成！",
            onOk() {
                form.validateFields().then((values) => {
                    modifyPolicyNum(values)
                })
            },
            onCancel() {},
        });
    };
    const modifyPolicyNum = (data) => {
        let params = {
            tripId: taskInfo.requestId,
            policyNo1: data.policyNo1,
            policyNo2: data.policyNo2,
        }
        setLoading(true)
        commonService.modifyPolicyNum(params).then(res => {
            if (res && res.success && res.code !== '1') {
                message.success('新增明细成功');
                window.close();
                props.updateChild()
            }else {
                message.error(res.message);
            }
            setLoading(false)
        })
    }
    return (
        <>
            <div className="boderRow carDetails">
                <div className="carTitle">
                    <div className="card_title">{props.moduleItem?.queryName}</div>
                </div>
                <Row>
                    <Col>
                        <Descriptions column={3}>
                            <Descriptions.Item label="行程号">
                                {taskInfo.tripNo}
                            </Descriptions.Item>
                            <Descriptions.Item label="用车时间">
                                {moment(taskInfo.dtStart).format('YYYY-MM-DD')}
                            </Descriptions.Item>
                            <Descriptions.Item label="机场">
                                {taskInfo.airport}
                            </Descriptions.Item>
                            <Descriptions.Item label="目的地">
                                {taskInfo.departure}
                            </Descriptions.Item>
                            <Descriptions.Item label="出发地">
                                {taskInfo.destination}
                            </Descriptions.Item>
                            <Descriptions.Item label="行程状态">
                                {taskInfo.statusTxt}
                            </Descriptions.Item>
                            <Descriptions.Item label="司机信息">
                                {taskInfo?.driver?.name} ({taskInfo?.driver?.phone})
                            </Descriptions.Item>
                            <Descriptions.Item label="车型">
                                {taskInfo?.driver?.carModel}
                            </Descriptions.Item>
                            <Descriptions.Item label="车牌号">
                                {taskInfo?.driver?.licensePlate}
                            </Descriptions.Item>
                            <Descriptions.Item label="行程/订单ID">
                                {taskInfo?.tripNo}
                            </Descriptions.Item>
                            <Descriptions.Item label="失败原因" span={2}>
                                {SmartStorage.get('detailRow')['失败原因']}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </div>
            <div className="boderRow carDetails">
                <div className="carTitle">
                    <div className="card_title">保险信息</div>
                    {!isShowInsurInfo ? <div className="BtnList">
                        <Button type="primary" style={{marginLeft: '20px'}} onClick={getInsuranceByTripId}>人工投保</Button>
                    </div> : null}
                </div>
                {isShowInsurInfo && (<>
                    <Row>
                        <Col>
                            <Descriptions column={3}>
                                <Descriptions.Item label="起止时间">
                                    {moment(insurInfo.beginDate).format('YYYY-MM-DD')} ~ {moment(insurInfo.endDate).format('YYYY-MM-DD')}
                                </Descriptions.Item>
                                <Descriptions.Item label="团号">
                                    {insurInfo.tripNo}
                                </Descriptions.Item>
                                <Descriptions.Item label="保单类型">
                                    个保
                                </Descriptions.Item>
                                <Descriptions.Item label="投被保关系">
                                    投保人为企业
                                </Descriptions.Item>
                                <Descriptions.Item label="公司名称">
                                    {insurInfo.invoiceTitle}
                                </Descriptions.Item>
                                <Descriptions.Item label="信用代码">
                                    {insurInfo.invoiceNum}
                                </Descriptions.Item>
                                <Descriptions.Item label="姓名">
                                    不记名
                                </Descriptions.Item>
                                <Descriptions.Item label="性别">
                                    男
                                </Descriptions.Item>
                                <Descriptions.Item label="证件类型">
                                   其他
                                </Descriptions.Item>
                                <Descriptions.Item label="证件号型">
                                    {insurInfo.carNum}
                                </Descriptions.Item>
                                <Descriptions.Item label="出生日期" span={2}>
                                    1990-01-01
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col>
                            <Form form={form} component={false} layout="horizontal" labelAlign="left">

                                    <Form.Item name='policyNo1' label='保单号1' style={{ margin: 0 }} rules={[ { required: true, message: '请填写保单号1', }, ]}>
                                        <Input style={{ width: '100%' }} placeholder="请填写保单号1"/>
                                    </Form.Item>


                                    <Form.Item name='policyNo2' label='保单号2' style={{ margin: 0 }} rules={[ { required: true, message: '请填写保单号2', }, ]}>
                                        <Input style={{ width: '100%' }} placeholder="请填写保单号2"/>
                                    </Form.Item>

                            </Form>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                        <div style={{textAlign: 'right', margin: '10px'}}>
                            <Button style={{marginRight: '20px'}} onClick={cancel}> 取消 </Button>
                            <Button type="primary" loading={loading} onClick={handleOk}> 已处理 </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default InsurNotice;
