import React from 'react';
import { Form, Col, Input } from 'antd';
import defaultSettings from './defaultSettings'

const {defaultLabelSpan,defaultWrapperSpan} = defaultSettings

const { TextArea } = Input

const xTextArea = (props) => {

    const item = props.item

    function defaultChangeClick(e, item, sData) {
        item.callBack && props.callback(e, item, sData);
    }

    return (
        <>
            <Col span={item.span} >
                <Form.Item
                    name={item.name}
                    rules={item.rules}
                    labelCol={item.lcol || { span: defaultLabelSpan }}
                    wrapperCol={item.wcol || { span: defaultWrapperSpan }}
                    label={item.label}
                    labelAlign="right"
                >
                <TextArea
                    disabled={item.disabled && true}
                    autoSize={item.autoSize}
                    placeholder={item.placeholder}
                    onChange={(e) => (defaultChangeClick(e, item))}
                />
                </Form.Item>
            </Col>
        </>
      )
}

export default xTextArea
