import React, {useEffect, useRef, useState} from "react";
import commonService from "@/service/common";
import "../../MopModules/modules.less";
import {Button, message, Popconfirm} from "antd";
import VenueTable from "./VenueTable";
import MealTable from "@/components/DetailDrawer/compments/MopModules/HoteDemand/MealTable";
import RoomTable from "@/components/DetailDrawer/compments/MopModules/HoteDemand/RoomTable";
import OtherTable from "@/components/DetailDrawer/compments/MopModules/HoteDemand/OtherTable";
import QRCodeModal from "@/components/DetailDrawer/compments/MopModules/HoteDemand/QRCodeModal";

const SupplierItem = (props) => {
    const [venues, setVenues] = useState([]);
    const [meals, setMeals] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [others, setOthers] = useState([]);
    const [invoiceTypeList, setInvoiceTypeList] = useState([]);
    const [defaultParams, setDefaultParams] = useState({});
    const [itsRooms, setItsRooms] = useState([]);
    const [itsVenues, setItsVenues] = useState([]);
    const [isGsk, setIsGSK] = useState(false)
    const qRCodeModal = useRef(null);
    useEffect(() => {
        setIsGSK(['2a0edd6a-b7f9-11ed-8b12-26d545d4edc5'].includes(props.listItem.tenantId))
        if (props.listItem.venues) {
            let venueInfo = props.listItem.venues && props.listItem.venues.filter(ele => {
                return ['酒店会场'].includes(ele.itemTypeTxt);
            });
            let teaList = props.listItem.venues.filter(ele => {
                return ['会场茶歇'].includes(ele.itemTypeTxt);
            });
            venueInfo.forEach(ele => {
                ele.teaList = teaList.filter(li => {
                    return ele.itemId === li.itemId;
                });
            });
            setVenues(venueInfo)
        }
        setMeals(props.listItem.meals || [])
        setRooms(props.listItem.rooms || [])
        setOthers(props.listItem.others || [])
        let params = {
            tenantId: props.listItem.tenantId,
            processId: props.listItem.processId,
            supplierId: props.listItem.supplierId,
            orderId: [props.listItem.orderId].includes(props.listItem.processId) ? null : props.listItem.orderId,
            taskStatus: props.listItem.supplierInfo?.taskStatus,
            isOffline: props.listItem.isOffline,
            inWhiteList: props.listItem.inWhiteList,
        }
        setDefaultParams(params)
        getVenueSetting()
        getAuditOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listItem]);
    const venueQrcode = () => {
        qRCodeModal.current.showModal(Object.assign(props.listItem.supplierInfo, {
            tenantId: props.listItem.tenantId,
            proposalId: props.listItem.processId,
        }))
    }
    const getAuditOptions = () => {
        let params = {
            collection: 'cfg-supplier-options-Xcenter',
            useCaching: false,
            cachingKey: '',
            filter: {
                tenantCode: { '$in': ['*', isGsk ? 'GSK' : '*'] },
                tenantId: { '$in': ['*', props.listItem.tenantId] },
            },
            projection: {
                options: 1
            }
        }
        commonService.GetSettings(params).then(res => {
            if (res && res.success) {
                setInvoiceTypeList(res.content.options)
            }
        })
    }
    const getVenueSetting = () => {
        let params = {
            proposalId: props.listItem.processId,
            itemId: props.listItem.items[0].itemId,
            supplierId: props.listItem.supplierId
        }
        commonService.GetItemQuote(params).then(res => {
            if (res && res.success) {
                setItsRooms(res.content.itsRooms)
                setItsVenues(res.content.itsVenues)
            }
        })
    }
    const NoResource = () => {
        let params = {
            ProcessId: props.listItem.processId,
            SupplierId: props.listItem.supplierId
        }
        commonService.NoResource(params).then(res => {
            if (res && res.success) {
                props.GetF1QuotedPriceTaskList()
                message.success("操作成功");
            }
        })
    }
    return (
        <>
            <div className="hotel-box">
                <div className="hotel-title">
                    <p>
                        <span>{props.listItem.supplierName}</span>
                        {props.listItem.payModeTxt && (<span>【{props.listItem.payModeTxt}】</span>)}
                        {props.listItem.statusTxt && (<span>【{props.listItem.statusTxt}】</span>)}
                        <span>【{props.listItem.isOffline ? '非平台酒店' : '平台酒店'}】</span>
                    </p>
                    { props.listItem.isOffline && props.isQuote && (<div className="quoteBtn">
                        <Popconfirm title="是否确认无资源?" onConfirm={() => NoResource()}>
                            <Button type="primary" style={{marginRight: '20px'}} danger>无资源</Button>
                        </Popconfirm>
                        <Button type="primary" onClick={venueQrcode}>报价二维码</Button>
                    </div>)}
                </div>
                {!props.listItem.isOffline && (
                    <div className="hotel-title">
                        <p>
                            <span>联系人：</span>
                            {props.listItem.contacter && (<span>{props.listItem.contacter}</span>)}
                            {props.listItem.contactMobile && (<span>（{props.listItem.contactMobile}）</span>)}
                        </p>
                    </div>
                )}
                <div className="quoteAmount">
                    总报价：￥{props.listItem.quote}
                </div>
                {venues.length > 0 && venues.map((item, index) => (
                    <div key={index} style={{marginTop:  '20px'}}>
                        <p style={{ fontWeight: 'bold' }}>酒店会场</p>
                        <VenueTable isGsk={isGsk} listItem={item} isQuote={props.isQuote} isOffline={props.listItem.isOffline} invoiceTypeList={invoiceTypeList}
                                    GetF1QuotedPriceTaskList={props.GetF1QuotedPriceTaskList} defaultParams={defaultParams} itsVenues={itsVenues}/>
                    </div>
                ))}
                {meals.length > 0 && meals.map((item, index) => (
                    <div key={index} style={{marginTop: '20px'}}>
                        <p style={{ fontWeight: 'bold' }}>酒店用餐</p>
                        <MealTable isGsk={isGsk} listItem={item} isQuote={props.isQuote} isOffline={props.listItem.isOffline} invoiceTypeList={invoiceTypeList}
                                   GetF1QuotedPriceTaskList={props.GetF1QuotedPriceTaskList} defaultParams={defaultParams}/>
                    </div>
                ))}
                {rooms.length > 0 && rooms.map((item, index) => (
                    <div key={index} style={{marginTop: '20px'}}>
                        <p style={{ fontWeight: 'bold' }}>酒店住宿</p>
                        <RoomTable isGsk={isGsk} listItem={item} isQuote={props.isQuote} isOffline={props.listItem.isOffline} invoiceTypeList={invoiceTypeList}
                                   GetF1QuotedPriceTaskList={props.GetF1QuotedPriceTaskList} defaultParams={defaultParams} itsRooms={itsRooms}/>
                    </div>
                ))}
                {others.length > 0 && others.map((item, index) => (
                    <div key={index} style={{marginTop: '20px'}}>
                        <p style={{ fontWeight: 'bold' }}>酒店其他</p>
                        <OtherTable isGsk={isGsk} listItem={item} isQuote={props.isQuote} isOffline={props.listItem.isOffline} invoiceTypeList={invoiceTypeList}
                                    GetF1QuotedPriceTaskList={props.GetF1QuotedPriceTaskList} defaultParams={defaultParams}/>
                    </div>
                ))}
            </div>
            <QRCodeModal ref={qRCodeModal}></QRCodeModal>
        </>
    );
};

export default SupplierItem;
