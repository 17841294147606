import React, { useState, useEffect } from "react";
import { SmartStorage } from "@/utils";
import commonService from "@/service/common";
import CarInfoCard from "@/components/DetailDrawer/compments/CarSupplierModules/CarInfoCard";
import CarDemand from "@/components/DetailDrawer/compments/CarSupplierModules/carDemand";
import CarBillingDetail from "@/components/DetailDrawer/compments/CarSupplierModules/CarBillingDetail";
import CarDetailBtn from "@/components/DetailDrawer/compments/CarSupplierModules/CarDetailBtn";
import InsureDemand from "@/components/DetailDrawer/compments/CarSupplierModules/InsureDemand";
import TravelDetails from "@/components/DetailDrawer/compments/CarSupplierModules/TravelDetails";
import InsurNotice from "@/components/DetailDrawer/compments/CarSupplierModules/InsurNotice";
import "./index.less";

const components = {
  CarInfoCard,
  CarDemand,
  CarBillingDetail,
  CarDetailBtn,
  InsureDemand,
  TravelDetails,
  InsurNotice
};

const DetailPage = () => {
  // 模块数组
  const [modules, setModules] = useState([]);
  const [btnList, setBtnList] = useState([]);
  const [taskInfo, setTaskInfo] = useState({});
  const [hasAddCarBillingBtn, setHasAddCarBillingBtn] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const getDetailMoudles = () => {
    let moudleParams = {
      collection: "cfg-web-modular",
      filter: {
        Application: "XCENTER",
        tenantId: "*",
        type: {
          $in: [SmartStorage.get("detailRow").nodeCode || '*'],
        },
        isNew: SmartStorage.get("detailRow").isNew,
        abbrCode: {
          $in: [SmartStorage.get("detailRow").nodeCode || '*'],
        },
      },
    };
    commonService.GetSettings(moudleParams).then((res) => {
      if (res && res.success) {
        setModules(res.content.modules);
        updateChild()
      }
    });
  };
  const updateChild = () => {
    if (['0'].includes(SmartStorage.get("detailRow").isNew)) {
      GetEOrder()
    } else {
      setIsNew(true)
      GetEOrderNew()
    }
  }
  const GetEOrderNew = () => {
    let params = {
      orderId: SmartStorage.get("detailRow").orderId,
      // orderId: '519deb54-f22e-44d1-a128-bd4635c3b8b6',
      isSupplier: true
    };
    commonService.GetEOrderNew(params).then((res) => {
      if (res && res.success) {
        setTaskInfo(res.content);
        QueryF2TaskActions(res.content)
      }
    });
  }
  const GetEOrder = () => {
    let params = {
      filters: {
        orderId: SmartStorage.get("detailRow").orderId,
        IsApproval: "true"
      }
    };
    commonService.GetEOrder(params).then((res) => {
      if (res && res.success) {
        setTaskInfo(res.content);
        QueryF2TaskActions(res.content)
      }
    });
  }
  const QueryF2TaskActions = (data) => {
    let params = {
      displayType: "detail",
      taskId: data.taskId
    };
    commonService.QueryF2TaskActions(params).then((res) => {
      if (res && res.success) {
        let data = res.content
        data.map(ele => ele.extData = JSON.parse(ele.extData))
        let result = data.some(ele => ele.actCode == 'AddDetail')
        setHasAddCarBillingBtn(result)
        setBtnList(data.filter(ele => !['AddDetail'].includes(ele.actCode)))
      }
    });
  }
  useEffect(() => {
    getDetailMoudles();
  }, []);
  return (
    <div className="detailPage">
      {modules?.map((item, index) => {
        const TagName = components[item.comName];
        return (
          <TagName
            key={index}
            requestVal={SmartStorage.get("detailRow")}
            miceInfo={taskInfo}
            moduleItem={item}
            btnList={btnList}
            hasAddCarBillingBtn={hasAddCarBillingBtn}
            updateChild={updateChild}
          ></TagName>
        );
      })}
    </div>
  );
};

export default DetailPage;
