import React from "react";
import {Col, Tooltip} from 'antd'
const RiskHint = ({mapFileds, name}) => {
    const target = mapFileds.get(name);
    return (
        target ?
        <Col span={4} style={{display: 'flex'}} className={target.inBlackList? 'hint' : ['hint','hint_green']}>
                       <Tooltip title={target.inBlackList && target.message ? target.message : null}>
                            <i className="iconfont icon-xinxitishi"></i>
                       </Tooltip>
                        <div>{target.inBlackList ? '有' : '无'}风险</div>
        </Col> : null
    )
}

export default RiskHint;
