import React, { useEffect, useState } from "react";
import commonService from "@/service/common";
import "../../MopModules/modules.less";
import {Button, Table, Form, Input, InputNumber, Select, Modal, message} from "antd";
import { UploadFiles } from "smart-filer-react";

const MealTable = (props) => {
    const [form] = Form.useForm();
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [dataSource,setDataSource] = useState([]);
    const [childSource,setChildSource] = useState([]);
    const [OperationMode,setOperationMode] = useState([{txt: '是', val: true},{txt: '否', val: false}]);
    const [soFileList, setSoFileList] = useState([])
    const [isEdit,setIsEdit] = useState(false);
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [amount,setAmount] = useState(0);
    const [uploadProps, setUploadProps] = useState();
    const mappingData = {
        'ProcessId': props.defaultParams.processId,
        'ItemId': props.listItem.itemId,
        'SupplierId': props.defaultParams.supplierId,
        'catalog': '报价文件'
    };
    const setMappingData = (fileList) => {
        let updata = {
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            defaultFileList: fileList,
            showUploadList: {
                showPreviewIcon: true,
                showRemoveIcon: true,
            },
            isMapping: true,
            disabled: !isEdit,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                setSoFileList(fileList);
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    setSoFileList(fileList);
                }
            },
        };
        setUploadProps(updata);
        setIsModalOpen(true)
    };
    const { TextArea } = Input;
    useEffect(() => {
        let data = JSON.parse(JSON.stringify(props.listItem))
        data.key = '0'
        let unit = ['自助餐', '商务套餐'].includes(data.mealType) ? '位' : '位'
        if (data.expenses.length > 0) {
            let agreementPrice = ''
            if (typeof data.expenses[0].agreementPrice === 'boolean') {
                agreementPrice = data.expenses[0].agreementPrice ? '是' : '否'
            } else {
                agreementPrice = props.isGsk && !props.defaultParams.inWhiteList ? '是' : '否'
            }
            let childData = {
                qty: data.expenses[0].qty,
                price: data.expenses[0].price,
                minQty: data.expenses[0].minQty,
                amount: data.expenses[0].amount,
                outPrice: data.expenses[0].outPrice,
                invoiceType: data.expenses[0].invoiceType,
                taxRate: data.expenses[0].taxRate || '',
                czbjDescr: data.expenses[0].descr,
                serviceDescr: data.expenses[0].serviceDescr,
                unit: data.expenses[0].unit || unit,
                agreementPrice: agreementPrice,
            }
            setAmount(data.expenses[0].amount)
            setChildSource([childData])
            form.setFieldsValue(childData);
        }
        data.dtStart = data.dtStart ? data.dtStart.replace('T', ' ') : ''
        setDataSource([data])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listItem]);
    const editInfo = () => {
        setIsEdit(true)
    }
    const saveInfo = () => {
        form.validateFields().then((values) => {
            if (values.minQty > props.listItem.expenses[0].qty) {
                message.error('保底数量需在预计人数范围内')
                return
            }
            let taxRate = Number(values.taxRate) / 100;
            const valueMap = {
                "是": true,
                "否": false,
                true: true,
                false: false,
                "": ""
            };
            let agreementPrice = valueMap[values.agreementPrice] !== undefined ? valueMap[values.agreementPrice] : null
            let params = {
                tenantId: props.defaultParams.tenantId,
                miceId: props.defaultParams.processId,
                orderId: props.defaultParams.orderId || null,
                itemId: props.listItem.itemId,
                supplierId: props.defaultParams.supplierId,
                quotes: [{
                    expenseId: props.listItem.expenses[0].expenseId,
                    costType: '酒店餐费', // 费用类型
                    price: values.price, // 单价
                    qty: props.listItem.expenses[0].qty, // 数量(用餐人数/间数…)
                    amount: amount, // 小计
                    outPrice: values.outPrice, // 对外价格
                    minQty: values.minQty, // 保底数量
                    unit: values.unit, // 单位
                    invoiceType: values.invoiceType, // 发票类型
                    taxRate: values.taxRate, // 税率
                    tax: Number((amount / (1 + taxRate) * taxRate).toFixed(2)), // 税额
                    descr: values.czbjDescr, // 说明
                    serviceDescr: values.serviceDescr, // 说明
                    agreementPrice: agreementPrice,
                    files: soFileList.map((item) => {
                        return item.fileKey;
                    }) // 文件
                }]
            }
            commonService.SaveQuote(params).then(res => {
                if (res && res.success) {
                    message.success("保存成功");
                    setIsEdit(false)
                    props.GetF1QuotedPriceTaskList()
                }
            })
        })
    }
    const openFile = () => {
        queryFile()
    }
    const queryFile = async () => {
        let params = {
            filterData: mappingData
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileList = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            setSoFileList(fileList);
            setMappingData(fileList)
        }
    }
    const handleChange = () => {
        let price = form.getFieldValue('price')
        let czNum = props.listItem.expenses[0].qty
        let total = price * czNum
        setAmount(total)
        form.setFieldValue('amount', total)
    }
    const handleSelectChange = (val) => {
        props.invoiceTypeList.forEach(ele => {
            if ([val].includes(ele.value)) {
                form.setFieldValue('invoiceType', ele.label)
            }
        })
    }
    const expandedRowRender = (record) => {
        const columns = [
            { title: '费用类型' , key: 'type', render: () => ( <span>酒店餐费</span> ),width: 90, },
            { title: '数量' , key: 'qty', dataIndex: 'qty',width: 90, },
            { title: (<>{record.mealType === '围餐' ? '保底桌数' : '保底人数'}</>), dataIndex: 'minQty', key: 'minQty',width: 140,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='minQty' style={{ margin: 0 }} rules={[
                                { type: 'number', required: false, message: 'Please input minQty!',},
                                { validator: (rule, value) => {
                                        if (value >= 0 && value <= props.listItem.expenses[0].qty) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('保底数量需在预计人数范围内');
                                    },
                                },
                            ]}>
                                <InputNumber style={{width: '100%',minWidth: '130px'}} placeholder="请填写保底数" onChange={handleChange} min={0} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || '0')}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                )},
            { title: (<><span style={{ color: 'red' }}>*</span> 单价</>), dataIndex: 'price', key: 'price',width: 120,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <>
                                <Form.Item name='price' style={{ margin: 0 }} rules={[ { required: true, message: '请填写单价', }, ]}>
                                    <InputNumber style={{width: '100%',minWidth: '110px'}} onChange={handleChange} min={0} />
                                </Form.Item>
                            </>
                        ) : (children)}
                    </>
                )},
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 单位</>) : '单位', dataIndex: 'unit', key: 'unit',width: 150,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='unit' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请输入单位', }, ]}>
                                <Input style={{width:'100%'}}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: '小计', dataIndex: 'amount', key: 'amount' ,width: 90,
                render: () => (
                    <> {amount} </>
                ) },
            { title: '对外价格', dataIndex: 'outPrice', key: 'outPrice' ,width: 120,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='outPrice' style={{ margin: 0 }} >
                                <InputNumber style={{width: '100%',minWidth: '110px'}} min={0} />
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 发票类型</>) : '发票类型', dataIndex: 'invoiceType', key: 'invoiceType',width: 150,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='invoiceType' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请选择发票类型', }, ]}>
                                <Select style={{ width: 140,minWidth: '130px' }} options={props.invoiceTypeList} onChange={handleSelectChange}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 税率%</>) : '税率%', dataIndex: 'taxRate', key: 'taxRate',width: 120,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='taxRate' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请填写税率', }, ]}>
                                <InputNumber style={{width: '100%', minWidth: '90px'}} min={0} max={100} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || '0')}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: '报价说明', dataIndex: 'czbjDescr', key: 'czbjDescr' ,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='czbjDescr' style={{ margin: 0 }} >
                                <TextArea rows={4} style={{minWidth: '250px'}} placeholder="用餐形式：桌餐/自助餐;桌数，餐厅名称，楼层，人均，保底数量"/>
                            </Form.Item>
                        ) : (children)}
                    </>
                )},
            { title: '服务描述', dataIndex: 'serviceDescr', key: 'serviceDescr' ,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='serviceDescr' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请输入服务描述', }, ]}>
                                <TextArea rows={4} style={{minWidth: '250px'}} placeholder="请输入服务描述"/>
                            </Form.Item>
                        ) : (children)}
                    </>
                )},
            { title: '是否协议价格', dataIndex: 'agreementPrice', key: 'agreementPrice',width: 150,
                    render: (children) => (
                        <>
                            { isEdit ? (
                                <Form.Item name='agreementPrice' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请选择是否协议价格', }, ]}>
                                    <Select fieldNames={{label: 'txt', value: 'val'}} options={OperationMode} placeholder="请选择是否协议价格"></Select>
                                </Form.Item>
                            ) : (children)}
                        </>
                ) },
            { title: '支持文件', dataIndex: 'file', key: 'file',
                render: () => (
                    <Button type="primary" onClick={() => openFile()}>{isEdit ? '上传' : '查看'}</Button>
                ) },
            { title: '操作', dataIndex: 'operation', key: 'operation',
                render: () => (
                    props.isQuote && props.isOffline ? (
                        <>
                            { !isEdit ? <Button type="primary" onClick={() => editInfo()}>编辑</Button> : <Button type="primary" onClick={() => saveInfo()}>保存</Button>}

                        </>
                    ) : ''
                ),
            },
        ];
        return (
            <Form form={form} component={false}>
                <Table columns={columns}
                       bordered
                       dataSource={childSource}
                       pagination={false}/>
            </Form>
        )
    };
    const columns = [
        { title: '需求项目', dataIndex: 'itemTypeTxt', key: 'itemTypeTxt' },
        { title: '用餐类型', dataIndex: 'itemName', key: 'itemName' },
        { title: '用餐形式', dataIndex: 'mealType', key: 'mealType' },
        { title: '用餐日期', dataIndex: 'dtStart', key: 'dtStart' },
        { title: '用餐人数', dataIndex: 'qty', key: 'qty' },
        { title: '备注', dataIndex: 'descr', key: 'descr' },
    ];
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            <Table
                columns={columns}
                bordered
                expandable={{ expandedRowRender: record => expandedRowRender(record), defaultExpandedRowKeys: ['0'] }}
                dataSource={dataSource}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
            <Modal title="支持文件" width={800} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <UploadFiles {...uploadProps} />
            </Modal>
        </>
    );
};

export default MealTable;
