import React, { useEffect, useState } from "react";
import commonService from "@/service/common";
import "../../MopModules/modules.less";
import {Spin} from 'antd'
import HoteDemandItem from "@/components/DetailDrawer/compments/MopModules/HoteDemand/HoteDemandItem";

const HoteDemand = (props) => {
    const [orderItem, setOrderItem] = useState([]);
    const [isOrderVenue, setIsOrderVenue] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        GetF1QuotedPriceTaskList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const GetF1QuotedPriceTaskList = () => {
        let params = {
            miceId: props.requestVal.processId,
            orderId: [props.requestVal._OrderId].includes(props.requestVal.processId) ? null : props.requestVal._OrderId,
            nodeCode: props.pageType,
        }
        setIsLoading(true)
        commonService.QueryVenueOrders(params).then(res => {
            if (res && res.success) {
                setOrderItem(res.content?.venueOrders || [])
                setIsLoading(false)
                setIsOrderVenue(res.content?.isOrderVenue)
            }
        })
    }
    return (
        <div className="boderRow mopDetails">
            <div className="card_titleBox">
                <p className="card_title">{props.moduleItem?.queryName}</p>
                <p className='venueQuoteTips'>特别提示：请按照预计人数（非保底人数）计算并填写总金额, 如10间房以上或因其他原因而需要设定担保数量，请在备注中说明</p>
            </div>
            {isLoading && (<Spin />)}
            {orderItem.length > 0 && isOrderVenue && orderItem.map((item, index) => (
                <div className='orderBox'>
                    <div className='orderTitleBox'>
                        <div className='orderTitle'>
                            <p className='orderNum'><span>订单号：{item.orderNum}</span></p>
                            <p className='statusTxt'><span>{item.statusTxt}</span></p>
                            {item.currentTaskOrder && item.canEditOrder && (<p className='orderStatus'>待处理</p>)}
                            {!item.currentTaskOrder && (<p className='orderStatusNo'>无需处理</p>)}
                        </div>
                        <p><span style={{fontSize: '12px'}}>订单总报价:</span><span className='quoteAmount'>￥{item.quoteAmount}</span></p>
                    </div>
                    <HoteDemandItem key={index} listItem={item} requestVal={props.requestVal}/>
                </div>
            ))}
            {
                !isOrderVenue && (
                    <HoteDemandItem listItem={{
                        orderId: props.requestVal._OrderId,
                        canEditOrder: ["MOP-Quote"].includes(props.pageType)
                    }} requestVal={props.requestVal}/>
                )
            }
        </div>
    );
};

export default HoteDemand;
