import React, { useState, useEffect } from "react";
import { Button, Modal, message, Form, Input } from "antd";
import commonService from "@/service/common";
import ModalDesc from "./ModalDesc";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const FooterBtnsCard = (props) => {
  const [btnList, setBtnList] = useState([]);
  const [btnInfo, setBtnInfo] = useState(null);
  const [modalVisable, setModalVisable] = useState(false);
  const [NotCloseWindow, setNotCloseWindow] = useState(false);
  // console.log(props, "footProps");
  const btnClick = (btnInfo) => {
    console.log(btnInfo);
    setBtnInfo(btnInfo);
    switch (btnInfo.actCode) {
      case "btn_ReBack":
      case "btn_Reject":
      case "btn_audit_pass":
        setModalVisable(true);
        // reBakck(btnInfo);
        break;
      case "btn_Done":
        Modal.confirm({
          title: "提示",
          icon: <ExclamationCircleOutlined />,
          content: `是否确认${btnInfo.actName}`,
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            let params = {
              taskId: props.requestVal.taskId,
              actionId: btnInfo.actionId,
            };
            commonService.performTask(params).then((res) => {
              if (res && res.success) {
                message.success("操作成功" || res.msg);
                setModalVisable(false);
                window.close();
                // props.iqueryDetailDrawerCallback();
              }
            });
          },
          onCancel: () => {},
        });
        break;
      default:
        break;
    }
  };

  const modalClose = (type) => {
    switch (type) {
      case "ok":
        setModalVisable(false);
        window.close();
        // props.iqueryDetailDrawerCallback();
        break;
      default:
        setModalVisable(false);
        break;
    }

  };
  useEffect(() => {
    queryTaskActions(props.requestVal.taskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const queryTaskActions = (id) => {
    let params = {
      taskId: id,
    };
    commonService.queryTaskActions(params).then((res) => {
      if (res && res.content) {
        setBtnList(res.content);
      }
      // console.log(res, "res");
    });
  };

  return (
    <>
      {btnList.length > 0 && (
        <div className="boderRow">
          <div className="btns">
            {btnList.map((item) => {
              return (
                <Button
                  onClick={() => {
                    btnClick(item);
                  }}
                  className={item.actionClassName}
                >
                  {item.actName}
                </Button>
              );
            })}
          </div>
        </div>
      )}
      {modalVisable && (
        <ModalDesc
          info={btnInfo}
          modalOpen={modalVisable}
          iqueryRow={props.requestVal}
          cancelModal={modalClose}
        ></ModalDesc>
      )}
    </>
  );
};

export default FooterBtnsCard;
