import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import { Modal, Row, Input, message } from 'antd';
import './CarInfoCard.less'; // 根据您的样式表路径进行修改

const MapEditor= forwardRef((props, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [sourceKey, setSourceKey] = useState('');
    const [sourceCity, setSourceCity] = useState('');
    const sourceCityRef = useRef(null);
    const [chooseValue, setChooseValue] = useState(null);
    const mapRef = useRef(null);
    const searchRef = useRef(null);

    useEffect(() => {
        if (isModalOpen) {
            initMap();
        }
    }, [isModalOpen]);

    const show = (options) => {
        const { sourceKey, sourceCity } = options;
        console.log(sourceCity);
        setSourceKey(sourceKey)
        setSourceCity(sourceCity)
        sourceCityRef.current = sourceCity;
        setIsModalOpen(true);
        // 设置其他属性...
    };

    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        show,
        setKeyword
    }));

    const onConfirm = async () => {
        console.log("地址", chooseValue)
        let {
            name = "", // 结果
            address = "", // 大概的地址
            pname = "", // 省份
            cityname = "", // 地级市
            adname = "", // 区
            district = "", // 这个字段同pname、cityname互斥，有他没我
        } = chooseValue;
        if(props.beforeSubmit) { // 确定之前需要卡控选择的地址是否在黑名单中
            const permits = await props.beforeSubmit(chooseValue);
            if(permits.type === 1) {
                message.error("该地址在黑名单中，请重新选择");
                return;
            }
        }
        setIsModalOpen(false);
        mapRef.current = null;
        props.mapDataCallback && props.mapDataCallback(chooseValue, props.currentField);
        props.updateForm && props.updateForm({
            [sourceKey]: `${name}-${district}${pname == cityname ? "" : pname }${cityname}${adname}${address}`,
            // [sourceCity]: `${district ? district.substring(district.indexOf('省') + 1,district.indexOf('市')+1) : ''}${cityname}`
            [sourceCity]: chooseValue[sourceCityRef.current]
        })
    };

    const onCancel = () => {
        mapRef.current = null;
        setIsModalOpen(false);
    };

    const initMap = () => {
        // 加载地图相关逻辑
        const AMapUI = window.AMapUI;
        const AMap = window.AMap;

        AMapUI.loadUI(
            ["misc/PoiPicker"],
            (PoiPicker) => {
                let mapConfig = {
                    zoom: 16,
                    resizeEnable: true,

                };
                let map = new AMap.Map(mapRef.current, mapConfig);
                // 创建poiPicker
                let poiPicker = new PoiPicker({
                    city: "海口市",
                    input: searchRef.current.input,
                });
                poiPickerReady(poiPicker, map);
                //异步加载地图控件
                AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.OverView', 'AMap.Geolocation'], () => {
                    // 工具条
                    map.addControl(new AMap.ToolBar({
                        position: "RB"
                    }));
                    // 比例尺
                    // map.addControl(new AMap.Scale());
                    // 缩略图
                    map.addControl(new AMap.OverView({
                        isOpen: false
                    }));
                    // 定位按钮
                    map.addControl(new AMap.Geolocation());
                });
            }
        );
    };

    const poiPickerReady = (poiPicker, map) => {
        var marker = new window.AMap.Marker();
        var infoWindow = new window.AMap.InfoWindow({
            offset: new window.AMap.Pixel(0, -20),
            autoMove: true
        });

        poiPicker.on("poiPicked", function (poiResult) {
            console.log("选取了某个POI", poiResult)
            setChooseValue(poiResult.item);
            let {
                name = "", // 结果
                location: Location = "", // 经纬度信息
                address = "", // 大概的地址
                pname = "", // 省份
                cityname = "", // 地级市
                adname = "", // 区
                district = "", // 这个字段同pname、cityname互斥，有他没我
            } = poiResult.item;
            setKeyword(name);
            marker.setMap(map);
            infoWindow.setMap(map);
            console.log(Location);
            marker.setPosition(Location);
            infoWindow.setPosition(Location);
            infoWindow.setContent(
                "位置信息: <p>名称:" +
                name +
                "</p>" +
                "<p>地址:" +
                `${district}${pname == cityname ? "" : pname }${cityname}${adname}${address}` +
                "</p>"
            );
            infoWindow.open(map, marker.getPosition());
            console.log(sourceCityRef.current);
            // const center = new window.AMap.LngLat(lng, lat);
            // map.setCenter([lng, lat]);
            map.setCenter(marker.getPosition());
            // 获取城市信息
            map.getCity((res) => {
                console.log('城市----',res);
                setChooseValue((pre) => ({...pre, [sourceCityRef.current]: res.city || res.province}))
            })
        });

        poiPicker.onCityReady(function () {
            poiPicker.suggest(keyword);
        });
    };

    return (
        <Modal title="地图选择" open={isModalOpen} onOk={onConfirm} onCancel={onCancel} width="900px">
            <Row className="container">
                <Input ref={searchRef} className="searchBox" placeholder="请输入关键字搜索地址" value={keyword} onChange={(e) => setKeyword(e.target.value)}/>
                <div id="js-container" className="mapContent" ref={mapRef}></div>
            </Row>
        </Modal>
    );
})

export default MapEditor;
