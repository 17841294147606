import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Steps, Button, notification } from "antd";
import commonService from "@/service/common";
// import DetailModal from "./detailModal";
// import DetailTableModal from "./DetailTableModal";
import { SmartStorage } from "@/utils";
import * as tinyCookie from "tiny-cookie";
// eslint-disable-next-line no-unused-vars
const userId =
  SmartStorage.get("userId") ||
  SmartStorage.get("user_id") ||
  tinyCookie.get("userId") ||
  tinyCookie.get("user_id");
const Finance = forwardRef((props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const [taskLogs, setTaskLogs] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      // eslint-disable-next-line no-eval
      eval(btn.actionCode)(btn, rowKeys, selectedRows, data);
    },
  }));
  // eslint-disable-next-line no-unused-vars
  const taskLogTable = async (btn, rowKeys, selectedRows, record) => {
    let params = {
      processId: record.processId,
    };
    setLoading(true);
    let res = await commonService.QueryProcessTaskLogs(params);
    setLoading(false);
    if (res && res.success) {
      setTaskLogs(res.content);
      setModalVisible(true);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const eventExpenseTable = async (btn, rowKeys, selectedRows, record) => {
    let params = {
      queryId: props.queryObject.variables.queryId4Expense,
      queryId4Count: props.queryObject.variables.queryIdExpense4Count,
      cluster: "default",
      parameters: {
        _batchId: record.batchId,
      },
      ExportMode: 0,
    };
    setLoading(true);
    let res = await commonService.ExportData(params);
    setLoading(false);
    if (res) {
      notification.success({
        message: "导出提示",
        description: <div dangerouslySetInnerHTML={{ __html: res.msg }}></div>,
        placement: "topRight",
        duration: 0,
      });
    }
  };
  const description = "This is a description.";
  return (
    <div>
      <Modal
        title="工作流-日志"
        centered
        visible={modalVisible}
        maskClosable
        footer={[<Button key="back">确定</Button>]}
      >
        <Steps
          direction="vertical"
          current={1}
          items={[
            {
              title: "Finished",
              description,
            },
            {
              title: "In Progress",
              description,
            },
            {
              title: "Waiting",
              description,
            },
          ]}
        />
      </Modal>
    </div>
  );
});

export default Finance;
