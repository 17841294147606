import React, { useEffect, useState } from "react";
import "./index.less";
import commonService from "@/service/common";
// eslint-disable-next-line no-unused-vars
import insightIconIndex from "@/assets/mock/insightIconIndex.json";

function Insight(props) {
  const filterData = JSON.parse(JSON.stringify(props.filterData || {}));
  const defaultParams = props.defaultParams;
  const [insightList, setInsightList] = useState([]);
  const [setup, isSetup] = useState(false);
  useEffect(() => {
    if (!filterData.parameters) return false;
    getInsightList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData]);
  const getInsightList = async () => {
    let params = Object.assign(filterData, defaultParams);
    let res = await commonService.getQueryData(params);
    if (res && res.success && res.content) {
      setInsightList(res.content.rows || []);
      isSetup(true);
    }
  };
  return (
    <>
      {setup && (
        <div className="insight-box">
          {insightList.length > 0 ? (
            insightList.map((item, index) => (
              <div className="insight-item" key={item.itemType}>
                <div className="item-title">{item.itemTypeTxt}</div>
                <div className="item-desc">{item.accAmount}</div>
              </div>
            ))
          ) : (
            <div className="noData">暂无数据</div>
          )}
        </div>
      )}
    </>
  );
}

export default Insight;
