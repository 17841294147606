import React, {useEffect, useRef, useState} from "react";
import { getCookie } from "tiny-cookie";
import './CarInfoCard.less'
import {Button, Form, Select, Table} from "antd";
import CarQuoteEditor from "@/components/DetailDrawer/compments/CarSupplierModules/CarQuoteEditor";
import {SmartStorage} from "@/utils";
const CarDemand = (props) => {
    const [taskInfo, setTaskInfo] = useState({});
    const [carItems, setCarItems] = useState([]);
    const [customQuoteAmount, setCustomQuoteAmount] = useState(0);
    const carQuoteEditor = useRef(null);
    useEffect(() => {
        setTaskInfo(props?.miceInfo)
        let value = JSON.parse(JSON.stringify(props?.miceInfo?.orderExpenseItems || []))
        value.map((ele, i) => {
            ele.key = i.toString();
            ele.demandType = ele?.orderRequest?.itsRequestData?.isSpecial ? "特殊需求" : "标准需求";
            ele.carModus = ele?.orderRequest?.itsRequestData?.carModus || "未知";// 用车形式
            ele.carType = ele?.orderRequest?.itsRequestData?.carType || "未知";// 车型
            ele.tripList = ele?.orderRequest?.itsRequestData?.tripList || []; // 包含出发地、目的地、用车时间等信息，用于报价、详情展示
            ele.bussinessId = ele?.orderRequest?.bussinessId;
            ele.descr = ele?.orderRequest?.itsRequestData?.descr;
            ele.departure = ele?.orderRequest?.itsRequestData?.departure; // 预计出发地
            ele.destination = ele?.orderRequest?.itsRequestData?.destination; //预计目的地
            ele.dtStart = ele?.orderRequest?.dtStart; // 预计用车时间
        });
        setCarItems(value)
        let amount = value.map(ele => ele.amount).concat([0]).reduce((l, r) => l + r)
        setCustomQuoteAmount(amount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);

    const columns = [
        { title: '需求类型', dataIndex: 'demandType', key: 'demandType' },
        { title: '用车形式', dataIndex: 'carModus', key: 'carModus' },
        { title: '车型', dataIndex: 'carType', key: 'carType' },
        { title: '数量', dataIndex: 'qty', key: 'qty',
            render: (children) => (<> {children}台 </> )
        },
        { title: '出发地', dataIndex: 'departure', key: 'departure' },
        { title: '目的地', dataIndex: 'destination', key: 'destination' },
        { title: '小计', dataIndex: 'amount', key: 'amount' },
        { title: '操作', dataIndex: 'action', key: 'action',
            render: (_, record) => (<> {[10].includes(taskInfo.status) && (
                <><Button type="primary" onClick={() => editInfo(record)}>报价</Button></>
            )} </> )
        },
    ];
    const editInfo = (record) => {
        carQuoteEditor.current.showModal(record)
    }
    const updateChild = () => {
        props.updateChild()
    }
    return (<div className="boderRow carDetails">
        <div className="card_title">{props.moduleItem?.queryName}</div>
        <Table
            columns={columns}
            bordered
            dataSource={carItems}
            pagination={false}
        />
        <h2 className="amount">报价总金额：<span>{['CarQuote'].includes(SmartStorage.get("detailRow").nodeCode) ? customQuoteAmount : taskInfo.quoteTotalAmount}</span>
        </h2>
        <CarQuoteEditor ref={carQuoteEditor} miceInfo={taskInfo} updateChild={updateChild}></CarQuoteEditor>
    </div>)
}

export default CarDemand;
