/* eslint-disable no-eval */
import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from "react";
import {Modal, Row, Form, Col, Select, Input, InputNumber, DatePicker, Button, Divider, message, Upload} from "antd";
import { EnvironmentOutlined , MinusCircleOutlined} from '@ant-design/icons';
import { UploadFiles } from "smart-filer-react";
import commonService from "@/service/common";
import _ from "lodash";
import { SmartStorage } from "@/utils";
import moment from 'moment';
import MapEditor from "@/components/DetailDrawer/compments/CarSupplierModules/MapEditor";
const CarBillingEditor = forwardRef((props, ref) => {
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [form] = Form.useForm();
    const mapEditor = useRef(null);
    const [uploadProps, setUploadProps] = useState();
    const [fileList, setFileList] = useState([]);
    const fileListRef = useRef([]);
    const [selectedData, setSelectedData] = useState({});
    const [carModusList, setCarModusList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [autoMapping, setAutoMapping] = useState(false);
    const [hasWheelPath, setHasWheelPath] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [rnd, setRnd] = useState();
    // 用于装载如sCity、eCity等额外不能直接放到表单的字段
    const [ExtData, setExtData] = useState({});
    const expenseIdRef = useRef(null);
    const { TextArea } = Input;
    const [passengersList, setPassengersList] = useState([{ name: '', hospital: '' }]);
    useEffect(() => {
        QueryCarModus()
        setHasWheelPath(['msd'].includes(props.miceInfo.tenantCode))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    const QueryCarModus = () => {
        let params = {
            keyCode: "CarModus"
        }
        commonService.queryBankList(params).then((res) => {
            if (res && res.success) {
                let data = []
                res.content.map(ele => {
                    data.push({label: ele.txt,value: ele.val})
                })
                setCarModusList(data)
            }
        });
    }
    const queryFile = async (expenseId) => {
        const {proposalId, supplierId} = SmartStorage.get("detailRow");
        let params = {
            filterData: {
                'processId': proposalId,
                'supplierId': supplierId || props.miceInfo.supplierId,
                "relatedId": expenseIdRef.current,
                "catalog": "行车轨迹"
            }
        }
        console.log(expenseId);
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileItem = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            console.log(fileItem);
            setFileList(fileItem);
            fileListRef.current = fileItem;
            form.setFieldValue('fileList', fileItem);
            setMappingData({}, fileItem)
        }
    }
    const setMappingData = (mappingData, fileItem) => {
        let config = {
            key: rnd + 1,
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            defaultFileList: fileItem,
            showUploadList: {
                showPreviewIcon: true,
                showRemoveIcon: true,
            },
            isMapping: false,
            onRemove: async (file) => {
                console.log(222);
                console.log(file);
                console.log(fileList);
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                console.log(fileListRef.current);
                const files = fileListRef.current;
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    const newFiles = files.filter((item) => item.fileKey !== file.fileKey);
                    setFileList(newFiles);
                    form.setFieldValue('fileList', newFiles);
                    fileListRef.current = newFiles;
                }
            },
            filterData: {},
            uploadCallback: async (file, fileList) => {
                setFileList(fileList);
                form.setFieldValue('fileList', fileList);
                fileListRef.current = fileList;
                console.log(expenseIdRef);
                // setTimeout(async () => {
                //     if(expenseIdRef.current){ // 编辑的时候上传就mapping
                //         await mappingssFile(expenseIdRef.current, { eventType: "onSave"});
                //     }
                // },1000)

            },
        };
        console.log('updata----',config);
        setUploadProps(config);
    }
    const showModal = (rowData) => {
        console.log('rowData---',rowData);
        console.log(!!Object.keys(rowData).length);
        setIsEdit(!!Object.keys(rowData).length);
        setRnd(new Date().getTime());
        form.resetFields();
        setSelectedData(rowData)

        // setAutoMapping(false)
        if(rowData?.expenseId) {
            console.log(rowData.expenseId);
            expenseIdRef.current = rowData.expenseId;
            // setAutoMapping(true)
            const {
                itsExpenseData,
                expenseId
            } = rowData;
            let passengersList = itsExpenseData.passengersList || [];
            setPassengersList(passengersList)
            queryFile(expenseId);
        }else {
            console.log('formss',form.getFieldsValue());
            fileListRef.current = null;
            setFileList(null);
            setMappingData([], []);
        }
        initForm(rowData)
    }
    const initForm = (rowData) => {
        const {
            itsExpenseData = {}
        } = rowData || {};
        form.setFieldsValue({
            carModus: itsExpenseData.carModus, // 用车形式
            carType: itsExpenseData.carType, // 车型
            licensePlate: itsExpenseData.licensePlate, //车牌号
            kilometers: itsExpenseData.kilometers, //公里数
            departure: itsExpenseData.departure, //出发地
            sCity: itsExpenseData.sCity, //出发城市
            destination: itsExpenseData.destination, // 目的地
            eCity: itsExpenseData.eCity, // 目的城市
            sDateTime: itsExpenseData.sDateTime ? moment(itsExpenseData.sDateTime) : undefined, //预计上车时间
            eDateTime: itsExpenseData.eDateTime ? moment(itsExpenseData.eDateTime) : undefined, //预计下车时间
            amount: itsExpenseData.money, //总金额
            qty: itsExpenseData.pieceQty, // 乘车人数
            descr: itsExpenseData.descr, // 备注
            passengersList: itsExpenseData.passengersList, //乘车人
            budgetDescr: itsExpenseData.budgetDescr // 超预算说明
        })
        const {sCity, eCity} = itsExpenseData;
        setExtData({sCity: sCity, eCity: eCity}); // 不要简写，需要key值
        setIsModalOpen(true);
    }
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    const handleOk = () => {
        form.validateFields().then((values) => {
            console.log(values);
            savePiece({ eventType: "onSave", data: values })
        })
    };
    const saveAndContinue = () => {
        form.validateFields().then((values) => {
            savePiece({ eventType: "saveAndContinue", data: values })
        })
    };
    const savePiece = (options) => {
        let editorForm = options.data
        const {
            itsExpenseData = {}
        } = selectedData || {};
        let params = {
            proposalId: SmartStorage.get("detailRow").proposalId, // 会议id
            tenantId: SmartStorage.get("detailRow").tenantId, //租户id
            amount: editorForm.amount, //总金额
            budgetDescr: editorForm.budgetDescr, // 超预算说明
            carModus: editorForm.carModus, // 用车形式
            carModusPriceField: "", // 未知
            carType: editorForm.carType, // 车型
            departure: editorForm.departure, //出发地
            sCity: editorForm.sCity, //出发城市
            descr: editorForm.descr, // 备注
            destination: editorForm.destination, // 目的地
            eCity: editorForm.eCity, // 目的城市
            sDateTime: editorForm.sDateTime ? moment(editorForm.sDateTime._d).format('YYYY-MM-DD HH:mm') : '', //预计上车时间
            eDateTime: editorForm.eDateTime ? moment(editorForm.eDateTime._d).format( 'YYYY-MM-DD HH:mm') : '', //预计下车时间
            kilometers: editorForm.kilometers, //公里数
            licensePlate: editorForm.licensePlate, //车牌号
            money: editorForm.amount, //总金额
            orderId: SmartStorage.get("detailRow").orderId, //订单id
            passengers: editorForm.passengersList, //乘车人
            passengersList: editorForm.passengersList, //乘车人
            pieceQty: editorForm.qty, //人数，编辑时后台返回的人数字段
            qty: editorForm.qty, //乘车人数
            supplierId: props.miceInfo.supplierId //供应商id
        }
        // 在这里区分保存、保存且继续（这种场景相当于新建，是没有expenseId的）
        if(options.eventType == 'onSave') {
            params.expenseId = selectedData.expenseId; // 费用id
            params.pieceId = itsExpenseData.pieceId; // 未知
        }
        setLoading(true)

        commonService.SavePiece({...params, ...ExtData}).then(async (res) => {
            if (res && res.success) {
                if(hasWheelPath) {
                    // if(!isEdit || options.eventType === 'saveAndContinue') { // 只要是新增都要mapping
                        expenseIdRef.current = res.content;
                        const flag = await mappingssFile(res.content, options);
                        if(flag) {
                            reloadBillingDetail(options)
                        }
                    // }else { // 编辑的时候要分情况
                    //     reloadBillingDetail(options)
                    // }
                }else {
                    reloadBillingDetail(options)
                }
            }
        })
    }
    const mappingssFile = (id, options) => {
        let params = {
            mappingData: {
                processId: SmartStorage.get("detailRow").proposalId || props.miceInfo.proposalId,
                supplierId: props.miceInfo.supplierId,
                relatedId: id,
                catalog: "行车轨迹",
                tenantId: SmartStorage.get("detailRow").tenantId,
            },
            fileKeys: fileListRef.current.map(item => item.fileKey),
        }
        return new Promise(resolve => {
            commonService.mapping(params).then((res) => {
                if (res && res.success) {
                    resolve(true);

                }
            })
        })

    }
    const reloadBillingDetail = (options) => {
        message.success(options.eventType == "onSave" ? "保存成功" : "保存成功，您还可以继续")
        setIsModalOpen(!['onSave'].includes(options.eventType));
        setLoading(false)
        props.updateChild();
        if (['saveAndContinue'].includes(options.eventType) && hasWheelPath) {
            form.setFieldValue('fileList', []);
            setFileList([]);
            setRnd(new Date().getTime());
            // queryFile();
            // ''
        }
        // options.eventType == "onSave" && window.location.reload();
    }
    const disabledStartDate = (sDateTime) => {
        let eDateTime = form.getFieldValue('eDateTime')
        if(!sDateTime || !eDateTime) {
            return false;
        }
        return new Date(moment(sDateTime._d).format("YYYY-MM-DD HH:mm:ss")).getTime() > new Date(moment(eDateTime._d).format("YYYY-MM-DD HH:mm:ss")).getTime();
    }
    const disabledEndDate = (eDateTime) => {
        let sDateTime = form.getFieldValue('eDateTime')
        if(!sDateTime || !sDateTime) {
            return false;
        }
        return new Date(moment(sDateTime._d).format("YYYY-MM-DD HH:mm:ss")).getTime() > new Date(moment(eDateTime._d).format("YYYY-MM-DD HH:mm:ss")).getTime();
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        expenseIdRef.current = null;
    };
    const addPassenger = () => {
        setPassengersList([...passengersList, { name: '', hospital: '' }]);
    };
    const selectMap = (sourceKey, sourceCity) => {
        mapEditor.current.show({
            sourceKey:sourceKey,
            sourceCity: sourceCity
        })
    }
    const removePassenger = (index) => {
        const updatedPassengersList = passengersList.filter((_, i) => i !== index);
        setPassengersList(updatedPassengersList);
    };

    const handleInputChange = (index, field, value) => {
        const updatedPassengersList = [...passengersList];
        updatedPassengersList[index][field] = value;
        setPassengersList(updatedPassengersList);
    };
    const updateForm = (data) => {
        const copyData = _.cloneDeep(ExtData);
        for (let val in data) {
            if(Object.keys(copyData).includes(val)) {
                copyData[val] = data[val];
            }
            form.setFieldValue(val, data[val])
        }
        setExtData(copyData);
    }
    return (
        <Modal title="录入明细" width={700} maskClosable={false} open={isModalOpen} onCancel={handleCancel} footer={[
            <Button loading={loading} onClick={handleCancel}> 取消 </Button>,
            <Button type="primary" loading={loading} onClick={addPassenger}> 添加乘车人 </Button>,
            <Button type="primary" loading={loading} onClick={handleOk}> 保存 </Button>,
            // <Button type="primary" loading={loading} onClick={saveAndContinue}> 保存且继续 </Button>,
        ]}>
            <Row gutter={[16, 24]}>
                <Form form={form} component={false} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} labelAlign="left">
                    <Col span={12}>
                        <Form.Item name='carModus' label='用车形式' style={{ margin: 0 }} rules={[ { required: true, message: '请选择用车形式', }, ]}>
                            <Select style={{ width: '100%'}} options={carModusList} placeholder="请选择用车形式"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='carType' label='车型' style={{ margin: 0 }} rules={[ { required: true, message: '请填写车型', }, ]}>
                            <Input style={{width:'100%'}} placeholder="请填写车型"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='licensePlate' label='车牌号' style={{ margin: 0 }} rules={[ { required: true, message: '请填写车牌号', }, ]}>
                            <Input style={{width:'100%'}} placeholder="请填写车牌号"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='kilometers' label='公里数' style={{ margin: 0 }} rules={[ { required: true, message: '请填写公里数', }, ]}>
                            <InputNumber style={{width: '100%'}} min={0} placeholder="请填写公里数"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='出发地' style={{ margin: 0 }}>
                            <Input.Group compact style={{width: '100%'}}>
                                <Form.Item name='departure' style={{ margin: 0, width: 'calc(100% - 32px)' }} rules={[ { required: true, message: '请选择出发地', }, ]}>
                                    <Input disabled style={{ width: '100%' }} placeholder="请选择出发地"/>
                                </Form.Item>
                                <Button onClick={() => selectMap('departure', 'sCity')} icon={<EnvironmentOutlined />} />
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='sDateTime' label='预计上车时间' style={{ margin: 0 }} rules={[ { required: true, message: '请选择预计上车时间', }, ]}>
                            <DatePicker placeholder="请选择预计上车时间" format="YYYY-MM-DD HH:mm:ss" disabledDate={disabledStartDate} showTime/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='目的地' style={{ margin: 0 }}>
                            <Input.Group compact style={{width: '100%'}}>
                                <Form.Item name='destination' style={{ margin: 0, width: 'calc(100% - 32px)' }} rules={[ { required: true, message: '请选择目的地', }, ]}>
                                    <Input disabled style={{ width: '100%' }} placeholder="请选择目的地"/>
                                </Form.Item>
                                <Button onClick={() => selectMap('destination', 'eCity')} icon={<EnvironmentOutlined />} />
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='eDateTime' label='预计下车时间' style={{ margin: 0 }} rules={[ { required: true, message: '请选择预计下车时间', }, ]}>
                            <DatePicker placeholder="请选择预计下车时间" format="YYYY-MM-DD HH:mm:ss" disabledDate={disabledEndDate} showTime/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='amount' label='总金额' style={{ margin: 0 }} rules={[ { required: true, message: '请填写总金额', }, ]}>
                            <InputNumber style={{width: '100%'}} min={0} placeholder="请填写总金额"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='qty' label='乘车人数' style={{ margin: 0 }} rules={[ { required: true, message: '请填写乘车人数', }, ]}>
                            <InputNumber style={{width: '100%'}} min={0} placeholder="请填写乘车人数"/>
                        </Form.Item>
                    </Col>
                    {props.miceInfo.tenantCode === 'msd'  ? <Col span={24}>
                        <Form.Item name='budgetDescr' label='超预算说明' style={{ margin: 0 }} labelCol={{span: 4}} wrapperCol={{span: 18}} rules={[ { required: false, message: '超预算说明', }, ]}>
                            <TextArea rows={4} style={{width: '100%'}} placeholder="请填写超预算说明"/>
                        </Form.Item>
                    </Col> : null}
                    {hasWheelPath && (
                        <Col span={24}>
                            <Form.Item name='fileList' label='行车轨迹' style={{ margin: 0 }} labelCol={{span: 4}} wrapperCol={{span: 18}} rules={[ { required: true, message: '请上传行车轨迹', }, ]}>
                                <UploadFiles {...uploadProps}/>
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={24}>
                        <Form.Item name='descr' label='备注' style={{ margin: 0 }} labelCol={{span: 4}} wrapperCol={{span: 18}} rules={[ { required: false, message: '请填写备注', }, ]}>
                            <TextArea rows={4} style={{width: '100%'}} placeholder="请填写备注"/>
                        </Form.Item>
                    </Col>
                    <Divider dashed />
                    {passengersList.map((passenger, index) => (
                        <Row key={`passenger-${index}`} style={{width: '100%'}}>
                            <Col span={11}>
                                <Form.Item label="乘车人" name={['passengersList', index, 'name']} rules={[{ required: props.miceInfo.tenantCode !== 'msd', message: '请填写乘车人!' }]}>
                                    <Input placeholder="请填写乘车人" value={passenger.name} onChange={(e) => handleInputChange(index, 'name', e.target.value)}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="乘车人公司" name={['passengersList', index, 'hospital']} rules={[{ required: props.miceInfo.tenantCode !== 'msd', message: '请填写乘车人公司!' }]}>
                                    <Input placeholder="请填写乘车人公司" value={passenger.hospital} onChange={(e) => handleInputChange(index, 'hospital', e.target.value)}/>
                                </Form.Item>
                            </Col>
                            <Col span={2} className="remove-icon-box">
                                {passengersList.length > 1 && (
                                    <MinusCircleOutlined onClick={() => removePassenger(index)}/>
                                )}
                            </Col>
                        </Row>
                    ))}
                </Form>
            </Row>
            <MapEditor ref={mapEditor} updateForm={updateForm}></MapEditor>
        </Modal>
    )
})
export default CarBillingEditor
