import { useState, useEffect } from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

function NoMatch() {
  let history = useHistory();
  const [countdown, setCountdown] = useState(10);

  const backHome = () => {
    history.push("/");
  };

  useEffect(() => {
    if(countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }else {
      backHome();
    }
  }, [countdown]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Result
      status="403"
      title="提示"
      subTitle="当前用户没有权限访问该页面"
      extra={
        <>
          <Button type="primary" onClick={backHome}>
            返回首页<span style={{ fontSize: "12px", marginLeft: "6px" }}>({countdown}s)</span>
          </Button>
        </>
      }
    />
  );
}

export default NoMatch;
