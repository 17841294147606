import React, { useEffect, useState } from "react";
import { Form, Col, Select } from "antd";
import defaultSettings from "./defaultSettings";
import commonService from "@/service/common";
import _ from "lodash";
import { getCookie } from "tiny-cookie";

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const { Option } = Select;

const XChainDrugSelect = (props) => {
  const item = props.item;

  const [options, setOptions] = useState(item.options || []);
  const [loading, setLoading] = useState(false);
  // 子级Select相关
  const [subSelectVal, setSubSelectVal] = useState(item.subSelectVal || "");
  const [subOptions, setSubOptions] = useState(item.subOptions || []);

  const defaultChangeClick = (e, item, sData) => {
    // console.log("选择连锁/门店回调：", e, item, sData);
    if (item.name !== (item.subName || "drugStore")) {
      setSubSelectVal(e);
    }
    props.callback(e, item, sData);
  };
  // 下拉框展开时，请求全量数据
  const onDropdownVisibleChange = (open) => {
    if (open) {
      getOptions(item.dataSource);
    }
  };
  // 子下拉框展开时，请求全量数据
  const onSubDropdownVisibleChange = (open) => {
    if (open) {
      getSubOptions(item.dataSource);
    }
  };

  const getOptions = async (source, value) => {
    if (source.method && source.url && source.params) {
      let method = source.method;
      let url = source.url;
      let params = Object.assign(source.params, {
        filter: [
          {
            $match: {
              UserIds: {
                $in: [getCookie("x_userId").user_id],
              },
              ScopeType: "30",
            },
          },
          {
            $lookup: {
              localField: "ScopeId",
              from: "cfg_drugstores",
              foreignField: "Id",
              as: "drugstoreInfo",
            },
          },

          { $unwind: "$drugstoreInfo" },
          {
            $match: {
              "drugstoreInfo.DrugstoreProperties": { $in: ["1", "3"] },
              "drugstoreInfo.Name": value ? { $regex: value } : undefined,
            },
          },
          {
            $project: {
              _id: 0,
              "drugstoreInfo.Code": 1,
              "drugstoreInfo.Id": 1,
              "drugstoreInfo.Name": 1,
              "drugstoreInfo.DrugstoreProperties": 1,
            },
          },
        ],
      });
      setLoading(true);
      let res = await commonService.common(method, url, params);
      setLoading(false);
      if (res && res.success) {
        // console.log("返回数据：", res.content);
        let chainStores = [];
        if (res.content) {
          // 为了解决未查到数据时，res.content返回是null的问题
          const content = res.content;
          // 设置下拉选项
          chainStores = content.map((v) => {
            return {
              Val: v.drugstoreInfo.Id,
              Txt: v.drugstoreInfo.Name,
            };
          });
        }
        // 为了 选择零售单店（无总店），手动推入 全部 选项
        chainStores.unshift({
          Val: "all",
          Txt: "暂无",
        });
        setOptions(chainStores);
      }
    }
  };
  // 获取子级选项配置
  async function getSubOptions(source, value) {
    // console.log("子下拉框搜索", value);
    if (source.params && source.method && source.url) {
      let method = source.method;
      let url = source.url;
      let params = Object.assign(source.params, {
        filter: [
          {
            $match: {
              UserIds: {
                $in: [getCookie("x_userId").user_id],
              },
              ScopeType: "30",
            },
          },
          {
            $lookup: {
              localField: "ScopeId",
              from: "cfg_drugstores",
              foreignField: "Id",
              as: "drugstoreInfo",
            },
          },
          {
            $match: {
              "drugstoreInfo.DrugstoreProperties": "2",
              "drugstoreInfo.ParentId":
                subSelectVal === "all" ? undefined : subSelectVal,
              "drugstoreInfo.Name": value ? { $regex: value } : undefined,
            },
          },
          { $unwind: "$drugstoreInfo" },
          {
            $project: {
              _id: 0,
              "drugstoreInfo.Code": 1,
              "drugstoreInfo.Id": 1,
              "drugstoreInfo.Name": 1,
              "drugstoreInfo.DrugstoreProperties": 1,
            },
          },
        ],
      });
      setLoading(true);
      let res = await commonService.common(method, url, params);
      setLoading(false);
      if (res && res.success) {
        let drugStores = [];
        if (res.content) {
          // 为了解决未查到数据时，res.content返回是null的问题
          drugStores = res.content.map((v) => {
            return {
              Val: v.drugstoreInfo.Id,
              Txt: v.drugstoreInfo.Name,
            };
          });
        }
        drugStores.unshift({
          Val: "none",
          Txt: "暂无",
        });
        setSubOptions(drugStores);
      }
    }
  }
  // 节流
  // function throttle(type, value) {
  //   if (timeout) {
  //     return;
  //   }
  //   timeout = setTimeout(() => {
  //     clearTimeout(timeout);
  //     timeout = null;
  //     if (type === "chain") {
  //       getOptions(item.dataSource, value);
  //     } else {
  //       getSubOptions(item.dataSource, value);
  //     }
  //   }, 500);
  // }

  useEffect(() => {
    if (item.dataSource) {
      getOptions(item.dataSource);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (subSelectVal) {
      getSubOptions(item.dataSource);
    }
  }, [subSelectVal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Col span={item.span}>
        <Form.Item
          name={item.name}
          rules={item.rules}
          labelCol={{ span: item.lcol ? item.lcol : defaultLabelSpan }}
          wrapperCol={{ span: item.wcol ? item.wcol : defaultWrapperSpan }}
          label={item.label}
          labelAlign="right"
        >
          <Select
            style={{ width: "100%" }}
            mode={item.mode || "default"}
            loading={loading}
            disabled={item.disabled && true}
            labelInValue={item.labelInValue}
            // labelInValue={true}
            allowClear={!item.allowClear}
            placeholder={item.placeholder}
            showSearch
            optionFilterProp="children"
            defaultValue={item.defaultValue}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            filterOption={(inputValue, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0
            }
            filterSort={(optionA, optionB) =>
              optionA.children &&
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            // onFocus={() => { getOptions(item.dataSource) }}
            onDropdownVisibleChange={onDropdownVisibleChange}
            onSearch={_.debounce((value) => {
              // setLoading(true)
              getOptions(item.dataSource, value);
            }, 500)}
            onChange={(e, sData) => defaultChangeClick(e, item, sData)}
          >
            {options.map((obj) => (
              <Option
                key={
                  obj.value || obj.Val || obj.Value || obj.Id + "__" + obj.Code
                }
                value={obj.value || obj.Val || obj.Value || obj.Id}
              >
                {obj.Txt || obj.Name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
      </Col>
      {subSelectVal && (
        <Col span={item.span}>
          <Form.Item
            name={item.subName || "drugStore"}
            rules={item.rules}
            labelCol={{ span: item.lcol ? item.lcol : defaultLabelSpan }}
            wrapperCol={{ span: item.wcol ? item.wcol : defaultWrapperSpan }}
            label={item.subLabel || "门店"}
            labelAlign="right"
          >
            <Select
              style={{ width: "100%" }}
              mode={item.mode || "default"}
              loading={loading}
              disabled={item.disabled && true}
              labelInValue={item.labelInValue}
              allowClear={false}
              placeholder={`请选择${item.subLabel || "门店"}`}
              showSearch
              optionFilterProp="children"
              defaultValue={item.defaultValue}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={(inputValue, option) =>
                option.children &&
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children &&
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onDropdownVisibleChange={onSubDropdownVisibleChange}
              onSearch={_.debounce((value) => {
                // setLoading(true)
                getSubOptions(item.dataSource, value);
              }, 500)}
              onChange={(e, sData) =>
                defaultChangeClick(
                  e,
                  { name: item.subName || "drugStore" },
                  sData
                )
              }
            >
              {subOptions.map((obj) => (
                <Option
                  key={
                    obj.value ||
                    obj.Val ||
                    obj.Value ||
                    obj.Id + "__" + obj.Code
                  }
                  value={obj.value || obj.Val || obj.Value || obj.Id}
                >
                  {obj.Txt || obj.Name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
        </Col>
      )}
    </>
  );
};

export default XChainDrugSelect;
