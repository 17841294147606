import React, { useEffect, useState } from "react";
import commonService from "@/service/common";
import "../../MopModules/modules.less";
import {Button, Table, Form, Input, InputNumber, Select, AutoComplete, Modal, message} from "antd";
import { UploadFiles } from "smart-filer-react";
import TeaTable from "@/components/DetailDrawer/compments/MopModules/HoteDemand/TeaTable";

const VenueTable = (props) => {
    const [form] = Form.useForm();
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [dataSource,setDataSource] = useState([]);
    const [childSource,setChildSource] = useState([]);
    const [OperationMode,setOperationMode] = useState([{txt: '是', val: true},{txt: '否', val: false}]);
    const [isEdit,setIsEdit] = useState(false);
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [amount,setAmount] = useState(0);
    const [lowPrice,setLowPrice] = useState(undefined);
    const [options, setOptions] = useState([])
    const [soFileList, setSoFileList] = useState([])
    const [teaList, setSoTeaList] = useState([])
    const [uploadProps, setUploadProps] = useState();
    const mappingData = {
        'ProcessId': props.defaultParams.processId,
        'ItemId': props.listItem.itemId,
        'SupplierId': props.defaultParams.supplierId,
        'catalog': '报价文件'
    }
    const setMappingData = (fileList) => {
        let updata = {
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            origin: 'SmartVenue',
            gateway: gatewayPath,
            defaultFileList: fileList,
            showUploadList: {
                showDownloadIcon: true,
                showPreviewIcon: true,
                showRemoveIcon: true,
            },
            isMapping: true,
            disabled: !isEdit,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                setSoFileList(fileList);
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    setSoFileList(fileList);
                }
            },
        };
        setUploadProps(updata);
        setIsModalOpen(true)
    };
    const { TextArea } = Input;
    useEffect(() => {
        let data = JSON.parse(JSON.stringify(props.listItem))
        data.key = '0'
        if (data.expenses.length > 0) {
            let agreementPrice = ''
            if (typeof data.expenses[0].agreementPrice === 'boolean') {
                agreementPrice = data.expenses[0].agreementPrice ? '是' : '否'
            } else {
                agreementPrice = props.isGsk && !props.defaultParams.inWhiteList ? '是' : '否'
            }
            let childData = {
                expenseId: data.expenses[0].expenseId,
                venueName: data.expenses[0].venueName,
                czNum: data.expenses[0].qty,
                czPrice: data.expenses[0].price,
                beyondCause: data.expenses[0].beyondCause,
                total: data.expenses[0].amount,
                outPrice: data.expenses[0].outPrice,
                beyondReason: data.expenses[0].beyondReason,
                invoiceType: data.expenses[0].invoiceType,
                taxRate: data.expenses[0].taxRate || '',
                czbjDescr: data.expenses[0].descr,
                serviceDescr: data.expenses[0].serviceDescr,
                unit: data.expenses[0].unit || '半天',
                agreementPrice: agreementPrice,
            }
            setAmount(data.expenses[0].amount)
            setChildSource([childData])
            form.setFieldsValue(childData);
        }
        data.dtStart = data.dtStart ? data.dtStart.replace('T', ' ') : ''
        data.dtEnd = data.dtEnd ? data.dtEnd.replace('T', ' ') : ''
        setDataSource([data])
        let tea = props.listItem?.teaList.length > 0 ? props.listItem?.teaList : []
        setSoTeaList(tea)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listItem]);
    useEffect(() => {
        let options1 = []
        props.itsVenues.forEach(ele => {
            options1.push({
                value: ele.name,
                label: `${ele.name}参考价：${ele.price ? ele.price : '无'}`
            })
        })
        setOptions(options1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.itsVenues]);
    const editInfo = () => {
        setIsEdit(true)
    }
    const saveInfo = () => {
        form.validateFields().then((values) => {
            let taxRate = Number(values.taxRate) / 100;
            const valueMap = {
                "是": true,
                "否": false,
                true: true,
                false: false,
                "": ""
            };
            let agreementPrice = valueMap[values.agreementPrice] !== undefined ? valueMap[values.agreementPrice] : null
            let params = {
                tenantId: props.defaultParams.tenantId,
                miceId: props.defaultParams.processId,
                orderId: props.defaultParams.orderId || null,
                itemId: props.listItem.itemId,
                supplierId: props.defaultParams.supplierId,
                quotes: [{
                    expenseId: props.listItem.expenses[0].expenseId,
                    costType: '场租', // 费用类型
                    price: values.czPrice, // 单价
                    beyondCause: values.beyondCause, // 超低价说明
                    qty: props.listItem.expenses[0].qty, // 数量(用餐人数/间数…)
                    amount: amount, // 小计
                    venueName: values.venueName, // 会场
                    serviceItemName: values.venueName, // 会场
                    outPrice: values.outPrice, // 对外价格
                    invoiceType: values.invoiceType, // 发票类型
                    unit: values.unit, // 单位
                    taxRate: values.taxRate, // 税率
                    tax: Number((amount / (1 + taxRate) * taxRate).toFixed(2)), // 税额
                    beyondReason: values.beyondReason, // 超参考价原因
                    lowPrice: lowPrice || 99999, // 最低价
                    descr: values.czbjDescr, // 说明
                    serviceDescr: values.serviceDescr, // 说明
                    agreementPrice: agreementPrice,
                    files: soFileList.map((item) => {
                        return item.fileKey;
                    }) // 文件
                }]
            }
            commonService.SaveQuote(params).then(res => {
                if (res && res.success) {
                    message.success("保存成功");
                    props.GetF1QuotedPriceTaskList()
                    setIsEdit(false)
                }
            })
        })
    }
    const openFile = () => {
        queryFile()
    }
    const queryFile = async () => {
        let params = {
            filterData: mappingData
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileList = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            setSoFileList(fileList);
            setMappingData(fileList)
        }
    }
    const handleChange = () => {
        let price = form.getFieldValue('czPrice')
        let czNum = form.getFieldValue('czNum')
        let total = price * czNum
        setAmount(total)
        form.setFieldValue('total', total)
    }
    const handleSelectChange = (val) => {
        props.invoiceTypeList.forEach(ele => {
            if ([val].includes(ele.value)) {
                form.setFieldValue('invoiceType', ele.label)
            }
        })
    }
    const onChange = (searchText) => {
        setLowPrice(undefined)
        props.itsVenues.forEach(ele => {
            if ([searchText].includes(ele.name)) {
                setLowPrice(Number(ele.lowPrice))
            }
        })
    }
    const renderPriceField = () => {
        return (
            <>
                <Form.Item name='czPrice' style={{ margin: 0 }} rules={[ { required: true, message: '请填写单价', }, ]}>
                    <InputNumber style={{ minWidth: '150px' }} onChange={handleChange} min={0} placeholder="请填写单价"/>
                </Form.Item>
                {(lowPrice || [0].includes(lowPrice)) && form.getFieldValue('czPrice') < lowPrice ? (
                    <Form.Item name='beyondCause' style={{ margin: 0 }} >
                        <TextArea rows={2} placeholder="请输入超最低价原因"/>
                    </Form.Item>
                ) : ''}
            </>
        );
    };
    const expandedRowRender = () => {
        const columns = [
            { title: '费用类型' , key: 'type', render: () => ( <span>场租</span> ),width: 90 },
            { title:  (<><span style={{ color: 'red' }}>*</span> 会场</>), dataIndex: 'venueName', key: 'venueName',width: 200,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <>
                                <Form.Item name='venueName' style={{ margin: 0 }} rules={[{ required: true, message: '请输入会场'}]}>
                                    <AutoComplete style={{ width: 190, minWidth: '150px' }} options={options} onChange={onChange}/>
                                </Form.Item>
                                {lowPrice || [0].includes(lowPrice) ? (
                                    <span className='errorTips'>最低价：{lowPrice}</span>
                                ) : ''}
                            </>
                        ) : (children)}
                    </>
                )},
            { title: '数量', key: 'czNum', dataIndex: 'czNum',width: 70},
            { title: (<><span style={{ color: 'red' }}>*</span> 单价</>), dataIndex: 'czPrice', key: 'czPrice',width: 170,
                render: (children) => (
                    <>
                        { isEdit ? renderPriceField() : (children) }
                    </>
                )},
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 单位</>) : '单位', dataIndex: 'unit', key: 'unit',width: 150,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='unit' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请输入单位', }, ]}>
                                <Input style={{width:'100%'}}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: '小计', dataIndex: 'total', key: 'total' ,width: 70 ,
                render: () => (
                    <> {amount} </>
                ) },
            { title: '对外价格', dataIndex: 'outPrice', key: 'outPrice' ,width: 130,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='outPrice' style={{ margin: 0 }} >
                                <InputNumber style={{ minWidth: '130px' }} min={0} placeholder="请填写对外价格"/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 发票类型</>) : '发票类型', dataIndex: 'invoiceType', key: 'invoiceType',width: 150,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='invoiceType' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请选择发票类型', }, ]}>
                                <Select style={{ width: 140, minWidth: '140px' }} options={props.invoiceTypeList} onChange={handleSelectChange}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 税率%</>) : '税率%', dataIndex: 'taxRate', key: 'taxRate',width: 120,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='taxRate' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请填写税率', }, ]}>
                                <InputNumber style={{width: '100%', minWidth: '90px'}} min={0} max={100} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || '0')}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: '报价说明', dataIndex: 'czbjDescr', key: 'czbjDescr' ,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='czbjDescr' style={{ margin: 0 }} >
                                <TextArea rows={4} style={{minWidth: '250px'}}
                                          placeholder="会议室面积、所在楼层
投影仪-免费/收费，是否包含投影幕布，投影仪流明数，投影仪价格（收费时）
话筒-免费数量，收费标准
讲台花-免费数量，收费标准
桌卡-免费数量，收费标准
纸笔水-免费/收费，收费标准"/>
                            </Form.Item>
                        ) : (children)}
                    </>
                )},
            { title: '服务描述', dataIndex: 'serviceDescr', key: 'serviceDescr' ,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='serviceDescr' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请输入服务描述', }, ]}>
                                <TextArea rows={4} style={{minWidth: '250px'}} placeholder="请输入服务描述"/>
                            </Form.Item>
                        ) : (children)}
                    </>
                )},
            { title: '是否协议价格', dataIndex: 'agreementPrice', key: 'agreementPrice',width: 150,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='agreementPrice' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请选择是否协议价格', }, ]}>
                                <Select fieldNames={{label: 'txt', value: 'val'}} options={OperationMode} placeholder="请选择是否协议价格"></Select>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: '支持文件', dataIndex: 'file', key: 'file',
                render: () => (
                    <Button type="primary" onClick={() => openFile()}>{isEdit ? '上传' : '查看'}</Button>
                ) },
            { title: '操作', dataIndex: 'operation', key: 'operation',
                render: () => (
                    props.isQuote && props.isOffline ? (
                        <>
                            { !isEdit ? <Button type="primary" onClick={() => editInfo()}>编辑</Button> : <Button type="primary" onClick={() => saveInfo()}>保存</Button>}

                        </>
                    ) : ''
                ),
            },
        ];
        return (
            <Form form={form} component={false}>
                <Table columns={columns} bordered dataSource={childSource} pagination={false}/>
            </Form>
        )
    };
    const columns = [
        { title: '需求项目', dataIndex: 'itemTypeTxt', key: 'itemTypeTxt' },
        { title: '摆台形式', dataIndex: 'itemName', key: 'itemName' },
        { title: '会场人数', dataIndex: 'qty', key: 'qty' },
        { title: '开始时间', dataIndex: 'dtStart', key: 'dtStart' },
        { title: '结束时间', dataIndex: 'dtEnd', key: 'dtEnd' },
        { title: '备注', dataIndex: 'descr', key: 'descr' },
    ];
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            <p className='venueQuoteTips' style={{textAlign: 'right'}}>请务必注明会议室名称、面积、楼层、超出参考价原因</p>
            <Table
                columns={columns}
                bordered
                expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                dataSource={dataSource}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
            {teaList.length> 0 && teaList.map((item, index) => (
                <div key={index}>
                    <TeaTable isGsk={props.isGsk} listItem={item} isQuote={props.isQuote} isOffline={props.isOffline} invoiceTypeList={props.invoiceTypeList}
                              GetF1QuotedPriceTaskList={props.GetF1QuotedPriceTaskList} defaultParams={props.defaultParams}></TeaTable>
                </div>
            ))}
            <Modal title="支持文件" width={800} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <UploadFiles {...uploadProps} />
            </Modal>
        </>
    );
};

export default VenueTable;
