import React, { useEffect, useState } from "react";
import { Tabs, Form, Button, Col, Row, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./index.less";
import XFormItem from "@/components/FormItem";
import itemList from "@/assets/mock/accountSettings.json";
const { TabPane } = Tabs;
const AccountSettings = () => {
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [imageUrl, setImageUrl] = useState();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传头像
      </div>
    </div>
  );
  // tab切换相关
  const [currentTab, setCurrentTab] = useState("1");
  useEffect(() => {
    console.log(window.location.search.substring(1));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function tabsCallback(e) {
    setCurrentTab(e);
  }
  return (
    <div className="accountSettingsBox">
      <Tabs
        tabPosition="left"
        defaultActiveKey="1"
        activeKey={currentTab}
        onChange={tabsCallback}
      >
        <TabPane tab="基本设置" key="1">
          <p>基本设置</p>
          <Row gutter={32}>
            <Col span={12}>
              <Form form={form} layout="vertical">
                <XFormItem itemList={itemList}></XFormItem>
              </Form>
              <Button type="primary">提交</Button>
            </Col>
            <Col span={8}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                // onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};
export default AccountSettings;
