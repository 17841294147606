import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import commonService from "@/service/common";
import {Modal, Collapse, Form, Input, Radio, Select, InputNumber, message} from "antd";
import "../MopModules/modules.less";
import { UploadFiles } from "smart-filer-react";

const ReviewEditModal = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [settingItem, setSettingItem] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    // eslint-disable-next-line
    const [brandList, setBrandList] = useState([]);
    const [uploadProps, setUploadProps] = useState();
    const [auditUploadProps, setAuditUploadProps] = useState();
    const [fileList, setFileList] = useState([]);
    const [auditFileList, setAuditFileList] = useState([]);
    const { Panel } = Collapse;
    const { TextArea } = Input;
    useEffect(() => {
        getBrandList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const queryFile = async (rowData, catalog) => {
        let params = {
            filterData: {
                'relatedId': rowData.auditId,
                'catalog': catalog
            }
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileItem = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            if (['businessLicense'].includes(catalog)) {
                setFileList(fileItem);
            } else {
                setAuditFileList(fileItem)
            }
            setMappingData(params.filterData, fileItem, catalog)
        }
    }
    const setMappingData = (mappingData, data, catalog) => {
        let updata = {
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            defaultFileList: data,
            showUploadList: {
                showPreviewIcon: true,
                showRemoveIcon: true,
            },
            isMapping: true,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                if (['businessLicense'].includes(catalog)) {
                    setFileList(fileList);
                } else {
                    setAuditFileList(fileList)
                }
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    if (['businessLicense'].includes(catalog)) {
                        setFileList(fileList);
                    } else {
                        setAuditFileList(fileList)
                    }
                }
            },
        };
        if (['businessLicense'].includes(catalog)) {
            setUploadProps(updata);
        } else {
            setAuditUploadProps(updata)
        }
    };
    const getBrandList = () => {
        commonService.getBrandList({}).then(res => {
            if (res && res.success) {
                let data = res.content.map(item => ({ label: item, value: item }))
                setBrandList(data)
            }
        })
    }
    const showModal = async (btn, rowData) => {
        setSelectedData(rowData)
        await queryFile(rowData, 'businessLicense')
        await queryFile(rowData, '酒店审核文件')
        getAuditOptions(rowData)
    }
    const handleOk = () => {
        form.validateFields().then((values) => {
            if (fileList.length) {
                values.businessLicenseFiles = fileList
            }
            if (auditFileList.length) {
                values.auditFile = auditFileList
            }
            let params = {
                auditId: selectedData.auditId,
                AuditData: values
            }
            commonService.saveAudit(params).then(res => {
                if (res && res.success) {
                    message.success("保存成功");
                    props.updateChild()
                    setIsModalOpen(false);
                }
            })
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    const getAuditOptions = (rowData) => {
        let params = {
            collection: "cfg-supplier-audit-options-Xcenter",
            filter: {
                tenantId: { "$in": ["*", props.defaultParams.tenantId] },
            },
            projection: {
                "elements": 1
            }
        };
        commonService.GetSettings(params).then(res => {
            if (res && res.success) {
                setSettingItem(res.content.elements)
                let formItem = []
                res.content.elements.forEach(ele => {
                    formItem = [...formItem, ...ele.child]
                })
                queryAudit(formItem, rowData)
            }
        })
    }
    const queryAudit = (formItem, rowData) => {
        let params = {
            auditId: selectedData.auditId ? selectedData.auditId : rowData.auditId
        }
        commonService.queryAuditData(params).then(res => {
            if (res && res.success) {
                let data = res.content
                formItem.forEach(ele => {
                    form.setFieldsValue({ [ele.val]: data[ele.val] });
                })
                setIsModalOpen(true);
            }
        })
    }
    function generateListItems (item) {
        let inputNode = <Input />
        switch (item.type) {
            case 'radio':
                inputNode = (<Radio.Group>
                    { item.children.length > 0 && item.children.map((ele, index) => {
                        return <Radio key={index} value={ele.val}> {ele.txt} </Radio>
                    })}

                </Radio.Group>)
                break
            case 'select':
                let options = item.children
                if (item.operate) {
                    const operateTxt = item.operate ? item.operate.replace(/"/g, "") : null
                    // eslint-disable-next-line no-eval
                    options = JSON.parse(eval(operateTxt))
                }
                inputNode = <Select options={options}/>
                break;
            case 'Number':
                inputNode = <InputNumber style={{width:'100%'}} min={0} max={100} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || 0)}/>
                break;
            case 'file':
                inputNode = <UploadFiles {...uploadProps} />
                break;
            case 'auditFile':
                inputNode = <UploadFiles {...auditUploadProps} />
                break;
            case 'textarea':
                inputNode = <TextArea rows={4} />
                break;
            default:
                inputNode = <Input disabled={item.disabled}/>
                break;
        }
        return (
            <Form.Item
                name={item.val}
                label={item.txt}
                style={{ margin: 0 }}
                rules={[ { required: item.required, message: item.message, }, ]}
            >
                {inputNode}
            </Form.Item>
        )
    }

    return (
        <Modal title="合规审核" width={800} maskClosable={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form form={form} component={false} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} labelAlign="left">
                <Collapse defaultActiveKey={settingItem.length > 0 ? settingItem[0].name : ''}>
                    { settingItem.length > 0 && settingItem.map((ele, index) => {
                        return (
                            <Panel header={ele.name} key={ele.name}>
                                {ele.child.length > 0 && ele.child.map((item) => (
                                    <React.Fragment key={item.val}>
                                        {generateListItems(item)}
                                    </React.Fragment>
                                ))}
                            </Panel>
                        )
                    })
                    }
                </Collapse>
            </Form>
        </Modal>
    );
});

export default ReviewEditModal;
