import React, { useEffect, useState, useRef } from 'react';
import { Drawer } from 'antd';
import './index.less';
import commonService from '@/service/common';
import InfoDetial from './compments/InfoDetial';
import ShopcoolTask from './compments/ShopcoolTask';
import showIframe from './compments/showIframe';
import showMicroApp from './compments/showMicroApp';
import FinanceTaskDetail from './compments/financeTaskDetail';
import EasyMeetingPayment from './compments/easyMeetingPayment';
import BillingWorkbench from './compments/billingWorkbench';
import SmartPay from './compments/SmartPay';
import InfoCard from './compments/MopModules/InfoCard';
import QueryCard from './compments/MopModules/QueryCard';
import FooterBtnsCard from './compments/MopModules/FooterBtnsCard';
import HotelDetails from './compments/MopModules/HotelDetails';
import HoteDemand from './compments/MopModules/HoteDemand/HoteDemand';
import OriginalForm from './compments/OriginalForm';
import OriginalYida from './compments/OriginalYida';
import SmartGallery from '@/pages/SmartGallery';
import FilePro from './compments/FinanceModules/FilePro';
import FinanceFooterBtnsCard from './compments/FinanceModules/FinanceFooterBtnsCard';
import FinanceInfoCard from './compments/FinanceModules/FinanceInfoCard';
import FinancePaymentRecord from './compments/FinanceModules/FinancePaymentRecord';
import FinanceSupportFiles from './compments/FinanceModules/FinanceSupportFiles';
import FinanceInvoice from './compments/FinanceModules/FinanceInvoice';
import ChannelInfoCard from './compments/FinanceChannelModules/ChannelInfoCard';
import ChannelQuery from './compments/FinanceChannelModules/ChannelQuery';
import Footer from './compments/FinanceChannelModules/Footer';

const components = {
    InfoDetial: InfoDetial,
    shopcoolTask: ShopcoolTask,
    financeTaskDetail: FinanceTaskDetail,
    easyMeetingPayment: EasyMeetingPayment,
    showIframe,
    OriginalForm,
    OriginalYida,
    showMicroApp,
    smartPay: SmartPay,
    billingWorkbench: BillingWorkbench,
    // mop的card
    InfoCard,
    QueryCard,
    FooterBtnsCard,
    HotelDetails,
    HoteDemand,
    smartGallery: SmartGallery,
    // finance modules
    FilePro,
    FinanceFooterBtnsCard,
    FinanceInfoCard,
    FinancePaymentRecord,
    FinanceSupportFiles,
    FinanceInvoice,
    // finance channel modules
    ChannelInfoCard,
    ChannelQuery,
    Footer,
};
const IqueryDetailDrawer = (props) => {
    // 模块数组
    const [modules, setModules] = useState([]);
    // 抽屉标题
    const [drawerTitle, setDrawerTitle] = useState('');
    // 详情页
    const [detialsData, setDetialsData] = useState(null);
    const [drawerWhite, setDrawerWhite] = useState(800);
    const [maskClosableData, setMaskClosableData] = useState('');

    const OriginalFormRef = useRef();

    // ref对象结构
    const refObj = {
        OriginalForm: OriginalFormRef,
    };

    // 点击行返回数据
    // eslint-disable-next-line no-unused-vars
    const [clickVal, setClickVal] = useState({});
    const getMeetingInfo = async () => {
        setClickVal();
        let moudleParams = {
            collection: 'cfg-web-modular',
            filter: {
                Application: 'XCENTER',
                tenantId: '*',
                type: props.drawerData.type || props.radioQueryData.pageType,
                tenantCode:
                    props.drawerData.type === 'smartGallery'
                        ? '*'
                        : props.drawerData?.tenantCode,
                abbrCode: {
                    $in:
                        props.drawerData.type === 'smartGallery'
                            ? ['*']
                            : [
                                  props.drawerData?.abbrCode ||
                                      props.radioQueryData.pageType,
                              ],
                },
            },
        };
        let tempParams = {
            collection: 'cfg-ievent-infoCard-template',
            filter: {
                tenantCode: 'hyq',
                tenantId: '*',
                platform: 'web',
                type: props.radioQueryData.pageType,
            },
        };
        const res1 = await commonService.GetSettings(moudleParams);
        console.log(res1, 'res1');
        setMaskClosableData(!res1?.content?.maskClosable);
        setDrawerWhite(
            res1?.content?.drawerWhite ? res1.content.drawerWhite : '1000',
        );
        res1.success && setModules(res1.content?.modules);
        const res2 = await commonService.GetSettings(tempParams);
        console.log('res', res2);
        res2.success && setDetialsData({ ...res2.content });
        setDrawerTitle(res1.content?.modules[0]?.title || '详情');
    };
    const onClose = () => {
        // console.log(props.iqueryDetailDrawerVisible);
        props.iqueryDetailDrawerCallback({ type: 'cancel' });
    };
    /**
     *
     * @param {*} btn 按钮信息
     */
    const callback = (info) => {
        if (
            info.btnInfo &&
            info?.btnInfo?.actDirective === 'pay-item-voucher'
        ) {
            setTimeout(() => {
                OriginalFormRef.current.refreshData();
            }, 1000);
        }
        OriginalFormRef.current.hanndleSubmit();
    };
    const onSumbit = (val) => {
        localStorage.setItem('relevanceData', JSON.stringify(val));
    };
    useEffect(() => {
        if (props.iqueryDetailDrawerVisible) {
            getMeetingInfo();
        }
    }, [props.iqueryDetailDrawerVisible, props.radioQueryData]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Drawer
            title={drawerTitle}
            placement="right"
            onClose={onClose}
            maskClosable={maskClosableData}
            destroyOnClose={true}
            visible={props.iqueryDetailDrawerVisible}
            width={drawerWhite}
        >
            {modules?.map((x, index) => {
                const TagName = components[x.comName];
                const refName = refObj[x.comName];
                return (
                    <TagName
                        key={index}
                        ref={refName}
                        dataInfo={detialsData}
                        requestVal={props.drawerData}
                        queryObject={props.queryObject}
                        pageType={props.radioQueryData.pageType}
                        microData={x.microData}
                        iqueryDetailDrawerCallback={
                            props.iqueryDetailDrawerCallback
                        }
                        modelInfo={modules}
                        moduleItem={x}
                        status={props.iqueryDetailDrawerVisible}
                        defultCol={2}
                        callback={callback}
                        onSumbit={onSumbit}
                    />
                );
            })}
        </Drawer>
    );
};
export default IqueryDetailDrawer;
