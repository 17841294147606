import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import commonService from "@/service/common";
import {Modal, Form, Input, InputNumber, message, DatePicker} from "antd";
import "../../MopModules/modules.less";
import moment from "moment/moment";

const ReviewEditModal = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [editRemark, setEditRemark] = useState(false);
    const dateFormat = 'YYYY/MM/DD';
    const { TextArea } = Input;
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const showModal = (rowData, type) => {
        setEditRemark(type === 'remark')
        setSelectedData(rowData)
        let childData = {
            quoteDeadTime: rowData.quoteDeadTime ? moment(rowData.quoteDeadTime) : '',
            dateNoFee: rowData.dateNoFee ? moment(rowData.dateNoFee) : '',
            datePercentFee: rowData.datePercentFee ? moment(rowData.datePercentFee) : '',
            lossPercent: rowData.lossPercent || 0,
            dateAllFee: rowData.dateAllFee ? moment(rowData.dateAllFee) : '',
            others: rowData.others,
            remark: rowData.remark,
        }
        form.setFieldsValue(childData);
        setIsModalOpen(true)
    }
    const handleOk = () => {
        form.validateFields().then((values) => {
            let params = {
                miceId: props.requestVal.processId,
                supplierId: selectedData.supplierId,
                orderId: props.listItem.orderId,
                quoteDeadTime: moment(values.quoteDeadTime).format('YYYY-MM-DD'),
                dateNoFee: moment(values.dateNoFee).format('YYYY-MM-DD'),
                datePercentFee: moment(values.datePercentFee).format('YYYY-MM-DD'),
                lossPercent: values.lossPercent,
                dateAllFee: moment(values.dateAllFee).format('YYYY-MM-DD'),
                others: values.others,
                remark: values.remark,
            }
            commonService.SaveSupplierClause(params).then(res => {
                if (res && res.success) {
                    message.success("保存成功");
                    props.queryClauses()
                    setIsModalOpen(false);
                }
            })
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));

    return (
        <Modal title="报价有效期及取消条款" width={800} maskClosable={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form form={form} component={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left">
                <Form.Item  label="供应商" style={{ margin: 0 }} >
                    {selectedData.supplierName}
                </Form.Item>
                <Form.Item  label="总报价" style={{ margin: 0 }} >
                    {selectedData.quote}
                </Form.Item>
                <Form.Item name="quoteDeadTime" label="价格有效期及资源保留期" style={{ margin: 0 }} rules={[ { required: true, message: '请选择价格有效期及资源保留期!', }, ]}>
                    <DatePicker format={dateFormat} disabled={editRemark}/>
                </Form.Item>
                <Form.Item label={(<><span style={{ color: 'red' }}>*</span> 取消条款</>)} style={{ margin: 0 }}>
                    <div className="formItemBox">
                        <div>
                            <Form.Item name="dateNoFee" rules={[{ required: true, message: '请选择不收取费用截止日期!' }]} style={{ display: 'inline-block' }}>
                                <DatePicker format={dateFormat}  disabled={editRemark}/>
                            </Form.Item>
                            前取消，不收取费用
                        </div>
                        <div>
                            <Form.Item name="datePercentFee" rules={[{ required: true, message: '请选择收取费用按合同金额百分比截止日期!' }]} style={{ display: 'inline-block' }}>
                                <DatePicker format={dateFormat}  disabled={editRemark}/>
                            </Form.Item>
                            前取消，按合同金额
                            <Form.Item name="lossPercent" rules={[{ required: true, message: '请填写收取费用百分比!' }]} style={{ display: 'inline-block' }}>
                                <InputNumber min={0} max={100} disabled={editRemark} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || '0')}/>
                            </Form.Item>
                            %收取费用
                        </div>
                        <div>
                            <Form.Item name="dateAllFee" rules={[{ required: true, message: '请选择收取全部费用截止日期!' }]} style={{ display: 'inline-block' }}>
                                <DatePicker format={dateFormat}  disabled={editRemark}/>
                            </Form.Item>
                            前取消，收取全部费用
                        </div>
                    </div>
                </Form.Item>
                <Form.Item name="others" label="其他条款" style={{ margin: 0 }} rules={[ { required: false, message: '', }, ]}>
                    <TextArea rows={4}  disabled={editRemark}/>
                </Form.Item>
                <Form.Item name="remark" label="含税金额合计备注" style={{ marginTop: '10px' }} rules={[ { required: false, message: '', }, ]}>
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default ReviewEditModal;
