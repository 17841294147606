import React from "react";
import { Form, Col, Radio } from "antd";
import defaultSettings from "./defaultSettings";

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const xRadio = (props) => {
  const item = props.item;

  function defaultChangeClick(e, item, sData) {
    item.callBack && props.callback(e, item, sData);
  }

  return (
    <>
      <Col span={item.span}>
        <Form.Item
          name={item.name}
          rules={item.rules}
          labelCol={{ span: item.lcol || defaultLabelSpan }}
          wrapperCol={{ span: item.wcol || defaultWrapperSpan }}
          label={item.label}
          hidden={item.hidden}
          labelAlign="right"
        >
          <Radio.Group
            options={item.options}
            disabled={item.disabled && true}
            onChange={(e) => defaultChangeClick(e, item)}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default xRadio;
