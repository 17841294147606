(function rem(win, doc) {
  console.log("REACT_APP_ENV:", process.env);
  if (process.env.REACT_APP_ENV === "prd") {
    win.onload = function () {
      var script = doc.createElement("script");
      script.innerHTML = `!(function(c,b,d,a){c[a]||(c[a]={});c[a].config=
      {
        pid:"9yzwejm09y@d54d58dfcb31461",
        appType:"web",
        imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",
        sendResource:true,
        enableLinkTrace:true,
        behavior:true
      };
    with(b)with(body)with(insertBefore(createElement("script"),firstChild))setAttribute("crossorigin","",src=d)
    })(window,document,"https://retcode.alicdn.com/retcode/bl.js","__bl");`;
      doc.body.appendChild(script);
    };
  }
})(window, document);
