import React, { useEffect } from "react";
import { Modal, Descriptions, Button } from "antd";
import moment from "moment";
import commonService from "@/service/common";
import styles from "./qrcodeModal.module.less";
// import QRCode from "qrcode.react";

const QrcodeModal = (props) => {
  const { qrcodeVisable, qrcodeData } = props;
  // const [qrcodeUrl, setQrcodeUrl] = useState("");
  const handleCancel = () => {
    props.qrcodeCancel(false);
  };
  const getQrcodeInfo = () => {
    commonService
      .common("post", "/api/iWork/qrCode/createQrCode", qrcodeData, {
        responseType: "blob",
      })
      .then((res) => {
        // eslint-disable-next-line no-undef
        const url = (URL || webkitURL).createObjectURL(
          new Blob([res], { type: "img/jpeg" })
        );
        let imgEle = document.getElementById("secret");
        imgEle.src = url;
        // imgEle.onload = (e) => {
        //   URL.revokeObjectURL(url);
        // };
        // console.log(src, "11111");
      });
  };
  // 二维码页面打印
  const printQcode = () => {
    setIframeStyle();
    setTimeout(() => {
      let printHtml = document.getElementById("qrCode").innerHTML;
      let syfPrint = document.getElementById("oprs-iframe");
      syfPrint.contentDocument.body.innerHTML = printHtml;
      // 也可在此处修改要打印的html内容再调用打印
      syfPrint.contentDocument.execCommand("Print");
    }, 200);
  };
  // 设置打印样式
  const setIframeStyle = () => {
    // 在打印的iframe里加入项目已经写好的样式
    let syfPrint = document.getElementById("oprs-iframe");
    let documentHead = document.getElementsByTagName("head")[0];
    let iframeHead = syfPrint.contentDocument.getElementsByTagName("head")[0];
    iframeHead.innerHTML = documentHead.innerHTML;
  };
  useEffect(() => {
    getQrcodeInfo();
    setIframeStyle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      title="预留信息"
      visible={qrcodeVisable}
      onCancel={handleCancel}
      footer={null}
      width={800}
      maskClosable
      centered
    >
      <div>
        {/* 打印按钮 */}
        <div className={styles.btns}>
          <Button size="small" onClick={printQcode}>
            打印
          </Button>
        </div>
        {/* 需要打印内容 */}
        <div id="qrCode">
          <Descriptions title="二维码信息" column={2}>
            <Descriptions.Item
              label={qrcodeData.bindCode ? "装订号/凭证号" : "封箱号"}
            >
              {qrcodeData.bindCode || qrcodeData.boxCode}
            </Descriptions.Item>
            <Descriptions.Item label="公司名称">
              {qrcodeData.company}
            </Descriptions.Item>
            <Descriptions.Item label="发票张数">
              {qrcodeData.invoiceCount}
            </Descriptions.Item>
            <Descriptions.Item
              label={qrcodeData.bindCode ? "装订人" : "封箱人"}
            >
              {qrcodeData.createdBy}
            </Descriptions.Item>
            <Descriptions.Item
              label={qrcodeData.bindCode ? "装订时间" : "封箱时间"}
            >
              {moment(qrcodeData.createdOn).format("YYYY-MM-DD HH:mm:ss")}
            </Descriptions.Item>
          </Descriptions>
          <div className={styles.imgDiv}>
            <img alt="" id="secret" />
          </div>
        </div>
        {/* 打印的隐藏iframe */}
        <iframe
          width="0"
          height="0"
          frameborder="0"
          id="oprs-iframe"
          title="qrCodePrint"
        ></iframe>
      </div>
    </Modal>
  );
};

export default QrcodeModal;
