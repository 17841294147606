/* eslint-disable no-eval */
import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from "react";
import {Modal, Button, Divider, Descriptions} from "antd";
import { UploadFiles } from "smart-filer-react";
import commonService from "@/service/common";
const ItinerarySettlementDetails = forwardRef((props, ref) => {
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [uploadProps, setUploadProps] = useState();
    const [form, setForm] = useState({})
    const [fileList, setFileList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [passengersList, setPassengersList] = useState([{ name: '', hospital: '' }]);
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    const queryFile = async (record, rowData)=> {
        let params = {
            filterData: {
                processId: record.orderId,
                actionId: record.requestId,
                itemid: record.bussinessId,
                relatedId: rowData.expenseId,
                catalog: "行车轨迹"
            }
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileItem = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            setFileList(fileItem);
            setMappingData(params.filterData, fileItem, rowData)
        }
    }
    const setMappingData = (mappingData, fileItem, rowData) => {
        let updata = {
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            defaultFileList: fileItem,
            disabled: true,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                setFileList(fileList);
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    setFileList(fileList);
                }
            },
        };
        setUploadProps(updata);
        initForm(rowData)
    }
    const showModal = (rowData, record) => {
        let passengersList = rowData.passengersList || [];
        setPassengersList(passengersList)
        queryFile(record, rowData)
    }
    const initForm = (rowData) => {
        let data = Object.assign(rowData, rowData.costDetails)
        setForm(data)
        setIsModalOpen(true);
    }
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <Modal title="结算信息" width={700} maskClosable={false} open={isModalOpen} onCancel={handleCancel} footer={[
            <Button onClick={handleCancel}> 取消 </Button>,
        ]}>
            <Descriptions column={2}>
                <Descriptions.Item label="用车形式">
                    {form.carModus}
                </Descriptions.Item>
                <Descriptions.Item label="车型">
                    {form.carType}
                </Descriptions.Item>
                <Descriptions.Item label="公里数">
                    {form.kilometers}
                </Descriptions.Item>
                <Descriptions.Item label="乘车人数">
                    {form.pieceQty}
                </Descriptions.Item>
                <Descriptions.Item label="出发地" span={2}>
                    {form.departure}
                </Descriptions.Item>
                <Descriptions.Item label="目的地" span={2}>
                    {form.destination}
                </Descriptions.Item>
                <Descriptions.Item label="上车时间">
                    {form.sDateTime}
                </Descriptions.Item>
                <Descriptions.Item label="下车时间">
                    {form.eDateTime}
                </Descriptions.Item>
                <Descriptions.Item label="车牌号"  span={2}>
                    {form.licensePlate}
                </Descriptions.Item>
                <Descriptions.Item label="行车轨迹" span={2}>
                    <UploadFiles {...uploadProps} />
                </Descriptions.Item>
                <Descriptions.Item label="费用明细" span={2}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="单价">
                            {form.price}
                        </Descriptions.Item>
                        <Descriptions.Item label="超公里费">
                            {form.overMileageFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="超时费">
                            {form.overtimeFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="路桥费">
                            {form.roadFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="停车费">
                            {form.parkingFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="等待费">
                            {form.waitingFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="接机/接站服务费">
                            {form.serviceFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="其它费用">
                            {form.otherFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="费用合计">
                            {form.money}
                        </Descriptions.Item>
                    </Descriptions>
                </Descriptions.Item>
                <Descriptions.Item label="供应商备注">
                    {form.descr}
                </Descriptions.Item>
                <Divider dashed />
                <Descriptions.Item label="乘车人">
                    {passengersList.length && passengersList.map((passenger, index) => (
                        <Descriptions  column={2} key={`passenger-${index}`}>
                            <Descriptions.Item label="">
                                {passenger.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="乘车人公司">
                                {passenger.hospital}
                            </Descriptions.Item>
                        </Descriptions>
                    ))}
                </Descriptions.Item>
            </Descriptions>
        </Modal>
    )
})
export default ItinerarySettlementDetails
