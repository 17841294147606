import { enterSearch } from "../actions/actionsType";

const initialValue = {
  enterState: false,
};

const enterReducer = (preState = initialValue, action) => {
  let { type, payload } = action;
  let newState = { ...preState };
  switch (type) {
    case enterSearch:
      newState.enterState = payload;
      return newState;
    default:
      return preState;
  }
};

export default enterReducer;
