import React, { useEffect, useState } from "react";
import { Form, Col, Cascader, Select, Input } from "antd";
import defaultSettings from "./defaultSettings";
import commonService from "@/service/common";

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const XCountryCascader = (props) => {
  const item = props.item;
  const [options, setOptions] = useState(item.options ? item.options : []);
  const [cityOptions, setCityOptions] = useState([]);
  const [showInput, setShowInput] = useState(item.showInput || "");
  const [loading, setLoading] = useState(true);

  const { Option } = Select;

  const defaultChangeClick = (e, item, sData, type) => {
    props.callback(e, item, sData, type);
    console.log(e, item, sData);

    if (type !== "Country") return;
    
    if (e === "China") {
      setShowInput("selectCity");
      let sityList = options.filter((item) => item.Val === "China");
      if(sityList.length > 0) {
        setCityOptions(sityList[0]?.SubTypes);
      } else {
        setCityOptions([])
      }
    } else {
      setShowInput("");
    }
  };

  const getOptions = async (source) => {
    let params = source.params;
    let method = source.method;
    let url = source.url;

    setLoading(true);
    let res = await commonService.common(method, url, params);
    setLoading(false);
    if (res && res.success && res.content) {
      setOptions(res.content);
    }
  };

  useEffect(() => {
    if(item.dataSource){
      getOptions(item.dataSource)
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (item.showInput === "selectCity" && options.length > 0) {
      let sityList = options.filter((item) => item.Val === "China");
      setCityOptions(sityList[0]?.SubTypes);
    }
  }, [item.showInput, options]);

  return (
    <>
      <Col span={item.span}>
        <Form.Item
          name={item.name}
          rules={item.rules}
          labelCol={item.lcol || { span: defaultLabelSpan }}
          wrapperCol={item.wcol || { span: defaultWrapperSpan }}
          label={item.label}
          labelAlign="right"
        >
          <Select
            style={{ width: "100%" }}
            mode={item.mode || "default"}
            loading={loading}
            disabled={item.disabled && true}
            allowClear={!item.allowClear}
            placeholder={item.placeholder}
            showSearch={item.showSearch}
            optionFilterProp="children"
            onChange={(e, sData) => defaultChangeClick(e, item, sData, "Country")}
          >
            {options.map((obj) => (
              <Option
                key={obj.value || obj.Val || obj.Value || obj._id + "__" + obj.Code}
                value={obj.value || obj.Val || obj.Value || obj.Id}
              >
                {obj.Txt || obj.Name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {showInput === "selectCity" && (
          <Form.Item
            name="provinceCity"
            rules={[{ required: true, message: "请选择省份城市" }, {type: "array", min: 2, message: "至少选择两级,选到城市名称"}]}
            labelCol={item.lcol || { span: defaultLabelSpan }}
            wrapperCol={item.wcol || { span: defaultWrapperSpan }}
            label="省份/城市"
            labelAlign="right"
          >
            <Cascader
              changeOnSelect
              options={cityOptions}
              showSearch={item.showSearch}
              allowClear={item.allowClear || true}
              fieldNames={item.fieldNames}
              // disabled={item.disabled && true}
              placeholder={item.placeholder}
              onChange={(e, sData) => defaultChangeClick(e, { name: "provinceCity" }, sData)}
            />
          </Form.Item>
        )}
        {showInput === "inputCity" && (
          <Form.Item
            name="areaCity"
            rules={[{ required: true, message: "请输入国外城市名" }]}
            labelCol={item.lcol || { span: defaultLabelSpan }}
            wrapperCol={item.wcol || { span: defaultWrapperSpan }}
            label="城市"
            labelAlign="right"
          >
            <Input
              placeholder="请输入国外城市名"
              allowClear
              onChange={(e) => defaultChangeClick(e, { name: "areaCity" })}
            />
          </Form.Item>
        )}
        {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
      </Col>
    </>
  );
};

export default XCountryCascader;
