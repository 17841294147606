
import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Tooltip, Row, Alert, Select, Tabs, Dropdown, Spin, Menu, Empty, Checkbox, Modal } from 'antd';

import XIconFont from "@/components/IconFont";
import RViewerJS from 'viewerjs-react';
import commonService from "@/service/common";
const { Option } = Select;
const AIRecognition = (props) => {
    const {
        galleryData,
        loding,
        lodeMore,
        scrollData,
        onlinePreview,
        pageIndex
    } = props;
    return (<>
        <div>敬请期待。。。</div>

    </>)
}
export default AIRecognition;