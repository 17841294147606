import React, { useEffect } from "react";
import "./index.less";
import { useHistory } from "react-router";
import { setCookie } from "tiny-cookie";
import commonService from "@/service/common";
const Authorization = () => {
  const history = useHistory();
  // const [num, setNum] = useState(3);
  const appendCookie = (data, setting, config) => {
    for (var i in setting) {
      let value = data[setting[i]];
      console.log(i, value, config);
      if (value) setCookie(i, value);
    }
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };
  const loginSuccess = (response) => {
    // 获取过期时间
    const userInfoSetting = {
      x_token: "access_token",
      tok_expires_in: "expires_in",
      ref_session: "refresh_token",
      organization_type: "organization_type",
      x_user_id: "id",
      user_name: "name",
      org_id: "org_id",
      tenant_code: "tenant_code",
      tenant_id: "tenant_id",
      x_role_codes: "role_codes",
      directory_id: "directory_id",
    };
    const userInfoConfig = { expires: "Session", domain: window.idomain };

    appendCookie(response, userInfoSetting, userInfoConfig);
  };
  const setData = (data) => {
    data && loginSuccess(data.auth);
  };
  const cacheData = () => {
    let url = window.location.search.substring(1);
    let urlParamsObj = {};
    if (url) {
      let urlParams = url.split("&");

      urlParams.forEach((item) => {
        let temp = item.split("=");
        urlParamsObj[temp[0]] = temp[1];
      });
    }
    console.log("====url参数====",urlParamsObj);
    if (!urlParamsObj.sessionId) return;
    return new Promise(async function (resolve) {
      let params = {
        sessionId: urlParamsObj.sessionId || "",
      };
      let res = await commonService.queryBySessionId(params);
      if (res && res.success) {
        setData(res.content);
      }
    });
  };
  useEffect(() => {
    console.log(window.location.search.substring(1));
    cacheData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="timeBox">
      {/* <span>{num}</span> */}
      跳转中，请稍等
    </div>
  );
};
export default Authorization;
