import React, { useEffect } from "react";
import { Modal, Button, Descriptions, Image } from "antd";
const DetailModal = (props) => {
  console.log(props, "props");
  const setModalVisible = () => {
    props.detailModalCallback({ type: "cancel" });
  };
  const fileUpload = (url) => {
    window.open(url);
  };
  useEffect(() => {}, [props.modalVisible]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Modal
      title="会议详情"
      centered
      visible={props.modalVisible}
      maskClosable
      onCancel={setModalVisible}
      footer={[
        <Button key="back" onClick={setModalVisible}>
          确定
        </Button>,
      ]}
      width={1000}
    >
      <Descriptions title="会议基本信息">
        <Descriptions.Item label="会议编号">
          {props.recordData.poNum}
        </Descriptions.Item>
        <Descriptions.Item label="会议名称">
          {props.recordData.name}
        </Descriptions.Item>
        <Descriptions.Item label="会议城市">
          {props.recordData.city}
        </Descriptions.Item>
        <Descriptions.Item label="会议时间">
          {props.recordData?.dtStart?.slice(0, 10)}~
          {props.recordData?.dtEnd?.slice(0, 10)}
        </Descriptions.Item>
        <Descriptions.Item label="PONum">
          {props.recordData.poNum}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="讲者信息">
        <Descriptions.Item label="讲者姓名">
          {props.recordData.speakerName}
        </Descriptions.Item>
        <Descriptions.Item label="讲者编号">
          {props.recordData.speakerNum}
        </Descriptions.Item>
        <Descriptions.Item label="医院">
          {props.recordData.hospital}
        </Descriptions.Item>
        <Descriptions.Item label="科室">
          {props.recordData.department}
        </Descriptions.Item>
        <Descriptions.Item label="手机号码">
          {props.recordData.mobile}
        </Descriptions.Item>
        <Descriptions.Item label="身份证号">
          {props.recordData.idNum}
        </Descriptions.Item>
        <Descriptions.Item label="开户行">
          {props.recordData.bank}
        </Descriptions.Item>
        <Descriptions.Item label="银行卡号">
          {props.recordData.bankCardNum}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="付款信息">
        <Descriptions.Item label="培训费金额">
          {props.recordData.orignalAmout}
        </Descriptions.Item>
        <Descriptions.Item label="付款金额">
          {props.recordData.payAmout}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="客户信息">
        <Descriptions.Item label="客户名称">
          {props.recordData.custumer}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="隔离原因">
        <Descriptions.Item label="隔离原因">
          {props.recordData.isolationReason}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={1} title="电子协议">
        <Descriptions.Item label="电子协议状态">
          {props.recordData.agreementStatus}
        </Descriptions.Item>
        <Descriptions.Item label="培训协议状态">
          {props.recordData.miceAgreementStatus}
        </Descriptions.Item>
        {props.recordData.url !== "" && (
          <Descriptions.Item label="培训协议文件">
            <Image
              width={200}
              preview={false}
              src="https://spkg.smartmice.cn/resources/images/public/ty.png"
              onClick={() => fileUpload(props.recordData.url)}
            />
          </Descriptions.Item>
        )}
        <Descriptions.Item label="讲者签到状态">
          {props.recordData.joinStatus}
        </Descriptions.Item>
        {props.recordData.signUrl !== "" && (
          <Descriptions.Item label="讲者签到记录">
            <Image width={200} src={props.recordData.signUrl} />
          </Descriptions.Item>
        )}
      </Descriptions>
    </Modal>
  );
};
export default DetailModal;
