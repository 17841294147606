import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row, Alert, Tabs, Spin, Modal, Menu, Tooltip } from 'antd';
import { SearchOutlined,FullscreenOutlined } from "@ant-design/icons";
import XFormItem from "@/components/FormItem";

import GalleryComponent from "./components/GalleryComponent";
import AIRecognition from "./components/AIRecognition";
import KKfileViewerModal from "@/components/KKfileViewerModal";
import RemakeRecognition from "./components/RemakeRecognition";
import "./index.less";

import commonService from "@/service/common";
import moment from "moment";

import { Base64 } from 'js-base64';

const components = {
    RemakeRecognition,
    GalleryComponent,
    AIRecognition
};
function getItem(label, key, icon, children, type, dataCommponent, dataType) {
    return {
        key,
        icon,
        children,
        label,
        type,
        dataCommponent,
        dataType
    };
}
let items = [
    getItem(<Tooltip placement="rightTop" title='所有' color="black">
        所有
    </Tooltip>, '1', '', '', '', 'GalleryComponent'),
    getItem('收藏', '2', '', '', '', 'GalleryComponent'),
    getItem('统计', '3', '', '', '', 'GalleryComponent'),
    getItem('实验室', '4', '', [
        getItem('翻拍识别', '5', null, '', '', 'RemakeRecognition', 'retake-recognition'),
        getItem('水单识别', '6', null, '', '', 'RemakeRecognition', 'waterBill'),
        getItem('发票识别', '7', null, '', '', 'AIRecognition', 'meal-menu-comprehend'),
        getItem('参会画像', '8', null, '', '', 'AIRecognition', 'meal-menu-comprehend'),
        getItem('人脸识别', '9', null, '', '', 'AIRecognition', 'meal-menu-comprehend'),
        getItem('机器人对话', '10', null, '', '', 'AIRecognition', 'meal-menu-comprehend'),
    ], '', 'AIRecognition'
    )
]
const SmartGallery = (props) => {
    const [form] = Form.useForm();
    const [galleryData, setGalleryData] = useState(null);
    const [pageIndex, setPageIndex] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loding, setLoding] = useState(false);
    const [parameters, setParameters] = useState(null);
    const [Fileds, setFileds] = useState(null);
    const [modules, setModules] = useState([{ comm: 'GalleryComponent' }]);
    const [iframeUrl, setIframeUrl] = useState('');
    useEffect(() => {
        if(props?.requestVal?.noSearch){
            setFileds(JSON.parse(props?.requestVal?.paramsData))
            getQueryData(1,JSON.parse(props?.requestVal?.paramsData))
        }else{
            GetQueryObjectX();
        }
        
    }, []);

    useEffect(() => {
        parameters && onSearch();
    }, [parameters]);
    const lodeMore = () => {
        let pageIndexP = pageIndex
        setPageIndex(pageIndexP + 1)
        getQueryData(pageIndexP + 1, Fileds);
    }
    const scrollData = async (dom) => {
        const { scrollHeight, clientHeight, scrollTop } = dom.target
        if (scrollTop + clientHeight >= scrollHeight && !loding) { // 5px buffer
            let pageIndexP = pageIndex
            setPageIndex(pageIndexP + 1)
            getQueryData(pageIndexP + 1, Fileds);
        }
    }
    const onlinePreview = (data) => {
        let url = data.url + '&fullfilename=' + data.fileName;
        setIframeUrl(process.env.REACT_APP_FILEWAY + '/onlinePreview?url=' + encodeURIComponent(Base64.encode(url)))
        setIsModalOpen(true)
        setLoding(true)
    }
    const getQueryData = async (pageIndexP, Fileds) => {
        let params = {
            "UsePaging": false,
            "QueryId": "56557347-4bc2-4de2-9b0d-e65a5c2042b8",
            "parameters": {
                "_pageIndex": pageIndexP,
                "_pageSize": 20
            },
            "cluster": "biz"
        }
        params.parameters = { ...Fileds, ...params.parameters }
        setLoding(true)
        commonService.getQueryDataX(props?.requestVal?.noSearch?Fileds:params).then((res) => {
            if (res && res.success) {
                if (pageIndexP == 1) {
                    setGalleryData(res.content.rows)
                } else {
                    let data = galleryData && JSON.parse(JSON.stringify(galleryData))
                    setGalleryData([...data, ...res.content.rows]);
                }

            }
            setLoding(false)
        })
    }
    const changeLoding = (type) => {
        setLoding(type)
    }
    const controlType = (type) => {
        let retunType = "input";
        switch (type) {
            case "query":
            case "enum":
            case "select":
                retunType = "selectQ";
                break;
            case "queryRender":
                retunType = "selectRender";
                break;
            case "text":
                retunType = "input";
                break;
            case "number":
                retunType = "numberInput";
                break;
            case "date":
                retunType = "datePicker";
                break;
            case "date-range":
                retunType = "rangePicker";
                break;
            case "radio":
                retunType = "radio";
                break;
            case "upload":
                retunType = "upload";
                break;
            case "list":
                retunType = "list";
                break;
            case "richtext":
                retunType = "richtext";
                break;
            case "customQuery":
                retunType = "customQuery";
                break;
            case "cascader":
                retunType = "cascader";
                break;
            default:
                retunType = "input";
                break;
        }
        return retunType;
    };
    const GetQueryObjectX = async () => {
        let paramsArr = decodeURIComponent(window.location.search).substr(1).split("&");
        let paramsObj = {};
        paramsArr.length > 1 && paramsArr.forEach((item) => {
            let keyValueArr = item.split("=");
            if (paramsObj[keyValueArr[0]]) return;
            paramsObj[keyValueArr[0]] = keyValueArr[1];
        });
        paramsObj = window.__MICRO_APP_ENVIRONMENT__
        ? window.microApp.getData()
        :
        (props.requestVal ? props.requestVal : paramsObj)
        console.log(paramsObj, "paramsObj");
        let params = {
            "QueryId": "56557347-4bc2-4de2-9b0d-e65a5c2042b8",
            "cluster": "biz"
        }
        setLoding(true)
        commonService.GetQueryObjectX(params).then((res) => {
            if (res && res.success) {
                let arr = [];
                let a = {};

                res.content.parameters.forEach(element => {
                    let obj = {
                        "lcol": 0,
                        "span": element.span || 6,
                        "label": "",
                        "cluster": element.cluster || 'biz',
                        "type": controlType(element.type),
                        "name": element.name || "name",
                        "defaultValue": paramsObj[element.name] ? paramsObj[element.name] : (element.value ? element.value : undefined),
                        "cascadeName": element.cascadeName || null,
                        "queryId": element.queryId,
                        "placeholder": element.placeholder || element.title,
                    }
                    if (obj.type === "rangePicker") {
                        let indexP = paramsObj[element.name] && paramsObj[element.name].indexOf(",")
                        let varl = indexP ? {
                            start: paramsObj[element.name].slice(0, indexP),
                            end: (paramsObj[element.name].slice(indexP + 1))
                        } : null

                        obj.span = 6;
                        obj.formate = element.formate || "YYYY-MM-DD";
                        obj.defaultValue = varl ? [
                            moment(varl.start, element.formate),
                            moment(varl.end, element.formate),
                        ] : (element.value
                            ? [
                                moment(element.value.start, element.formate),
                                moment(element.value.end, element.formate),
                            ] : undefined);
                        obj.placeholder = element.placeholder;

                    }
                    arr.push(obj)
                    a[obj.name] = obj.defaultValue;
                    console.log(obj, "=======")
                    form.setFieldsValue(a);
                });
                setParameters(arr);

            }
            setLoding(false)
        })
    }
    const onSearch = () => {
        form.validateFields().then((data) => {
            console.log(data);
            let Fileds = {};

            parameters && parameters.forEach((element) => {
                let datatype =
                    element.type == "rangePicker" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
                switch (element.type) {
                    case "rangePicker":
                        Fileds[element.name] = {
                            start:
                                data[element.name] && data[element.name][0]
                                    ? moment(data[element.name][0]).format(datatype)
                                    : "?",
                            end:
                                data[element.name] && data[element.name][1]
                                    ? moment(data[element.name][1]).format(datatype)
                                    : "?",
                        };
                        break;
                    case "selectQ":
                        Fileds[element.name] = data[element.name] ? data[element.name] : "?";
                        break;
                    default:
                        console.log(data[element.name]);
                        const target = data[element.name];
                        if (target || target === 0) {

                            Fileds[element.name] =
                                typeof target === "string"
                                    ? target.replace(/^\s*|\s*$/g, "")
                                    : target;
                        } else {
                            Fileds[element.name] = "?";
                        }
                        break;
                }
            })
            setFileds(Fileds)
            console.log("Fileds", Fileds);
            setGalleryData([])
            setPageIndex(1)
            getQueryData(1, Fileds)
        })
    }
   
    const onMenuClick = ({ item, key, keyPath, domEvent }) => {
        console.log(item);
        let data = {
            comm: item.props.dataCommponent || 'GalleryComponent',
            type: item.props.dataType
        }
        setModules([data])
    }
    return (<>
        <div className="smartGalleryBox">
            {
                !props?.requestVal?.noSearch&&<>
                <Form
                form={form}
                name="advanced_search"
                className="gallery-search-form"
            >
                <Row gutter={8}>
                    {
                        parameters && <XFormItem
                            itemList={parameters}
                            callBack={() => { }}
                            formInfo={form}
                        />
                    }

                    <Col
                        span={4}
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button type="primary" onClick={() => { onSearch() }} htmlType="submit">
                            <SearchOutlined />
                            查询
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="leftMenu">
                <Menu
                    onClick={onMenuClick}
                    style={{
                        width: 140
                    }}
                    popupClassName="menuClassName"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['4']}
                    mode="inline"
                    items={items}
                />

            </div>
                </>
            }
            
            <div className={(props?.requestVal?.noSearch?"fuillBox ":'')+"rightBox"}>
                {modules?.map((item, index) => {
                    const TagName = components[item.comm];
                    return (
                        <TagName
                            key={index}
                            modeType={item.type}
                            requestVal={props?.requestVal}
                            changeLoding={changeLoding} pageIndex={pageIndex} onlinePreview={onlinePreview} scrollData={scrollData} lodeMore={lodeMore} galleryData={galleryData} loding={loding}
                        ></TagName>
                    );
                })}
            </div>
            <KKfileViewerModal closeModal={()=>{setIsModalOpen(false)}} isModalOpen={isModalOpen} iframeUrl={iframeUrl}></KKfileViewerModal>
        </div>

    </>)
}
export default SmartGallery;