/* eslint-disable no-eval */
import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from "react";
import {Modal, Row, Form, Col, Button, Divider, Descriptions} from "antd";
import moment from "moment/moment";
const Drivers = forwardRef((props, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentDriver, setCurrentDriver] = useState({});
    const [historyDriver, setHistoryDriver] = useState({});
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    const showModal = (rowData) => {
        let driver = rowData.drivers || []
        setCurrentDriver(driver[0])
        setHistoryDriver(driver[1])
        setIsModalOpen(true);
    }
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <Modal title="查看司机和车辆信息" width={700} maskClosable={false} open={isModalOpen} onCancel={handleCancel} footer={[
            <Button onClick={handleCancel}> 取消 </Button>,
        ]}>
            <Row>
                <Col span={24}>
                    {historyDriver && (
                        <>
                            <p>历史计划</p>
                            <Descriptions column={1}>
                                <Descriptions.Item label="司机信息">
                                    {historyDriver.name} {historyDriver.phone}
                                </Descriptions.Item>
                                <Descriptions.Item label="车辆信息">
                                    { historyDriver.carModel } { historyDriver.licensePlate }
                                </Descriptions.Item>
                                <Descriptions.Item label="修改时间">
                                    {/*{historyDriver.createdOn}*/}
                                    {historyDriver.createdOn && historyDriver.createdOn.indexOf('T') > 0 ? historyDriver.createdOn.replace(/T/g, ' ') : historyDriver.createdOn}
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider dashed />
                        </>
                    )}
                </Col>
                <Col span={24}>
                    {currentDriver && (
                        <>
                            <p>实际行车计划</p>
                            <Descriptions column={1}>
                                <Descriptions.Item label="司机信息">
                                    {currentDriver.name} {currentDriver.phone}
                                </Descriptions.Item>
                                <Descriptions.Item label="车辆信息">
                                    { currentDriver.carModel } { currentDriver.licensePlate }
                                </Descriptions.Item>
                                <Descriptions.Item label="修改时间">
                                    {/*{currentDriver.phone}*/}
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider dashed />
                        </>
                    )}
                </Col>
            </Row>
        </Modal>
    )
})
export default Drivers
