import React, { useEffect, useState, useRef } from 'react';
import './index.less';
import { SmartStorage } from '@/utils';
import { getCookie, setCookie } from 'tiny-cookie';
import { Card, Button, List, Skeleton, Tooltip } from 'antd';
import iSignalrM from '../../controllers/smart-signalr';
import IqueryDetailDrawer from '@/components/DetailDrawer/indexIquery';
import remindSrc from '@/assets/audios/alert.mp3';
import {
    CheckCircleOutlined,
    UserOutlined,
    PhoneOutlined,
} from '@ant-design/icons';
import commonService from '@/service/common';
const userId =
    SmartStorage.get('userId') ||
    SmartStorage.get('user_id') ||
    getCookie('userId') ||
    getCookie('user_id');
const PaymentPage = () => {
    const [paymentData, setPaymentData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [drawerData, setDrawerData] = useState({});
    const [radioQueryData, setRadioQueryData] = useState(null);
    const [pageIndex, setPageIndex] = useState(1);
    const [queryObject, setQueryObject] = useState({});
    const [iqueryDetailDrawerVisible, setIqueryDetailDrawerVisible] =
        useState(false);
    var refreshPayHub = new iSignalrM({
        hubName: 'taskHub',
        queryParams: 'group=' + userId,
        listeners: [
            {
                methodName: 'newTask',
                method: function (data) {
                    console.log(data, 'data-----');
                    setTimeout(function () {
                        var audio = new Audio(remindSrc);
                        audio.play().catch((error) => {
                            console.error('音频播放失败:', error);
                        });
                        reloadData();
                    }, 3000);
                },
            },
        ],
        host: process.env.REACT_APP_SIGNALER,
    });
    useEffect(() => {
        let role_codes =
            SmartStorage.get('x_role_codes') ||
            getCookie('x_role_codes') ||
            SmartStorage.get('role_codes') ||
            getCookie('role_codes');
        role_codes &&
            role_codes.indexOf('EX.FIN3') > -1 &&
            refreshPayHub.install();
        let UrlParams = SmartStorage.get('menuParams')?.UrlParams;
        setRadioQueryData(UrlParams[0]);
        queryPaymentData(1);
        return componentWillUnmount;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const reloadData = () => {
        queryPaymentData(1);
    };
    const componentWillUnmount = () => {
        refreshPayHub.uninstall();
    };
    const queryPaymentData = async (pageIndex) => {
        let UrlParams = SmartStorage.get('menuParams')?.UrlParams;
        let data = UrlParams[0];
        setRadioQueryData(data);
        let params = {
            UsePaging: false,
            QueryId: data?.queryId,
            cluster: data?.cluster,
            parameters: {
                _pageIndex: pageIndex,
                _pageSize: 12,
            },
        };
        setLoading(true);
        let res = await commonService.getQueryData(params);
        if (res && res.success) {
            setPaymentData(
                [1].includes(pageIndex)
                    ? [].concat(res.content.rows)
                    : paymentData.concat(res.content.rows),
            );
            setLoading(false);
        }
    };
    const onLoadMore = () => {
        setPageIndex(pageIndex + 1);
        queryPaymentData(pageIndex + 1);
    };
    const iqueryDetailDrawerCallback = (data) => {
        setIqueryDetailDrawerVisible(false);
        if (!data || !['cancel'].includes(data.type)) {
            reloadData();
        }
    };
    const HandleClick = (record) => {
        setDrawerData(record);
        setIqueryDetailDrawerVisible(true);
    };
    const loadMore = !loading ? (
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button onClick={onLoadMore}>加载更多</Button>
        </div>
    ) : null;
    return (
        <div className="paymentBox">
            <List
                className="demo-loadmore-list"
                grid={{ gutter: 16, column: 4 }}
                loading={loading}
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={paymentData}
                renderItem={(item) => (
                    <List.Item>
                        <Skeleton
                            avatar
                            title={false}
                            loading={item.loading}
                            active
                        >
                            <Card
                                onClick={() => HandleClick(item)}
                                hoverable
                                className={
                                    item.taskStatus
                                        ? 'paymentCard'
                                        : 'paymentCard active'
                                }
                                bodyStyle={{ padding: '20px' }}
                            >
                                <div className="paymentTitle">
                                    <div className="payIcon">
                                        <div
                                            className={
                                                item.expense_PaySelect
                                                    ? 'paymentType'
                                                    : ''
                                            }
                                        >
                                            {item.expense_PaySelect}
                                        </div>
                                        <div
                                            className={
                                                item.item_ItemName
                                                    ? 'paymentType1'
                                                    : ''
                                            }
                                        >
                                            {item.item_ItemName}
                                        </div>
                                    </div>
                                    <div>{item.createdOn}</div>
                                </div>
                                <div className="paymentBody">
                                    <div className="paymentAmount">
                                        {item.expense_OriginAmount && '￥'}{' '}
                                        {item.expense_OriginAmount}
                                        {item.taskStatus && (
                                            <CheckCircleOutlined />
                                        )}
                                    </div>
                                    <div className="paymentInfo">
                                        <div>
                                            <UserOutlined />
                                            {item.event_OwnerName}
                                        </div>
                                        <div>
                                            <PhoneOutlined />
                                            {item.event_OwnerMobile}
                                        </div>
                                    </div>
                                </div>
                                <div className="paymentFooter">
                                    <div>{item.event_PONum}</div>
                                    <div className="payMiceType">
                                        <Tooltip title={item.event_Type}>
                                            <span> {item.event_Type}</span>
                                        </Tooltip>
                                    </div>
                                    <div className="paySuppler">
                                        <Tooltip title={item.item_ShopName}>
                                            <span> {item.item_ShopName}</span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Card>
                        </Skeleton>
                    </List.Item>
                )}
            />
            {iqueryDetailDrawerVisible && (
                <IqueryDetailDrawer
                    radioQueryData={radioQueryData}
                    drawerData={drawerData}
                    queryObject={queryObject}
                    iqueryDetailDrawerVisible={iqueryDetailDrawerVisible}
                    iqueryDetailDrawerCallback={iqueryDetailDrawerCallback}
                />
            )}
        </div>
    );
};
export default PaymentPage;
