import commonService from "@/service/common";
/**
 * 下载附件
 */
const downloadFile = async ({ processId, loadingCallback, folerName }) => {
  const params = {
    ProcessId: processId,
    Origin: "emp",
    FolerName: folerName,
  };

  loadingCallback(true);
  let res = await commonService.DownloadFile(params);
  loadingCallback(false);

  if (res && res.success) {
    localDownloadFile(res.content);
  }
};

export const localDownloadFile = (fileUrl) => {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = fileUrl;
  link.click();
}

export default downloadFile;
