import React, { useEffect, useState } from "react";
import { Form, Col, Select } from "antd";
import defaultSettings from "./defaultSettings";
import commonService from "@/service/common";
import { SmartStorage } from "@/utils";
const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const { Option } = Select;

const XChainDrugSelect = (props) => {
  const item = props.item;
  const [options, setOptions] = useState(item.options || []);
  const [loading, setLoading] = useState(false);
  // 显示/隐藏子级Select
  const [showSubSelect, setShowSubSelect] = useState(item.showSubSelect || "");
  const [subOptions, setSubOptions] = useState(item.subOptions || []);

  const defaultChangeClick = (e, item, sData) => {
    // console.log("产品/SKU 选择回调：", e, item, sData);
    // 如果不是子级name， 则显示子级
    if (item.name !== "sku") {
      setShowSubSelect(e);
    }
    // 回调
    props.callback(e, item, sData);
  };

  const getOptions = async (source) => {
    if (source.params && source.method && source.url) {
      let params = source.params;
      let method = source.method;
      let url = source.url;

      setLoading(true);
      let res = await commonService.common(method, url, params);
      setLoading(false);
      if (res && res.success && res.content) {
        let arr = res.content.map((v) => {
          if (v.Txt) {
            return v;
          } else {
            return { ...v, Txt: v.dspName, Val: v.userId };
          }
        });
        item.name === "visitedBy" && SmartStorage.set("visitedBy", arr);
        if (item.optionName) {
          setOptions(arr[0][item.optionName]);
        } else {
          setOptions(arr);
        }
      }
    }
  };
  // 获取子级选项配置
  async function getSubOptions(source, productVal) {
    if (source.params && source.method && source.url) {
      let method = source.method;
      let url = source.url;
      let params = {
        db: "empx_tpm",
        collection: "cfg_skus",
        filter: { ProductVal: productVal },
        projection: {},
        ContextDataKeys: {},
        sort: { Sort: 1 },
        limit: 1000,
      };

      setLoading(true);
      let res = await commonService.common(method, url, params);
      setLoading(false);
      if (res && res.success && res.content) {
        let arr = res.content.map((v) => {
          if (v.Txt) {
            return v;
          } else {
            return { ...v, Txt: v.dspName, Val: v.userId };
          }
        });
        item.name === "visitedBy" && SmartStorage.set("visitedBy", arr);
        if (item.subOptionName) {
          setSubOptions(arr[0][item.subOptionName]);
        } else {
          setSubOptions(arr);
        }
      }
    }
  }

  useEffect(() => {
    if (item.dataSource) {
      getOptions(item.dataSource);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // 监听 显示子级 属性变化，及时获取子级options的数据
  useEffect(() => {
    getSubOptions(item.dataSource, showSubSelect)
  }, [showSubSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Col span={item.span}>
        <Form.Item
          name={item.name}
          rules={item.rules}
          labelCol={{ span: item.lcol ? item.lcol : defaultLabelSpan }}
          wrapperCol={{ span: item.wcol ? item.wcol : defaultWrapperSpan }}
          label={item.label}
          labelAlign="right"
        >
          <Select
            style={{ width: "100%" }}
            mode={item.mode || "default"}
            loading={loading}
            disabled={item.disabled && true}
            labelInValue={item.labelInValue}
            allowClear={false}
            placeholder="请选择SKU"
            showSearch
            optionFilterProp="children"
            defaultValue={item.defaultValue}
            filterOption={(inputValue, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0
            }
            filterSort={(optionA, optionB) =>
              optionA.children &&
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            onChange={(e, sData) => defaultChangeClick(e, item, sData)}
          >
            {options.map((obj) => (
              <Option
                key={
                  obj.value || obj.Val || obj.Value || obj.Id + "__" + obj.Code
                }
                value={obj.value || obj.Val || obj.Value || obj.Id}
              >
                {obj.Txt || obj.Name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
      </Col>
      {/* 子级Select， 在父级选择数据之后渲染 */}
      {showSubSelect && (
        <Col span={item.span}>
          <Form.Item
            name={item.subName || "sku"}
            rules={item.rules}
            labelCol={{ span: item.lcol ? item.lcol : defaultLabelSpan }}
            wrapperCol={{ span: item.wcol ? item.wcol : defaultWrapperSpan }}
            label={item.subLabel || "SKU"}
            labelAlign="right"
          >
            <Select
              style={{ width: "100%" }}
              mode={item.mode || "default"}
              loading={loading}
              disabled={item.disabled && true}
              labelInValue={item.labelInValue}
              allowClear={!item.allowClear}
              placeholder={item.placeholder}
              showSearch
              optionFilterProp="children"
              defaultValue={item.defaultValue}
              filterOption={(inputValue, option) =>
                option.children &&
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children &&
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onChange={(e, sData) =>
                defaultChangeClick(e, { name: "sku" }, sData)
              }
            >
              {subOptions.map((obj) => (
                <Option
                  key={
                    obj.value ||
                    obj.Val ||
                    obj.Value ||
                    obj.Id + "__" + obj.Code
                  }
                  value={obj.value || obj.Val || obj.Value || obj.Id}
                >
                  {obj.Txt || obj.Name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
        </Col>
      )}
    </>
  );
};

export default XChainDrugSelect;
