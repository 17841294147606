import React, { useEffect, useState } from "react";
import "../MopModules/modules.less";
import {Dropdown, Button, Space, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import HotelDetailsItem from './hotelDetailsItem'

const HotelDetailsItems = (props) => {
    const [venueData, setVenueData] = useState([]);

    useEffect(() => {
        setVenueData(props.venueData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.venueData]);
    const menuClick = (e) => {
        let data = JSON.parse(JSON.stringify(venueData))
        data.forEach(ele => {
            if (ele.typeList.includes(e.key)) {
                let data = {}
                ele.column.forEach(item => {
                    data[item.key] = '';
                })
                data.expenseId = '新增'
                data.costType = e.key
                ele.datasource.push(data)
            }
        })
        setVenueData(data)
    };
    const menu = (
        <Menu className="menu" onClick={(e) => menuClick(e)}>
            {props.typeAll.length > 0 && props.typeAll.map((item) => (
                <Menu.Item key={item}>
                    <span className="menu_item">
                        <span>{item}</span>
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    );
    const defaultParams = {
        tenantId: props.requestVal.tenantId,
        supplierId: props.supplierInfo.supplierId,
        orderId: [props.requestVal._OrderId].includes(props.requestVal.processId) ? null : props.requestVal._OrderId,
        miceId: props.requestVal.processId,
    }
    return (
        <>
            <div className="HotelDetailsTitle">
                {props.supplierInfo.supplierName}
                {props.typeAll.length > 0 && <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}>
                    <Button >
                        <Space>
                            <span>新增</span>
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>}
            </div>
            {venueData.length > 0 && venueData.map((ele, index) => {
                return (<HotelDetailsItem key={index} venueData={ele} defaultParams={defaultParams} dataKey="expenseId" getDetail={props.getDetail}/>)
            })}
        </>
    );
};

export default HotelDetailsItems;
