import {
    SmartHttp
} from "../utils";

const request = new SmartHttp({
    baseURL: process.env.REACT_APP_GATEWAY,
    // baseURL: 'https://gateway-mice.smartmice.cn'
    // baseURL:'https://gateway-uat.eventsystem.cn'
}).request;

const longTimeRequest = new SmartHttp({
    baseURL: process.env.REACT_APP_GATEWAY,
    timeout: 0,
}).request;

// // 罗氏请求
const rocheRequest = new SmartHttp({
    baseURL: process.env.REACT_APP_ROCHEGATEWAY,
}).request;
// // 罗氏请求
const gskRequest = new SmartHttp({
    baseURL: process.env.REACT_APP_GSKGATEWAY,
}).request;
// // 获取二维码
const QRRequest = new SmartHttp({
    baseURL: process.env.REACT_APP_QRWAY,
}).request;

export {
    request,
    longTimeRequest,
    rocheRequest,
    gskRequest,
    QRRequest
};