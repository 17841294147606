import React,{useState} from "react";
import Yida from "../../YidaRender/yidaRender";

const OriginalYida = (props) => {  
    const {moduleItem,requestVal} =props
    
    const [yidaModel] = useState({
        pageId: moduleItem.pageId,
        info:requestVal
      });
    const callback = (info)=>{      
      props.callback(info)
    }
  return (
    <>
      <Yida model={yidaModel} callback={callback} iqueryDetailDrawerCallback={props.iqueryDetailDrawerCallback}></Yida>
    </>
  );
};

export default OriginalYida;
