import SmartQueryReact from "smart-iquery-react";
import React, { useState } from "react";
const SubTable = ({
  info,
  expandable,
  record
}) => {
  
  const params =expandable.getQueryData;
  params[0].additional['_'+expandable.expandableKey]=record[expandable.expandableKey];
  console.log(info, record,params);
  return (
          <>
            <div style={{ margin: "10px 0 10px 76px", fontWeight: "bold" }}>
              {expandable.title}
            </div>
            <SmartQueryReact
              gatewayPath={ process.env.REACT_APP_GATEWAY}
            iSignalr={process.env.REACT_APP_SIGNALER}
              getQueryData={params}
              goDetail={() => { }}
              handleMethod={() => { }}
              getQueryObject={() => { }}
              rowMenuHandleClick={() => { }}
              headerBtnHandleClick={() => { }}
              returnSelectedRows={() => { }}
            ></SmartQueryReact>
          </>

      )
};
export default SubTable;
