import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { Form, Modal, Select, Col, Input, Upload, Button, Icon, Spin, notification } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';
// eslint-disable-next-line no-unused-vars
import { getCookie } from "tiny-cookie";
// eslint-disable-next-line no-unused-vars
import { UploadFiles } from "smart-filer-react";
import commonService from "@/service/common";
import "./supplement.modal.less";
const { Option } = Select;
const { confirm } = Modal;
const SupplementModal = (props) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState();
  const [uploadProps, setUploadProps] = useState(null);
  const [oldUploadProps, setOldUploadProps] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [companyInvoice, setCompanyInvoice] = useState(null);
  const [ocrInvoice, setOcrInvoice] = useState(false);
  const [FileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [LoadingTip, setLLoadingTip] = useState(false);

  const handleOk = () => {
    if(!FileData){
      setModalVisible()
      return
    }
    form.validateFields().then((values) => {
      saveInvoice(values)
    })
  };
  const saveInvoice = async (values) => {
    if (props.UploadItem.actionCode == 'addWaterFile') {
      setModalVisible()
      return
    }
    setLLoadingTip('加载中。。。')
    setLoading(true)
    let params = {
      amount: values.amountInFiguers,
      billDate: values.invoiceDate,
      invoiceCode: values.invoiceCode,
      cost: values.totalAmount,
      invoiceNum: values.invoiceNum,
      invoiceType: values.invoiceType,
      purchaserName: values.purchaserName,
      purchaserTaxId: values.purchaserRegisterNum,
      salesBank: values.sellerBank,
      salesContact: values.sellerName,
      salesName: values.sellerName,
      salesTaxId: values.sellerRegisterNum,
      tax: values.taxRate,
      uploadMethod: 1,
      itemId: props.rowDate.itemId,
      fileIds: [FileData.fileKey],
      TenantId: props.rowDate.tenantId
    }
    let mappingRes = await mappingAsync();
    let res = await commonService.saveInvoice(params);
    if(!res.success && res.msg == '当前发票号已存在'){
      let params = {
        fileKeys: [FileData.fileKey]
      }
      
      let res = await commonService.deleteFiles(params);
      setLoading(false)
      return
    }
    setLoading(false)
    if(res && res.success){
      setModalVisible()
    }
  };
  const mappingAsync = () => {
    return new Promise(async (resolve, reject) => {
    let mappingParams = {
      mappingData: {
        processId: props.rowDate.proposalId,
        itemId: props.rowDate.itemId,
        relatedType: "Invoice_supplement",
        catalog: "发票"
      },
      fileKeys: [FileData.fileKey],
    };
      let res = await commonService.mapping(mappingParams);
      if(res && res.success){
        resolve(true)
      }
    })
  };
  const setModalVisible = () => {
    form.resetFields();
    setIsModalOpen(false)
    props.supplementModalCallback({ type: "cancel" });
  };
  const queryFile = async () => {
    setLLoadingTip('加载中。。。')
    setLoading(true)
    let actionCode = props.UploadItem.actionCode
    let res
    let params = {
      filterData:{
        processId: props.rowDate.proposalId,
        relatedId: props.rowDate.itemId,
        catalog: '水单',
        relatedType: 'settle_supplement'
      }
    }
    if (actionCode == 'addWaterFile') {
      res = await commonService.queryFiles(params);
    }
    if (actionCode == 'addInvoiceFile') {
      params = {
        filterData:{
          processId: props.rowDate.proposalId,
          relatedId: props.rowDate.itemId,
          catalog: '发票',
          relatedType: 'Invoice_supplement'
        }
      }
      res = await commonService.queryMealFiles({
        proposalId: props.rowDate.proposalId,
        itemId: props.rowDate.itemId
      })
      // res = await commonService.queryFiles(params);
    }
    if (res && res.success) {
      let fileItem = res.content.map((item) => {
        return {
          fileKey: item.fileKey || item.fileId,
          uid: item.fileKey || item.fileId,
          name: item.fileName || "附件" + item.fileType,
          status: "done", // uploading done error removed
          url: item.url || item.filePath,
          detailId: item.detailId || null
        };
      });
      setFileList(fileItem);
      setOldMappingData(params.filterData, fileItem)
    }
  };
  const setOldMappingData = (mappingData, fileItem) => {
    let updata = {
      accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
      type: "picture-card",
      gateway: props.gatewayPath,
      defaultFileList: fileItem,
      filterData: mappingData,
      hideAddButton: props.UploadItem.actionCode == 'addInvoiceFile',
      isMapping: props.UploadItem.actionCode == 'addWaterFile',
      showUploadList: {
        showDownloadIcon: true,
        showPreviewIcon: true,
        showRemoveIcon: true,
      },
      onBeforeUpload:()=>{
        return new Promise((resolve, reject) => {
          setLLoadingTip('上传中。。。')
          setLoading(true)
          resolve(true)
        })
      },
      uploadCallback: async (file, uploadList) => {
        setLoading(false)
      },
      onRemove: async (file) => {
        return new Promise((resolve, reject) => {
          confirm({
            title: '是否确定删除当前文件？',
            icon: <ExclamationCircleFilled />,
            onOk: async()=> {
              let arr = [];
              if (file.fileKey) {
                arr.push(file.fileKey)
              } else {
                arr.push(file?.response?.content?.fileKey)
              }
              let params = {
                fileKeys: arr
              }
              if(props.UploadItem.actionCode == 'addInvoiceFile'){
                await commonService.deleteInvoice1({
                  detailId: file.detailId
                })
                await commonService.deleteFiles({
                  fileKeys: [file.fileKey]
                })
                resolve(true)
                queryFile()
                return
              }
              let res = await commonService.deleteFiles(params);
              if (res && res.success) {
                resolve(true)
                queryFile()
              }
            },
          });
        })
        
      },
    };
    setOldUploadProps(updata)
    setLoading(false)
  };
  const setMappingData = () => {// 发票单独上传组件
    let updata = {
      accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
      type: "picture-card",
      gateway: props.gatewayPath,
      defaultFileList: [],
      isMapping: false,
      maxCount: 1,
      showUploadList: {
        showDownloadIcon: true,
        showPreviewIcon: true,
        showRemoveIcon: true,
      },
      onBeforeUpload:()=>{
        return new Promise((resolve, reject) => {
          setLLoadingTip('上传中。。。')
          setLoading(true)
          resolve(true)
        })
      },
      uploadCallback: async (file, uploadList) => {
        setLoading(false)
        setFileData(file)
        UploadOCR(file)
      },
      onRemove: async (file) => {
        return new Promise((resolve, reject) => {
          confirm({
            title: '是否确定删除当前文件？',
            icon: <ExclamationCircleFilled />,
            onOk: async()=> {
              let arr = [];
              if (file.fileKey) {
                arr.push(file.fileKey)
              } else {
                arr.push(file?.response?.content?.fileKey)
              }
              let params = {
                fileKeys: arr
              }
              let res = await commonService.deleteFiles(params);
              setFileData(null)
              setOcrInvoice(false)
              resolve(true)
            },
          });
        })
        
      },
    };
    setUploadProps(updata);
  };
  const UploadOCR = async (file) => {
    setLoading(true)
    setLLoadingTip('识别中。。。')
    var formData = new FormData();
    formData.append("url", file.url);
    var response = await commonService.common("post", "/api/ocr/invoice/comprehensive/scan/v/2", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    if (response && response.success && response.code != 210) {
      setLLoadingTip('上传中。。。')
      form.setFieldsValue({ ...response.content[0] });
      setLoading(false)
      setOcrInvoice(true)
    }else{
      let arr = [];
      if (file.fileKey) {
        arr.push(file.fileKey)
      } else {
        arr.push(file?.response?.content?.fileKey)
      }
      let params = {
        fileKeys: arr
      }
      let res = await commonService.deleteFiles(params);
      setFileData(null)
      setLoading(false)
      notification.warning({
        message: "上传失败",
        description:response.message
      });
      
    }
  };
  const getCompanyInvoice = async () => {
    let params = {
      processId: props.rowDate.proposalId,
    }
    let res = await commonService.getCompanyInvoice(params);
    if (res && res.success) {
      setCompanyInvoice(res.content)
    }
  };

function renderFormItem(key, name, fieldKey, restField, fieldName, label) {
  return (
    <Form.Item
      {...restField}
      key={key}
      label={label}
      name={[name, fieldName]}
      fieldKey={[fieldKey, fieldName]}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Input placeholder={`请输入${label}`} />
    </Form.Item>
  );
}
useEffect(() => {
  setTitle(props.UploadTitle);
  if (props.supplementModalVisible) {
    queryFile(props.rowDate)
    if (props.UploadItem.actionCode == 'addInvoiceFile') {
      setMappingData()
      getCompanyInvoice(props.rowDate)
    }
  }
  setIsModalOpen(props.supplementModalVisible);
}, [props.supplementModalVisible]);

return (
    <Modal
      title={title}
      destroyOnClose
      open={isModalOpen}
      onOk={handleOk}
      width={1000}
      onCancel={setModalVisible}
      confirmLoading={loading}
    >
      {companyInvoice && <div className="invoiceInfo">
        <p>开票信息</p>
        <p>发票抬头：{companyInvoice.name || ''}</p>
        <p>纳税人识别号：{companyInvoice.taxPayerCode || ''}</p>
        <p>地址、电话：{companyInvoice.address} / {companyInvoice.mobile || ''}</p>
        <p>开户银行：{companyInvoice.bankName || ''}</p>
        <p>开户账号：{companyInvoice.bankAccount || ''}</p>
        {/* <p>纳税人识别号：{companyInvoice.taxPayerCode}</p> */}
      </div>}
      
      <Spin spinning={loading}  tip={LoadingTip}>
        <UploadFiles
          {...oldUploadProps}>
        </UploadFiles>
        {
          uploadProps && 
          <UploadFiles
            {...uploadProps}>
          </UploadFiles>
        }
        {ocrInvoice &&
          <div className="formBox">
            <Form
              name="basic"
              form={form}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="inline"
            >
              <Col span={12}>
                <Form.Item
                  label="发票类型"
                  name="invoiceType"
                  rules={[{ required: false, message: "发票类型不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="发票代码"
                  name="invoiceCode"
                  rules={[{ required: false, message: "发票代码不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="校验码"
                  name="invoiceCode"
                  rules={[{ required: false, message: "校验码不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="发票号码"
                  name="invoiceNum"
                  rules={[{ required: false, message: "发票号码不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="开票日期"
                  name="invoiceDate"
                  rules={[{ required: false, message: "开票日期不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="含税总金额"
                  name="amountInFiguers"
                  rules={[{ required: false, message: "含税总金额不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="不含税总金额"
                  name="totalAmount"
                  rules={[{ required: false, message: "不含税总金额不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <p className="ocrTitle">购买方信息</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="购买方信息"
                  name="purchaserName"
                  rules={[{ required: false, message: "购买方信息不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="购买方信息"
                  name="purchaserName"
                  rules={[{ required: false, message: "购买方信息不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="纳税人识别号"
                  name="purchaserRegisterNum"
                  rules={[{ required: false, message: "纳税人识别号不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="地址、电话"
                  name="purchaserAddress"
                  rules={[{ required: false, message: "地址、电话不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="开户行及账号"
                  name="purchaserBank"
                  rules={[{ required: false, message: "开户行及账号不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <p className="ocrTitle">销售方信息</p>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="销售方名称"
                  name="sellerName"
                  rules={[{ required: false, message: "销售方名称不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="纳税人识别号"
                  name="sellerRegisterNum"
                  rules={[{ required: false, message: "纳税人识别号不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="地址、电话"
                  name="sellerName"
                  rules={[{ required: false, message: "地址、电话不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="开户行及账号"
                  name="sellerBank"
                  rules={[{ required: false, message: "开户行及账号不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="税率（%）"
                  name="taxRate"
                  rules={[{ required: false, message: "税率不能为空" }]}
                >
                  <Input
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <p className="ocrTitle">项目信息</p>
              </Col>
              <Form.List
                name="invoiceDetail"
              >
                {(fields) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <>
                        {renderFormItem(key, name, fieldKey, restField, "commodityName", "项目名称")}
                        {renderFormItem(key, name, fieldKey, restField, "commodityUnit", "单位")}
                        {renderFormItem(key, name, fieldKey, restField, "commodityNum", "数量")}
                        {renderFormItem(key, name, fieldKey, restField, "commodityPrice", "单价")}
                        {renderFormItem(key, name, fieldKey, restField, "commodityAmount", "金额")}
                        {renderFormItem(key, name, fieldKey, restField, "commodityTaxRate", "税率（%）")}
                        {renderFormItem(key, name, fieldKey, restField, "commodityTax", "税额")}
                      </>
                    ))}
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        }
      </Spin>
    </Modal>
);
};
export default SupplementModal;
