import React, { useState, useEffect } from "react";
import { Menu, Skeleton, Tooltip, Modal } from "antd";
import "./sliderNav.less";
import { withRouter } from "react-router-dom";
import SmartStorage from "@/utils/storage/index.js";
import { connect } from "react-redux";
import { updateMenuTitle } from "@/redux/actions/baseInfo";
import XIconFont from "../IconFont";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getCookie, removeCookie, setCookie } from "tiny-cookie";

// const { SubMenu } = Menu;

const SiderNav = (props) => {
  // 格式化菜单
  const [regMenuList, setRegMenuList] = useState([]);
  // 菜单默认key值
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    renderDefaultMenu(props.menus, "Root");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.location.pathname === "/" &&
      props.history.push({
        pathname: regMenuList[0]?.children[0].children
          ? regMenuList[0]?.children[0].children[0].Path
          : regMenuList[0]?.children[0]?.Path,
      });
    setDefaultMenuKey();
    if (!SmartStorage.get("breadRoutes")) {
      if (regMenuList.length > 0) {
        let href = regMenuList[0]?.children[0]?.Path;
        let firstLevel = href.substr(0, href.indexOf("/", 2));
        let checkArr = [firstLevel, href];
        BreadCrumbRouter(checkArr);
      }
    }
    // console.log(regMenuList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regMenuList]);
  useEffect(() => {
    setSelectedKeys(props.location.pathname);
    SmartStorage.set("selectedKeys", props.location.pathname);
    let menuItem = props.menus.filter(
      (item) => item.Path === props.location.pathname
    );
    if (menuItem[0] && menuItem[0].BreadCrumb) {
      BreadCrumbRouter(menuItem[0].BreadCrumb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);
  useEffect(() => {
    setDefaultMenuKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setDefaultMenuKey = () => {
    let currentPath = window.location.pathname;
    // 初始登录如果是高级会议库，删除token 用x_token
    if (currentPath === "/shared/all-event-library") {
      removeCookie("token", { domain: ".eventworld.cn" });
    }
    let currentPaths = currentPath.split("/");
    if (currentPath === "/") {
      setSelectedKeys(["/" + regMenuList[0]?.children[0]?.Path]);
    }
    setSelectedKeys([SmartStorage.get("selectedKeys")]);
    setGridCode(currentPath);
    // setOpenKeys([SmartStorage.get("openKeys")])
    // console.log(currentPaths);
    setOpenKeys(currentPaths.length > 4 ? [] : ["/" + currentPaths[1]]);
    // setOpenKeys(['/'+currentPaths[1]+"/"+currentPaths[2]]);
  };
  // 获取菜单数据
  const renderDefaultMenu = (list, root) => {
    const regList = list.map((ele) => {
      if (ele.Parent !== "Root") {
        ele.label = (
          <Tooltip placement="rightTop" title={ele.Txt} color="black">
            {ele.Txt}
          </Tooltip>
        );
      } else {
        ele.label = ele.Txt;
      }
      ele.key = ele.Path;
      if (ele.Parent === "Root") {
        ele.icon = (
          <XIconFont type={ele.Settings.Icon ? ele.Settings.Icon : " "} />
        );
      }
      return ele;
    });
    const arr = [];
    let nData = JSON.parse(JSON.stringify(regList));
    nData.forEach((item) => {
      if (item.Parent === root) {
        const children = renderDefaultMenu(nData, item.Value);
        if (children.length > 0) {
          item.children = children;
        }
        arr.push(item);
      }
    });
    // console.log("arr", arr);
    setRegMenuList(() => {
      return arr;
    });
    return arr;
  };
  const setGridCode = (currentPath) => {
    // if (currentPath === "/venueOperatingCenter/venueOperatingCenter") {
    //   SmartStorage.set("menuParams", { UrlParams: props.dynimcQueryData });
    // } else {
    props.menus.forEach((item) => {
      if (item.Path === currentPath) {
        SmartStorage.set("menuParams", item.Params);
      }
    });
    // }
  };

  const selectMenu = (e) => {
    // console.log("选择菜单", e);
    // 高级会议库中第三方应用会出现串token，所以删除，其程序菜单需要token，所以需要追加
    if (e.key === "/shared/all-event-library") {
      removeCookie("token", { domain: ".eventworld.cn" });
      menuTo(e);
    } else {
      setCookie("token", getCookie("x_token"), { domain: ".eventworld.cn" });
      let selectedKeys = SmartStorage.get("selectedKeys");
      if (selectedKeys === "/report/invoice-batch-scan") {
        Modal.confirm({
          icon: <ExclamationCircleOutlined />,
          okText: "确认",
          cancelText: "取消",
          content: <span>是否离开当前扫描页面</span>,
          onOk() {
            menuTo(e);
          },
          onCancel() {
            return;
          },
        });
      } else {
        menuTo(e);
      }
    }
  };
  const menuTo = (e) => {
    console.log("====导航到====",e);
    BreadCrumbRouter(e.keyPath);
    setGridCode(e.key);
    document.title = "智会云 - " + e.item.props["Txt"];
    setSelectedKeys(e.selectedKeys || []);
    setOpenKeys(e.keyPath[1] || []);
    SmartStorage.set("selectedKeys", e.key);
    SmartStorage.set("openKeys", e.keyPath[1] || []);
    SmartStorage.remove("jGridSessionId");
    SmartStorage.set(
      "UrlParams",
      e.item.props["Params"] && e.item.props["Params"].UrlParams
    );
    SmartStorage.set(
      "templateUrl",
      e.item.props["Params"] && e.item.props["Params"].TemplateUrl
    );
    SmartStorage.set(
      "localizedItemTxt",
      e.item.props["data-val"] && e.item.props["data-val"].Txt
    );
    SmartStorage.set("subassembly", e.item.props["subassembly"] || []);
    const rnd = new Date().getTime();
    props.history.push({
      pathname: e.key + "?rnd=" + rnd,
    });
  };
  // 面包屑路由筛选
  const BreadCrumbRouter = (keyPath) => {
    let itemRoutes = [];
    props.menus.forEach((item) => {
      if (keyPath.includes(item.Path)) {
        itemRoutes.push({
          path: item.Path,
          breadcrumbName: item.Txt,
          breadCrumbLevel: item.BreadCrumbLevel,
        });
      }
    });
    props.getRoutes(
      itemRoutes.sort((a, b) => {
        return a.breadCrumbLevel - b.breadCrumbLevel;
      })
    );
  };
  // useEffect(() => {
  //   window.location.pathname === "/" &&
  //     props.history.push({
  //       pathname: "/dashboard",
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [window.location.pathname]);
  return (
    <>
      {regMenuList.length > 0 ? (
        <div className="menuBox">
          <Menu
            mode="inline"
            defaultOpenKeys={openKeys}
            defaultSelectedKeys={selectedKeys}
            selectedKeys={selectedKeys}
            onSelect={selectMenu}
            items={regMenuList}
          ></Menu>
        </div>
      ) : (
        <Skeleton
          style={{ widht: "90%", margin: "0 5%" }}
          paragraph={{ rows: 10 }}
          active
        />
      )}
    </>
  );
};

export default connect((state) => state, { updateMenuTitle })(
  withRouter(SiderNav)
);
