/**
 * 组件改造后，gcar和会很酷都的看板都走这里
 */
import React, { useEffect, useState } from "react";
import { Col, Row, Divider, Select, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import "./index.less";
// import moment from "moment";
import { SmartStorage } from "@/utils";
import commonService from "@/service/common";
import summaryBg from "@/assets/img/summaryBg.png";
import summaryIcon from "@/assets/img/summaryIcon.png";
const UrlParams = SmartStorage.get("menuParams")?.UrlParams;
const GCarSummary = () => {
  const history = useHistory();

  const [isHHK, setIsHHK] = useState(false); // 判断是会很酷还是gcar
  const [options, setOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState();
  const [panelConfig, setPanelConfig] = useState([]);
  const [defaultValue, setDefaultValue] = useState('1');
  const [queryOrderDatas, setQueryOrderDatas] = useState({});
  const [queryStartOrderDatas, setQueryStartOrderDatas] = useState({});
  const [futureDays, setFutureDays] = useState(null);

  const queryOrders = async (data, isTotal, _futureDays) => {
    let params = {
      UsePaging: false,
      QueryId: data?.queryId,
      cluster: data?.cluster,
      parameters: {
        _futureDays: _futureDays,
      },
    };
    let res = await commonService.getQueryData(params);
    if (res && res.success) {
      isTotal
        ? setQueryOrderDatas(res.content.rows[0])
        : setQueryStartOrderDatas(res.content.rows[0]);
    }
  };
  const handleChange = (e) => {
    console.log(e);
    setFutureDays(e);
    queryOrders(UrlParams[0], true, e);
  };
  const goOrderDetail = (data) => {
    console.log(data);
    if(isHHK) { // 会很酷的跳转
      // let UrlParam = UrlParams[0].querysUrlParams.UrlParams;
      let breadRoutes = SmartStorage.get("breadRoutes");
      const target = panelConfig.find(i => (i.value === data.split('=')[1]));
      console.log(target);
      breadRoutes[breadRoutes.length-1].breadcrumbName = target.querysUrlParams.Name;
      breadRoutes[breadRoutes.length-1].path = target.querysUrlParams.Path;
      console.log(breadRoutes);
      SmartStorage.set("breadRoutes", breadRoutes);
      SmartStorage.set("menuParams", { UrlParams: target.querysUrlParams.UrlParams });
      SmartStorage.set("selectedKeys", target.querysUrlParams.Path);
      const params = target.querysUrlParams.UrlParams[0].params;
      params.rnd = new Date().getTime();
      const str = Object.entries(params).map(i => {
        return `${i[0]}=${eval(i[1])}`
      }).join('&')
      console.log(str);
      SmartStorage.set("selectedKeys", target.querysUrlParams.Path);
      history.push({
        pathname: `${target.querysUrlParams.Path}?${str}`,
      });
    }else { // gcar的跳转
      let UrlParam = UrlParams[0].querysUrlParams.UrlParams;
      let Path = UrlParams[0].querysUrlParams.Path;
      SmartStorage.set("UrlParams", UrlParam);
      let breadRoutes = SmartStorage.get("breadRoutes");

      breadRoutes[breadRoutes.length-1].breadcrumbName = UrlParams[0].querysUrlParams.Name;
      breadRoutes[breadRoutes.length-1].path = Path;
      SmartStorage.set("breadRoutes", breadRoutes);
      SmartStorage.set("menuParams", { UrlParams: UrlParam });
      SmartStorage.set("selectedKeys", Path);
      let pathname = Path + "?" + data +(futureDays?("&futureDays=" + futureDays):'') ;
      const rnd = new Date().getTime();
      history.push({
        pathname: pathname + "&rnd=" + rnd,
      });
    }

  };
  useEffect(() => {
    console.log(UrlParams);

    setIsHHK(window.location.pathname === '/summary-order-hhk');
    // 会很酷和gcar的部分走配置
    const {pannelConfigs, filterConfig} = UrlParams[0];
    console.log(filterConfig);
    setPanelConfig(pannelConfigs);
    setPlaceholder(filterConfig[0].placeholder);
    setOptions(filterConfig[0].options);
    setFutureDays(filterConfig[0].options[0].value);
    if(window.location.pathname === '/summary-order-hhk') {
      queryOrders(UrlParams[0], true, filterConfig[0].options[0].value);
    }else {
      queryOrders(UrlParams[0], true);
      queryOrders(UrlParams[1], false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return history.listen((location,action) => {
      console.log(location);
      console.log(action);
    })
  }, [history])
  return (
    <div className="fundingSummaryBox">
      <div className="fundingSummaryTitleBox">
        <div className="titleLeftBox">
          <p>{UrlParams[0].queryName}</p>
          <Select
            allowClear
            style={{
              width: 180,
              marginTop: 20,
            }}
            value={futureDays}
            placeholder={placeholder}
            onChange={handleChange}
            options={options}
          ></Select>
        </div>
      </div>
      <div className="fundingSummaryContentBox">
        <Row gutter={16}>
          {panelConfig.map(i =>
            (
                <Col
                    span={6}
                    onClick={(e) => {
                      goOrderDetail(`orderParam=${i.value}`);
                    }}
                >
                  <div className={i.value === 'failedCarOrders' ? 'statisticBox redColor' : 'statisticBox'}>
                    <p>{i.name}</p>
                    <span className="num">{queryOrderDatas[i.value]}</span>
                    <img className="summaryBg" src={summaryBg} alt=""></img>
                    <img className="summaryIcon" src={summaryIcon} alt=""></img>
                  </div>
                </Col>
            )
          )}
        </Row>
        <Divider dashed />
        {isHHK ? <>
            </> :
        <>
          <Row gutter={16}>
          <Col span={6}>
            <div className="statisticBox">
              <p>出发前1小时内尚未接单数</p>
              <Button
                  type="link"
                  size="small"
                  onClick={(e) => {
                    goOrderDetail("carType=网约车&orderParam=oneHourWycOrders");
                  }}
              >
                网约车：{queryStartOrderDatas?.oneHourWycOrders}
              </Button>
              <Button
                  type="link"
                  size="small"
                  onClick={(e) => {
                    goOrderDetail("carType=专车&orderParam=oneHourWycOrders");
                  }}
              >
                专车：{queryStartOrderDatas?.oneHourZcOrders}
              </Button>
              <img className="summaryBg" src={summaryBg} alt=""></img>
              <RightOutlined className="summaryIcon goIcon" />
              {/* <img className="summaryIcon" src={summaryIcon}></img> */}
            </div>
            {/* <Statistic title="订单总数" value={112893} /> */}
          </Col>



          <Col span={6}>
            <div className="statisticBox">
              <p>出发前2小时内尚未接单数</p>
              <Button
                  type="link"
                  size="small"
                  onClick={(e) => {
                    goOrderDetail("carType=网约车&orderParam=twoHourWycOrders");
                  }}
              >
                网约车：{queryStartOrderDatas?.twoHourWycOrders}
              </Button>
              <Button
                  type="link"
                  size="small"
                  onClick={(e) => {
                    goOrderDetail("carType=专车&orderParam=twoHourWycOrders");
                  }}
              >
                专车：{queryStartOrderDatas?.twoHourZcOrders}
              </Button>
              <img className="summaryBg" src={summaryBg} alt=""></img>
              <RightOutlined className="summaryIcon goIcon" />
              {/* <img className="summaryIcon" src={summaryIcon}></img> */}
            </div>
          </Col>
          <Col span={6}>
            <div className="statisticBox">
              <p>出发前4小时内尚未接单数</p>
              <Button
                  type="link"
                  size="small"
                  onClick={(e) => {
                    goOrderDetail("carType=网约车&orderParam=fourHourWycOrders");
                  }}
              >
                网约车：{queryStartOrderDatas?.fourHourWycOrders}
              </Button>
              <Button
                  type="link"
                  size="small"
                  onClick={(e) => {
                    goOrderDetail("carType=专车&orderParam=fourHourWycOrders");
                  }}
              >
                专车：{queryStartOrderDatas?.fourHourZcOrders}
              </Button>
              {/* <span>{queryStartOrderDatas.fourHourOrders}</span> */}
              <img className="summaryBg" src={summaryBg} alt=""></img>
              <RightOutlined className="summaryIcon goIcon" />
              {/* <img className="summaryIcon" src={summaryIcon}></img> */}
            </div>
          </Col>
          <Col span={6}>
            <div className="statisticBox">
              <p>出发前5小时内尚未接单数</p>
              <Button
                  type="link"
                  size="small"
                  onClick={(e) => {
                    goOrderDetail("carType=网约车&orderParam=fiveHourZcOrders");
                  }}
              >
                网约车：{queryStartOrderDatas?.fiveHourWycOrders}
              </Button>
              <Button
                  type="link"
                  size="small"
                  onClick={(e) => {
                    goOrderDetail("carType=专车&orderParam=fiveHourZcOrders");
                  }}
              >
                专车：{queryStartOrderDatas?.fiveHourZcOrders}
              </Button>
              {/* <span>{queryStartOrderDatas.fiveHourOrders}</span> */}
              <img className="summaryBg" src={summaryBg} alt=""></img>
              <RightOutlined className="summaryIcon goIcon" />
              {/* <img className="summaryIcon" src={summaryIcon}></img> */}
            </div>
          </Col>
        </Row>
          <Row gutter={16}>
            <Col span={6}>
              <div className="statisticBox">
                <p>出发前6小时内尚未接单数</p>
                <Button
                    type="link"
                    size="small"
                    onClick={(e) => {
                      goOrderDetail("carType=网约车&orderParam=sixHourZcOrders");
                    }}
                >
                  网约车：{queryStartOrderDatas?.sixHourWycOrders}
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={(e) => {
                      goOrderDetail("carType=专车&orderParam=sixHourZcOrders");
                    }}
                >
                  专车：{queryStartOrderDatas?.sixHourZcOrders}
                </Button>
                {/* <span>{queryStartOrderDatas.sixHourOrders}</span> */}
                <img className="summaryBg" src={summaryBg} alt=""></img>
                <RightOutlined className="summaryIcon goIcon" />
                {/* <img className="summaryIcon" src={summaryIcon}></img> */}
              </div>
              {/* <Statistic title="订单总数" value={112893} /> */}
            </Col>
            <Col span={6}>
              <div className="statisticBox">
                <p>出发前8小时内尚未接单数</p>
                <Button
                    type="link"
                    size="small"
                    onClick={(e) => {
                      goOrderDetail("carType=网约车&orderParam=eightHourZcOrders");
                    }}
                >
                  网约车：{queryStartOrderDatas?.eightHourWycOrders}
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={(e) => {
                      goOrderDetail("carType=专车&orderParam=eightHourZcOrders");
                    }}
                >
                  专车：{queryStartOrderDatas?.eightHourZcOrders}
                </Button>
                {/* <span>{queryStartOrderDatas.eightHourOrders}</span> */}
                <img className="summaryBg" src={summaryBg} alt=""></img>
                <RightOutlined className="summaryIcon goIcon" />
                {/* <img className="summaryIcon" src={summaryIcon}></img> */}
              </div>
            </Col>
          </Row>
        </>}


      </div>
    </div>
  );
};
export default GCarSummary;
