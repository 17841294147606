/* 
    该文件专门用于暴露一个store对象，整个项目只有一个
*/

// 引入createStore，专门用于创建redux最核心的store
import { createStore, applyMiddleware, combineReducers,compose } from "redux";
import thunk from "redux-thunk";
import reduxPromise from 'redux-promise'
import BaseInfo from "./reducers/baseInfo.js";
import JGridReducer from "./reducers/JGriddata.js";
import enterReducer from './reducers/enterState'

// 通过combineReducers可以合并多个仓库
const reducer = combineReducers({
  BaseInfo,
  enterReducer,
  JGridReducer,
 
})
// 配置chrom redux-develop 开发插件
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
// 创建store对象
const store = createStore(reducer,  composeEnhancers(applyMiddleware(thunk,reduxPromise)))


export default store

