import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions } from "antd";
import moment from "moment";
import './CarInfoCard.less'
import {SmartStorage} from "@/utils";
const CarInfoCard = (props) => {
    const [taskInfo, setTaskInfo] = useState({});
    useEffect(() => {
        let data = {}
        if (['0'].includes(SmartStorage.get("detailRow").isNew)) {
            data = {
                name: props.miceInfo.subject,
                tuDspName: props.miceInfo.creater,
                extNum: props.miceInfo.extNum,
                city: props.miceInfo?.itsExtData?.CityDictVal,
                orderNum: props.miceInfo.orderNum,
                dspName: props.miceInfo.dspName,
                phoneNumber: props.miceInfo.phoneNumber,
                dtStart: props.miceInfo.dtStart,
                dtEnd: props.miceInfo.dtEnd,
                statusTxt: props.miceInfo.statusTxt,
                poNum: props.miceInfo?.itsExtData?.PoNum,
            }
        } else {
            data = Object.assign(props.miceInfo?.miceInfo || {}, {
                province: props.miceInfo?.province,
                city: props.miceInfo?.city,
            })
        }
        setTaskInfo(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    return (<>
        {taskInfo && JSON.stringify(taskInfo) !== "{}" && (
            <div className="boderRow">
                <Row className="rowStyle">
                    <Col className="borderCol">{taskInfo.statusTxt}</Col>
                    <Col className="title">{taskInfo.name}</Col>
                </Row>
                <Row>
                    <Col span={24} className="Num">
                        {taskInfo?.poNum}
                    </Col>
                </Row>
                <Row className="info_center">
                    <Col span={24}>
                        <Descriptions column={4}>
                            <Descriptions.Item label="客户名称">
                                {taskInfo.tenantName}
                            </Descriptions.Item>
                            <Descriptions.Item label="办会人">
                                {taskInfo.creater || taskInfo.tuDspName}
                            </Descriptions.Item>
                            <Descriptions.Item label="客户编号">
                                {taskInfo.extNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="会议日期">
                                {moment(taskInfo.dtStart).format('YYYY-MM-DD')}~{moment(taskInfo.dtEnd).format('YYYY-MM-DD')}
                            </Descriptions.Item>
                            <Descriptions.Item label="会议城市">
                                {taskInfo.province ? taskInfo.province + '~' : ''}{taskInfo.city ? taskInfo.city : ''}
                                {!taskInfo.province && !taskInfo.city ? '未知' : ''}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                {['0'].includes(SmartStorage.get("detailRow").isNew) && (
                    <Row className="info_center">
                        <Col span={24}>
                            <Descriptions column={4}>
                                <Descriptions.Item label="订单号">
                                    {taskInfo.orderNum}
                                </Descriptions.Item>
                                <Descriptions.Item label="下单人">
                                    {taskInfo.dspName}
                                </Descriptions.Item>
                                <Descriptions.Item label="下单人手机">
                                    {taskInfo.phoneNumber}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                )}
            </div>
        )}
    </>)
}

export default CarInfoCard;
