import React from 'react';
import { Form, Col, TimePicker } from 'antd';
import defaultSettings from './defaultSettings'

const {defaultLabelSpan,defaultWrapperSpan} = defaultSettings

const xTimePicker = (props) => {

    const item = props.item

    function defaultChangeClick(e, item, sData) {
        item.callBack && props.callback(e, item, sData);
    }

    return (
        <>
            <Col span={item.span} >
                <Form.Item
                    name={item.name}
                    rules={item.rules}
                    labelCol={item.lcol || { span: defaultLabelSpan }}
                    wrapperCol={item.wcol || { span: defaultWrapperSpan }}
                    label={item.label}
                    labelAlign="right"
                >
                <TimePicker
                    style={{ width: "100%" }}
                    format={item.format || "HH:mm:ss"}
                    disabled={item.disabled && true}
                    placeholder={item.placeholder}
                    onChange={(e) => (defaultChangeClick(e, item))}
                />
                </Form.Item>
            </Col>
        </>
      )
}

export default xTimePicker
