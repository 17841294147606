import React, { forwardRef, useImperativeHandle, useState } from "react";
// eslint-disable-next-line no-unused-vars
import {
  message,
  Modal,
  // eslint-disable-next-line no-unused-vars
  Button,
  // eslint-disable-next-line no-unused-vars
  Table,
  Form,
  // eslint-disable-next-line no-unused-vars
  DatePicker,
  // eslint-disable-next-line no-unused-vars
  Divider,
  Select,
  Input,
} from "antd";
import commonService from "@/service/common";
// import "./index.less";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
// eslint-disable-next-line no-unused-vars
const { Option } = Select;
const FinConfirmReceipt = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [addInvoiceVisible, setAddInvoiceVisible] = useState(false);
  const [btnData, setBtnData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [selectedRows, setSelectedRows] = useState([]);
  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      console.log(btn, rowKeys, selectedRows, data);
      setBtnData(btn);
      // setSelectedRows(selectedRows || data);
      // eslint-disable-next-line no-eval
      eval(btn.val || btn.actionCode)(btn, rowKeys, selectedRows, data);
    },
  }));
  // eslint-disable-next-line no-unused-vars
  const createExpress = () => {
    setAddInvoiceVisible(true);
  };
  const cancelAddInvoiceModal = () => {
    setAddInvoiceVisible(false);
    form.resetFields();
  };
  const commonHandle = async (params, api) => {
    let url = api;
    let method = "post";
    let res = await commonService.common(method, url, params);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      cancelAddInvoiceModal();
      props.reloadData();
    }
  };
  const saveInvoice = () => {
    form.validateFields().then((values) => {
      let params = JSON.parse(JSON.stringify(btnData.schema.parameters));
      for (let key in params) {
        params[key] = values[key];
      }
      commonHandle(params, btnData.schema.api);
    });
  };
  return (
    <div>
      <Modal
        title="新建快递"
        width="600px"
        visible={addInvoiceVisible}
        onOk={saveInvoice}
        destroyOnClose
        onCancel={cancelAddInvoiceModal}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          autoComplete="off"
        >
          <Form.Item
            name="expressCode"
            label="供应商"
            rules={[{ required: true, message: "请输入供应商!" }]}
          >
            <Input
              style={{
                width: "300px",
              }}
              placeholder="请输入供应商"
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="expressCompany"
            label="快递单号"
            rules={[{ required: true, message: "请输入快递单号!" }]}
          >
            <Input
              style={{
                width: "300px",
              }}
              placeholder="请输入快递单号"
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default FinConfirmReceipt;
