import React, { useEffect, useState } from "react";
import { Button, DatePicker } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import "./index.less";
import moment from "moment";
import { SmartStorage } from "@/utils";
import commonService from "@/service/common";
const FundingSummary = () => {
  const history = useHistory();
  let UrlParams = SmartStorage.get("menuParams")?.UrlParams;
  const [queryPriceDatas, setQueryPriceDatas] = useState([]);
  // const [advancePrice, setAdvancePrice] = useState(fal
  const queryPriceData = async (id, type, date) => {
    let params = {
      UsePaging: false,
      QueryId: id,
      parameters: {
        fillingDate: date,
        category: type,
      },
      cluster: UrlParams[0].cluster,
    };
    let res = await commonService.getQueryData(params);
    if (res && res.success) {
      return res.content.rows;
    }
  };
  const goFundDetail = (item, obj) => {
    console.log(item, obj);
    console.log(UrlParams[0]);
    let UrlParam =
      UrlParams[0].querysUrlParams[obj.availableFundsField].UrlParams;

    SmartStorage.set("UrlParams", UrlParam);
    let breadRoutes = SmartStorage.get("breadRoutes");
    breadRoutes[1].breadcrumbName = obj.availableFundsField;
    breadRoutes[1].path =
      UrlParams[0].querysUrlParams[obj.availableFundsField].Path;
    SmartStorage.set("breadRoutes", breadRoutes);
    SmartStorage.set("menuParams", { UrlParams: UrlParam });
    SmartStorage.set(
      "selectedKeys",
      UrlParams[0].querysUrlParams[obj.availableFundsField].Path
    );

    let pathname = "";
    switch (obj.availableFundsField) {
      case "专项资金":
        pathname = "/fund_daily_details?dtCreated=" + item.datePicker;
        break;
      case "其他资金":
        pathname = "/fund_other_details?dtCreated=" + item.datePicker;
        break;
      case "理财资金":
        pathname = "/fund_daily_details?dtCreated=" + item.datePicker;
        break;
      case "贴现额度":
        pathname = "/fund_linediscount_details?dtCreated=" + item.datePicker;
        break;
      case "资金第三方存款明细表":
        pathname =
          "/fund_thirdpartydeposits_details?dtCreated=" + item.datePicker;
        break;
      case "银行资金":
        pathname = "/fund_daily_details?dtCreated=" + item.datePicker;
        break;
      default:
        pathname = "/fund_demand_details?dtCreated=" + item.datePicker;
        break;
    }

    const rnd = new Date().getTime();
    history.push({
      pathname: pathname + "&rnd=" + rnd,
    });
  };
  const priceNervousLevel = (pwarning) => {
    if (pwarning < 1.3) {
      return "level1";
    }
    if (pwarning < 1.5) {
      return "level1";
    }
    if (pwarning < 1.75) {
      return "level2";
    }
    if (pwarning < 1.9) {
      return "level3";
    }
    if (pwarning < 2.4) {
      return "level4";
    }
  };
  const toggleAdvancePrice = async (item, i) => {
    console.log(item);
    let data =
      !item.advancePrice &&
      (await queryPriceData(UrlParams[0].queryId, item.type, item.datePicker));

    let a = JSON.parse(JSON.stringify(queryPriceDatas));
    a[i].advancePrice = !a[i].advancePrice;
    a[i].priceList = data;
    a[i].type = item.type;
    console.log(a);
    setQueryPriceDatas(a);
  };
  const datePickerOnChange = async (e, item, i) => {
    console.log(item);
    let dateP = moment(e).format("YYYY-MM-DD");
    let data = await queryPriceData(
      UrlParams[0].queryPriceId,
      item.type,
      dateP
    );
    let dataPrice =
      item.advancePrice &&
      (await queryPriceData(UrlParams[0].queryId, item.type, dateP));
    let a = JSON.parse(JSON.stringify(queryPriceDatas));
    data[0].priceList = dataPrice;
    data[0].datePicker = dateP;
    data[0].type = item.type;
    data[0].advancePrice = item.advancePrice;
    a[i] = data[0];
    setQueryPriceDatas(a);
  };
  const queryPrice = async () => {
    let arr = [];
    for (let index = 0; index < UrlParams[0].categoryList.length; index++) {
      let today = moment(new Date()).format("YYYY-MM-DD");
      let getdata = await queryPriceData(
        UrlParams[0].queryPriceId,
        UrlParams[0].categoryList[index],
        today
      );
      console.log(getdata);
      getdata[0].advancePrice = false;
      getdata[0].datePicker = today;
      getdata[0].type = UrlParams[0].categoryList[index];
      arr.push(getdata[0]);
      console.log(arr);
      // arr.length==UrlParams[0].categoryList.length &&setQueryPriceDatas(arr)
    }
    setQueryPriceDatas(arr);
  };
  useEffect(() => {
    queryPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="fundingSummaryBox">
      <div className="fundingSummaryTitleBox">
        <p>{UrlParams[0].queryName}</p>
        <span>{UrlParams[0].titleTips}</span>
      </div>
      <div className="fundingSummaryContentBox">
        <ul>
          {queryPriceDatas?.map((item, oindex) => {
            return (
              <li key={oindex}>
                <div className="title">
                  <div className="titieLeft">
                    <p>{item.category}</p>
                    <span>无收款可用天数 {item.available_days}</span>
                  </div>
                  <div className="titleRight">
                    <DatePicker
                      onChange={(e) => {
                        datePickerOnChange(e, item, oindex);
                      }}
                      defaultValue={moment(item.datePicker, "YYYY-MM-DD")}
                      suffixIcon={<DownOutlined />}
                      allowClear={false}
                      bordered={false}
                    />
                  </div>
                </div>
                <div className="priceBox">
                  {item.amount_balance}
                  <span>万元(资金余额)</span>
                  <Button
                    onClick={(e) => toggleAdvancePrice(item, oindex)}
                    className="telescopingBtn"
                    size="small"
                    type="primary"
                    icon={item.advancePrice ? <DownOutlined /> : <UpOutlined />}
                  >
                    {item.advancePrice ? "收起明细" : "展开明细"}
                  </Button>
                </div>
                <div className="progressBox">
                  <div className={priceNervousLevel(item.early_status_value)}>
                    <div className="progressCircle">
                      <div className="circle_left ab"></div>
                      <div className="circle_right ab"></div>
                      <div className="circle_text">
                        <p>{item.early_status}</p>
                        <span>可用/需求=预警值{item.early_warning_value}</span>
                      </div>
                    </div>
                  </div>

                  <div className="progressPrice">
                    <span></span>可用资金额度
                    <p>{item.available_funds}</p>
                    <span className="blueColor"></span>资金需求
                    <p>{item.fund_demand}</p>
                  </div>
                </div>
                {item.advancePrice ? (
                  <div className="telescopingBox">
                    <ul>
                      <li>
                        {item.priceList.map((obj, io) =>
                          io === 0 ? (
                            <div>
                              <div className="bigFont">
                                {obj.availableFundsField}
                              </div>
                              <div className="bigFont fontPrice">
                                {obj.availableFunds}
                              </div>
                            </div>
                          ) : (
                            <p
                              onClick={(e) => {
                                goFundDetail(item, obj);
                              }}
                            >
                              {obj.availableFundsField}
                              <span>{obj.availableFunds}</span>
                            </p>
                          )
                        )}
                      </li>
                      <li>
                        {item.priceList.map((obj, io) =>
                          io === 0 ? (
                            <div>
                              <div className="bigFont">
                                {obj.fundingNeedsField}
                              </div>
                              <div className="bigFont fontPrice">
                                {obj.fundingNeeds}
                              </div>
                            </div>
                          ) : (
                            <p>
                              {obj.fundingNeedsField}
                              <span>{obj.fundingNeeds}</span>
                            </p>
                          )
                        )}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div></div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default FundingSummary;
