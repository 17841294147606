import React, { useEffect, useState } from "react";
import { Card, Radio, Input, Button, Modal, Select, Form, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import commonService from "@/service/common";
import shopcoolService from "@/service/shopcoolManage";
const { Option } = Select;
const { TextArea } = Input;
const ShopcoolTask = (props) => {
  const { requestVal, iqueryDetailDrawerCallback } = props;
  const [form] = Form.useForm();
  // const disableStatut=requestVal.taskStatus=='已办'?true:false

  const [btnList, setBtnList] = useState([]);
  const [status, setStatus] = useState(null);

  const [remarks, setRemarks] = useState(null);

  const [checkNick, setCheckNick] = useState(false);
  const [options, setOptions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectBtn, setSelectBtn] = useState({});

  const QueryTaskActions = async (requestVal) => {
    let parame = {
      taskId: requestVal.taskId,
    };
    const res = await commonService.QueryTaskActions(parame);
    if (res && res.success) {
      setBtnList(res.content);
    }
  };
  useEffect(() => {
    form.validateFields(["reasonRemarks"]);
  }, [checkNick, form]);
  const selectChange = (e) => {
    setCheckNick(e === "其他" ? true : false);
  };
  const rollBack = async (btn) => {
    let params = {
      keyCode: JSON.parse(btn.actData).keyCode,
    };
    const res = await shopcoolService.QueryDictionaryValView(params);
    setOptions(res);
    form.resetFields();
    setIsModalVisible(true);
    setSelectBtn(btn);
  };
  const handleOk = () => {
    form.validateFields().then((values) => {
      processTask(selectBtn, checkNick ? values.reasonRemarks : values.reason);
      console.log("表单验证项： ", values);
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const processTask = async (btn, val) => {
    if (btn.actDirective === "doneXmsShop" && (!remarks || !status)) {
      message.warning("请选择状态填写备注");
      return;
    }
    let actData = JSON.parse(btn.actData);
    let params = {
      TaskId: btn.taskId,
      ActionId: btn.actionId,
      formData: {
        tenantId: requestVal.tenantId,
        shopId: requestVal.shopId,
        taskId: requestVal.taskId,
        recommendId: requestVal.recommendId,
        status: status,
        remarks: remarks,
      },
    };
    if (val) {
      //动态配置退回、访问失败参数
      for (var key in actData) {
        if (key === "fieldTxt") {
          params.formData[actData[key]] = val;
        }
      }
    }
    const res = await commonService.ProcessTask(params);
    if (res.success) {
      message.success("操作成功！");
      iqueryDetailDrawerCallback({ type: "cancel", loadData: true });
      handleCancel();
    }
  };
  const handleBtn = async (btn) => {
    switch (btn.actDirective) {
      case "rollBack":
        rollBack(btn);
        break;
      default:
        Modal.confirm({
          title: "提示",
          icon: <ExclamationCircleOutlined />,
          content: "确认" + btn.actName + "吗？",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            processTask(btn);
          },
          onCancel() {},
        });
        break;
    }
  };
  useEffect(() => {
    if ([3, 4].includes(requestVal.status)) {
      setStatus(requestVal.status);
    }
    requestVal.remarks ? setRemarks(requestVal.remarks) : setRemarks(null);
    QueryTaskActions(requestVal);
  }, [requestVal]);
  return (
    <div>
      <Modal
        title="请选择退回原因"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="选择原因"
            name="reason"
            rules={[{ required: true, message: "请选择选择原因!" }]}
          >
            <Select onChange={selectChange}>
              {options?.map((item, oindex) => {
                return (
                  <Option key={oindex} value={item.val}>
                    {item.txt}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="其他原因"
            name="reasonRemarks"
            rules={[{ required: checkNick, message: "请输入其他原因!" }]}
          >
            <TextArea rows={4} placeholder="请输入其他原因" maxLength={6} />
          </Form.Item>
        </Form>
      </Modal>
      <Card>
        <p>餐厅名称：{requestVal.shopName}</p>
        <p>餐厅地址：{requestVal.address}</p>
        <p>餐厅电话：{requestVal.phoneNumber}</p>
      </Card>
      <Radio.Group
        // disabled={disableStatut}
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        style={{ margin: "20px 0" }}
      >
        <Radio value={4}>已上线</Radio>
        <Radio value={3}>拓展失败</Radio>
      </Radio.Group>
      <p>备注：</p>
      <TextArea
        // disabled={disableStatut}
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
        rows={4}
        placeholder="请输入内容"
      />
      <div style={{ margin: "20px 0", textAlign: "right" }}>
        {btnList?.map((item, bindex) => {
          return (
            <Button
              key={bindex}
              onClick={(e) => handleBtn(item)}
              type={["rollBack"].includes(item.actDirective) && "primary"}
              style={{ marginLeft: "10px" }}
            >
              {item.actName}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default ShopcoolTask;
