/**
 * @description GCAR客服台-会议结算
 */

import React, {
    forwardRef,
    useImperativeHandle, useRef,
    useState
} from "react";
import _ from "lodash";
import EditableFormTable from "@/pages/HomePage/ConfirmSettlementCode/EditTable";
import {Modal, Table, Pagination, Form, message, Button, Drawer, Space, Row, Col, Input, Radio, InputNumber} from 'antd';
import {column1, mockData1, column2, mockData2, column3} from "@/pages/HomePage/ConfirmSettlementCode/dataSet";
import smartService from "@/service";
import "./index.less"
const dataSet1 = ['total', 'tripFinish', 'tripCancel', 'tripCancelHaveFee', 'tripInvalid', 'tripNoOrder', 'tripNoTrain'];
const dataSet2 = ['noDeliver', 'changeEndAddress', 'driverService', 'customerService', 'specialCase', 'overBudget', 'noTrain', 'otherCase']
const { TextArea } = Input;
const validateNumber = (rule, value) => {
        if (value &&!validateNumber(value)) {
            return Promise.reject('请输入数字');
        }
        return Promise.resolve();
    }
export default forwardRef((props, ref) => {
    // drawer visible control
    const [modalVisible, setModalVisible] = useState(false);
    // preview modal control
    const [previewVisible, setPreviewVisible] = useState(false);
    // email content
    const [emailObject, setEmailObject] = useState(null);
    // proposalId
    const [proposalId, setProposalId] = useState();
    // set visible on passenger
    const [settingVisible, setSettingVisible] = useState(false);
    // save tripId temporarily
    const [tripId, setTripId] = useState();
    // identify is alter price or not
    const [isAlterPrice, setIsAlterPrice] = useState(false);
    // save passengerId temporarily
    const [passengerId, setPassengerId] = useState();
    // row data
    const [rowData, setRowData] = useState();
    // form instance
    const [formSetting] = Form.useForm();
    // meeting infos
    const [miceInfo, setMiceInfo] = useState({});
    // table-related configuration, from top to bottom , table1, table2, table3
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [tableData3, setTableData3] = useState([]);
    const editTableRef = useRef();
    // expose to parent component
    useImperativeHandle(ref, () => ({
        handle: (btn, rowKeys, selectedRows, data) => {
            console.log(btn, rowKeys, selectedRows, data);
            if(['ChangeTripAmountBtn', 'SetPassengerInvalid'].includes(btn.actionCode)){
                setTripId(selectedRows.tripId);
                setPassengerId(selectedRows.passengerId);
                setRowData(selectedRows);
                setSettingVisible(true);
                // 确定是否是改价（标识改价和）
                setIsAlterPrice(btn.actionCode === "ChangeTripAmountBtn");
            }else {
                setProposalId(data.proposalId);
                setModalVisible(true);
                GetInfo(data.proposalId);
            }
        }
    }))
    // settlement modal on ok handler
    const onOk = () => {
        console.log(tableData2);
        const params = {
            "ProposalId": proposalId
        }
        dataSet2.forEach((i, index) => {
            params[i] = {
                tripCount: tableData2[index].tripCount,
                tripFee: tableData2[index].tripFee,
                tripHaveTaxFee: tableData2[index].tripHaveTaxFee,
                tripServiceFee: tableData2[index].tripServiceFee,
                tripSumFee: tableData2[index].tripSumFee
            }
        })
        console.log(params);
        smartService.SaveMiceSettleInfo(params).then(res => {
            if(res.success){
                message.success('成功');
                onCancel();
                props.reloadData();
            }
        })
    }
    // settlement modal on cancel handler
    const onCancel = () => {
        setModalVisible(false);
    }
    // setting modal on ok handler
    const onOkSetting = () => {
        formSetting.validateFields().then(values => {
            console.log(values);
            const params = isAlterPrice ? {
                "TenantId": rowData.tenantId,
                "id": rowData.id,
                "MiceId": rowData.miceId,
                ...values
            } : {
                ...values,
                "TripId": tripId,
                "PassengerId": passengerId
            }
            const promise = isAlterPrice? smartService.ChangeConfirmFee(params) : smartService.NullifiedPassenger(params);
            promise.then(res => {
                if(res.success){
                    message.success('成功');
                    onCancelSetting();
                    props.queryModalRef.current.freshQuery();
                }
            })
        })
    }
    // setting modal on cancel handler
    const onCancelSetting = () => {
        setSettingVisible(false);
    }
    // get related mice settlement fee
    const GetInfo = (id) => {
        const params = {"ProposalId": id};
        smartService.GetMiceSettleInfo(params).then(res => {
            console.log(res);
            if(res.success){
                const target = res.content;
                column2[0].title = (
                    <div>
                        <div style={{borderBottom: '1px solid #f0f0f0'}}>meetingCode</div>
                        <div>{target.meetingCode}</div>
                    </div>
                );
                const {miceTripsFee, miceReduceFee, miceSettleFee} = res.content;
                // tabledata in table1 and table3 are origin from mockData1, to prevent cross affection, make a copy of original data;
                const cloneMockData = _.cloneDeep(mockData1);
                const arr1 = mockData1.map((i, index) => {
                    if(index > 0){
                        i.code = target.meetingCode;
                    }
                    const str = dataSet1[index];
                    i.tripCount = miceTripsFee[str]['tripCount'] || 0;
                    i.tripHaveTaxFee = miceTripsFee[str]['tripHaveTaxFee'] || 0;
                    i.tripServiceFee = miceTripsFee[str]['tripServiceFee'] || 0;
                    i.tripSumFee = miceTripsFee[str]['tripSumFee'] || 0;
                    i.key = index + 1;
                    return i
                })
                setTableData1(arr1);
                const arr2 = mockData2.map((i, index) => {
                    const str = dataSet2[index];
                    i.tripCount = miceReduceFee[str]['tripCount'] || 0;
                    i.tripHaveTaxFee = miceReduceFee[str]['tripHaveTaxFee'] || 0;
                    i.tripServiceFee = miceReduceFee[str]['tripServiceFee'] || 0;
                    i.tripSumFee = miceReduceFee[str]['tripSumFee'] || 0;
                    i.key = index + 1;
                    return i
                })
                console.log(arr2);
                setTableData2(arr2);
                // !!assign data to child component
                editTableRef.current.handleSet(arr2);
                const arr3 = cloneMockData.map((i, index) => {
                    if(index > 0){
                        i.code = target.meetingCode;
                    }
                    const str = dataSet1[index];
                    i.tripCount = miceSettleFee[str]['tripCount'] || 0;
                    i.tripHaveTaxFee = miceSettleFee[str]['tripHaveTaxFee'] || 0;
                    i.tripServiceFee = miceSettleFee[str]['tripServiceFee'] || 0;
                    i.tripSumFee = miceSettleFee[str]['tripSumFee'] || 0;
                    i.key = index + 1;
                    return i
                })
                setTableData3(arr3);
            }
        })
    }
    // calculate the deduction fee
    const calculateFee = (data, record) => {
        console.log(data);
        setTableData2(data);
        // total reduce
        const total = ['tripHaveTaxFee', 'tripServiceFee'].map(i => {
            const sum = data.reduce((x, y) => {
                return x + y[i]
            }, 0)
            return sum
        })
        console.log(total);
        const data1 = _.cloneDeep(tableData1);
        const arr = data1.map((i, index) => {
            if(index < 2) {
                i.tripHaveTaxFee =_.round(i.tripHaveTaxFee - total[0], 2); // 车费
                i.tripServiceFee = _.round(i.tripServiceFee - total[1], 2); // 服务费
                i.tripSumFee = _.round((i.tripHaveTaxFee + i.tripServiceFee), 2); // 总价
                return i
            }
            return  i
        })
        console.log(arr);
        setTableData3(arr);
    }
    // send emails button
    const handleSendEmails = (IsPreView) => {
        const params = {
            "ProposalId": proposalId,
            IsPreView: IsPreView
        }
        return new Promise((resolve, reject) => {
            smartService.SendEmail(params).then(res => {
                if(res.success){
                    console.log(res);
                    if(IsPreView){
                        setEmailObject(res.content);
                        setPreviewVisible(true);
                        resolve();
                    }else {
                        resolve(true);
                        message.success('成功');
                    }
                }
            })
        })
    }
    // handle preview
    const onOkPreview = () => {
        Modal.confirm({
            title: '重要提示？',
            content: '确定发送邮件吗？',
            onOk: async () => {
                const flag = await handleSendEmails(false);
                console.log(flag);
                if(flag){
                    setPreviewVisible(false);
                    setModalVisible(false);
                }
            },
        });

    }
    // cancel preview
    const onCancelPreview = () => {
        setPreviewVisible(false);
    }
    return (
        <>
        {/* nullified passages and customers service alter fee*/}
            <Modal
                title={isAlterPrice ? '客服改价' : '设置乘车人是否有效'}
                destroyOnClose
                visible={settingVisible}
                width="30%"
                onOk={onOkSetting}
                okText="确定"
                onCancel={onCancelSetting}
            >
                {isAlterPrice ? <Form
                    form={formSetting}
                    name="setting"
                    labelCol={{
                        span: 6,
                    }}
                >
                    <Form.Item
                        name="ConfirmFee"
                        label="订单金额（不含税）"
                        rules={[
                            {required: true, message: '请填写'}
                        ]}>
                            <InputNumber min={0} precision={2} controls={false} placeholder="请输入"/>
                    </Form.Item>
                    <Form.Item
                    name="ConfirmServiceFee"
                    label="服务费"
                    rules={[
                        {required: true, message: '请填写', validator: validateNumber}
                    ]}>
                    <InputNumber min={0} precision={2} controls={false} placeholder="请输入"/>
                </Form.Item>
                    <Form.Item
                        name="Remark"
                        label="备注">
                        <TextArea />
                    </Form.Item>
                </Form> : <Form
                    form={formSetting}
                    name="setting"
                >
                    <Form.Item
                        name="Remark"
                        label="备注">
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        name="ValidType"
                        label="是否有效">
                        <Radio.Group>
                            <Radio value="1">有效</Radio>
                            <Radio value="0">无效</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>}
            </Modal>
        {/* bill html */}
        <Drawer
            title="对账单"
            width="80%"
            className="settlement_modal"
            onClose={onCancel}
            open={modalVisible}
            extra={
                <Space>
                    <Button type="primary" onClick={() => handleSendEmails(true)}>
                        预览邮件
                    </Button>
                </Space>
            }
            footer={
                <Space align="end" direction="horizontal">
                    <Button onClick={() => onCancel()}>
                       取消
                    </Button>
                    <Button type="primary" onClick={() => onOk()}>
                        确定修改
                    </Button>
                </Space>
            }
            >
            <div className="container">
                <div className="header_line">
                    1,系统总价（车费含税、服务费不含税）
                </div>
                <Table className="settlement_table" columns={column1} bordered size="small"
                       style={{maxWidth: "100%", wordBreak: "break-word"}}
                       pagination={false} dataSource={tableData1}/>
                <div className="header_line">
                    2,对账调账（车费、服务费）
                </div>
                <EditableFormTable ref={editTableRef} tableData2={tableData2} calculateFee={calculateFee}/>
                <div className="header_line">
                    3,确认结算总价（车费含税、服务费不含税）
                </div>
                <Table className="settlement_table" columns={column3} bordered size="small"
                       style={{maxWidth: "100%", wordBreak: "break-word"}}
                       pagination={false} dataSource={tableData3}/>
            </div>
        </Drawer>
            <Modal
                wrapClassName="settlement_modal"
                title="预览邮件"
                destroyOnClose
                visible={previewVisible}
                width="70%"
                onOk={onOkPreview}
                okText="发送邮件"
                onCancel={onCancelPreview}
            >
                <Row style={{margin: '10px 0'}}>
                    <Col span={2}>发送人:</Col>
                    <Col span={22}>
                        {emailObject && emailObject.toList.map(i => {
                            return (
                                <span key={i.email}>
                                    {i.email}, &nbsp;
                                </span>
                            )
                        })}
                    </Col>
                </Row>
                <Row>
                    <Col span={2}>抄送:</Col>
                    <Col span={22}>
                        {emailObject && emailObject.ccList.map(i => {
                            return (
                                <span key={i.email}>
                                    {i.email}, &nbsp;
                                </span>
                            )
                        })}
                    </Col>
                </Row>
                <div style={{padding: 0}} dangerouslySetInnerHTML={{__html: emailObject? emailObject.body : ''}}></div>
            </Modal>
        </>
    )
})
