import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {Modal, Descriptions, Col, Row} from "antd";
import moment from "moment/moment";
import commonService from "@/service/common";
import { UploadFiles } from "smart-filer-react";
import {SmartStorage} from "@/utils";
const CarBillingDetailModal = forwardRef((props, ref) => {
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [billingForm, setBillingForm] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [passengersList, setPassengersList] = useState([]);
    const [uploadProps, setUploadProps] = useState();

    const showModal = async (rowData) => {
        setBillingForm(rowData)
        let data = rowData?.itsExpenseData?.passengersList || []
        setPassengersList(data)
        queryFile(rowData)
    }
    const queryFile = async (rowData) => {
        let params = {
            filterData: {
                'processId': SmartStorage.get("detailRow").proposalId,
                'supplierId': SmartStorage.get("detailRow").supplierId,
                "relatedId": '',
                "catalog": "行车轨迹"
            }
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileItem = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            setFileList(fileItem);
            setMappingData(params.filterData, fileItem)
        }
    }
    const setMappingData = (mappingData, fileItem) => {
        let updata = {
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            disabled: true,
            defaultFileList: fileItem,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                setFileList(fileList);
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    setFileList(fileList);
                }
            },
        };
        setUploadProps(updata);
        setIsModalOpen(true);
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    return (
        <Modal title="报价" width={800} maskClosable={false} open={isModalOpen} onOk={handleCancel} onCancel={handleCancel}>
            <Descriptions column={2}>
                <Descriptions.Item label="用车形式">
                    {billingForm?.itsExpenseData?.carModus}
                </Descriptions.Item>
                <Descriptions.Item label="车型">
                    {billingForm?.itsExpenseData?.carType}
                </Descriptions.Item>
                <Descriptions.Item label="车牌号">
                    {billingForm?.itsExpenseData?.licensePlate}
                </Descriptions.Item>
                <Descriptions.Item label="公里数">
                    {billingForm?.itsExpenseData?.kilometers}
                </Descriptions.Item>
                <Descriptions.Item label="出发地">
                    {billingForm?.itsExpenseData?.departure}
                </Descriptions.Item>
                <Descriptions.Item label="预计上车时间">
                    {moment(billingForm?.itsExpenseData?.sDateTime).format('YYYY-MM-DD HH:mm:ss')}
                </Descriptions.Item>
                <Descriptions.Item label="目的地">
                    {billingForm?.itsExpenseData?.destination}
                </Descriptions.Item>
                <Descriptions.Item label="预计下车时间">
                    {moment(billingForm?.itsExpenseData?.eDateTime).format('YYYY-MM-DD HH:mm:ss')}
                </Descriptions.Item>
                <Descriptions.Item label="总金额">
                    {billingForm?.itsExpenseData?.money}
                </Descriptions.Item>
                <Descriptions.Item label="乘车人数">
                    {billingForm?.itsExpenseData?.pieceQty} 人
                </Descriptions.Item>
            </Descriptions>
            <Row>
                <Col span={4}>
                    行车轨迹：
                </Col>
                <Col span={20}>
                    <UploadFiles {...uploadProps} />
                </Col>
            </Row>
            <Descriptions column={2} className="info_center">
                {passengersList.length>0 && passengersList.map((item, index) =>  (<>
                    <Descriptions.Item label="乘车人" key={`name${index}`}>
                        {item.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="乘车人公司" key={`hospital${index}`}>
                        {item.hospital}
                    </Descriptions.Item>
                </>) )}
            </Descriptions>
        </Modal>
    );
})
export default CarBillingDetailModal
