import { request } from "./request";

const shopcoolService = {
   // 字典查询
   QueryDictionaryValView: (params) => {
    return request.post("/api/shopkeeper/Dictionary/QueryDictionaryValView", params);
  }
};

export default shopcoolService;
