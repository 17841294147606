import React, { useEffect, useState } from "react";
import {Row, Col, Descriptions, Form, Divider, InputNumber, Button, message} from "antd";
import moment from "moment";
import './CarInfoCard.less'
import { UploadFiles } from "smart-filer-react";
import {SmartStorage} from "@/utils";
import commonService from "@/service/common";
const InsureDemand = (props) => {
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [uploadProps, setUploadProps] = useState();
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [taskInfo, setTaskInfo] = useState({});
    const [carItems, setCarItems] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setTaskInfo(props.miceInfo)
        form.setFieldValue('amount', props.miceInfo?.quote || 0)
        queryFile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    const queryFile = async () => {
        let params = {
            filterData: {
                'processId': SmartStorage.get("detailRow").proposalId,
                'supplierId': SmartStorage.get("detailRow").supplierId,
                "catalog": "行车轨迹"
            }
        }
        let res = await commonService.queryFiles(params);
        if (res && res.success) {
            let fileItem = res.content.map((item) => {
                return {
                    fileKey: item.fileKey,
                    uid: item.fileKey,
                    name: item.fileName,
                    status: "done", // uploading done error removed
                    url: item.url,
                };
            });
            setFileList(fileItem);
            setMappingData(params.filterData, fileItem)
        }
    }
    const setMappingData = (mappingData, fileItem) => {
        let updata = {
            accept: ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
            type: "picture-card",
            gateway: gatewayPath,
            defaultFileList: fileItem,
            disabled: true,
            filterData: mappingData,
            uploadCallback: (file, fileList) => {
                setFileList(fileList);
            },
            removeCallback: async (file, fileList) => {
                let arr = [];
                if (file.fileKey) {
                    arr.push(file.fileKey)
                } else {
                    arr.push(file?.response?.content?.fileKey)
                }
                let params = {
                    fileKeys: arr
                }
                let res = await commonService.deleteFiles(params);
                if (res && res.success) {
                    setFileList(fileList);
                }
            },
        };
        setUploadProps(updata);
    }
    const handleSubmit = () => {
        form.validateFields().then((values) => {
            savePiece(values)
        })
    }
    const savePiece = (data) => {
        let params = {
            itemId: props.miceInfo.itemId,
            orderId: props.miceInfo.orderId,
            quote: data.amount,
            proposalId: props.miceInfo.proposalId
        };
        commonService.OfflineCarQuoteNew(params).then(res => {
            if (res && res.success) {
                message.success('保存成功')
                props.updateChild()
            }
        })
    }
    return (<>
        {taskInfo && JSON.stringify(taskInfo) !== "{}" && (
            <div className="boderRow carDetails">
                <div className="card_title">{props.moduleItem?.queryName}</div>
                <Descriptions column={4}>
                    <Descriptions.Item label="平台订单号">
                        {taskInfo.orderNum}
                    </Descriptions.Item>
                    <Descriptions.Item label="供应商订单号">
                        {taskInfo.supplierOrderNum}
                    </Descriptions.Item>
                </Descriptions>
                <div className="statusTxt">{SmartStorage.get("radioQueryData").queryName || '全部'}</div>
                <Row className="info_center">
                    <Col span={24}>
                        <Descriptions column={3}>
                            <Descriptions.Item label="用车日期">
                                {moment(taskInfo.dtStart).format('YYYY-MM-DD')}
                            </Descriptions.Item>
                            <Descriptions.Item label="用车形式">
                                { taskInfo.carModus }
                            </Descriptions.Item>
                            <Descriptions.Item label="车型">
                                { taskInfo.carType }
                            </Descriptions.Item>
                            <Descriptions.Item label="叫车人">
                                { taskInfo.buyerName } ({taskInfo.buyerPhone})
                            </Descriptions.Item>
                            <Descriptions.Item label="乘车人">
                                { taskInfo.passengerName } ({taskInfo.passengerPhone})
                            </Descriptions.Item>
                            <Descriptions.Item label="其他乘车人">
                                { taskInfo.otherPassengers }
                            </Descriptions.Item>
                            <Descriptions.Item label="乘客数量">
                                { taskInfo.passengerCount }
                            </Descriptions.Item>
                            <Descriptions.Item label="用车数量">
                                { taskInfo.qty }
                            </Descriptions.Item>
                            <Descriptions.Item label="举牌服务">
                                {taskInfo.plateService}
                            </Descriptions.Item>
                            <Descriptions.Item label="预计轨迹" span={3}>
                                <UploadFiles {...uploadProps} />
                            </Descriptions.Item>
                            {taskInfo.tripList && taskInfo.tripList.map((item, index) => (
                                <>
                                    <Descriptions.Item label="预计出发地" key={`departure${index}`}>
                                        { item.departure }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="预计目的地" key={`destination${index}`}>
                                        { item.destination }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="预计用车时间" key={`dtStart${index}`}>
                                        { item.dtStart }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="航班/车次" key={`flight${index}`}>
                                        { item.flight || '无' }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="乘车人" key={`name${index}`}>
                                        { item.name }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="乘车人电话" key={`phone${index}`}>
                                        { item.phone }
                                    </Descriptions.Item>
                                </>
                            ))}
                            <Descriptions.Item label="备注" span={3}>
                                { taskInfo.descr || '暂无' }
                            </Descriptions.Item>
                            <Descriptions.Item label="供应商备注" span={3}>
                                { taskInfo.supplierDescr || '暂无' }
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                {['CarQuote'].includes(SmartStorage.get("detailRow").nodeCode) && !props.miceInfo.trips.length ? (
                    <Row className="info_center">
                        <Col span={12}>
                            <Form form={form} component={false} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} labelAlign="left">
                                <Form.Item name='amount' label='报价金额' style={{ margin: 0 }} rules={[ { required: true, message: '请输入报价金额', }, ]}>
                                    <InputNumber style={{width: '100%'}} min={0} placeholder="请输入报价金额"/>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" loading={loading} onClick={handleSubmit}> 保存 </Button>
                        </Col>
                    </Row>
                ) : (
                    <Row  className="info_center">
                        <Col span={12}>
                            <Descriptions column={3}>
                                <Descriptions.Item label="报价金额">
                                    { taskInfo.quote }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                )}
            </div>
        )}
    </>)
}

export default InsureDemand;
