import React, { useEffect, useState } from "react";
import { Form, Col, Select } from "antd";
import defaultSettings from "./defaultSettings";
import commonService from "@/service/common";

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const { Option } = Select;

const XCascaderSelect = (props) => {
  const item = props.item;
  const [options, setOptions] = useState(item.options || []);
  const [subOptions, setSubOptions] = useState(item.subOptions || []);

  const [showSub, /* setShowSub */] = useState(true);
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);

  const rootChangeClick = (e, item, sData) => {
    console.log("级联选择父级 - ",e,item,sData);
    item.subSelect = false;
    defaultChangeClick(e, item, sData);
    if(e !== undefined){
      let subFitler = {};
      subFitler["Val"] = e;
      // subFitler[item.name] = e;
      // setShowSub(true);  //显示sub select
      getSubOptions(item.subDataSource,subFitler);
    }
  };

  const subChangeClick = (e, item, sData) => {
    console.log("级联选择子级 - ",e,item,sData);
    item.subSelect = true;
    defaultChangeClick(e, item, sData);
  };

  const defaultChangeClick = (e, item, sData) => {
    console.log(e,item,sData);
    item.callBack && props.callback(e, item, sData);
  };

  const getOptions = async (source) => {
    if (source.params && source.method && source.url) {
      let params = source.params;
      let method = source.method;
      let url = source.url;

      setLoading(true);
      let res = await commonService.common(method, url, params);
      setLoading(false);
      if (res && res.success && res.content) {
        setOptions(res.content);
      }
    }
  };

  const getSubOptions = async (source,filter) => {
    if (source.params && source.method && source.url) {
      let params = source.params;
      if(filter){
        params.filter = Object.assign(params.filter,filter)
      }
      let method = source.method;
      let url = source.url;

      setSubLoading(true);
      let res = await commonService.common(method, url, params);
      setSubLoading(false);
      if (res && res.success && res.content) {
        if(res.content.length > 0){
          let subOptions = []
          console.log(res.content);
          if(item.subOptionsProps){
            subOptions = res.content[0][item.subOptionsProps].map((item)=>{
              return {
                Val: item.Val,
                Txt: item.Txt,
              }
            })
          }else{
            subOptions = res.content.map((item)=>{
              return {
                Val: item.Val,
                Txt: item.Txt,
              }
            })
          }
          setSubOptions(subOptions)
        }else{
          setSubOptions([]);
        }
      }
    }
  };

  useEffect(() => {
    if (item.dataSource) {
      getOptions(item.dataSource);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {
        item.layout === "vertical"
        ? <>
            <Col span={item.span}>
              <Form.Item
                name={item.name}
                rules={item.rules}
                labelCol={{ span: item.lcol ? item.lcol : defaultLabelSpan }}
                wrapperCol={{ span: item.wcol ? item.wcol : defaultWrapperSpan }}
                label={item.label}
                labelAlign="right"
              >
                <Select
                  style={{ width: "100%" }}
                  mode={item.mode || "default"}
                  loading={loading}
                  disabled={item.disabled && true}
                  labelInValue={item.labelInValue}
                  allowClear={!item.allowClear}
                  placeholder={item.placeholder}
                  showSearch={item.showSearch}
                  optionFilterProp="children"
                  defaultValue={item.defaultValue}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  filterOption={(inputValue, option) =>
                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(e, sData) => rootChangeClick(e, item, sData)}
                >
                  {options.map((obj) => (
                    <Option
                      key={obj.value || obj.Val || obj.Value || obj.Id + "__" + obj.Code}
                      value={obj.value || obj.Val || obj.Value || obj.Id}
                    >
                      {obj.Txt || obj.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
              { showSub
                ? <>
                    <Form.Item
                    name={item.subName}
                    rules={item.subRules}
                    labelCol={{ span: item.subLcol ? item.subLcol : defaultLabelSpan }}
                    wrapperCol={{ span: item.subWcol ? item.subWcol : defaultWrapperSpan }}
                    label={item.subLabel}
                    labelAlign="right"
                  >
                    <Select
                      style={{ width: "100%" }}
                      mode={item.subMode || "default"}
                      loading={subLoading}
                      disabled={item.subDisabled && true}
                      labelInValue={item.subLabelInValue}
                      allowClear={!item.subAllowClear}
                      placeholder={item.subPlaceholder}
                      showSearch={item.subShowSearch}
                      optionFilterProp="children"
                      defaultValue={item.subDefaultValue}
                      filterOption={(inputValue, option) =>
                        option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={(e, sData) => subChangeClick(e, item, sData)}
                    >
                      {subOptions.map((obj) => (
                        <Option
                          key={obj.value || obj.Val || obj.Value || obj.Id + "__" + obj.Code}
                          value={obj.value || obj.Val || obj.Value || obj.Id}
                        >
                          {obj.Txt || obj.Name}
                        </Option>
                      ))}
                    </Select>
                    </Form.Item>
                    {item.subTips ? <div className="form-item-tips">{item.subTips}</div> : ""}
                  </>
                : ""
              }

            </Col>
          </>
        : <>
            <Col span={item.span}>
              <Form.Item
                name={item.name}
                rules={item.rules}
                labelCol={{ span: item.lcol ? item.lcol : defaultLabelSpan }}
                wrapperCol={{ span: item.wcol ? item.wcol : defaultWrapperSpan }}
                label={item.label}
                labelAlign="right"
              >
                <Select
                  style={{ width: "100%" }}
                  mode={item.mode || "default"}
                  loading={loading}
                  disabled={item.disabled && true}
                  labelInValue={item.labelInValue}
                  allowClear={!item.allowClear}
                  placeholder={item.placeholder}
                  showSearch={item.showSearch}
                  optionFilterProp="children"
                  defaultValue={item.defaultValue}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  filterOption={(inputValue, option) =>
                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(e, sData) => rootChangeClick(e, item, sData)}
                >
                  {options.map((obj) => (
                    <Option
                      key={obj.value || obj.Val || obj.Value || obj.Id + "__" + obj.Code}
                      value={obj.value || obj.Val || obj.Value || obj.Id}
                    >
                      {obj.Txt || obj.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
            </Col>
            { showSub
              ? <Col span={item.subSpan}>
                  <Form.Item
                    name={item.subName}
                    rules={item.subRules}
                    labelCol={{ span: item.subLcol ? item.subLcol : defaultLabelSpan }}
                    wrapperCol={{ span: item.subWcol ? item.subWcol : defaultWrapperSpan }}
                    label={item.subLabel}
                    labelAlign="right"
                  >
                    <Select
                      style={{ width: "100%" }}
                      mode={item.subMode || "default"}
                      loading={subLoading}
                      disabled={item.subDisabled && true}
                      labelInValue={item.subLabelInValue}
                      allowClear={!item.subAllowClear}
                      placeholder={item.subPlaceholder}
                      showSearch={item.subShowSearch}
                      optionFilterProp="children"
                      defaultValue={item.subDefaultValue}
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      filterOption={(inputValue, option) =>
                        option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={(e, sData) => subChangeClick(e, item, sData)}
                    >
                      {subOptions.map((obj) => (
                        <Option
                          key={obj.value || obj.Val || obj.Value || obj.Id + "__" + obj.Code}
                          value={obj.value || obj.Val || obj.Value || obj.Id}
                        >
                          {obj.Txt || obj.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {item.subTips ? <div className="form-item-tips">{item.subTips}</div> : ""}
                </Col>
              : ""
            }
          </>
      }

    </>
  );
};

export default XCascaderSelect;
