import React from "react";
import { Modal, Input, message } from "antd";
import { SmartStorage } from "@/utils";
import moment from "moment";
const { TextArea } = Input;

const LogModal = (props) => {
  console.log(props, "props");
  var logMessage = "";
  // 解构props
  const {
    modalTitle,
    modalVisable,
    selectQueryModalData,
    editLogInfo,
    logType,
  } = props;
  const handleOk = () => {
    if (logType === "add") {
      let addObj = {};
      if (logMessage !== "") {
        addObj.invoiceId = selectQueryModalData.invoiceId;
        addObj.txt = logMessage;
        addObj.userName = SmartStorage.get("userInfo").userName;
        addObj.date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        props.saveLog("add", addObj);
      } else {
        message.error("沟通记录不能为空！！！");
      }
    } else {
      let editObj = editLogInfo;
      editObj.txt = logMessage;
      props.saveLog("edit", editObj);
    }
  };
  const handleCannel = () => {
    props.logModalCancel();
  };
  return (
    <Modal
      title={modalTitle || "添加沟通记录"}
      visible={modalVisable}
      onOk={handleOk}
      onCancel={handleCannel}
    >
      <TextArea
        defaultValue={logType === "add" ? "" : editLogInfo.txt}
        rows={4}
        onChange={(e) => {
          logMessage = e.target.value;
        }}
      />
    </Modal>
  );
};

export default LogModal;
