/* 
    该文件是用于创建一个专门为Count组件服务的reducer,reducer的本质就是一个函数
    reducer函数接到2个参数，之前的状态，和动作对象
*/

import { UPDATEMENUTITLE } from "../actions/actionsType";

const initialValue = {
  title: '主页'
}
function Title(preState = initialValue.title, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATEMENUTITLE:
      return data;
    default:
      return preState;
  }
}

const BaseInfo = {
  Title
}

export default BaseInfo;

