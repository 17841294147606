import React, { useState, useEffect } from "react";
import {
  Modal,
  Image,
  Dropdown,
  Menu,
  Avatar,
  Badge,
  Tabs,
  Form,
  AutoComplete,
  Input,
  Progress,
  notification,
  Empty,
} from "antd";
import { SmartStorage } from "@/utils";
import publicMethod from "@/utils/public.js";
import commonService from "@/service/common";
import {
  BellOutlined,
  QuestionCircleOutlined,
  ToolOutlined,
  LogoutOutlined,
  EditOutlined,
  UserOutlined,
  SearchOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import "./index.less";
import { Link } from "react-router-dom";
const verifyTxt = [
  {
    id: "hasEightNum",
    title: "长度必须大于8位",
    verify: false,
  },
  {
    id: "isUpperCase",
    title: "必须包含大写字母",
    verify: false,
  },
  {
    id: "isLowerCase",
    title: "必须包含小写字母",
    verify: false,
  },
  {
    id: "isHasNum",
    title: "必须包含数字",
    verify: false,
  },
  {
    id: "isSpecialChar",
    title: "必须包含特殊字符",
    verify: false,
  },
];
const HeaderBar = (props) => {
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState([]);
  const [verifyModes, setVerifyModes] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // const [sessionId, setSessionId] = useState("");

  // 搜索输入框显示隐藏
  const [isOpen, setIsOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const logout = (isCpwd) => {
    Modal[isCpwd ? "warning" : "confirm"]({
      title: "再次确认",
      okText: "确认",
      cancelText: "我再想想",
      content: "是否确认退出登录?",
      onOk() {
        publicMethod.deleteLocalData();
      },
      onCancel() {},
    });
  };
  useEffect(() => {
    // changePassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changePassword = async () => {
    let params = {
      UserId: SmartStorage.get("userInfo")?.userId,
      // Day: tenantDays[getCookie("tenant")] || 0,
      Day: 90,
    };
    let response = await commonService.isChangePassword(params);
    console.log("================", response);
    if (response && response.success) {
      if (response.content.change) {
        setModalVisible(true);
      } else if (response.content.days <= 10) {
        Modal.confirm({
          title: "密码过期提醒",
          okText: "去重置",
          cancelText: "暂时跳过",
          content: `您的密码将在${response.content.days}天后过期，请及时重置`,
          onOk() {
            setModalVisible(true);
          },
          onCancel() {},
        });
      }
    }
  };
  const menu = (
    <Menu className="menu" onClick={(e) => menuClick(e)}>
      <Menu.Item key={1}>
        <span className="menu_item">
          <ToolOutlined className="icon" />
          <Link className="grayColor" to="/accountSettings">
            <span>个人设置</span>
          </Link>
        </span>
      </Menu.Item>
      <Menu.Item key={3}>
        <span className="menu_item">
          <EditOutlined className="icon" />
          <span>修改密码</span>
        </span>
      </Menu.Item>
      <Menu.Item key={2}>
        <span className="menu_item">
          <LogoutOutlined className="icon" />
          <span>退出登录</span>
        </span>
      </Menu.Item>
      
    </Menu>
  );
  const menuClick = (item) => {
    switch (item.key) {
      case "2":
        logout();
        break;
      case "1":
        // this.$router.push({path: '/accountSettings'})
        // props.history.push({
        //   pathname: '/accountSettings',
        // });
        break;
      case "3":
        setModalVisible(true);
        break;
      default:
        break;
    }
    // if (item.key === "2") {
    //   logout();
    // }
  };
  const messageDetial = () => {
    return (
      <div className="info">
        <Tabs tabPosition="top" defaultActiveKey="1">
          <Tabs.TabPane tab="通知" key="1">
            <Empty />
          </Tabs.TabPane>
          <Tabs.TabPane tab="消息" key="2">
            <Empty />
          </Tabs.TabPane>
          <Tabs.TabPane tab="待办" key="3">
            <Empty />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  };
  // 显示与隐藏
  const autoShow = () => {
    setIsOpen(true);
  };
  const handleOk = () => {
    form.validateFields().then(async (values) => {
      console.log(values);
      let params = values;
      console.log(params);
      setLoading(true);
      let res = await commonService.changePassword(params);
      setLoading(false);
      if (res.success) {
        notification.info({
          message: "提示",
          description: "修改密码成功，请重新登录！",
          placement: "topRight",
        });
        pwdChange('')
        setModalVisible(false);
        form.resetFields();
        logout(true);
      }
    });
  };
  const pwdChange = (e) => {
    let data = e?.target?.value;
    let modes = 0;
    let newPwd = data?.replace(/\s*/g, "")||[];
    // console.log('newPwd==',newPwd)
    let obj = {
      hasEightNum: false,
      isHasNum: false,
      isLowerCase: false,
      isUpperCase: false,
      isSpecialChar: false,
    };
    if (newPwd.length >= 8) {
      obj.hasEightNum = true;
      modes++;
    }
    if (/\d/.test(newPwd)) {
      obj.isHasNum = true;
      modes++;
    } //数字
    if (/[a-z]/.test(newPwd)) {
      obj.isLowerCase = true;
      modes++;
    } //小写
    if (/[A-Z]/.test(newPwd)) {
      obj.isUpperCase = true;
      modes++;
    } //大写
    if (/(?=.*[\W_])/.test(newPwd)) {
      obj.isSpecialChar = true;
      modes++;
    } //特殊字符
    verifyTxt.map((s) => {
      s.verify = obj[s.id];
      return s;
    });
    setVerifyModes(modes * 20);
  };
  return (
    <>
      <div className="header_left">
        <Image
          className="img"
          src="https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/logo/v2/xcenter_logo.png"
          alt="LOGO"
          preview={false}
        />
      </div>
      <div className="header_right">
        <div className="search">
          <SearchOutlined className="search_icon" onClick={autoShow} />
          {isOpen && (
            <AutoComplete
              style={{ width: 200 }}
              options={options}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              onBlur={() => setIsOpen(false)}
            />
          )}
        </div>

        <QuestionCircleOutlined className="questIcon" />
        <div className="badge">
          <Badge count={0} size="small" overflowCount={99}>
            <Dropdown
              overlay={messageDetial()}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <BellOutlined className="bellIcon" />
            </Dropdown>
          </Badge>
        </div>

        <div className="owner">
          <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}>
            <div>
              {props.userInfo.Avatar ? (
                <Avatar size="small" src="https://joeschmoe.io/api/v1/random" />
              ) : (
                <Avatar size="small" icon={<UserOutlined />} />
              )}
              <span className="userName">{props.userInfo.dspName || ""}</span>
            </div>
          </Dropdown>
        </div>
      </div>
      <Modal
        title="修改密码"
        width="800px"
        destroyOnClose={true}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={() => {
          form.resetFields();
          pwdChange('')
          setModalVisible(false);
        }}
      >
        <Form
          form={form}
          preserve={false}
          component={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            name="old"
            label="原始密码"
            rules={[
              {
                required: true,
                message: "请输入原始密码!",
              },
            ]}
          >
            <Input.Password
              placeholder="请输入原始密码"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            name="new"
            label="输入新密码"
            rules={[
              {
                required: true,
                message: "请输入新密码!",
              },
            ]}
          >
            <Input.Password
              placeholder="请输入新密码"
              onChange={(e) => pwdChange(e)}
            />
          </Form.Item>
          <div className="verify_pwd_Box">
            <Progress
              style={{
                marginBottom: "10px",
              }}
              percent={verifyModes}
            />
            <div class="verify_pwd_con">
              <p>您的密码必须包含</p>
              {verifyTxt.map((item) => (
                <div className={item.verify ? "isVerify" : ""}>
                  <CheckCircleOutlined />
                  {item.title}
                </div>
              ))}
            </div>
          </div>

          <Form.Item
            name="confirm"
            label="确认密码"
            rules={[
              {
                required: true,
                message: "请输入确认密码!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("确认新密码需和新密码保持一致!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="确认新密码需和新密码保持一致" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default HeaderBar;
