import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { Form, Modal, Select, Input, Upload, Button, Icon } from "antd";
// eslint-disable-next-line no-unused-vars
import { getCookie } from "tiny-cookie";
// eslint-disable-next-line no-unused-vars
import { UploadFiles } from "smart-filer-react";
import commonService from "@/service/common";
const { Option } = Select;
const BatchAudit = (props) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formStructure, setFormStructure] = useState([]);
  const [selectItem, setSelectItem] = useState({});
  const [selectItemValue, setSelectItemValue] = useState("");
  const [required, setRequired] = useState(false);
  const handleOk = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const values = await form.validateFields();
      let formTokens = [];
      for (let index in props.selectedRows) {
        formTokens.push(props.selectedRows[index].token);
      }
      let string = `{"` + selectItem.name + `":"` + selectItemValue + `"}`;
      let params = {
        collectMappingName: props.selectedRows[0].collect_mapping_name,
        formTokens: formTokens,
        setValues: JSON.parse(string),
      };
      console.log(params);
      let res = await commonService.batchSetForms(params);
      if (res && res.success) {
        setModalVisible();
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const setModalVisible = () => {
    setSelectItem({});
    setSelectItemValue("");
    form.resetFields();
    props.batchAuditCallback({ type: "cancel" });
  };
  const getFormStructure = async () => {
    let params = {
      formToken: props.selectedRows[0].form_token,
    };
    let res = await commonService.getFormStructure(params);
    if (res && res.success) {
      setFormStructure(res.content);
    }
  };
  const selectChangeClick = (e, e1) => {
    for (let item in formStructure) {
      if (formStructure[item].label === e) {
        setRequired(
          formStructure[item].rules.length !== 0
            ? formStructure[item].rules[0].required
            : false
        );
        setSelectItem(formStructure[item]);
        setSelectItemValue("");
      }
    }
  };
  const selectItemChangeClick = (e, e1) => {
    setSelectItemValue(e);
  };
  const inputChangeClick = (e, e1) => {
    setSelectItemValue(e.target.value);
  };
  useEffect(() => {
    props.batchAuditVisible && getFormStructure();
    form.validateFields(["nickname", "reason"]);
    setIsModalOpen(props.batchAuditVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={setModalVisible}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="选择字段"
            name="reason"
            rules={[{ required: true, message: "请选择选择字段!" }]}
          >
            <Select style={{ width: "70%" }} onChange={selectChangeClick}>
              {formStructure.map((obj) => (
                <Option key={obj.label} value={obj.label}>
                  {obj.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectItem.type === "input" ? (
            <div>
              <Form.Item
                label={selectItem.label}
                name="nickname"
                rules={[{ required: { required }, message: "" }]}
              >
                <Input
                  style={{ width: "70%" }}
                  value={selectItemValue}
                  onChange={inputChangeClick}
                />
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          {["radio", "attachment", "select"].includes(selectItem.type) ? (
            <div>
              <Form.Item
                label={selectItem.label}
                name="nickname"
                rules={[{ required: { required }, message: "" }]}
              >
                {selectItem.options.length > 0 ? (
                  <Select
                    style={{ width: "70%" }}
                    onChange={selectItemChangeClick}
                  >
                    {selectItem.options[0].map((obj) => (
                      <Option key={obj.name} value={obj.value}>
                        {obj.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  "暂无数据"
                )}
              </Form.Item>
            </div>
          ) : (
            ""
          )}
        </Form>
      </Modal>
    </div>
  );
};
export default BatchAudit;
