

import commonService from "@/service/common";
import React, { useState, useContext, useEffect,useRef } from 'react';
import ReactRenderer from '@alilc/lowcode-react-renderer';
import { buildComponents } from '@alilc/lowcode-utils';
import { useHistory } from "react-router-dom";
import { injectComponents } from '@alilc/lowcode-plugin-inject';
import { getCookie } from "tiny-cookie";
import LayoutContext from "@/pages/Layout/layoutContext";
import IqueryTable from "./iquerTableLocal";
import { SmartStorage } from "@/utils";
import { Modal, message } from 'antd';
import {LoadingOutlined} from "@ant-design/icons"
import  Xform from '@/components/Xform/xformRender'
import KKfileViewerModal from "@/components/KKfileViewerModal";
import { Base64 } from 'js-base64';
const App = (props) => {


  const { LayoutState } = useContext(LayoutContext);
  const history = useHistory();
  const [schemaInfo, setSchemaInfo] = useState({})
  const [Model, setModel] = useState({})
  const [isPage, setIsPage] = useState(false)
  const [xformData, setXformData] = useState(null)
  // 文件预览
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  const XformRef = useRef();


  console.log("====yida中的props=====", props);
  console.log("====本次渲染使用schema====", schemaInfo)


const onlinePreview = (data) => {
        // console.log("=====文件预览=====",data)
        if(!data.url) return
        data.fileName=data.fileName?data.fileName:data.name;
        let url =  data.url&&(data.url + '&fullfilename=' + data.fileName);
        setIframeUrl(process.env.REACT_APP_FILEWAY + '/onlinePreview?url=' + encodeURIComponent(Base64.encode(url)))
        setIsModalOpen(true)
    }
    
  useEffect(async () => {
    if (schemaInfo.schema && schemaInfo.components || !LayoutState.libraryMap) {
      return;
    }

    // 路由模式或者组件模式不同的model获取方式
    let YidaModel
    if (props?.location?.pathname) {
      YidaModel = SmartStorage.get("YidaModel")
      setIsPage(true)
    } else {
      YidaModel = props.model
    }

    setModel(YidaModel)

    console.warn("====没有获取到schema，开始拉取配置重新渲染====")
    // 渲染前置处理，初始化项目 schema 和资产包为渲染模块所需的 schema prop 和 components prop
    try {
      let res = await commonService.common("post", "/ndata/api/xquery/getQueryData", {
        "QueryId": "e4a78227-9c48-4c1f-9b32-882bfebf5375",
        "cluster": "default",
        "parameters": {
          "deconstruct": false,
          _collection: "\"lowcodePages\"",
          _query: {
            "_id": {
              "$oid": YidaModel.pageId
            }
          }
        }
      })
      console.log("====yida获取到数据库的原始schema====", structuredClone(res))

      const { componentsMap: componentsMapArray, componentsTree } = res.content.rows[0].schema;
      const componentsMap = {};
      componentsMapArray.forEach((component) => {
        componentsMap[component.componentName] = component;
      });
      const schema = componentsTree[0];
      const components = await injectComponents(buildComponents(LayoutState.libraryMap, componentsMap));
      // 本地组件加入渲染列表，逐步加入yida-material
      components["IqueryTable"] = IqueryTable
      setSchemaInfo({
        schema,
        components,
      })
    } catch (error) {
      console.log("====yida error=====", error)
    }
  }, [schemaInfo, LayoutState.libraryMap])

const callback = (info)=>{
 if(info.formRef) {
  setXformData({formRef:info.formRef,record:Model.info,refresh:info.refresh,btnInfo: info.btnInfo || false})
 }
  props.callback(info)
}
  let renderModel = {
    // yida渲染信息
    ...schemaInfo,
    //注入业务变量
    ...Model,
    // 注入全局变量
    $history: history,
    $gateway: process.env.REACT_APP_GATEWAY,
    $token: getCookie("x_token") || getCookie("token"),
    $http: commonService,
    $Modal: Modal,
    $message: message,
    $onlinePreview:onlinePreview
  }

  useEffect(() => {
    if(xformData) {
      XformRef.current.handelFormAction({
        actionType: "openModal",
      });
    }
  }, [xformData])
  
 

  // 加载依赖未完成
  return <>
   {
    !LayoutState.libraryMap ? <div style={{ cssText: "display: grid;justify-content: center; padding: 20px;" }}>
    <LoadingOutlined style={{ fontSize: "30px", color: "#eb2f96", marginBottom: "20px" }} />
    <span style={{ color: "#888888" }}>
      依赖未完成加载，请稍后。
    </span>
  </div>:<div style={{ marginTop: isPage ? "50px" : "" }}>{schemaInfo.schema && <ReactRenderer {...renderModel} callback={callback} />}</div>
   }
   {xformData &&
     <Xform
     ref={XformRef}
     XformData={xformData}
     isPage={false}
     iqueryDetailDrawerCallback={props.iqueryDetailDrawerCallback}
     onSumbit={props.onSumbit}
   ></Xform>
   }
    <KKfileViewerModal closeModal={()=>{setIsModalOpen(false)}} isModalOpen={isModalOpen} iframeUrl={iframeUrl}></KKfileViewerModal>
  </>
};
export default App;