import React, { useState, useEffect } from 'react';
import SmartQueryReact from 'smart-iquery-react';
import styles from './order.module.less';

const OrderList = (props) => {
    const { drawerData, activeData, selectIds, fieldsValue } = props;

    const iSignalr = process.env.REACT_APP_SIGNALER;
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [queryData, setQueryData] = useState([]);
    const returnSelectedRows = (rowsId, rows) => {};
    const rowMenuHandleClick = (e, record, item) => {};
    const headerBtnHandleClick = (btn, item, items) => {
        console.log('headerBtnHandleClick');
    };

    useEffect(() => {
        setQueryData(null);
        setTimeout(() => {
            setQueryData([
                {
                    queryId: activeData?.activeCategory?.detailId,
                    queryId4Count: activeData?.activeCategory?.detailCount,
                    cluster: 'default',
                    additional: {
                        _miceId:
                            selectIds && selectIds.length > 0
                                ? selectIds.join(',')
                                : '?',
                        _rebateStatus: 0,
                        _taskId: drawerData?.taskId || '?',
                        _insuranceId: '?',
                        _feeType: fieldsValue.feeType || '?',
                    },
                },
            ]);
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectIds]);
    return (
        <>
            <div className={styles.title}>已选订单</div>
            {queryData && (
                <SmartQueryReact
                    gatewayPath={gatewayPath}
                    iSignalr={iSignalr}
                    getQueryData={queryData}
                    returnSelectedRows={returnSelectedRows}
                    rowMenuHandleClick={rowMenuHandleClick}
                    headerBtnHandleClick={headerBtnHandleClick}
                ></SmartQueryReact>
            )}
        </>
    );
};

export default OrderList;
