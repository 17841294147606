import React, { useState,useEffect } from 'react';
import { Form, Col, Cascader } from 'antd';
import defaultSettings from './defaultSettings';
import commonService from "@/service/common"

const {defaultLabelSpan,defaultWrapperSpan} = defaultSettings

const XCascaderCity = (props) => {

    const item = props.item
    const [options,setOptions] = useState(item.options ? item.options : [])

    const defaultChangeClick = (e, item, sData) => {
        // console.log("defaultChangeClick:", e, item, sData)
        item.callBack && props.callback(e, item, sData);
        // item.callBack && item.callBack(e, item, sData);
    }

    const getOptions = async(source) => {
        let params = source.params;
        let method = source.method;
        let url = source.url;
    
        let res = await commonService.common(method,url,params)
        if(res && res.success && res.content) {
            res.content[0].SubTypes.forEach(element => {
                element.value=element.Name;
                element.label=element.Name;
                element.SubTypes?.forEach(ele => {
                    ele.value=ele.Name;
                    ele.label=ele.Name;
                });
                element.children=element.SubTypes
            });
          setOptions(res.content[0].SubTypes)
        }
      }

    useEffect(()=>{
        if(item.dataSource){
          getOptions(item.dataSource)
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Col span={item.span} >
                <Form.Item
                    name={item.name}
                    rules={item.rules}
                    labelCol={item.lcol || { span: defaultLabelSpan }}
                    wrapperCol={item.wcol || { span: defaultWrapperSpan }}
                    label={item.label}
                    labelAlign="right"
                >
                <Cascader
                    options={options}
                    showSearch={item.showSearch}
                    allowClear={item.allowClear || true}
                    fieldNames={item.fieldNames}
                    disabled={item.disabled && true}
                    onChange={(e,sData) => (defaultChangeClick(e, item, sData))}
                />
                </Form.Item>
                {item.tips 
                ? <div className="form-item-tips">{item.tips}</div>
                : ""
                }
            </Col>
        </>
      )
}

export default XCascaderCity
