import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from "react";
import commonService from "@/service/common";
import { getCookie } from "tiny-cookie";
import {Input, Button, Modal, Row, Col, message} from 'antd';
import "../../MopModules/modules.less";
import QRious from 'qrious';
import html2canvas from 'html2canvas'
// eslint-disable-next-line
import XIconFont from "@/components/IconFont";
const { TextArea } = Input;
const QRCodeModal = forwardRef((props, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreatedQrcode, setIsCreatedQrcode] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [errorTxt, setErrorTxt] = useState('');
    const [phoneTxt, setPhoneTxt] = useState('');
    const [initQCode, setInitQCode] = useState('');
    const qrCodeRef = useRef(null);
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const showModal = (rowData) => {
        setSelectedData(rowData)
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    const createQRCode = () => {
        let phoneList = phoneTxt.replace(/ /g, '').replace(/，/g, ',');
        // 校验是否是数字和逗号构成
        let isLegal = /^[\d,]+$/;
        if ( !isLegal.test(phoneList) ) {
            setErrorTxt('输入不合法，请输入执行人手机号，用逗号隔开');
            return false;
        }
        let arr = phoneList.split(',');
        const reg = /^1[3-9]\d{9}$/;
        let errorArr = [];
        //eslint-disable-next-line array-callback-return
        arr.map((item) => {
            if (!reg.test(item)) {
                errorArr.push(item);
            }
        });
        if (errorArr.length > 0) {
            setErrorTxt(`${errorArr.join()} 不合法`);
            return;
        }
        createQRCodeContext(phoneList)
    }
    const createQRCodeContext =(phoneList) => {
        let params = {
            url: process.env.REACT_APP_IVENUE_URL,
            generatePolicy: 1 | 4 | 8,
            assistantFactor: {
                userId: getCookie('userId'),
                authMobiles: phoneList.split(","),
                cacheEntryOptions: {
                    absoluteExpirationRelativeToNow: "3:0:0:0", // int days,int hours,int minutes,int seconds
                },
            },
            context: {
                data: selectedData,
                cacheEntryOptions: {
                    absoluteExpirationRelativeToNow: "3:0:0:0", // int days,int hours,int minutes,int seconds
                },
            }
        }
        commonService.createQRCodeShorten(params).then(res => {
            if (res && res.success) {
                setInitQCode(res.content)
                setIsCreatedQrcode(true)
                //eslint-disable-next-line
                const qr = new QRious({
                    element: qrCodeRef.current,
                    value: res.content,
                    size: 200, // 设置二维码尺寸
                });
            }
        })
    }
    const handleTextAreaChange = (event) => {
        setPhoneTxt(event.target.value); // 更新 phoneTxt 的值
    };
    const copyUrl = () => {
        let oInput = document.createElement('input');
        oInput.value = initQCode;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        document.execCommand('Copy'); // 执行浏览器复制命令
        oInput.remove();
        message.success('复制成功')
    }
    const copyImage = async () => {
        let dom = document.getElementsByTagName("canvas")[0];
        html2canvas(dom, { useCORS: true }).then((canvasFull) => {
            canvasFull.toBlob((blob) => {
                const item = new ClipboardItem({ "image/png": blob });
                navigator.clipboard.write([item]);
                message.success('复制成功')
            });
        });
    }
    const downloadCode = () => {
        const myCanvas = document.getElementsByTagName("canvas")[0];
        downFile(myCanvas, 'QrCode.png')
    };
    const downFile = (canvas,name) => {
        let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let link = document.createElement("a");
        let blob = dataURLtoBlob(image);
        let objurl = URL.createObjectURL(blob);
        link.download = name?name:"pic.png";
        link.href = objurl;
        link.click();
    }
    const dataURLtoBlob = (dataurl) =>{
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }
    return (
        <Modal title="酒店报价二维码" width={600} maskClosable={false} open={isModalOpen} onOk={handleCancel} onCancel={handleCancel}>
            <TextArea value={phoneTxt} rows={2} placeholder="执行人手机号，多个执行人请用逗号隔开" onChange={handleTextAreaChange}/>
            <span style={{color: 'red'}}>{errorTxt}</span>
            <div style={{margin: '10px 0'}}>
                {!isCreatedQrcode && (<Button type="primary" style={{width: '100%'}} onClick={() => createQRCode()}>生成酒店报价二维码</Button>)}
                {isCreatedQrcode && (<Button type="primary" style={{width: '100%'}} onClick={() => createQRCode()}>重新生成</Button>)}
            </div>
            { isCreatedQrcode && (
                <Row className="qrcode-modal-content">
                    <Col span={18}>
                        <canvas className="code_box" ref={qrCodeRef} />
                    </Col>
                    <Col span={6}>
                        <div className="share-btn" onClick={() => copyUrl()}>
                            {/*<XIconFont type={icon} />*/}
                            复制链接
                        </div>
                        <div className="share-btn" onClick={() => copyImage()}>
                            {/*<XIconFont type={icon} />*/}
                            复制二维码
                        </div>
                        <div className="share-btn" onClick={() => downloadCode()}>
                            {/*<XIconFont type={icon} />*/}
                            下载到本地
                        </div>
                    </Col>
                </Row>
            )}
        </Modal>
    );
});

export default QRCodeModal;
