import React, { useState } from 'react';
import { Image, Upload, Button } from 'antd';
import { getCookie } from 'tiny-cookie';
import { PlusOutlined } from '@ant-design/icons';

function XUpload(props) {
    const { fileList } = props;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    // 文件上传之前
    const beforeUpload = (file) => {};
    const handlePreview = async (file) => {
        // 图片预览，非图片的类型文件直接window.open打开
        let previewType = [
            '.IMG',
            '.IMAGE',
            '.JPG',
            '.JPEG',
            '.PNG',
            '.GIF',
            '.BMP',
        ];
        if (previewType.includes(file?.fileType?.toUpperCase())) {
            setPreviewImage(file?.url);
            setPreviewOpen(true);
        } else {
            window.open(file.url);
        }
    };
    const handleChange = (file) => {
        props.handleChangeCallback(file);
    };
    // 上传按钮
    const uploadButton = (
        <Button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                上传
            </div>
        </Button>
    );
    return (
        <>
            <Upload
                action={`${process.env.REACT_APP_GATEWAY}/foundation/aliyun/api/oss/upload`}
                accept="*"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                headers={{
                    Authorization: `Bearer${getCookie('token')}`,
                    SqlInjectionMiddleware: false,
                }}
            >
                {uploadButton}
            </Upload>
            {previewImage && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                            !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </>
    );
}

export default XUpload;
