import { request, rocheRequest, gskRequest, QRRequest } from './request';

const commonService = {
    /**
     * 公共
     */
    common: (method = 'post', url, params, conf) => {
        if (method.toLocaleLowerCase() === 'post') {
            return request.post(url, params || {}, conf || {});
        } else {
            return request.get(
                url,
                {
                    params: params,
                } || {
                    params: {},
                },
                conf || {},
            );
        }
    },
    // querybySessionId
    queryBySessionId: (params) => {
        return request.post('/tpm/api/redis/query', params);
    },
    // 文件查询
    QueryOssFiles: (params) => {
        return request.post('/xfile/api/oss/query', params);
    },

    // mapping
    MappingFiles: (params) => {
        return request.post('/xfile/api/oss/Mapping', params);
    },
    // 文件删除
    DeleteOssFiles: (params) => {
        return request.post('/xfile/api/oss/Delete', params);
    },
    // TPM GetSettings
    GetSettings: (params) => {
        return request.post('/api/tpm/cfg/getsettings', params);
    },
    EditSettings: (params) => {
        return request.post('/api/tpm/cfg/XUpdateDocument', params);
    },
    GetMe: (params) => {
        return request.get('/foundation/passport/IUserService/GetMe', {
            params: params,
        });
    },

    QuerySettings: (params) => {
        return request.post('api/tpm/cfg/querysettings', params);
    },
    //按钮
    QueryTaskActions: (params) => {
        return request.post(
            'api/foundation/workflow/Task/QueryTaskActions',
            params,
        );
    },
    //操作按钮
    ProcessTask: (params) => {
        return request.post('api/flow/FnFlowTask/ProcessTask', params);
    },
    //批量处理
    batchRecommendShop: (params, headers) => {
        return request.post(
            '/api/smartxresourcejshop/shop/batchRecommendShop',
            params,
            headers,
        );
    },
    //批量审核
    batchSetForms: (params) => {
        return request.post(
            'api/open-v2/jin-shu-ju/form/batch-set-forms',
            params,
        );
    },
    //批量审核获取字段
    getFormStructure: (params) => {
        return request.get('api/open-v2/jin-shu-ju/form/get-form-structure', {
            params: params,
        });
    },
    //上传批量处理
    uploadAndPub: (params, headers) => {
        return request.post('/aliyun/api/Oss/UploadAndPub', params, headers);
    },
    // 获取token
    getToken: (params) => {
        return request.post('foundation/passport/api/account/auth', params);
    },
    // 罗氏单独获取token
    getRocheToken: (params) => {
        return rocheRequest.post(
            'foundation/passport/api/account/auth',
            params,
        );
    },
    //+导出文件
    ExportData: (params, headers) => {
        return request.post(
            '/ndata/api/xquery/exportQueryData',
            params,
            headers,
        );
    },
    //日志
    QueryProcessTaskLogs: (params) => {
        return request.post(
            '/api/foundation/bpm/Process/QueryProcessTaskLogs',
            params,
        );
    },
    //数据
    getQueryData: (params) => {
        return request.post('/ndata/api/xquery/getQueryData', params);
    },
    getQueryDataX: (params) => {
        return request.post('/xchief/api/xquery/getQueryData', params);
    },
    GetQueryObjectX: (params) => {
        return request.post('/xchief/api/xquery/GetQueryObject', params);
    },
    //获取银行账户列表
    queryBankList: (params) => {
        return request.post('/foundation/dict/api/fndictionary/query', params);
    },
    //创建待办
    FinaceDiff: (params) => {
        return request.post('/api/jfinance/add/diff', params);
    },
    //调账
    FinaceUpdateDiff: (params) => {
        return request.post('/api/jfinance/update/diff', params);
    },
    //调账关闭
    FinaceCloseDiff: (params) => {
        return request.post('/api/jfinance/close/diff', params);
    },
    //平台
    addInfoExpressBill: (params) => {
        return request.post(
            '/api/iwork/InfoExpressBill/addInfoExpressBill',
            params,
        );
    },
    // 内部项目
    addInfoProject: (params) => {
        return request.post('/api/iwork/InfoProject/addInfoProject', params);
    },
    // 发票上传
    batchScan: (params) => {
        return request.post('/api/iwork/infoInvoice/batchScan', params);
    },
    // 获取餐厅详情
    getDetailsOfRestaurant(params) {
        return request.post('/api/jagent/merManage/merchantDetails', params);
    },
    // 保护业务数据
    // saveCacheData(params) {
    //   return request.post("/api/statestore/redis/create", params);
    // },
    saveCacheData(params) {
        return request.post('/xchief/api/xcache/create', params);
    },
    // 查询文件
    queryFiles(params) {
        return request.post('/aliyun/api/oss/query', params);
    },
    // 删除文件
    deleteFiles(params) {
        return request.post('/aliyun/api/oss/delete', params);
    },
    //客服操作
    changeTripType: (params) => {
        return request.post(
            '/api/category/car/mergecar/trips/ChangeTripType',
            params,
        );
    },
    // 获取酒店明细列表数据
    getVenueDetail: (params) => {
        return request.post(
            '/api/operation/operater/Settlement/Query4Venue',
            params,
        );
    },
    //在线客服消息获取
    getOnlineGroupMessage: (params) => {
        return gskRequest.get('/webchat/online-group-message', {
            params,
        });
    },
    //在线客服@列表
    getMember: (params) => {
        return gskRequest.get('/api/wechat/meeting-member-list', {
            params,
        });
    },
    //联系客服发送消息
    group_message_send: (params) => {
        return gskRequest.post(
            `/webchat/group_message_send?currentMudId=${params.currentMudId}`,
            params,
        );
    },
    // mop 按钮操作
    queryTaskActions: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/QueryTaskActions',
            params,
        );
    },
    // mop- 操作提交按钮
    performTask: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/PerformTask',
            params,
        );
    },
    //在线客服消息获取
    // getOnlineGroupMessage: (params) => {
    //   return gskRequest.get("/webchat/online-group-message",{params});
    // },
    //在线客服@列表
    //  getMember: (params) => {
    //    return gskRequest.get("/api/wechat/meeting-member-list",{params});
    //  },
    //联系客服发送消息
    //  group_message_send: (params) => {
    //    return gskRequest.post(`/webchat/group_message_send?currentMudId=${params.currentMudId}`, params);
    //  },
    // 保存酒店明细
    saveVenueDetail: (params) => {
        return request.post(
            `/api/operation/operater/Settlement/SaveDetail`,
            params,
        );
    },
    // 删除酒店明细
    removeHotelDetail: (params) => {
        return request.post(
            `/api/operation/operater/Settlement/RemoveDetail`,
            params,
        );
    },
    // 查询审核详情
    queryAuditData: (params) => {
        return request.post(`/api/Category/Venue/Audit/QueryAuditData`, params);
    },
    // 查询银行列表
    getBrandList: (params) => {
        return request.post(
            `/foundation/dict/IBaseDataService/GetBanks`,
            params,
        );
    },
    // 保存审核
    saveAudit: (params) => {
        return request.post(`/api/Category/Venue/Audit/SaveAudit`, params);
    },
    // 选择酒店名字之前需要进行黑名单验证
    blackListCheck: (params) => {
        return request.post(
            `/api/category/venue/venuesite/CheckMapSelection`,
            params,
        );
    },
    // 保存酒店审核编辑
    saveHotelAuditEdit: (params) => {
        return request.post(
            `/api/Category/Venue/Audit/SaveBlackListKeyWord`,
            params,
        );
    },
    // 酒店城市黑名单查询
    QueryCityAcrByCustomerId: (params) => {
        return request.post(
            `/api/category/venue/audit/QueryCityAcrByCustomerId`,
            params,
        );
    },
    // 酒店城市黑名单批量保存
    BathSaveCityAcr: (params) => {
        return request.post(
            `/api/category/venue/audit/BathSaveCityAcr`,
            params,
        );
    },
    // 黑名单查询
    queryBlackList: (params) => {
        return request.post(
            `api/Category/Venue/Audit/QueryBlackListKeyword`,
            params,
        );
    },
    // 获取审核记录（主要是为了里面的审核id）
    getAuditRecord: (params) => {
        return request.post(`api/Category/Venue/Audit/GetAuditData`, params);
    },
    // 查询会议订单
    QueryVenueOrders: (params) => {
        return request.post(
            `/api/operation/operater/MiceData/QueryVenueOrders`,
            params,
        );
    },
    //
    GetF1QuotedPriceTaskList: (params) => {
        return request.post(
            `/api/operation/operater/MiceData/GetF1QuotedPriceTaskList`,
            params,
        );
    },
    // 查询酒店需求
    QueryVenueItem: (params) => {
        return request.post(
            `/api/operation/operater/MiceData/QueryVenueSuppliers`,
            params,
        );
    },
    // 查询酒店需求
    GetItemQuote: (params) => {
        return request.get(`/api/category/venue/SupplierExpense/GetItemQuote`, {
            params: params,
        });
    },
    // 保存报价
    SaveQuote: (params) => {
        return request.post(`/api/category/venue/Quote/SaveQuotes`, params);
    },
    // 查询地区信息
    queryTreeAsync: (params) => {
        return request.post(
            `/foundation/dict/IDictService/QueryTreeAsync`,
            params,
        );
    },
    // 字典查询
    queryAsync: (params) => {
        return request.post(`/foundation/dict/IDictService/QueryAsync`, params);
    },
    // 获取酒店品牌
    getHotelBrands: (params) => {
        return request.post(`api/venue/brand/getlist`, params);
    },
    // 保存预付款
    saveAdvance: (params) => {
        return request.post(
            `/api/operation/operater/Payment/SaveAdvance`,
            params,
        );
    },
    // 协议盖章
    DoSign: (params) => {
        return request.post(`/api/ntemplate/FileSIgn/DoSign`, params);
    },
    // 签署协议
    signProtocol: (params) => {
        return request.post(`/api/operation/operater/Protocol/Sign`, params);
    },
    // nodeByIquery
    accessNodesByIQuery: (params) => {
        return request.post(
            `/api/foundation/bpm/foundation/workflow/v2/Task/QueryAccessNodesByIQuery`,
            params,
        );
    },
    // 获取验证码(绑定手机,修改手机)
    sendCaptcha: (params) => {
        return request.post(`/foundation/aliyun/api/Sms/SendCaptcha`, params);
    },
    // 修改密码
    changePassword: (params) => {
        return request.post(`/api/foundation/user/ChangePassword`, params);
    },
    // 是否修改密码
    isChangePassword: (params) => {
        return request.post(
            `/foundation/passport/IUserService/IsChangePassword`,
            params,
        );
    },
    // 回复投诉
    replyComp: (params) => {
        return request.post(`/api/Jeventorder/suggestions/reply`, params);
    },
    // 获取投诉列表
    queryCompList: (params) => {
        return request.get(`/api/Jeventorder/suggestions/queryList`, {
            params: params,
        });
    },
    // 酒店无资源
    NoResource: (params) => {
        return request.post(`/api/category/venue/category/NoResource`, params);
    },
    // 酒店报价有效期
    queryClauses: (params) => {
        return request.post(`/api/category/venue/Quote/QueryClauses`, params);
    },
    // 保存条款
    SaveSupplierClause: (params) => {
        return request.post(
            `/api/category/venue/SupplierExpense/SaveSupplierClause`,
            params,
        );
    },
    // 获取二维码
    createQRCodeShorten: (params) => {
        return QRRequest.post(`/api/Generic/Shorten`, params);
    },
    // 查询首页酒店日志数据
    queryProcessTaskLogs: (params) => {
        return request.post(
            `/api/category/venue/category/queryProcessTaskLogs`,
            params,
        );
    },
    // 日志退回操作
    DealTask: (params) => {
        return request.post(`/api/category/venue/category/DealTask`, params);
    },
    // 车详情
    GetEOrder: (params) => {
        return request.post(`/api/car/eorder/Order/GetEOrder`, params);
    },
    // 车详情
    GetEOrderNew: (params) => {
        return request.post(`/api/car/eorder/orderv2/Detail`, params);
    },
    // 车供应商-报价
    OfflineCarQuote: (params) => {
        return request.post(`/api/category/car/OfflineCar/Quote`, params);
    },
    // 车供应商-报价新流程
    OfflineCarQuoteNew: (params) => {
        return request.post(`/api/car/eorder/orderv2/Quote`, params);
    },
    // 获取按钮接口 -- 车F2
    QueryF2TaskActions: (params) => {
        return request.post(
            `/api/foundation/bpm/foundation/workflow/v2/Task/QueryTaskActions`,
            params,
        );
    },
    // 删除用车明细
    RemovePiece: (params) => {
        return request.post(`/api/category/car/OfflineCar/RemovePiece`, params);
    },
    // 导出车-结算明细
    ExportCarDetailToExcel: (params) => {
        return request.post('api/eorder/Order/ExportCarDetailToExcel', params);
    },

    // 获取取消有损的时候的信息
    GetLossFeeDetailOfEdit(params) {
        return request.post('/api/car/eorder/OrderExpense/Detail', params);
    },
    // 用车明细保存
    SavePiece: (params) => {
        return request.post('/api/category/car/OfflineCar/SavePiece', params);
    },
    // 未使用
    Unuse: (params) => {
        return request.post('/api/car/eorder/OrderRequest/UnUsed', params);
    },
    // 图片mapping
    mapping: (params) => {
        return request.post('/foundation/aliyun/api/oss/mapping', params);
    },
    // 详情按钮操作--转流程F2
    PerformTask: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/PerformTask',
            params,
        );
    },
    // 删除明细
    CancelTrip: (params) => {
        return request.post('/api/car/eorder/OrderRequest/Cancel', params);
    },
    // 购买保险
    insuranceApply: (params) => {
        return request.post('/api/jinsurapi/insurance/insuranceApply', params);
    },
    // 编辑新增明细
    SaveTrip: (params) => {
        return request.post('/api/car/eorder/OrderRequest/SaveTrip', params);
    },
    // 编辑新增司机信息
    SaveDrivers: (params) => {
        return request.post('/api/car/eorder/OrderRequest/SaveDrivers', params);
    },
    // 查询已保存结算信息
    SettleDetail: (params) => {
        return request.post(
            '/api/car/eorder/OrderRequest/SettleDetail',
            params,
        );
    },
    // 根据行程ID获取保险信息
    GetInsuranceByTripId: (params) => {
        return request.post(
            '/api/jinsurapi/insurance/getInsuranceByTripId',
            params,
        );
    },
    // 修改保单号
    modifyPolicyNum: (params) => {
        return request.post('/api/jinsurapi/insurance/modifyPolicyNum', params);
    },
    // 查询开票信息
    getCompanyInvoice: (params) => {
        return request.get('/api/category/meal/Invoice/GetCompanyInvoice', {
            params: params,
        });
    },
    // 保存发票
    saveInvoice: (params) => {
        return request.post('/api/Invoice/Invoice/SaveInvoice', params);
    },
    // 删除发票附件
    deleteInvoice: (params) => {
        return request.get('/foundation/aliyun/api/oss/delete', {
            params: params,
        });
    },
    // 删除发票
    deleteInvoice1: (params) => {
        return request.get('/api/Invoice/Invoice/Delete', {
            params: params,
        });
    },
    // 餐发票查询
    queryMealFiles: (params) => {
        return request.post('/api/Invoice/Invoice/Query', params);
    },
    // nodeByIquery
    QueryV1AccessNodesByIQuery: (params) => {
        return request.post(
            `/api/foundation/workflow/task/QueryV1AccessNodesByIQuery`,
            params,
        );
    },
    // 排队详情
    QueryPayVisitors: (params) => {
        return request.post('/rpc/pay/pay/QueryPayVisitors', params);
    },
    // 排队作废
    CancelPayVisitor: (params) => {
        return request.post('/rpc/pay/pay/CancelPayVisitor', params);
    },
    // 下载文件列表
    DownloadFiles: (params) => {
        return request.post('/xfile/download-and-zip', params, {
            responseType: 'blob',
        });
    },
    ReMakeSubmit: (params) => {
        return request.post("/xfile/experience",
            params, {
                contentType: 'multipart/form-data'
            });
    },
    //水单识别
    WaterBillSubmit: (params) => {
        return request.get("/xfile/image-comprehend/waterBill", {
            params: params,
        });
    },
    QueryFiles: (params) => {
        return request.post('/foundation/aliyun/api/oss/query', params);
    },
   
};

export default commonService;
