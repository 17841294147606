import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Xform from "../../../components/Xform/xformRender";
import { useGeneric } from "@/controllers/useGeneric";
import commonService from "@/service/common";

const OriginalForm = forwardRef((props, ref) => {
  
  const { moduleItem, requestVal } = props;

  const { replaceEvalObject } = useGeneric();

  const [dependentInfo, setDependentInfo] = useState(null);

  useImperativeHandle(ref, () => ({
    hanndleSubmit,
    refreshData
  }));

  const hanndleSubmit = () => {
    XformRef.current.handleFlow();
  };
  const XformRef = useRef();
  const dataFromApi = (record) => {
    let replaceObj = replaceEvalObject(moduleItem.parameters, record);
    commonService
      .common("post", replaceObj.url, replaceObj.body)
      .then((res) => {
        if (res.success) {
          setDependentInfo(res.content);
        }
      });
  };
  const refreshData = () =>{
    dataFromApi(requestVal)
  }
  useEffect(() => {
    if (moduleItem.parameters) {
      dataFromApi(requestVal);
    }else {
      setDependentInfo(requestVal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dependentInfo && (
    <Xform
      ref={XformRef}
      XformData={{ formRef: moduleItem.formRef, record: dependentInfo }}
      info={requestVal}
      isPage={true}
      onSumbit={props.onSumbit}
    ></Xform>
  )
});

export default OriginalForm;
