import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Button,
  Drawer,
  Alert,
  Form,
  Row,
  Col,
  Input,
  Select,
  Rate,
  Checkbox,
  Radio,
  Upload,
  message,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import commonService from "@/service/common";
const { TextArea } = Input;
const payModesList = {
  支付宝: 2,
  美团到: 7,
  商务卡: 10,
  饿了么: 8,
  自行刷卡: 12,
  微信: 3,
  小秘书: 5,
  美餐: 4,
  闪惠: 1,
  口碑: 6,
};
const beverageList = {
  机打水单: 1,
  手写水单: 2,
};

const invoiceType = {
  定额发票: 2,
  非定额发票: 4,
};

// eslint-disable-next-line no-unused-vars
const category = {};
export default forwardRef(function (props, onref) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  const [details, setDetails] = useState({});
  const [formInstance] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [imageUrl, setImageUrl] = useState();
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(1);
  const [fileList, setFileList] = useState([]);
  useImperativeHandle(onref, () => ({
    setOpen,
    setRowId,
  }));
  const changeIsShow = (val) => {
    console.log(val);
    // details.isShow = val.target.checked ? 1 : 0;
  };
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const onClose = () => {
    setOpen(false);
  };
  const _getDetailsOfRestaurant = () => {
    const params = {
      rowId,
    };
    commonService.getDetailsOfRestaurant(params).then((res) => {
      console.log(res);
      if (res.success) {
        const obj = res.content;
        obj.isShow = !!obj.isShow;
        obj.isDeleted = !!obj.isDeleted;
        obj.isSign = !!obj.isSign;
        setDetails(res.content);
        setFileList([{ url: obj.imgThumbnail }]);
        formInstance.setFieldsValue(obj);
        console.log(res.content);
      }
    });
  };
  // eslint-disable-next-line no-unused-vars
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  // const handleChange = (info) => {
  // if (info.file.status === 'uploading') {
  //     setLoading(true);
  //     return;
  // }
  // if (info.file.status === 'done') {
  //     Get this url from response in real world.
  // getBase64(info.file.originFileObj, (url) => {
  //     setLoading(false);
  //     setImageUrl(url);
  // });
  // }
  // };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  // eslint-disable-next-line no-unused-vars
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  // eslint-disable-next-line no-unused-vars
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  useEffect(() => {
    console.log(open);
    console.log(rowId);
    if (open && rowId) {
      _getDetailsOfRestaurant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <div>
      <Drawer
        width="800px"
        title="编辑"
        open={open}
        onClose={onClose}
        footer={[<Button key="back">确定</Button>]}
      >
        <Alert message="注：' * '号为必填项" type="warning" />
        <Form {...layout} form={formInstance} name="restaurant">
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item label="餐厅ID" name="rowId">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="外部ID" name="id">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item label="城市" name="cityVal">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="商圈" name="regionVal">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item label="区县" name="district">
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="餐厅名称"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "请输入",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item
                label="餐厅全名"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "请输入",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="联系电话"
                name="tel"
                rules={[
                  {
                    required: true,
                    message: "请输入",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item
                label="餐厅地址"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "请输入",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="评论数" name="commentNum">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item label="星级" name="starVal">
                <Rate />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="菜系" name="categoryId">
                <Select />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item label="支付模式" name="payFlag">
                <Select
                  options={Object.keys(payModesList).map((i) => {
                    return {
                      label: i,
                      value: payModesList[i],
                    };
                  })}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="人均价格" name="priceAvgVal">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item label="是否删除" name="isDeleted">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="是否上线" onChange={changeIsShow} name="isShow">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              {details.isShow ? (
                <Form.Item label="上架时间" name="createdOn">
                  <Input disabled />
                </Form.Item>
              ) : (
                <Form.Item label="下架时间" name="reasonDate">
                  <Input disable />
                </Form.Item>
              )}
            </Col>
            <Col span={10}>
              <Form.Item label="水单类型" name="swriteType">
                <Select
                  options={Object.keys(beverageList).map((i) => {
                    return {
                      label: i,
                      value: beverageList[i],
                    };
                  })}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            {!details.isShow ? (
              <Col span={10}>
                <Form.Item label="下架原因" name="soldOutReason">
                  <Input />
                </Form.Item>
              </Col>
            ) : null}
            <Col span={10}>
              <Form.Item label="是否签约" name="isSign">
                <Checkbox disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item label="是否推荐" name="isTop">
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                labelCol={{ span: 10, offset: 0 }}
                label="是否完成入库核查"
                name="isExamination"
              >
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item
                labelCol={{ span: 12, offset: 0 }}
                label="是否有包厢或独立隔断"
                name="isPrivateRoom"
              >
                <Radio.Group>
                  <Radio value={true}>有</Radio>
                  <Radio value={false}>无</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="发票类型" name="ticketType">
                <Select
                  options={Object.keys(invoiceType).map((i) => {
                    return {
                      label: i,
                      value: invoiceType[i],
                    };
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="是否有表演/娱乐项目"
                name="entertainmentItemTxt"
              >
                <TextArea
                  rows={4}
                  placeholder="请输入表演/娱乐项目的种类"
                  maxLength={6}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item
                labelCol={{ span: 8, offset: 2 }}
                label="合规培训结果"
                name="complianceRes"
              >
                <Radio.Group>
                  <Radio value={true}>成功</Radio>
                  <Radio value={false}>失败</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="合规告知通过"
                labelCol={{ span: 8, offset: 2 }}
                name="complianceInform"
              >
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label="餐厅照片"
                labelCol={{ span: 10, offset: 2 }}
                name="imgThumbnail"
              >
                <Upload
                  action="https://gateway-dev.eventworld.cn/foundation/aliyun/api/oss/upload"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleChange}
                >
                  {fileList.length < 1 ? (
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        上传
                      </div>
                    </div>
                  ) : null}
                </Upload>
                {/*<Upload*/}
                {/*    name="avatar"*/}
                {/*    listType="picture-card"*/}
                {/*    className="avatar-uploader"*/}
                {/*    showUploadList={false}*/}
                {/*    action="https://gateway-dev.eventworld.cn/foundation/aliyun/api/oss/upload"*/}
                {/*    beforeUpload={beforeUpload}*/}
                {/*    onChange={handleChange}*/}
                {/*>*/}
                {/*    {imageUrl ? (*/}
                {/*        <img*/}
                {/*            src={imageUrl}*/}
                {/*            alt="avatar"*/}
                {/*            style={{*/}
                {/*                width: '100%',*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    ) : (*/}
                {/*        uploadButton*/}
                {/*    )}*/}
                {/*</Upload>*/}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
});
