import { registerMicroApps, start } from "qiankun";
// import SmartStorage from "@/utils/storage/index.js";
// let selectedKeys=SmartStorage.get("selectedKeys")
registerMicroApps([
  // {
  //   name: 'invoiceManageOcr',
  //   entry: '//https://invoice-dev.eventworld.cn/invoice-manage-ocr',
  //   container: '#container',
  //   activeRule: '/invoiceManageOcr',
  // },
  //财务工作台
  // {
  //   name: "finance",
  //   entry: "//localhost:4200/",
  //   container: "#financeContainer",
  //   activeRule: "/finance/financePlatform",
  //   props: {
  //     // selectedKeys:selectedKeys
  //   }
  // },{
  //   name: "thirdReconciliation",
  //   entry: "//localhost:4200/",
  //   container: "#financeContainer",
  //   activeRule: "/finance/thirdReconciliation",
  //   props: {
  //     // selectedKeys:selectedKeys
  //   }
  // },{
  //   name: "receivablesManage",
  //   entry: "//localhost:4200/",
  //   container: "#financeContainer",
  //   activeRule: "/finance/receivables-manage",
  //   props: {
  //     // selectedKeys:selectedKeys
  //   }
  // }
])

start();