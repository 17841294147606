import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import commonService from "@/service/common";
import "../../MopModules/modules.less";
import {Button, Table} from "antd";
import QuoteTermsModal from "@/components/DetailDrawer/compments/MopModules/HoteDemand/QuoteTermsModal";

const QuoteTerms = forwardRef ((props , ref) => {
    const [quoteTermsData,setQuoteTermsData] = useState([]);
    const quoteTermsModal = useRef(null);
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const queryClauses = () => {
        let params = {
            tenantId: props.requestVal.tenantId,
            miceId: props.requestVal.processId,
            taskId: props.requestVal.taskId,
            orderId: props.listItem.orderId,
        }
        commonService.queryClauses(params).then(res => {
            if (res && res.success) {
                res.content.forEach(ele => {
                    ele.quoteDeadTime = ele.quoteDeadTime ? new Date(ele.quoteDeadTime) : ''
                    ele.dateNoFee = ele.dateNoFee ? new Date(ele.dateNoFee) : ''
                    ele.datePercentFee = ele.datePercentFee ? new Date(ele.datePercentFee) : ''
                    ele.dateAllFee = ele.dateAllFee ? new Date(ele.dateAllFee) : ''
                })
                setQuoteTermsData(res.content)
            }
        })
    }
    const editInfo = (row) => {
        quoteTermsModal.current.showModal(row)
    }
    const editRemarkInfo = (row) => {
        quoteTermsModal.current.showModal(row, 'remark')
    }
    const renderButtons = (record) => {
        return record.buttons.map((item, index) => {
          if (item.processor === 'edit' && props.isQuote) {
            return (
              <Button key={`edit-${index}`} type="primary" onClick={() => editInfo(record)}>
                编辑
              </Button>
            );
          }
          if (item.processor === 'remark.edit') {
            return (
              <Button key={`remark-${index}`} type="primary" onClick={() => editRemarkInfo(record)}>
                编辑
              </Button>
            );
          }
          return null;
        });
      };
    const columns = [
        { title: '供应商', dataIndex: 'supplierName', key: 'supplierName' },
        { title: '总报价', dataIndex: 'quote', key: 'quote' },
        { title: '保留期', dataIndex: 'quoteDeadTime', key: 'quoteDeadTime', render: (text, record) => (<>{record.quoteDeadTime ? record.quoteDeadTime.toLocaleDateString('zh-CN') : ''}</>)},
        { title: '取消条款', dataIndex: 'dateNoFee', key: 'dateNoFee',
            render: (text, record) => (
                <div>
                    <div>
                        { record.dateNoFee ? record.dateNoFee.toLocaleDateString('zh-CN') : ''}前取消，不收取费用；
                    </div>
                    <div>
                        {record.datePercentFee ? record.datePercentFee.toLocaleDateString('zh-CN') : ''}前取消，按合同金额{record.lossPercent}%收取费用；
                    </div>
                    <div>
                        {record.dateAllFee ? record.dateAllFee.toLocaleDateString('zh-CN') : ''}前取消收取全部费用
                    </div>
                </div>
            )
        },
        { title: '其他条款', dataIndex: 'others', key: 'others' },
        { title: '含税金额合计备注', dataIndex: 'remark', key: 'remark' },
        { title: '操作', dataIndex: 'operation', key: 'operation' ,
            render: (text, record) => (
                <>
                    {renderButtons(record)}
                </>
            ),},
    ];
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        queryClauses
    }))
    return (
        <>
            <p style={{ fontWeight: 'bold' }}>报价有效期及取消条款 </p>
            {quoteTermsData.length > 0 && (
                <Table columns={columns} bordered dataSource={quoteTermsData} pagination={false} />
            )}
            <QuoteTermsModal requestVal={props.requestVal} listItem={props.listItem} ref={quoteTermsModal} queryClauses={queryClauses}></QuoteTermsModal>
        </>
    );
});

export default QuoteTerms;
