import React, { useState, useRef, useEffect } from "react";
import {Button, message, Modal} from "antd";
import './CarInfoCard.less'
import commonService from "@/service/common";
import ItinerarySettlement from "@/components/DetailDrawer/compments/CarSupplierModules/ItinerarySettlement";
import {SmartStorage} from "@/utils";
const CarDetailBtn = (props) => {
    const [btnList, setBtnList] = useState([]);
    const itinerarySettlement = useRef(null);
    const [selectBtn, setSelectBtn] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setBtnList(props.btnList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.btnList]);
    const clickBtn = (item) => {
        console.log(item);
        const extData = item.extData;
        setSelectBtn(item)
        switch (item.actCode) {
            case 'lossFee':
                const trips = props.miceInfo.trips;
                if(trips.every(x => x.status === -1)) {
                    itinerarySettlement.current.showModal()
                }else {
                    message.error('不能取消有损')
                }
                break;
            default:
                //
                Modal.confirm({
                    title: extData.title,
                    content: extData.tips,
                    okText: "确认",
                    cancelText: "取消",
                    onOk(){
                        PerformTask(item)
                    }
                })
                break
        }
    }
    const PerformTask = (item) => {
        console.log(item);
        let params = {
            taskId: props.miceInfo.taskId,
            actionId: item.actionId,
            actDescr: ""
        }
        setLoading(true)
        commonService.PerformTask(params).then(res => {
            setLoading(false)
            if (res && res.success) {
                message.success("操作成功");
                window.close();
            }
        })
    }
    return (
        <>
            {btnList.length > 0 && (
                <div className="boderRow">
                    <div className="btns">
                        {btnList.map((item) => {
                            return (
                                <Button type="primary" loading={loading} onClick={() => clickBtn(item)} style={{backgroundColor: item.extData.color || '#1890ff', borderColor: item.extData.color || '#1890ff',marginLeft: '30px'}}> {item.actName} </Button>
                            );
                        })}
                    </div>
                </div>
            )}
            <ItinerarySettlement ref={itinerarySettlement} miceInfo={props.miceInfo} updateChild={() => PerformTask(selectBtn)}></ItinerarySettlement>
        </>
    );
};

export default CarDetailBtn;
