/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { getCookie } from "tiny-cookie";

const ShopcoolTask = (props) => {
  const token = getCookie("x_token");

  console.log("====抽屉属性", props);
  return (
    <div>
      <iframe
        style={{
          position: "absolute",
          height: "calc(100% - 55px)",
          width: "100%",
          border: "none",
          left: "0",
          top: "55px",
        }}
        src={
          process.env.REACT_APP_INVOICE +
          `/detail?s=${Date.now()}&taskId=${
            props.requestVal.taskId
          }&token=${token}`
        }
      ></iframe>
    </div>
  );
};

export default ShopcoolTask;
