import React, { useEffect, useState } from "react";
import {Col, Row} from 'antd';
import commonService from "@/service/common";
import "../MopModules/modules.less";
import HotelDetailsItems from './hotelDetailsItems'

const HotelDetails = (props) => {
    const [hotelDetailsData, setHotelDetailsData] = useState([]);
    const [aggregates, setAggregates] = useState([]);
    const [venueSetting, setVenueSetting] = useState([]);
    const [typeAll, setTypeAll] = useState([]);

    const getSetting = () => {
        let params = {
            collection: 'cfg-supplier-enterDetail',
            filter: {
                Application: "XCENTER",
                tenantId: { "$in": [['2a0edd6a-b7f9-11ed-8b12-26d545d4edc5'].includes(props.requestVal.tenantId) ? 'GSK' : '*'] },
            },
        }
        commonService.QuerySettings(params).then((res) => {
            if (res && res.success) {
                setVenueSetting(res.content)
                let data = []
                res.content.forEach(ele => {
                    data = [...data, ...ele.typeList]
                })
                setTypeAll(data);
                getVenueDetail()
            }
        })
    }
    const getVenueDetail = () => {
        let param = {
            tenantId: props.requestVal.tenantId,
            miceId: props.requestVal.processId,
            orderId: props.requestVal._OrderId,
            taskId: props.requestVal.taskId
        };
        commonService.getVenueDetail(param).then((res) => {
            if (res && res.success) {
                setHotelDetailsData(res.content.details);
                setAggregates(res.content.aggregates);
            }
        });
    };
    function generateListItems(supplierInfo) {
        let venueData = []
        hotelDetailsData.forEach(ele => {
            ele = Object.assign(ele, JSON.parse(ele.expenseData || '{}'));
            switch (ele.costType) {
                case '酒店餐费':
                    ele.venueDate = ele.mealDtStart ? `${ele.mealDtStart} - ${ele.mealDtEnd}` : ''
                    break
                case '茶歇':
                    ele.venueDate = ele.teaDtStart ? `${ele.teaDtStart} - ${ele.teaDtEnd}` : ''
                    break
                case '场租':
                    ele.venueDate = ele.venueDtStart ? `${ele.venueDtStart} - ${ele.venueDtEnd}` : ''
                    break
                default:
                    break
            }
            if (ele.supplierId === supplierInfo.supplierId) {
                venueData.push(ele)
            }
        })
        let venueSettingCopy = JSON.parse(JSON.stringify(venueSetting))
        // 遍历data2中的每一项
        venueData.forEach(item2 => {
            const { costType } = item2;
            // 在data1中查找匹配的子项
            const matchingSubItem = venueSettingCopy.find(item1 => item1.typeList.includes(costType));
            if (matchingSubItem) {
                matchingSubItem.datasource.push(item2);
            }
        });
        return (
            <HotelDetailsItems venueData={venueSettingCopy} requestVal={props.requestVal} supplierInfo={supplierInfo} typeAll={typeAll} getDetail={getVenueDetail}/>
        );
    }
    useEffect(() => {
        getSetting()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="boderRow mopDetails">
            <div className="card_title">{props.moduleItem?.queryName}</div>
            {aggregates.length > 0 && aggregates.map((item, i) => (
                <div key={i}>
                    {generateListItems(item)}
                    <div className="hotelDetailsRow">
                        <Row>
                            <Col span={8}>{ item.supplierName }</Col>
                            <Col span={8}>预付款：¥{ item.prePayment }</Col>
                            <Col span={8}>尾  款：¥{ item.balanceAmount }</Col>
                        </Row>
                        <Row>
                            <Col span={8} offset={8}>结算总金额：¥{ item.settleAmount }</Col>
                            <Col span={8}>明细小计：¥{ item.detailTotal }</Col>
                        </Row>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default HotelDetails;
