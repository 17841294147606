import React from "react";
import { Form, Col, DatePicker, Dropdown, Menu } from "antd";
import defaultSettings from "./defaultSettings";
import moment from "moment";
import { ThunderboltOutlined } from "@ant-design/icons";
import dateFast from "./DateFast.json";
import "./index.less";
const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const { RangePicker } = DatePicker;
const xRangePicker = (props) => {
  const { item } = props;
  // 表格操作列表下拉菜单操作
  const actionsMenu = () => (
    <Menu className="customer-menu">
      {dateFast.map((itemd) => {
        return (
          <Menu.Item
            key={itemd.value}
            onClick={(e) => {
              dateFastChange(e, itemd);
            }}
          >
            <span>{itemd.text}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  function defaultChangeClick(e, item, sData) {
    item.callback && props.callback(e, item, sData);
  }
  const dateFastChange = (e, itemd) => {
    let dateCode = itemd.value;
    let startData = "";
    let endData = "";
    let dataVal = {};
    let year = moment().year() - 1;
    switch (dateCode) {
      case 1:
        startData = moment().startOf("week").format(item.formate);
        endData = moment().endOf("week").format(item.formate);
        break;
      case 2:
        startData = moment().startOf("month").format(item.formate);
        endData = moment().endOf("month").format(item.formate);
        break;
      case 3:
        startData = moment().startOf("year").format(item.formate);
        endData = moment().endOf("year").format(item.formate);
        break;
      case 4:
        startData = moment()
          .month(moment().month() - 1)
          .startOf("month")
          .format(item.formate);
        endData = moment()
          .month(moment().month() - 1)
          .endOf("month")
          .format(item.formate);
        break;
      case 5:
        startData = moment()
          .year(moment().year() - 1)
          .startOf("year")
          .format(item.formate);
        endData = moment()
          .year(moment().year() - 1)
          .endOf("year")
          .format(item.formate);
        break;
      case 6:
        startData = moment()
          .week(moment().week() - 1)
          .startOf("week")
          .format(item.formate);
        endData = moment()
          .week(moment().week() - 1)
          .endOf("week")
          .format(item.formate);
        break;
      case 7:
        startData = moment().subtract(7, "days").format(item.formate);
        endData = moment().format(item.formate);
        break;
      case 8:
        startData = moment().subtract(14, "days").format(item.formate);
        endData = moment().format(item.formate);
        break;
      case 9:
        startData = moment().subtract(30, "days").format(item.formate);
        endData = moment().format(item.formate);
        break;
      case 10:
        startData = moment().subtract(60, "days").format(item.formate);
        endData = moment().format(item.formate);
        break;
      case 11:
        startData = moment().subtract(90, "days").format(item.formate);
        endData = moment().format(item.formate);
        break;
      case 12:
        if ((year % 4 === 0 && !(year % 100 === 0)) || year % 400 === 0) {
          startData = moment().subtract(366, "days").format(item.formate);
        } else {
          startData = moment().subtract(365, "days").format(item.formate);
        }
        endData = moment().format(item.formate);
        break;
      default:
        startData = moment().startOf("month").format(item.formate);
        endData = moment().endOf("month").format(item.formate);
        break;
    }
    console.log(startData, endData, item);
    dataVal[item.name] = [
      moment(startData, item.formate),
      moment(endData, item.formate),
    ];
    props.formInfo.setFieldsValue(dataVal);
  };
  const disabledDate = (current) => {
    // return current && current < moment().endOf('day');
    if (item.startDate || item.endDate) {
      return (
        current < moment(item.startDate, item.formate) ||
        current > moment(item.endDate, item.formate)
      );
    }
    if (item.todaylater) {
      return current && current < moment().subtract(1, "day");
    } else {
      return false;
    }
  };

  // 弹出日历与关闭日历回调
  const defaultOpenChangeCallback = (open) => {
    // console.log("弹出关闭日历", open);  // 返回true 或 false
    item.defaultOpenChangeCallback && props.defaultOpenChangeCallback(open);
  };
  // 待选日期发生变化回调
  const defaultCalendarChangeCallback = (dates) => {
    // console.log("待选日期发生变化", dates);
    item.defaultCalendarChangeCallback &&
      props.defaultCalendarChangeCallback(dates, item);
  };

  return (
    <>
      <Col span={item.span || 6}>
        <Form.Item
          name={item.name}
          rules={item.rules}
          style={{ width: "calc(100% - 32px)", float: "left" }}
          labelCol={{ span: item.lcol || defaultLabelSpan }}
          wrapperCol={{ span: item.wcol || defaultWrapperSpan }}
          label={item.label}
          labelAlign="right"
        >
          <RangePicker
            style={{ width: "100%" }}
            allowClear={item.allowClear || true}
            showTime={item.showTime}
            // format={item.formate}
            disabledDate={disabledDate}
            disabledTime={item.disabledTime}
            defaultValue={item.defaultValue || []}
            disabled={item.disabled && true}
            placeholder={["请选择开始日期", "请选择结束日期"]}
            onChange={(e) => defaultChangeClick(e, item)}
            onOpenChange={(open) => defaultOpenChangeCallback(open)}
            onCalendarChange={(dates) => defaultCalendarChangeCallback(dates)}
          />
        </Form.Item>
        <Dropdown
          key={"operations-action"}
          overlay={() => actionsMenu()}
          trigger={["click"]}
        >
          <ThunderboltOutlined className="fastDataIcons" />
        </Dropdown>
      </Col>
    </>
  );
};

export default xRangePicker;
