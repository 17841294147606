import React from "react";
import { Form, Col, Input } from "antd";
import defaultSettings from "./defaultSettings";
import { changeStatus } from "@/redux/actions/enterState";
import { connect } from "react-redux";

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const xInput = (props) => {
  const item = props.item;
  function defaultChangeClick(e, item, sData) {
    item.callBack && props.callback(e, item, sData);
  }
  const inputKeyUp = (e) => {
    e.keyCode === 13 ? props.changeStatus(true) : props.changeStatus(false);
  };

  return (
    <>
      {item.hidden !== true && (
        <Col span={item.span}>
          <Form.Item
            name={item.name}
            rules={item.rules}
            labelCol={{ span: item.lcol || defaultLabelSpan }}
            wrapperCol={{ span: item.wcol || defaultWrapperSpan }}
            label={item.label}
            hidden={item.hidden}
            labelAlign={item.labelAlign || "right"}
          >
            <Input
              style={{ width: "100%" }}
              allowClear
              disabled={item.disabled && true}
              placeholder={item.placeholder}
              autoComplete="off"
              maxLength={item.maxLength || 100}
              onChange={(e) => defaultChangeClick(e, item)}
              onKeyUp={inputKeyUp}
            />
          </Form.Item>
          {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
        </Col>
      )}
    </>
  );
};
const mapDispatchToProps = {
  changeStatus,
};

export default connect(null, mapDispatchToProps)(xInput);
