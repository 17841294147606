import React, { useEffect, useState } from "react";
import styles from "../../Invoices/invoices.module.less";
import { Form, Tabs, Row, Col, Button, message, notification } from "antd";
import XFormItem from "@/components/FormItem";
import commonService from "@/service/common";
import moment from "moment";
import { SmartStorage } from "@/utils";
import * as tinyCookie from "tiny-cookie";
import iSignalr from "@/controllers/smart-signalr";
const userId =
  SmartStorage.get("userId") ||
  SmartStorage.get("user_id") ||
  tinyCookie.get("userId") ||
  tinyCookie.get("user_id");
var websocket = null;
const InvoiceBatchScan = () => {
  var refreshHub = new iSignalr({
    hubName: "SmartHub",
    queryParams: "group=" + userId,
    listeners: [
      {
        methodName: "invoiceBatchScanMsg",
        method: function (data) {
          console.log(data);
          notification.success({
            message: "提示",
            description: (
              <div dangerouslySetInnerHTML={{ __html: data.Msg }}></div>
            ),
            placement: "topRight",
          });
        },
      },
    ],
    host: process.env.REACT_APP_SIGNALER,
    // host: "https://signalr-uat.eventsystem.cn",
  });
  const tabsList = [
    {
      key: "0",
      label: `平台发票`,
    },
    {
      key: "1",
      label: `内部发票`,
    },
  ];
  const [activeKey, setActiveKey] = useState("0");
  const [itemList, setItemList] = useState([]);
  // const [relateId, setRelateId] = useState("");
  const [openScanMessage, setOpenScanMessage] = useState("");
  const [socketUserMessage, setSocketUserMessage] = useState("");
  const [scanMessage, setScanMessage] = useState("");
  const [form] = Form.useForm();
  // 信号方法
  const componentWillUnmount = () => {
    refreshHub.uninstall();
  };
  useEffect(() => {
    refreshHub.install();
    return componentWillUnmount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 表单返回方法
  const callBackFunc = (e, item, optionData) => {
    switch (item.callBack) {
      case "invioceTypeFunc":
        // 这里动态获取后面表单结构
        break;

      default:
        break;
    }
    console.log(e, item, optionData);
  };
  // 标签页切换
  const typeChange = (key) => {
    form.resetFields();
    SmartStorage.remove("relateId");
    setActiveKey(key);
  };
  // 获取MongoDB模板ui
  const getFormTemplate = () => {
    let params = {
      collection: "cfg-crossevent-ui-template",
      filter: {
        module: "x-center",
        templateCode:
          activeKey === "0" ? "platformInvoiceScan" : "insideInvoiceScan",
      },
      projection: {
        _id: 0,
        template: 1,
      },
    };
    commonService.GetSettings(params).then((res) => {
      if (res && res.success) {
        setItemList(res.content.template);
      }
    });
  };
  // 保存提交
  const submit = () => {
    form.validateFields().then((values) => {
      console.log(values);
      if (activeKey === "0") {
        commonService.addInfoExpressBill(values).then((res) => {
          if (res && res.success) {
            // console.log(res.content.expressId, "expressId");
            SmartStorage.set("relateId", res.content.expressId);
            // setRelateId(res.content.expressId);
            message.success("创建订单成功");
          } else {
            message.error("创建订单失败请重新创建");
          }
        });
      } else {
        commonService.addInfoProject(values).then((result) => {
          if (result && result.success) {
            // setRelateId(result.content.projectId);
            SmartStorage.set("relateId", result.content.projectId);
            message.success("创建订单成功");
          } else {
            message.error("创建订单失败请重新创建");
          }
        });
      }
    });
  };
  // 开启扫描
  const openScan = () => {
    // eslint-disable-next-line no-array-constructor
    // let readyState = new Array("on connection", "Connection established","Closing connection", "Close connection");
    let host = "ws://127.0.0.1:16801/echoTwain/";
    websocket = new WebSocket(host);
    if (websocket !== null) {
      websocket.onopen = () => {
        if (websocket.readyState === 1) {
          websocket.send("0000");
        }
      };
    }
    websocket.onmessage = (event) => {
      let strCmd = event.data.substr(0, 5);
      var strsub = event.data.substr(5);
      if (strCmd === "&0000" && strsub === "start scan successed") {
        setOpenScanMessage(strsub);
        if (websocket != null) {
          websocket.send("10011001");
        }
      }
      if (strCmd === "&1001" && strsub === "set user'id successed") {
        setSocketUserMessage(strsub);
        if (websocket != null) {
          websocket.send("2001");
        }
      }
      if (strCmd === "&2001" && strsub !== "") {
        setScanMessage(strsub);
        if (websocket != null) {
          websocket.send("2002" + strsub);
        }
      }
      if (strCmd === "&2002" && strsub !== "") {
        if (websocket != null) {
          websocket.send("3001" + moment(new Date()).format("YYYYMMDDHHmmss"));
        }
      }
      if (strCmd === "&3001") {
        if (websocket != null) {
          websocket.send("3002jpg");
        }
      }
      if (strCmd === "&6006" && strsub !== "") {
        saveInvoice(strsub);
      }
      if (strCmd === "&9999") {
        webSocketClose();
      }
    };

    websocket.onclose = function () {
      webSocketClose();
    };
  };
  const startScan = () => {
    if (!SmartStorage.get("relateId")) {
      notification.warning({
        message: "提示",
        description: "请先创建快递订单",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    } else {
      if (websocket !== null) {
        websocket.send("6006D:\\Image\\");
      }
    }
  };
  // 关闭扫描
  const endScan = () => {
    if (websocket !== null) {
      setOpenScanMessage("");
      setSocketUserMessage("");
      setScanMessage("");
      websocket.send("9999");
      webSocketClose();
    }
  };
  const webSocketClose = () => {
    if (websocket.readyState === 1) {
      websocket.close();
    }
  };
  const saveInvoice = (src) => {
    commonService
      .batchScan({
        image: src,
        invoiceType: SmartStorage.get("activeKey") * 1,
        relateId: SmartStorage.get("relateId"),
      })
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    getFormTemplate();
    SmartStorage.set("activeKey", activeKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);
  useEffect(() => {
    if (openScanMessage === "") {
      openScan();
    }
    // 组件销毁前进行相关操作
    return () => {
      endScan();
      SmartStorage.remove("relateId");
      SmartStorage.remove("activeKey");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.content}>
      <h1>发票批量扫描</h1>
      <Tabs defaultActiveKey={activeKey} onChange={typeChange}>
        {tabsList.map((item) => {
          return (
            <Tabs.TabPane tab={item.label} key={item.key}>
              <Row>
                <Col span={10} className={styles.rightRow}>
                  <Form form={form} layout="horizontal" autoComplete="off">
                    <XFormItem
                      itemList={itemList}
                      callBack={callBackFunc}
                    ></XFormItem>
                  </Form>
                  <Form.Item wrapperCol={{ offset: 4 }}>
                    <Button type="primary" htmlType="submit" onClick={submit}>
                      保存
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className={styles.rightModule}>
                    {/* <Button type="primary" onClick={openScan}>
                      开启扫描
                    </Button> */}
                    <p className={styles.scanConfig}>
                      <span className={styles.configLabel}>开启扫描:</span>
                      <span
                        className={
                          openScanMessage === "start scan successed" &&
                          styles.configInfo
                        }
                      >
                        {openScanMessage === "start scan successed"
                          ? "成功"
                          : ""}
                      </span>
                    </p>
                    <p>
                      <span className={styles.configLabel}>设置用户ID:</span>
                      <span
                        className={
                          socketUserMessage === "set user'id successed" &&
                          styles.configInfo
                        }
                      >
                        {socketUserMessage === "set user'id successed"
                          ? "成功"
                          : ""}
                      </span>
                    </p>
                    <p>
                      <span className={styles.configLabel}>设置扫描仪:</span>
                      <span>{scanMessage}</span>
                    </p>
                    <div className={styles.rightBtns}>
                      <Button
                        type="primary"
                        className={styles.scanBtn}
                        onClick={startScan}
                      >
                        开始扫描
                      </Button>
                      {/* <Tooltip
                        title={
                          <span className={styles.tooltip}>
                            扫描机出现异常，可重启扫描服务
                          </span>
                        }
                        color="#f50"
                        placement="rightTop"
                      >
                        <Button type="link" onClick={restService}>
                          重启打印服务
                        </Button>
                      </Tooltip> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default InvoiceBatchScan;
