/* eslint-disable no-eval */
import React, { useEffect, useState, useRef } from "react";
import SmartQueryReact from "smart-iquery-react";
import ReviewEditModal from "./ReviewEditModal";
import Agreement from "./Agreement";
import AuditModule from "@/components/DetailDrawer/compments/MopModules/AuditModule";

const QueryCard = (props) => {
  console.log(props, "props");
  const iSignalr = process.env.REACT_APP_SIGNALER;
  const gatewayPath = process.env.REACT_APP_GATEWAY;
  const [queryData, setQueryData] = useState([]);
  const [childKey, setChildKey] = useState(1);
  const reviewEditModal = useRef(null);
  const agreement = useRef(null);
  const defaultParams = {
    tenantId: props.requestVal.tenantId,
    orderId: props.requestVal._OrderId || null,
    miceId: props.requestVal.processId,
  };
  const evalParams = (params) => {
    // eslint-disable-next-line no-unused-vars
    const regQueryData = props.moduleItem.queryParamsList.map((item) => {
      let obj = {};
      if (item.additional) {
        for (let key in item.additional) {
          obj[key] = params[item.additional[key]];
        }
        item.additional = obj;
      }
      return item;
    });
    setQueryData(regQueryData);
  };
  useEffect(() => {
    evalParams(props.requestVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const returnSelectedRows = (rowsId, rows) => {
    console.log(rowsId, rows);
  };
  const rowMenuHandleClick = (e, record, item) => {
    console.log(e, record, item);
    switch (item.actionCode) {
      case "viewFile":
        viewFile(record);
        break;

      default:
        eval(e.key)(item, record);
        break;
    }
  };
  // 查看文件
  const viewFile = (record) => {
    window.open(record.url);
  };
  const headerBtnHandleClick = (btn, item, items) => {
    console.log(btn, item, items);
  };
  // eslint-disable-next-line no-unused-vars
  const audit = (btn, row) => {
    reviewEditModal.current.showModal(btn, row);
  };
  // eslint-disable-next-line no-unused-vars
  const edit = (btn, row) => {
    agreement.current.showModal(btn, row);
  };
  const updateChild = () => {
    let data = childKey + 1
    setChildKey(data)
  }
  return (
      <>
        {queryData.length > 0 && (
            <div className="boderRow mopDetails">
              <div className="card_title">{props.moduleItem?.queryName}</div>
              <SmartQueryReact
                  key={childKey}
                  gatewayPath={gatewayPath}
                  iSignalr={iSignalr}
                  getQueryData={queryData}
                  returnSelectedRows={returnSelectedRows}
                  rowMenuHandleClick={rowMenuHandleClick}
                  headerBtnHandleClick={headerBtnHandleClick}
              ></SmartQueryReact>
              <ReviewEditModal ref={reviewEditModal} defaultParams={defaultParams} updateChild={updateChild}></ReviewEditModal>
              {/*<AuditModule></AuditModule>*/}
              <Agreement ref={agreement} defaultParams={defaultParams} updateChild={updateChild}></Agreement>
            </div>
        )}
      </>
  );
};

export default QueryCard;
