/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
import moment from "moment";
let _cloneDeep = structuredClone

export function useGeneric() {
    /**
     * 生成36位uuid
     * @returns {string}
     */
    const generateUUID = () => {
        let d = new Date().getTime();
        let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
  /**
 * 适配object不配置replaceEvalPath的情况,但是需要替换的值必须由双括号包裹: "{{context.aaa}}"
 * @param objectItem
 * @param context
 * @returns {*}
 */
  const object2StrRender = (objectItem, context) => {
    let object = _cloneDeep(objectItem)
    let template = JSON.stringify(object)
    return JSON.parse(template.replace(/\"\{\{(.*?)\}\}\"/g,
      (match, key) => {
        let str = evil(key.trim())
        return str === undefined ? '""' : JSON.stringify(str)
      }));
  }
  /**
   * 执行配置中需要eval的字段
   * @param config Array  配置
   * @param context Object  原数据
   */
  const replaceEvalSingle = (config, context = {}) => {
    config = JSON.parse(JSON.stringify(config));
    if (config.length === 0) return config;
    config.forEach((item) => {
      if (item.replaceEvalPath) {
        // 精确定位需要eval的字段
        item.replaceEvalPath.forEach((path) => {
          let arr = path.split("/");
          if (arr.length === 1) {
            try {
              item[arr[0]] = evil(item[arr[0]]);
            } catch (error) {
              console.log(error);
            }
          } else {
            arr.unshift("item");
            // 目标key
            let goalKey = arr.pop();
            let tmp = arr.reduce((left, right) => {
              try {
                return evil(left)[right];
              } catch (error) {
                console.log(error);
              }
            });
            try {
              tmp[goalKey] = evil(tmp[goalKey]);
            } catch (error) {
              console.log(error);
            }
          }
        });
      }
    });
    return config[0].params.metas[0];
  };
  /**
   * 执行配置中需要eval的字段
   * @param config Array  配置
   * @param context Object  原数据
   */
  const replaceEvalObject = (objectItem, context = {}) => {
    console.log("replaceEvalObject context:", context);
    let object = _cloneDeep(objectItem);
    if (
      object.replaceEvalPath &&
      object.replaceEvalPath.length &&
      object.replaceEvalPath !== "[]"
    ) {
      object.replaceEvalPath.forEach((path) => {
        console.log("replaceEvalObject path:", path);
        let arr = path.split("/");

        if (arr.length === 1) {
          console.log(object[arr[0]], context,"------")
          object[arr[0]] = object[arr[0]].includes('?') ? replaceVal(object[arr[0]], Array.isArray(context.record)?context.record[0]:context.record) : object[arr[0]];
        } else {
          arr.unshift("object");
          // 目标key
          let goalKey = arr.pop();
          let tmp = arr.reduce((left, right) => {
            try {
              // return generic.evil(left)[right]
              return eval(left)[right];
            } catch (error) {
              console.log(error, left);
            }
          });
          
          // 个性化配置
          // 1.中台 - 餐运营中心 - 餐厅异常信息 - 更新发票, 批量操作，需回传RowIds
          if(goalKey === "RowIds") {
            tmp.RowIds = context.record.map(item => item.rowId)
            return;
          }
          tmp[goalKey] = replaceVal(tmp[goalKey], {...Array.isArray(context.record)?context.record[0]:context.record, ...context});
        }
      });
    }
    return object;
  };
  /**
   *
   * @param {*} context mongodb 中的元数据
   * @param {*} obj  需要更新的元数据
   * @returns
   */
  const replaceVal = (evalStr, context) => {
    let val = "";
    try {
      val = evil(evalStr, context);
    } catch (error) {
      console.log(`${evalStr}_error`, error);
    }
    return val;
  };
  /**
   * 替代eval执行表达式
   * @param evalStr
   * @returns {*}
   */
  const evil = (evalStr, context) => {
    window.context = context;
    window._moment = moment;
    // eslint-disable-next-line no-new-func
    return new Function("return " + evalStr)();
  };
  /**
   *
   * @param {*} components
   * @param {*} context
   * @returns
   */
  const handleComponents = (components, context) => {
    if (components.length === 0) return [];
    let stashComponents = _cloneDeep(components);
    let componentsTmp = [];
    stashComponents.forEach((item, index) => {
      // 是否有需要替换的值
      let cacheItem = replaceEvalObject(item, context);
      if (
        item.expression &&
        item.expression.length &&
        item.expression !== "[]"
      ) {
        // 用于动态修改当前表单项的属性，比如：disabled...
        context._cacheFormItem = cacheItem;
        // 命中规则才显示
        if (item.expression.every((subItem) => replaceVal(subItem, context))) {
          componentsTmp.push(cacheItem);
        }
      } else {
        componentsTmp.push(cacheItem);
      }
      // 合并规则参数pattern
      if (cacheItem.pattern?.pattern && cacheItem.pattern.pattern !== "") {
        let RegExpPattern = {};
        if (!cacheItem.rules) {
          cacheItem.rules = [];
        } else {
          RegExpPattern.pattern = new RegExp(item.pattern.pattern, "g");
          RegExpPattern.message = item.pattern.message;
          cacheItem.rules.push(RegExpPattern);
        }
      }
    });
    return componentsTmp;
  };

  return {
      generateUUID,
    object2StrRender,
    replaceVal,
    replaceEvalSingle,
    replaceEvalObject,
    handleComponents,
    evil,
  };
}

