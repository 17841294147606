import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Select, message, Modal, Input, Button, Form } from "antd";
import {
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import commonService from "@/service/common";
const { confirm } = Modal;
const DynFormAction = forwardRef((props, ref) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [modalVisible, setModalVisible] = useState(false);
  const [btnData, setBtnData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [form] = Form.useForm();
  const [formStructure, setFormStructure] = useState([
    { label: "已通过", val: "已通过" },
    { label: "未通过", val: "未通过" },
  ]);
  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      console.log(btn, rowKeys, selectedRows, data);
      setBtnData(btn);
      setSelectedRows(selectedRows || data);
      // eslint-disable-next-line no-eval
      eval(btn.val)(btn, rowKeys, selectedRows, data);
    },
  }));
  const showDialog = (btn, selectedRowKeys, selectedRows, data) => {
    if (selectedRowKeys.length < 1 && !data) {
      message.info("请至少选择一条数据！");
      return;
    }
    setModalVisible(true);
  };
  const batchSettleMethod = (btn, selectedRows) => {
    Modal.confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "确认" + btn.text + "吗？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        let params = {};
        let parameters = btn.parameters;
        params = parameters.constant;
        let arr = [];
        selectedRows &&
          selectedRows.forEach((element) => {
            arr.push(element._id);
          });
        params.Updates =
          btn.val === "batchSettleAll"
            ? {
                _ids: [],
                Set: {},
              }
            : {
                _ids: arr,
                Set: {},
              };
        params.Request = props.dataParame;
        commonHandle(params, btn);
      },
      onCancel() {},
    });
  };
  // eslint-disable-next-line no-unused-vars
  const batchSettleAll = (btn, selectedRowKeys, selectedRows, data) => {
    batchSettleMethod(btn, selectedRows);
  };
  // eslint-disable-next-line no-unused-vars
  const batchSettle = (btn, selectedRowKeys, selectedRows, data) => {
    if (selectedRowKeys.length < 1 && !data) {
      message.info("请至少选择一条数据！");
      return;
    }
    batchSettleMethod(btn, selectedRows);
  };
  // eslint-disable-next-line no-unused-vars
  const batchCloseAll = () => {
    setSelectedRows(props.listData);
    setModalVisible(true);
  };
  // eslint-disable-next-line no-unused-vars
  const batchAuditAll = (btn, selectedRowKeys, selectedRows, data) => {
    setSelectedRows(props.listData);
    setModalVisible(true);
  };
  // eslint-disable-next-line no-unused-vars
  const batchAudit = (btn, selectedRowKeys, selectedRows, data) => {
    setFormStructure([
      { label: "已通过", val: "已通过" },
      { label: "未通过", val: "未通过" },
    ]);
    showDialog(btn, selectedRowKeys, selectedRows, data);
  };
  // eslint-disable-next-line no-unused-vars
  const batchClose = (btn, selectedRowKeys, selectedRows, data) => {
    setFormStructure([
      { label: "已完成", val: "已完成" },
      { label: "处理中", val: "处理中" },
      { label: "未完成", val: "未完成" },
    ]);
    showDialog(btn, selectedRowKeys, selectedRows, data);
  };
  // eslint-disable-next-line no-unused-vars
  const batchDel = (btn, selectedRowKeys, selectedRows, data) => {
    if (selectedRowKeys.length < 1 && !data) {
      message.info("请至少选择一条数据！");
      return;
    }
    confirm({
      title: btn.text || btn.actionName,
      icon: <ExclamationCircleFilled />,
      mask: true,
      maskClosable: true,
      content: (
        <div>
          <p>确定{btn.text || btn.actionName}吗？</p>
        </div>
      ),
      okText: "确定",
      cancelText: "取消",
      onOk() {
        let parameters = btn.parameters;
        let params = parameters.constant;
        if (data) {
          params._ids = [data._id];
        } else {
          let arr = [];
          selectedRows.forEach((element) => {
            arr.push(element._id);
          });
          params._ids = arr;
        }
        console.log(params);
        commonHandle(params, btn);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const commonHandle = async (params, btn) => {
    let parameters =
      btn && btn.parameters ? btn.parameters : btnData.parameters;
    let url = parameters.api;
    let method = "post";
    let res = await commonService.common(method, url, params);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      cancelModal();
      props.reloadData();
    }
  };
  const saveBatch = () => {
    form.validateFields().then((values) => {
      console.log(values);
      let arr = [];
      selectedRows.forEach((element) => {
        let obj = ["batchAudit", "batchAuditAll"].includes(btnData.val)
          ? {
              Set: {
                approval_status: values.status,
                approval_remark: values.remark || "",
              },
              _id: element._id,
            }
          : {
              Set: {
                pay_status: values.status,
                pay_remark: values.remark || "",
              },
              _id: element._id,
            };
        arr.push(obj);
      });
      let params = {};
      let parameters = btnData.parameters;
      params = parameters.constant;
      params.Docs = arr;
      commonHandle(params);
    });
  };
  const cancelModal = () => {
    setModalVisible(false);
    form.resetFields();
  };
  return (
    <div>
      <Modal
        title="批量审核"
        centered
        visible={modalVisible}
        maskClosable
        onCancel={cancelModal}
        footer={[
          <Button key="back" onClick={saveBatch}>
            提交
          </Button>,
        ]}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label={
              ["batchAudit", "batchAuditAll"].includes(btnData.val)
                ? "审核状态"
                : "完成状态"
            }
            name="status"
            rules={[
              {
                required: true,
                message: ["batchAudit", "batchAuditAll"].includes(btnData.val)
                  ? "请选择审核状态"
                  : "请选择完成状态",
              },
            ]}
          >
            <Select style={{ width: "100%" }}>
              {formStructure.map((obj) => (
                <Option key={obj.label} value={obj.label}>
                  {obj.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              ["batchAudit", "batchAuditAll"].includes(btnData.val)
                ? "审核备注"
                : "完成备注"
            }
            name="remark"
          >
            <TextArea rows={4} style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default DynFormAction;
