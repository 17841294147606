/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { message, Modal, Input, Form, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import LectureManageService from "@/service/lectureManage";
import commonService from "@/service/common";
import DetailModal from "./detailModal";
import { SmartStorage } from "@/utils";
import * as tinyCookie from "tiny-cookie";
const userId =
  SmartStorage.get("userId") ||
  SmartStorage.get("user_id") ||
  tinyCookie.get("userId") ||
  tinyCookie.get("user_id");
const LectureManage = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { TextArea } = Input;
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState({});
  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      switch (btn.actionCode) {
        case "lectureManage_settlement":
        case "lectureManage_quarantine":
        case "lectureManage_exception":
          form.resetFields();
          batchData(btn, rowKeys, selectedRows, data);
          break;
        case "lectureManage_detail":
        case "lectureManage_detailTable":
        case "lectureManage_download":
        case "lectureManage_submitPayment":
          let code = btn.actionCode.split("_")[1];
          // eslint-disable-next-line no-eval
          eval(code)(btn, rowKeys, selectedRows, data);
          break;
        default:
          break;
      }
    },
  }));
  const batchData = (btn, rowKeys, selectedRows, data) => {
    if (rowKeys.length < 1 && !data) {
      message.info("请至少选择一条数据！");
      return;
    }
    // setSelectedRowKeys(rowKeys)
    confirm({
      title: "确认" + btn.actionName,
      icon: <ExclamationCircleOutlined />,
      content: ["lectureManage_exception", "lectureManage_settlement"].includes(
        btn.actionCode
      ) ? (
        "请确认是否要提交结算？"
      ) : (
        <Form form={form} layout="vertical" autoComplete="off">
          <Form.Item name={["reasonData"]} label="请输入隔离原因">
            <TextArea rows={4} placeholder="请输入隔离原因" />
          </Form.Item>
        </Form>
      ),
      onOk() {
        btn.actionCode === "lectureManage_quarantine" &&
        !form.getFieldValue("reasonData")
          ? message.error("操作失败！请输入隔离原因！")
          : Approval(data, btn, rowKeys);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
    console.log(111);
  };
  const detail = async (btn, rowKeys, selectedRows, record) => {
    const { relatedId, processId } = record;
    console.log(record);
    let params = {
      filterData: {
        //参数返回中获取
        catalog: "讲者协议函",
        relatedId,
        processId,
      },
      OrderByData: {
        //固定值
        catalog: "asc",
        CreatedOn: "asc",
      },
    };
    let params2 = {
      filterData: {
        //参数返回中获取
        catalog: "讲者签到记录",
        relatedId,
        processId,
      },
      OrderByData: {
        //固定值
        catalog: "asc",
        CreatedOn: "asc",
      },
    };
    const photoURL = await LectureManageService.getAgreementPhoto(params);
    console.log(photoURL, "photoURL");
    const signInRecord = await LectureManageService.getAgreementPhoto(params2);
    console.log(signInRecord, "sin");
    // if (photoURL.content[0]) {
    setRecordData({
      ...record,
      ...{
        url: photoURL.content.length > 0 ? photoURL.content[0].url : "",
      },
      ...{
        signUrl:
          signInRecord.content.length > 0 ? signInRecord.content[0].url : "",
      },
    });
    setModalVisible(true);
    // }
  };
  const download = async (btn, rowKeys, selectedRows, record) => {
    console.log(record);
    let params = {
      queryId: record.queryId,
      // queryId4Count: record.queryId4Count,
      cluster: record.cluster || "default",
      UsePaging: true,
      parameters: {
        _batchId: record.batchId,
      },
      ExportMode: record.exportMode || 0,
      total: record.batchCount,
    };
    console.log(params);
    setLoading(true);
    let res = await commonService.ExportData(params);
    setLoading(false);
    if (res) {
      notification.success({
        message: "导出提示",
        description: <div dangerouslySetInnerHTML={{ __html: res.msg }}></div>,
        placement: "topRight",
        duration: 0,
      });
    }
  };
  const submitPayment = async (btn, rowKeys, selectedRows, data) => {
    console.log("2222", data);
    let params = {
      BatchId: data.batchId,
    };
    setLoading(true);
    let res = await LectureManageService.BatchTransfer(params);
    setLoading(false);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      props.reloadData();
      // getGridData(1, 10);
    }
  };
  const detailModalCallback = ({ type, itemType, values }) => {
    setModalVisible(false);
  };
  async function Approval(data, btn, rowKeys) {
    let params = {
      isAllowed: [
        "lectureManage_exception",
        "lectureManage_settlement",
      ].includes(btn.actionCode)
        ? true
        : false, //true为结算，false为隔离
      queueIds: rowKeys.length > 0 ? rowKeys : [],
      Reason: ["lectureManage_exception", "lectureManage_settlement"].includes(
        btn.actionCode
      )
        ? ""
        : form.getFieldValue("reasonData"),
    };
    rowKeys.length < 1 && params.queueIds.push(data.queueId);
    console.log(params);
    let res = await LectureManageService.approval(params);
    if (res && res.success) {
      message.info("操作成功！");
      props.reloadData();
      // getGridData(1, 10);
    }
  }
  return (
    <div>
      <DetailModal
        recordData={recordData}
        modalVisible={modalVisible}
        detailModalCallback={detailModalCallback}
      ></DetailModal>
    </div>
  );
});

export default LectureManage;
