import React from "react";
import styles from "../../meetingOpsCenter.module.less";
const MopMeetingDetail = () => {
  const microUrl = process.env.REACT_APP_MOP_URL;
  return (
    <div className={styles.orderContainer}>
      <micro-app
        name="mopDetail"
        url={microUrl}
        baseroute="/micro-apps"
      ></micro-app>
    </div>
  );
};

export default MopMeetingDetail;
