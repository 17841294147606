import React, {
    forwardRef, useEffect,
    useImperativeHandle, useRef,
    useState
} from "react";
import {Drawer, Space, Row, Col, Upload} from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import smartService from "@/service";
import UploadImages from "./UploadImages";
import "../index.less";
import {filter} from "lodash/collection";
const uploadButton = (
    <div>
        <PlusOutlined />
        <div
            style={{
                marginTop: 8,
            }}
        >
            Upload
        </div>
    </div>
);


export default forwardRef((props, ref) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [supplierId, setSupplierId] = useState();
    const [rowInfo, setRowInfo] = useState({});
    const [imagesObj, setImagesObj] = useState([]);
    useImperativeHandle(ref, () => ({
        handle: (btn, rowKeys, selectedRows, data) => {
            console.log(btn, rowKeys, selectedRows, data);
            setRowInfo(data);
            setSupplierId(selectedRows.supplierId);
            setModalVisible(true);
        }
    }))
    const onOk = () => {}
    const onCancel = () => {
        setModalVisible(false)
    }
    // download file
    const downloadTemplate = (url) => {
        console.log(url);
        window.open(url);
    }
    // get all images infoo
    const getVenueImages = () => {
        const params = {
            "supplierId": supplierId
        }
        smartService.GetSupplierImageInfos(params).then(res => {
            console.log(res);
            if(res.success){
                const {basicInfoFiles, financeInfoFiles, roomInfoFiles, venueInfoFiles, mealInfoFiles, facilitiesInfoFiles} = res.content;
                const arr = [
                    {
                        name: '基础信息',
                        files: basicInfoFiles
                    },
                    {
                        name: '财务信息',
                        files: financeInfoFiles
                    },
                    {
                        name: '房间信息',
                        files: roomInfoFiles
                    },
                    {
                        name: '会场信息',
                        files: venueInfoFiles
                    },
                    {
                        name: '餐厅信息',
                        files: mealInfoFiles || []
                    },
                    {
                        name: '会议设施',
                        files: facilitiesInfoFiles || []
                    }
                ]
                setImagesObj(value => arr.filter(value => value.files.length > 0));
            }
        })

    }
    useEffect(() => {
        if (modalVisible) {
            getVenueImages();
        }
    }, [modalVisible])
    return(
        <Drawer
            title="图片维护"
            width="80%"
            className="images_drawer"
            onClose={onCancel}
            open={modalVisible}>
            <div className="infos container">
                <div className="name">{rowInfo.name}</div>
                <div className="address">{rowInfo.address}</div>
                <div className="address">{rowInfo.contact} {rowInfo.mobile}</div>
            </div>
            {
                imagesObj.map(i => {
                    return (
                        <div className="basic_infos container">
                            <div className="column_title">
                                {i.name}
                                <div className="hint">仅支持JPG,PNG</div>
                            </div>
                            {
                                i.files && i.files.map(x => {
                                    return (<>
                                        {i.name === '房间信息' ? <Row style={{margin: "20px 0 4px 0"}}>
                                            <Col span={4}>房间类型： {x.roomType}</Col>
                                            <Col span={6} style={{width: '100px',overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>房间名称： {x.name}</Col>
                                            <Col offset={1} span={4}>房间数量： {x.qty}</Col>
                                            <Col span={4}>房间面积： {x.area}</Col>
                                        </Row> : null}
                                            {i.name === '会场信息' ? <Row style={{margin: "20px 0 4px 0"}}>
                                                <Col span={6} style={{width: '100px',overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>会场名称： {x.name}</Col>
                                            </Row> : null}
                                            {i.name === '餐厅信息' ? <Row style={{margin: "20px 0 4px 0"}}>
                                                <Col span={6} style={{width: '100px',overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>餐厅名称： {x.name}</Col>
                                            </Row> : null}
                                        <Row style={{alignItems: 'center',border: '1px dashed #e8e8e8',padding: '10px', marginBottom: '10px'}}>
                                            <Col span={2}>
                                                {x.name}
                                                {x.templateUrl && <div className="download" onClick={() => downloadTemplate(x.templateUrl)}>下载模板</div>}
                                            </Col>
                                            <Col span={22}>
                                                <UploadImages imageList={x.files} resourceId={x.resourceId} catalog={x.catalog}/>
                                            </Col>
                                        </Row>
                                        </>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }

            {/*<div className="finance_infos container">*/}
            {/*    <div className="column_title">*/}
            {/*        财务信息*/}
            {/*        <div className="hint">仅支持JPG,PNG</div>*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        imagesObj.financeInfoFiles && imagesObj.financeInfoFiles.map(i => {*/}
            {/*            return (*/}
            {/*                <Row style={{alignItems: 'center'}}>*/}
            {/*                    <Col span={2}>{i.catalog}</Col>*/}
            {/*                    <Col span={22}>*/}
            {/*                        <UploadImages imageList={i.files} resourceId={i.resourceId}/>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
            {/*<div className="room_infos container">*/}
            {/*    <div className="column_title">*/}
            {/*        房间信息*/}
            {/*        <div className="hint">仅支持JPG,PNG</div>*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        imagesObj.roomInfoFiles && imagesObj.roomInfoFiles.map(i => {*/}
            {/*            return (*/}
            {/*                <Row style={{alignItems: 'center'}}>*/}
            {/*                    <Col span={2}>{i.catalog}</Col>*/}
            {/*                    <Col span={22}>*/}
            {/*                        <UploadImages imageList={i.files} resourceId={i.resourceId}/>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
            {/*<div className="place_infos container">*/}
            {/*    <div className="column_title">*/}
            {/*        会场信息*/}
            {/*        <div className="hint">仅支持JPG,PNG</div>*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        imagesObj.venueInfoFiles && imagesObj.venueInfoFiles.map(i => {*/}
            {/*            return (*/}
            {/*                <Row style={{alignItems: 'center'}}>*/}
            {/*                    <Col span={2}>{i.catalog}</Col>*/}
            {/*                    <Col span={22}>*/}
            {/*                        <UploadImages imageList={i.files} resourceId={i.resourceId}/>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
            {/*<div className="cafeteria_infos container">*/}
            {/*    <div className="column_title">*/}
            {/*        餐厅信息*/}
            {/*        <div className="hint">仅支持JPG,PNG</div>*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        imagesObj.mealInfoFiles && imagesObj.mealInfoFiles.map(i => {*/}
            {/*            return (*/}
            {/*                <Row style={{alignItems: 'center'}}>*/}
            {/*                    <Col span={2}>{i.catalog || i.name}</Col>*/}
            {/*                    <Col span={22}>*/}
            {/*                        <UploadImages imageList={i.files} resourceId={i.resourceId}/>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
            {/*<div className="facilities_info container">*/}
            {/*    <div className="column_title">*/}
            {/*        会议设施*/}
            {/*        <div className="hint">仅支持JPG,PNG</div>*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        imagesObj.facilitiesInfoFiles && imagesObj.facilitiesInfoFiles.map(i => {*/}
            {/*            return (*/}
            {/*                <Row style={{alignItems: 'center'}}>*/}
            {/*                    <Col span={2}>{i.catalog}</Col>*/}
            {/*                    <Col span={22}>*/}
            {/*                        <UploadImages imageList={i.files} resourceId={i.resourceId}/>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
        </Drawer>
    )
})
