import React, { useEffect, useState } from "react";
import commonService from "@/service/common";
import "../../MopModules/modules.less";
import {Button, Table, Form, Input, InputNumber, Select, message} from "antd";

const TeaTable = (props) => {
    const [form] = Form.useForm();
    const [dataSource,setDataSource] = useState([]);
    const [childSource,setChildSource] = useState([]);
    const [OperationMode,setOperationMode] = useState([{txt: '是', val: true},{txt: '否', val: false}]);
    const [isEdit,setIsEdit] = useState(false);
    const [price,setPrice] = useState(0);
    const { TextArea } = Input;
    useEffect(() => {
        let data = JSON.parse(JSON.stringify(props.listItem))
        data.key = '0'
        if (data.expenses.length > 0) {
            let agreementPrice = ''
            if (typeof data.expenses[0].agreementPrice === 'boolean') {
                agreementPrice = data.expenses[0].agreementPrice ? '是' : '否'
            } else {
                agreementPrice = props.isGsk && !props.defaultParams.inWhiteList ? '是' : '否'
            }
            let childData = {
                qty: data.expenses[0].qty,
                price: data.expenses[0].price,
                minQty: data.expenses[0].minQty,
                amount: data.expenses[0].amount,
                outPrice: data.expenses[0].outPrice,
                invoiceType: data.expenses[0].invoiceType,
                taxRate: data.expenses[0].taxRate || '',
                descr: data.expenses[0].descr,
                serviceDescr: data.expenses[0].serviceDescr,
                unit: data.expenses[0].unit || '位',
                agreementPrice: agreementPrice,
            }
            setChildSource([childData])
            form.setFieldsValue(childData);
        }
        setPrice(data.expenses[0].price)
        data.dtStart = data.dtStart ? data.dtStart.replace('T', ' ') : ''
        data.dtEnd = data.dtEnd ? data.dtEnd.replace('T', ' ') : ''
        setDataSource([data])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listItem]);
    const editInfo = () => {
        setIsEdit(true)
    }
    const saveInfo = () => {
        form.validateFields().then((values) => {
            let taxRate = Number(values.taxRate) / 100;
            const valueMap = {
                "是": true,
                "否": false,
                true: true,
                false: false,
                "": ""
            };
            let agreementPrice = valueMap[values.agreementPrice] !== undefined ? valueMap[values.agreementPrice] : null
            let params = {
                tenantId: props.defaultParams.tenantId,
                miceId: props.defaultParams.processId,
                orderId: props.defaultParams.orderId || null,
                itemId: props.listItem.itemId,
                supplierId: props.defaultParams.supplierId,
                quotes: [{
                    expenseId: props.listItem.expenses[0].expenseId,
                    costType: '茶歇', // 费用类型
                    price: price, // 单价
                    qty: props.listItem.expenses[0].qty, // 数量(用餐人数/间数…)
                    amount: values.amount, // 小计
                    outPrice: values.outPrice, // 对外价格
                    minQty: values.minQty, // 保底数量
                    invoiceType: values.invoiceType, // 发票类型
                    unit: values.unit, // 单位
                    taxRate: values.taxRate, // 税率
                    tax: Number((values.amount / (1 + taxRate) * taxRate).toFixed(2)), // 税额
                    descr: values.descr, // 说明
                    serviceDescr: values.serviceDescr, // 说明
                    agreementPrice: agreementPrice,
                }]
            }
            commonService.SaveQuote(params).then(res => {
                if (res && res.success) {
                    message.success("保存成功");
                    setIsEdit(false)
                    props.GetF1QuotedPriceTaskList()
                }
            })
        })
    }
    const handleChange = () => {
        let amount = form.getFieldValue('amount')
        let czNum = props.listItem.expenses[0].qty
        let price = amount / czNum
        setPrice(price)
    }
    const handleSelectChange = (val) => {
        props.invoiceTypeList.forEach(ele => {
            if ([val].includes(ele.value)) {
                form.setFieldValue('invoiceType', ele.label)
            }
        })
    }
    const expandedRowRender = () => {
        const columns = [
            { title: '费用类型' , key: 'type', render: () => ( <span>茶歇</span> ),width: 90 },
            { title: '数量' , key: 'qty', dataIndex: 'qty',width: 90 },
            { title: '保底数量', dataIndex: 'minQty', key: 'minQty',width: 140,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='minQty' style={{ margin: 0 }} >
                                <InputNumber style={{width: '100%', minWidth: '130px'}} placeholder="请填写保底数量" onChange={handleChange} min={0} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || '0')}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                )},
            { title: '单价', dataIndex: 'price', key: 'price',width: 70,
                render: () => (
                    <> {price} </>
                ) },
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 单位</>) : '单位', dataIndex: 'unit', key: 'unit',width: 150,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='unit' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请输入单位', }, ]}>
                                <Input style={{width:'100%'}}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title:  (<><span style={{ color: 'red' }}>*</span> 小计</>), dataIndex: 'amount', key: 'amount',width: 120 ,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <>
                                <Form.Item name='amount' style={{ margin: 0 }} rules={[ { required: true, message: '请填写总金额', }, ]}>
                                    <InputNumber  style={{width: '100%', minWidth: '110px'}} onChange={handleChange} min={0} />
                                </Form.Item>
                            </>
                        ) : (children)}
                    </>
                )},
            { title: '对外价格', dataIndex: 'outPrice', key: 'outPrice',width: 120 ,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='outPrice' style={{ margin: 0 }} >
                                <InputNumber style={{width: '100%', minWidth: '110px'}} min={0} />
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 发票类型</>) : '发票类型', dataIndex: 'invoiceType', key: 'invoiceType',width: 150,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='invoiceType' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请选择发票类型', }, ]}>
                                <Select style={{ width: 140 , minWidth: '140px'}} options={props.invoiceTypeList} onChange={handleSelectChange}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: props.isGsk ? (<><span style={{ color: 'red' }}>*</span> 税率%</>) : '税率%' ,dataIndex: 'taxRate', key: 'taxRate' ,width: 120,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='taxRate' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请填写税率', }, ]}>
                                <InputNumber style={{width: '100%', minWidth: '90px'}} min={0} max={100} formatter={value => `${parseInt(value || '0')}`} parser={value => parseInt(value || '0')}/>
                            </Form.Item>
                        ) : (children)}
                    </>
                ) },
            { title: '报价说明', dataIndex: 'descr', key: 'descr' ,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='descr' style={{ margin: 0 }} >
                                <TextArea rows={4} style={{minWidth: '250px'}}
                                          placeholder="会议室面积、所在楼层
投影仪-免费/收费，是否包含投影幕布，投影仪流明数，投影仪价格（收费时）
话筒-免费数量，收费标准
讲台花-免费数量，收费标准
桌卡-免费数量，收费标准
纸笔水-免费/收费，收费标准"/>
                            </Form.Item>
                        ) : (children)}
                    </>
                )},
            { title: '服务描述', dataIndex: 'serviceDescr', key: 'serviceDescr' ,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='serviceDescr' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请输入服务描述', }, ]}>
                                <TextArea rows={4} style={{minWidth: '250px'}} placeholder="请输入服务描述"/>
                            </Form.Item>
                        ) : (children)}
                    </>
                )},
            { title: '是否协议价格', dataIndex: 'agreementPrice', key: 'agreementPrice',width: 150,
                render: (children) => (
                    <>
                        { isEdit ? (
                            <Form.Item name='agreementPrice' style={{ margin: 0 }} rules={[ { required: props.isGsk, message: '请选择是否协议价格', }, ]}>
                                <Select fieldNames={{label: 'txt', value: 'val'}} options={OperationMode} placeholder="请选择是否协议价格"></Select>
                            </Form.Item>
                        ) : (children)}
                    </>
            ) },
            { title: '操作', dataIndex: 'operation', key: 'operation',
                render: () => (
                    props.isQuote && props.isOffline ? (
                        <>
                            { !isEdit ? <Button type="primary" onClick={() => editInfo()}>编辑</Button> : <Button type="primary" onClick={() => saveInfo()}>保存</Button>}

                        </>
                    ) : ''
                ),
            },
        ];
        return (
            <Form form={form} component={false}>
                <Table columns={columns}
                       bordered
                       dataSource={childSource}
                       pagination={false}/>
            </Form>
        )
    };
    const columns = [
        { title: '需求项目', dataIndex: 'itemTypeTxt', key: 'itemTypeTxt' },
        { title: '茶歇名称', dataIndex: 'itemName', key: 'itemName' },
        { title: '茶歇人数', dataIndex: 'qty1', key: 'qty1' },
        { title: '开始时间', dataIndex: 'dtStart', key: 'dtStart' },
        { title: '结束时间', dataIndex: 'dtEnd', key: 'dtEnd' },
    ];
    return (
        <>
            <Table
                columns={columns}
                bordered
                expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                dataSource={dataSource}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </>
    );
};

export default TeaTable;
