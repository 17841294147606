import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  message,
  Drawer,
  Button,
  Table,
  Form,
  DatePicker,
  Divider,
  Select,
  InputNumber,
} from "antd";
import commonService from "@/service/common";
import "./index.less";
import moment from "moment";
const { Option } = Select;
const FinConfirmReceipt = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [btnData, setBtnData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [actualAmount, setActualAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [bankList, setBankList] = useState([]);
  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      console.log(btn, rowKeys, selectedRows, data);
      setBtnData(btn);
      // setSelectedRows(selectedRows || data);
      // eslint-disable-next-line no-eval
      eval(btn.val)(btn, rowKeys, selectedRows, data);
    },
  }));
  const setTotalAmount = (rows) => {
    let amounts = 0;
    rows.forEach((element) => {
      amounts += Number(element.amount.replace(/,/g, ""));
    });
    let row = [
      {
        customerName: "合计",
        amount: amounts.toFixed(2),
      },
    ];
    setActualAmount(amounts.toFixed(2));
    let ServiceCharge = form.getFieldValue("ServiceCharge");
    console.log(ServiceCharge);
    let PayableAmount = ServiceCharge ? amounts - ServiceCharge : amounts;
    setPayableAmount(PayableAmount.toFixed(2));

    let rowArr = [...rows, ...row];
    console.log(rowArr);
    setSelectedRows(rowArr);
    setColumnsBtn(rowArr);
  };
  const setColumnsBtn = (rows) => {
    let arr = [];
    props.queryObject.columns.forEach((element) => {
      if (
        ["customerName", "invoiceNum", "billingDate", "amount"].includes(
          element.name
        )
      ) {
        let data = {
          title: element.title,
          dataIndex: element.name,
          key: element.title,
          align: element.alignContent || "left",
          width: element.width,
          fixed: element.fixed,
          sorter: element.allowSort,
          onCell: (record) => ({
            // colSpan:(record.customerName=='合计'&&element.name=="customerName")?3:1
          }),
        };
        arr.push(data);
      }
    });
    let rowBtn = {
      title: "操作",
      dataIndex: "Buttons",
      key: "Buttons",
      align: "center",
      fixed: "left",
      width: 80,
      render: (text, record, index) =>
        record.customerName !== "合计" && (
          <Button onClick={() => removeData(record, index, rows)}>移除</Button>
        ),
    };
    arr.unshift(rowBtn);
    setColumns(arr);
  };
  const removeData = (record, index, selectedRows1) => {
    console.log(selectedRows1);
    console.log(selectedRows);
    let rows = JSON.parse(JSON.stringify(selectedRows1));
    console.log(rows);
    rows.splice(index, 1);
    rows.pop();
    console.log(index, rows);
    setTotalAmount(rows);
  };
  // eslint-disable-next-line no-unused-vars
  const confirmReceipt = (btn, selectedRowKeys, selectedRows1, data) => {
    if (selectedRowKeys.length < 1 && !data) {
      message.info("请至少选择一条数据！");
      return;
    }
    let equalFlag = selectedRows1.some(
      (item) =>
        selectedRows1.filter((ele) => ele.customerId !== item.customerId)
          .length >= 1
    );
    if (equalFlag) {
      message.info("请选择同一租户！");
      return;
    }
    if (props.dataParame.parameters.status !== "未收款") {
      message.info("请选择搜索状态为'未收款'！");
      return;
    }
    console.log(equalFlag);
    getBankList();
    setTotalAmount(selectedRows1 || data);
    setModalVisible(true);
  };
  const cancelModal = () => {
    setModalVisible(false);
    form.resetFields();
  };
  const getBankList = async () => {
    let params = {
      KeyCode: "bank",
    };
    const res = await commonService.queryBankList(params);
    if (res.success) {
      setBankList(res.content);
    }
  };
  const commonHandle = async (url, params, btn) => {
    let method = "post";
    let res = await commonService.common(method, url, params);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      cancelModal();
      props.reloadData();
    }
  };
  const saveData = () => {
    form.validateFields().then((values) => {
      values.DtPay = moment(values.DtPay).format("YYYY-MM-DD HH:mm:ss");
      let params = {};
      if (btnData.parameters.Request) {
        params = Object.assign(btnData.parameters || {}, {
          Request: props.dataParame,
        });
      } else {
        let rows = JSON.parse(JSON.stringify(selectedRows));
        rows.pop();
        params = Object.assign(btnData.parameters || {}, { Rows: rows });
      }
      params.Updates = values;
      params.Updates.ActualAmount = actualAmount;
      params.Updates.PayableAmount = payableAmount;
      bankList.forEach((element) => {
        if (element.val === values.BankNameVal)
          params.Updates.BankName = element.txt;
      });
      let url =
        btnData.parameters.pai ||
        props.queryObject.variables.api4BatchSetQueryData;
      commonHandle(url, params);
    });
  };
  const selectChange = (e) => {
    console.log(e);
  };
  const dataOnOk = (e) => {
    console.log(e);
  };
  const actualAmountChange = (e) => {
    console.log(e);
    // if(e){
    let count = actualAmount - e;
    setPayableAmount(count.toFixed(2));
    // }
    // console.log(e)
  };
  return (
    <div>
      <Drawer
        title="收款确认"
        placement="right"
        onClose={cancelModal}
        visible={modalVisible}
        width={1200}
      >
        <div className="FininfoBox">
          <div className="leftForm">
            <Form
              name="basic"
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              autoComplete="off"
            >
              <p className="priceBox">应收金额: {actualAmount}</p>
              <Form.Item
                name="ServiceCharge"
                label="现贴手续费"
                rules={[{ required: true, message: "请输入现贴手续费!" }]}
              >
                <InputNumber
                  style={{
                    width: "200px",
                  }}
                  placeholder="请输入现贴手续费"
                  onChange={(e) => actualAmountChange(e)}
                />
              </Form.Item>
              <p className="priceBox">实收金额: {payableAmount}</p>
              <Form.Item
                name="BankNameVal"
                label="收款账户"
                rules={[{ required: true, message: "请选择收款账户!" }]}
              >
                <Select
                  onChange={selectChange}
                  style={{
                    width: "200px",
                  }}
                >
                  {bankList?.map((item, oindex) => {
                    return (
                      <Option key={oindex} value={item.val}>
                        {item.txt}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="DtPay"
                label="收款日期"
                rules={[{ required: true, message: "请选择收款日期!" }]}
              >
                <DatePicker
                  style={{
                    width: "200px",
                  }}
                  showTime
                  onOk={dataOnOk}
                />
              </Form.Item>
            </Form>
          </div>
          <div className="rightInfo">
            <Table
              size="small"
              rowKey="invoiceId"
              columns={columns}
              dataSource={selectedRows}
              pagination={false}
              style={{ maxWidth: "100%", wordBreak: "break-word" }}
              scroll={{ x: "max-content" }}
            />
          </div>
        </div>
        <Divider />
        <Button
          style={{ float: "right", marginLeft: "16px" }}
          onClick={() => saveData()}
          type="primary"
        >
          确 认
        </Button>
        <Button style={{ float: "right" }} onClick={cancelModal}>
          取消
        </Button>
      </Drawer>
    </div>
  );
});

export default FinConfirmReceipt;
