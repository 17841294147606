import React, { useEffect, useState, useRef } from "react";
import { Input, Form, Button, Select } from "antd";
import commonService from "@/service/common";
import "./index.less";
import ThirdPartApp from "@/pages/HomePage/ThirdPartApp/ThirdPartApp";
const { Option } = Select;
const AppSimulator = () => {
    const [form] = Form.useForm();
    const [tenants, setTenants] = useState([]);
    const [tenantData, setTenantData] = useState({});
    const [futureDays, setFutureDays] = useState(null);
    const ThirdPartAppRef = useRef();
    const opneApp = () => {
        form.validateFields().then((values) => {
            console.log(values,tenantData)
            let btn={actionCode:'platformCar',actionUrl:tenantData}
            console.log(btn,values,values,values)
            ThirdPartAppRef.current.handle(btn, values, values, {loginAccount:values.name});
        })
        
    }
    const getTenants = async () => {
        let params = { "collection": "cfg-account-sse", "useCaching": true, "cachingKey": "", "filter": {}, "sort": { "sortNo": 1 }, "limit": 1000 }
        let res = await commonService.QuerySettings(params);
        if (res && res.success) {
            setTenants(res.content)
        }
    }
    const tenantChange=async(e, sData)=>{
        // setTenantData(sData)
        let url="https://spkg.smartmice.cn/configuration/iapp-config/"+sData.value+"/"+ process.env.REACT_APP_NODE_ENV+"/uris.json";
        let params = {
            rnd: new Date().getTime()
          };
    
        let res= await commonService.common('get',url, params);
        setTenantData(res.uris['webapp-ievent'])
        // console.log(e, sData)
    }
    useEffect(() => {
        getTenants()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="appSimulatorBox">
            <div className="appForm">
                <Form
                    name="basic"
                    form={form}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="用户名"
                        name="name"
                        rules={[{ required: true, message: "请输入用户名进行搜索!" }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="选择租户"
                        name="reason"
                        rules={[{ required: true, message: "请选择租户!" }]}
                    >
                        <Select onChange={(e, sData) => tenantChange(e, sData)}>
                            {tenants.map((obj) => (
                                <Option tenantData={obj} key={obj._id} value={obj.tenantCode}>
                                    {obj.tenantName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
                <Button type="primary" onClick={(e)=>{opneApp()}} className="appSibtn">打开模拟器</Button>
            </div>

            <ThirdPartApp ref={ThirdPartAppRef}></ThirdPartApp>
        </div>
    );
};
export default AppSimulator;
