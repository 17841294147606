import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import React, {useEffect, useState} from 'react';
import smartService from "@/service";
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const UploadImages = ({imageList, resourceId, catalog}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ file, fileList: newFileList }) => {
        console.log(file);
        if(file.response){
            const {fileType, fileName, fileKey} = file.response.content;
            const params = {
                "RelatedId": resourceId,
                "Catalog": catalog,
                "Tag": fileType,
                "FileKey": fileKey
            }
            smartService.SaveImages(params);
        }

        setFileList(newFileList)
    };
    // 删除图片
    const onRemove = (file) => {
        console.log(file);
        return new Promise((resolve, reject) => {
            const params = {
                "RelatedId": resourceId,
                "Catalog": file.name,
                "FileKey": file.fileKey
            }
            smartService.DeleteImages(params).then(res => {
                if(res.success){
                    resolve()
                }else {
                    resolve(false)
                }
            })
        })
    }
    useEffect(() => {
        const arr = imageList.map(i => {
            return {
                name: i.catalog,
                url: i.fileUrl,
                fileKey: i.fileKey,
                resourceId: resourceId
            }
        })
        setFileList(arr);
    }, [imageList])
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                上传
            </div>
        </div>
    );
    return (
        <>
            <Upload
                action={process.env.REACT_APP_GATEWAY + '/foundation/aliyun/api/oss/upload'}
                listType="picture-card"
                fileList={fileList}
                accept="image/jpeg, image/png, image/jpg"
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={onRemove}
            >
                { uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
};
export default UploadImages;
