import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Affix, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { SmartStorage } from "@/utils";
import styles from "./microApp.module.less";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MicroApp = (props) => {
  const history = useHistory();
  const menus = SmartStorage.get("menus");
  console.log(props);
  const [microParams, setMicroParams] = useState(null);
  // 解构
  // const { microName, microUrl, microRoute } = props.mciroObj;
  const microAppError = () => {};
  // micro 创建
  const microAppCreate = () => {};
  // micro 即将被创建回调
  const microAppBeforemount = () => {};
  // micro 已经被渲染完成
  const microAppMounted = () => {};
  // micro 已经被卸载
  const microUnmount = () => {};
  // 筛选出当前路由的菜单项
  const filterActiveMenu = (pathname) => {
    console.log(pathname);
    const filterItem = menus.filter((item) => item.Path === pathname);
    console.log(filterItem);
    if (filterItem.length > 0) {
      setMicroParams(filterItem[0].Params.UrlParams);
    }
  };
  // 返回上一级
  const goback = () => {
    // window.setTimeout(() => history.goBack(), 0);
    const rnd = new Date().getTime();
    history.push(`${microParams.goBackUrl}?rnd=${rnd}`);
  };
  // 获取来自子应用的信号,返回之前的路由地址
  const handleSignalFromNGAPP = (e) => {
    console.log("REACT_APP_ENV:", process.env);
    const rnd = new Date().getTime();
    const url = SmartStorage.get("previousPath");
    history.push(url + `?rnd=${rnd}`);
  };
  useEffect(() => {
    filterActiveMenu(props.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    microParams && (
      <div className={styles.content}>
        {microParams.backbtn && (
          <div className={styles.btn}>
            <Affix>
              <Button
                type="primary"
                icon={<LeftOutlined />}
                onClick={() => goback(microParams)}
              >
                返回上一级
              </Button>
            </Affix>
          </div>
        )}
        <micro-app
          name={microParams.microName}
          url={microParams.microUrl}
          baseroute={microParams.microRoute}
          onCreated={microAppCreate}
          onBeforemount={microAppBeforemount}
          onMounted={microAppMounted}
          onUnmount={microUnmount}
          onError={microAppError}
          onDataChange={(e) => handleSignalFromNGAPP(e)}
          destroy
        ></micro-app>
      </div>
    )
  );
};

export default MicroApp;
