import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from "react";
import commonService from "@/service/common";
import {Form, Modal, Row, InputNumber, message, Col, Input, Button, DatePicker, Divider} from "antd";
import { SmartStorage } from "@/utils";
import moment from "moment/moment";
import './CarInfoCard.less'
import {EnvironmentOutlined} from "@ant-design/icons";
import MapEditor from "@/components/DetailDrawer/compments/CarSupplierModules/MapEditor";
const EditItinerary = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const mapEditor = useRef(null);
    const [selectedData, setSelectedData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDispatch, setIsDispatch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editType, setEditType] = useState('');
    const { TextArea } = Input;
    useEffect(() => {
        setIsDispatch([35, '35'].includes(SmartStorage.get("detailRow").status))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const showModal = async (rowData) => {
        console.log(rowData);
        form.resetFields();
        setLoading(false)
        setEditType(rowData.type)
        if (['edit', 'copy'].includes(rowData.type)) {
            setSelectedData(rowData.data)
            form.setFieldsValue({ dtStart: moment(rowData.data.dtStart) });
            form.setFieldsValue({ departure: rowData.data.departure });
            form.setFieldsValue({ destination: rowData.data.destination });
            form.setFieldsValue({ quote: rowData.data.quote });
            form.setFieldsValue({ descr: rowData.data.descr });
            if (rowData.data.driver && rowData.type === 'copy') {
                form.setFieldsValue({ carModel: "" });
                form.setFieldsValue({ name: "" });
                form.setFieldsValue({ phone: "" });
                form.setFieldsValue({ licensePlate: "" });
            }
        }
        setIsModalOpen(true);
    }
    const handleOk = () => {
        form.validateFields().then((values) => {
            SaveTrip(values)
        })
    };
    const SaveTrip = (values) => {
        let params = {
            departure: values.departure,
            destination: values.destination,
            quote: values.quote,
            dtStart: moment(values.dtStart).format('YYYY-MM-DD HH:mm'),
            dtEnd: "",
            bussinessId: props.miceInfo.itemId,
            orderId: props.miceInfo.orderId,
            startLngLat: [],
            endLngLat: [],
            destCity: values.sCity,
            descr: values.descr,
            end_type: "",
            isSupplier: true
        };
        if (['edit'].includes(editType)) {
            params.requestId = selectedData.requestId
        }
        setLoading(true)
        commonService.SaveTrip(params).then(res => {
            if (res && res.success) {
                if (!isDispatch) {
                    message.success(['edit'].includes(editType) ? "修改成功" : '新增成功')
                    props.updateChild()
                    setIsModalOpen(false);
                    setLoading(false)
                } else {
                    SaveDrivers(values, res.content)
                }
            }else {
                setLoading(false);
            }
        })
    }
    const SaveDrivers = (values, requestId) => {
        let params = {
            requestId: requestId,
            orderId: props.miceInfo.orderId,
            name: values.name,
            phone: values.phone,
            carModel: values.carModel,
            licensePlate: values.licensePlate
        };
        commonService.SaveDrivers(params).then(res => {
            setLoading(false)
            if (res && res.success) {
                message.success(['edit'].includes(editType) ? "修改明细成功" : '新增明细成功')
                props.updateChild()
                setIsModalOpen(false);
            }
        })
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));

    const selectMap = (sourceKey, sourceCity) => {
        mapEditor.current.show({
            sourceKey:sourceKey,
            sourceCity: sourceCity
        })
        // 回显
        const target = form.getFieldsValue()[sourceKey]
        mapEditor.current.setKeyword(target);
    }
    const updateForm = (data) => {
        for (let val in data) {
            form.setFieldValue(val, data[val])
        }
    }
    return (
        <Modal title="行程" width={800} maskClosable={false} open={isModalOpen} onCancel={handleCancel} footer={[
            <Button loading={loading} onClick={handleCancel}> 取消 </Button>,
            <Button type="primary" loading={loading} onClick={handleOk}> 保存 </Button>,
        ]}>
            <Row gutter={[16, 24]}>
                <Form form={form} component={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left">
                    <Col span={12}>
                        <Form.Item name='dtStart' label='用车时间' style={{ margin: 0 }} rules={[ { required: true, message: '请选择用车时间', }, ]}>
                            <DatePicker placeholder="请选择用车时间" style={{ width: '100%' }} format="YYYY-MM-DD HH:mm:ss" showTime/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='出发地' style={{ margin: 0 }} required>
                            <Input.Group compact style={{width: '100%'}}>
                                <Form.Item name='departure' style={{ margin: 0, width: 'calc(100% - 32px)' }} rules={[ { required: true, message: '请选择出发地', }, ]}>
                                    <Input style={{ width: '100%' }} placeholder="请选择出发地" disabled/>
                                </Form.Item>
                                <Button onClick={() => selectMap('departure', 'sCity')} icon={<EnvironmentOutlined />} />
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='目的地' style={{ margin: 0 }} required>
                            <Input.Group compact style={{width: '100%'}}>
                                <Form.Item name='destination' style={{ margin: 0, width: 'calc(100% - 32px)' }} rules={[ { required: true, message: '请选择目的地', }, ]}>
                                    <Input style={{ width: '100%' }} placeholder="请选择目的地" disabled/>
                                </Form.Item>
                                <Button onClick={() => selectMap('destination', 'eCity')} icon={<EnvironmentOutlined />} />
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='quote' label='报价金额' style={{ margin: 0 }} rules={[ { required: true, message: '请输入报价金额', }, ]}>
                            <InputNumber style={{width: '100%'}} min={0} placeholder="请输入报价金额"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='descr' label='备注' style={{ margin: 0 }} labelCol={{span: 3}} wrapperCol={{span: 19}} rules={[ { required: false, message: '请填写备注', }, ]}>
                            <TextArea rows={4} style={{width: '100%'}} placeholder="请填写备注"/>
                        </Form.Item>
                    </Col>
                    {isDispatch && (<>
                        <Divider dashed />
                        <p className="driversInfo">司机信息</p>
                        <Col span={12}>
                            <Form.Item name='name' label='司机姓名' style={{ margin: 0 }} rules={[ { required: true, message: '请输入司机姓名', }, ]}>
                                <Input style={{ width: '100%' }} placeholder="请输入司机姓名"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='phone' label='联系方式' style={{ margin: 0 }} rules={[ { required: true, message: '请输入联系方式', }, ]}>
                                <Input style={{ width: '100%' }} placeholder="请输入联系方式"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='carModel' label='车辆型号' style={{ margin: 0 }} rules={[ { required: true, message: '请输入车辆型号', }, ]}>
                                <Input style={{ width: '100%' }} placeholder="请输入车辆型号"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='licensePlate' label='车牌号' style={{ margin: 0 }} rules={[ { required: true, message: '请输入车牌号', }, ]}>
                                <Input style={{ width: '100%' }} placeholder="请输入车牌号"/>
                            </Form.Item>
                        </Col>
                    </>)}
                </Form>
            </Row>
            <MapEditor ref={mapEditor} updateForm={updateForm}></MapEditor>
        </Modal>
    );
})
export default EditItinerary
