import React from "react";
import { Form, Col, InputNumber } from "antd";
import defaultSettings from "./defaultSettings";

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const xNumberInput = (props) => {
  const item = props.item;

  function defaultChangeClick(e, item, sData) {
    console.log(e, item, sData);
    props.callback && props.callback(e, item, sData);
  }

  return (
    <>
      <Col span={item.span}>
        <Form.Item
          name={item.name}
          rules={item.rules}
          labelCol={{ span: item.lcol ? item.lcol : defaultLabelSpan }}
          wrapperCol={{ span: item.wcol ? item.wcol : defaultWrapperSpan }}
          label={item.label}
          labelAlign="right"
        >
          <InputNumber
            style={{ width: "100%" }}
            disabled={item.disabled && true}
            min={item.min}
            max={item.max || 100000000000000000}
            controls={item.controls}
            precision={item.precision}
            formatter={item.formatter}
            placeholder={item.placeholder}
            onChange={(e) => defaultChangeClick(e, item)}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default xNumberInput;
