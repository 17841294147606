/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useMemo } from "react";
import { Empty, Space, Spin } from "antd";
import jsxCustomEvent from "@micro-zoe/micro-app/polyfill/jsx-custom-event";
import { withRouter } from "react-router-dom";
import commonService from "@/service/common";
import { getCookie } from "tiny-cookie";

const ShowMicroApp = (props) => {
  // 解构
  console.log(props.requestVal);
  const { microData, requestVal, status } = props;
  const [microObj, setMicroObj] = useState({});
  const [appError, setAppError] = useState(false);
  const [drawerLoading, setDrawerLoading] = useState(true);
  // 通过接口获取token
  const getAccessToken = () => {
    let params = {
      client_id: "internal.passportless",

      client_secret: "smartx",

      grant_type: "passwordless",

      userName: requestVal.loginAccount,
    };
    // if (requestVal.tenantCode === "roche") {
    //   commonService.getRocheToken(params).then((res) => {
    //     if (res.success) {
    //       setMicroObj(() => {
    //         return {
    //           ...requestVal,
    //           ...{ microToken: res.content.access_token },
    //         };
    //       });
    //     }
    //   });
    // } else {
    commonService.getToken(params).then((res) => {
      if (res.success) {
        setMicroObj(() => {
          return {
            ...requestVal,
            ...{ microToken: res.content.access_token },
          };
        });
      }
    });
    // }
  };
  // 针对对象监听
  const obj = useMemo(() => ({ ...requestVal }), [requestVal]);

  useEffect(() => {
    if (props.pageType === "allEventLibrary") {
      getAccessToken();
    } else {
      setMicroObj(() => {
        return {
          ...requestVal,
          ...{ microToken: getCookie("x_token") },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj]);
  // micro渲染出错
  const microAppError = () => {
    setAppError(true);
    setDrawerLoading(false);
  };
  // micro 创建
  const microAppCreate = () => {};
  // micro 即将被创建回调
  const microAppBeforemount = () => {
    setDrawerLoading(true);
  };
  // micro 已经被渲染完成
  const microAppMounted = () => {
    setDrawerLoading(false);
  };
  // micro 已经被卸载
  const microUnmount = () => {
    setAppError(false);
    setDrawerLoading(true);
    setMicroObj({});
  };
  return (
    <>
      {status && JSON.stringify(microObj) !== "{}" ? (
        <micro-app
          name={microData.name}
          url={microData.http}
          baseroute={microData.baseRouter}
          data={microObj}
          onCreated={microAppCreate}
          onBeforemount={microAppBeforemount}
          onMounted={microAppMounted}
          onUnmount={microUnmount}
          onError={microAppError}
          destroy
        ></micro-app>
      ) : null}
      {drawerLoading && (
        <Space>
          <Spin tip="数据加载中..." size="large">
            <div className="content" />
          </Spin>
        </Space>
      )}
      {appError && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="项目集成中，请耐心等待..."
        />
      )}
    </>
  );
};

export default withRouter(ShowMicroApp);
