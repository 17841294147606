import React, {useEffect, useRef, useState} from "react";
import { getCookie } from "tiny-cookie";
import './CarInfoCard.less'
import {Button, message, Modal, Popconfirm, Table, Typography} from "antd";
import {SmartStorage} from "@/utils";
import EditItinerary from "@/components/DetailDrawer/compments/CarSupplierModules/EditItinerary";
import ItinerarySettlement from "@/components/DetailDrawer/compments/CarSupplierModules/ItinerarySettlement";
import ItinerarySettlementDetails from "@/components/DetailDrawer/compments/CarSupplierModules/ItinerarySettlementDetails";
import Drivers from "@/components/DetailDrawer/compments/CarSupplierModules/Drivers";
import moment from "moment";
import commonService from "@/service/common";
const TravelDetails = (props) => {
    const editItinerary = useRef(null);
    const itinerarySettlement = useRef(null);
    const itinerarySettlementDetails = useRef(null);
    const drivers = useRef(null);
    const [taskInfo, setTaskInfo] = useState({});
    const [dataList, setDataList] = useState([]);
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setTaskInfo(props?.miceInfo)
        props?.miceInfo?.trips && props?.miceInfo?.trips.map(ele => {
            ele.dtStart = moment(ele.dtStart).format('YYYY-MM-DD HH:mm')
        })
        setDataList(props?.miceInfo?.trips?.map(i => {
            console.log(i);
            if(i.settlePiece) {
                return {
                    ...i,
                    settlePiece: {
                        ...i.settlePiece,
                        departure: i.settlePiece.departure === 'null' ? '' : i.settlePiece.departure,
                        destination: i.settlePiece.destination === 'null' ? '' : i.settlePiece.destination,
                    }
                }
            }else {
                return i
            }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    useEffect(() => {
        getTableColumn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const columnsKey = [
        { title: '序号', dataIndex: 'index', key: 'index',
            render: (text, record, index) => ( index + 1)
        },
    ];
    const columnsBtn = [
        { title: '操作', dataIndex: 'buttons', key: 'buttons',
            render: (buttons, record) => (
                <>
                    {buttons && buttons.map((ele, index) => (
                        ['warn'].includes(ele.css) ? (
                            <Typography.Link key={index} onClick={() => btnClick(ele.processor, record)} style={{ marginRight: 8, color: 'red', display: "inline-block"}}>
                                {ele.txt}
                            </Typography.Link>
                        ) : (
                            <Typography.Link key={index} onClick={() => btnClick(ele.processor, record)} style={{ marginRight: 8, display: "inline-block"}}>
                                {ele.txt}
                            </Typography.Link>
                        )
                    ))}
                </>
            )
        },
    ];
    const btnClick = (btnName, record) => {
        const actions = {
            quote: editInfo,
            dispatch: editInfo,
            settle: editSettleInfo,
            editSettle: editSettle,
            cancel: remove,
            copy: copyInfo,
            settleDetail: (rec) => editSettle(rec, 'look'),
            carDetail: carDetail,
            insure: insuranceApply
        };
        console.log(btnName);
        const action = actions[btnName];
        if (action) {
            action(record);
        } else {
            console.warn(`Action '${btnName}' not found`);
        }
    }
    const carDetail = (record) => {
        drivers.current.showModal(record)
    }
    const editInfo = (record) => {
        editItinerary.current.showModal({type: 'edit', data: record})
    }
    const copyInfo = (record) => {
        editItinerary.current.showModal({type: 'copy', data: record})
    }
    const editSettleInfo = (record) => {
        itinerarySettlement.current.showModal({
            departure: record.departure,
            destination: record.destination,
            requestId: record.requestId,
            expenseId: record.expenseId,
            orderId: record.orderId,
            pieceId: record.pieceId,
            licensePlate: record?.driver?.licensePlate,
            carModel: record?.driver?.carModel
        }, record)
    }
    const editSettle = (record, type) => {
        let params = {
            requestId: record.requestId
        }
        commonService.SettleDetail(params).then((res) => {
            if (res && res.success) {
                if (['look'].includes(type)) {
                    itinerarySettlementDetails.current.showModal(res.content, record)
                } else {
                    itinerarySettlement.current.showModal(res.content, record)
                }
            }
        });
    }
    const remove = (record) => {
        Modal['confirm']({
            title: "取消行程确认",
            okText: "确认",
            cancelText: "我再想想",
            content: "请确认已经与用户确认此行程要取消！\n" +
                "\n" +
                "注意行程取消后将不可再恢复。",
            onOk() {
                let params = {
                    requestId: record.requestId,
                    isSupplier: true
                }
                commonService.CancelTrip(params).then((res) => {
                    if (res && res.success) {
                        message.success("取消成功")
                        props.updateChild()
                    }
                });
            },
            onCancel() {},
        });

    }
    const insuranceApply = (record) => {
        Modal['confirm']({
            title: "购买保险确认",
            okText: "确认",
            cancelText: "我再想想",
            content: "请确认您要手动触发此行程的保险下单，一旦确认后系统将自动购买保险。",
            onOk() {
                let params = {
                    tripId: record.requestId
                }
                commonService.insuranceApply(params).then((res) => {
                    if (res && res.success && res.code !== '1') {
                        message.success("购买成功")
                        props.updateChild()
                    }else {
                        message.error(res.message)
                    }
                });
            },
            onCancel() {},
        });
    }
    const addBilling = () => {
        editItinerary.current.showModal({type: 'add'})
    }
    const updateChild = () => {
        props.updateChild()
    }
    const getTableColumn = () => {
        let params = {
            collection: "cfg-web-tableColumn",
            filter: {
                Application: "XCENTER",
                tenantId: "*",
                type: {
                    $in: [SmartStorage.get("detailRow").nodeCode || '*'],
                },
                isNew: SmartStorage.get("detailRow").isNew,
                abbrCode: {
                    $in: [SmartStorage.get("detailRow").nodeCode || '*'],
                },
            },
        }
        commonService.GetSettings(params).then((res) => {
            if (res && res.success) {
                console.log([...columnsKey, ...res.content.column, ...columnsBtn]);
                setColumns([...columnsKey, ...res.content.column, ...columnsBtn])
            }
        });
    }
    return (<div className="boderRow carDetails">
        <div className="carTitle">
            <div className="card_title">{props.moduleItem?.queryName}</div>
            <div className="BtnList">
                {[10, '10', 35, '35'].includes(SmartStorage.get("detailRow").status) && (<Button type="primary" style={{marginLeft: '20px'}} onClick={addBilling}>添加行程</Button>)}
            </div>
        </div>
        <Table columns={columns} bordered dataSource={dataList} pagination={false} />
        {[10, '10', 35, '35'].includes(SmartStorage.get("detailRow").status) ? (
            <h2 className="amount">报价总金额：<span>{taskInfo.quote}</span></h2>
        ) : (
            <h2 className="amount">结算总金额：<span>{taskInfo.settle}</span></h2>
        )}
        <EditItinerary ref={editItinerary} miceInfo={taskInfo} updateChild={updateChild}></EditItinerary>
        <ItinerarySettlement ref={itinerarySettlement} miceInfo={taskInfo} updateChild={updateChild}></ItinerarySettlement>
        <ItinerarySettlementDetails ref={itinerarySettlementDetails} miceInfo={taskInfo}></ItinerarySettlementDetails>
        <Drivers ref={drivers}></Drivers>
    </div>)
}

export default TravelDetails;
