import React, { useEffect, useState } from "react";
import { Form, Col, Select } from "antd";
import defaultSettings from "./defaultSettings";
import commonService from "@/service/common";
import { SmartStorage } from "../../utils";
import _ from "lodash";

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const { Option } = Select;

const XSelectQ = (props) => {
  const item = props.item;
  const [options, setOptions] = useState(
    typeof item.dataSource === "string"
      ? JSON.parse(item.dataSource)
      : item.dataSource || []
  );
  const [loading, setLoading] = useState(false);

  const defaultChangeClick = (e, item, sData) => {
    // let citem = props.itemList.find((citem) => citem.cascadeName == item.name);
    // citem && props.formInfo.setFieldsValue({ [citem.name]: undefined });
    // console.log(sData);
    let optionData = null;
    if (sData && sData.constructor === Array) {
      // 多选时，此处应该是返回数组对象
      optionData = sData.map((v) => v.key);
    } else {
      // 单选，参数返回对象
      if (sData) {
        const filteredArr = options.filter(
          (v) => (v.Val || v.Id || v.value || v.Value) === sData.value
        );
        optionData = Object.assign(filteredArr[0] || {}, sData);
      } else {
        optionData = [];
      }
    }
    // console.log(optionData, "option");
    // let a = {};
    // a[item.name] = optionData;
    // console.log(a, "a");
    // props.formInfo.setFieldsValue(a);
    item.callBack && props.callback(e, item, optionData);
  };
  // 下拉框展开回调
  const onDropdownVisibleChange = (open) => {
    if (open && item.searchKeyName) {
      getOptions(item.dataSource);
    }
  };

  const getOptions = async (source, searchKey) => {
    //setOptions置空会造成页面select加载下拉框没有数据，先注释，回调功能具体用法根据业务再做调整
    setOptions([]);
    if (source.method && source.url) {
      let params = source.params || {};
      let method = source.method;
      let url = source.url;
      if (searchKey) {
        // params.filter[item.searchKeyName] = "/" + searchKey + "/";
        params.filter[item.searchKeyName] = {
          $regex: searchKey,
          $options: "i",
        }; // 模糊搜索不区分大小写
      } else {
        params.filter && delete params.filter[item.searchKeyName];
      }

      setLoading(true);
      let res = await commonService.common(method, url, params);
      setLoading(false);
      if (res && res.success && res.content) {
        let arr = res.content.map((v) => {
          if (v.Txt) {
            return v;
          } else {
            return { ...v, Txt: v.dspName, Val: v.userId };
          }
        });
        item.name === "visitedBy" && SmartStorage.set("visitedBy", arr);

        if (arr.length === 0) {
          arr.push({ Txt: "暂无", Val: "暂无" });
        } else if (item.name === "subOrgName") {
          arr.unshift({
            Val: "none",
            Txt: "暂无",
          });
        }
        if (item.optionName) {
          setOptions(arr[0][item.optionName]);
        } else {
          setOptions(arr);
        }
      }
    }
  };
  const dataValidateFields = (queryId) => {
    setOptions([]);
    let parent = "";
    if (item.cascadeName) {
      props.formInfo.validateFields().then((values) => {
        parent = values[item.cascadeName];
        console.log(!item.cascadeName);
        if ((item.cascadeName && parent) || !item.cascadeName) {
          getQueryOptions(queryId, { parent: parent });
        }
      });
    } else {
      getQueryOptions(queryId);
    }
  };
  const getQueryOptions = async (queryId, qParams) => {
    let params = {
      UsePaging: false,
      QueryId: queryId,
      parameters: {},
      cluster: item.cluster || "api",
    };
    params.parameters = qParams || {};
    setLoading(true);
    await commonService.getQueryDataX(params).then((res) => {
      setLoading(false);
      if (res && res.success) {
        let index = res.content.rows.findIndex((item) => item.value === "?");
        if (index !== -1) {
          res.content.rows.splice(index, 1);
        }
        setOptions(res.content.rows);
        if (item.defaultValue) {
          res.content.rows.forEach((element) => {
            if (element.name == item.defaultValue) {
              props.formInfo.setFieldsValue({ [item.name]: element.value });
            }
          });
        }
      }
      })
   
  };
  const handleSearch = (val) => {
    let data = [];
    options &&
      options.forEach((r) => {
        r && r.name && r.name.indexOf(val) > -1 && data.push(r);
      });
    console.log(data, val);
    if (data.length > 0 && val) {
      setOptions(data);
    } else {
      getQueryOptions(item.queryId);
    }
  };
  useEffect(() => {
    if (item.queryId) {
      getQueryOptions(item.queryId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {item.hidden !== true && (
        <Col span={item.span}>
          <Form.Item
            name={item.name}
            label={item.label}
            rules={item.rules}
            hidden={item.hidden}
            labelAlign="right"
          >
            {item.queryId ? (
              <Select
                style={{ width: "100%" }}
                mode={item.mode || "default"}
                loading={loading}
                showArrow={true}
                labelInValue={item.labelInValue}
                allowClear={!item.allowClear}
                placeholder={
                  item.placeholder.indexOf("请选择") > -1
                    ? item.placeholder
                    : `请选择${item.placeholder}`
                }
                maxTagCount="responsive"
                showSearch={true}
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                defaultValue={item.defaultValue}
                filterOption={(inputValue, option) =>
                  option.children &&
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
                onFocus={() => {
                  dataValidateFields(item.queryId);
                }}
                onDropdownVisibleChange={onDropdownVisibleChange}
                onSearch={(e) => handleSearch(e)}
                onChange={(e, sData) => defaultChangeClick(e, item, sData)}
              >
                {options &&
                  options.map((obj, index) => {
                    return (
                      <Option key={obj.value} value={obj.value}>
                        {obj.name}
                      </Option>
                    );
                  })}
              </Select>
            ) : (
              <Select
                style={{ width: "100%" }}
                mode={item.mode || "default"}
                loading={loading}
                disabled={item.disabled && true}
                labelInValue={item.labelInValue}
                allowClear={!item.allowClear}
                placeholder={item.placeholder}
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                defaultValue={item.defaultValue}
                filterOption={(inputValue, option) =>
                  option.children &&
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
                onFocus={() => {
                  props.onFocus && props.onFocus({ item: item });
                  if (item.name === "subOrgName") {
                    getOptions(item.dataSource);
                  }
                }}
                onDropdownVisibleChange={onDropdownVisibleChange}
                onChange={(e, sData) => defaultChangeClick(e, item, sData)}
              >
                {item.dataSource &&
                  item.dataSource.map((obj, index) => {
                    return (
                      <Option key={obj.val} value={obj.val}>
                        {obj.txt}
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
          {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
        </Col>
      )}
    </>
  );
};

export default XSelectQ;
