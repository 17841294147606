import React, { useEffect, useState } from "react";
import commonService from "@/service/common";
import { Row, Col, Descriptions, Button, message } from "antd";
import "../MopModules/modules.less";
import {SmartStorage} from "@/utils";

const InfoCard = (props) => {
  const [taskInfo, setTaskInfo] = useState({});

  const getQueryData = () => {
    let queryParams = props.moduleItem?.queryParams;
    if (queryParams.parameters) {
      let obj = {};
      for (let key in queryParams.parameters) {
        obj[key] = props.requestVal[queryParams.parameters[key]];
      }
      queryParams.parameters = obj;
    }
    commonService.getQueryData(queryParams).then((res) => {
      if (res && res.success) {
        if (res.content.rows.length > 0) {
          setTaskInfo(res.content.rows[0]);
          // 混入会议类型 自办会还是协办会
          const obj = SmartStorage.get("detailRow");
          SmartStorage.set("detailRow", {...obj, actionType: res.content.rows[0].actionType});
        }
      }
    });
  };
  const copyValue = (value, key, additional) => {
    if(key === 'tuName') {
      if(value.indexOf('(') > 0) {
        value = value.substring(0, value.indexOf('('));
        navigator.clipboard.writeText(value + '(' + additional + ')');
      }
    }else {
      navigator.clipboard.writeText(value);
    }
    message.success("复制成功");
  }
  useEffect(() => {
    getQueryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {JSON.stringify(taskInfo) !== "{}" && (
        <div className="boderRow">
          <Row className="rowStyle">
            <Col className="borderCol">{taskInfo.actionType}</Col>
            <Col className="borderCol">{taskInfo.statusTxt}</Col>
            <Col className="title">{taskInfo.name}</Col>
          </Row>
          <Row>
            <Col span={24} className="Num">
              {taskInfo.poNum}
            </Col>
          </Row>
          <Row className="info_center">
            <Col span={24}>
              <Descriptions column={3}>
                <Descriptions.Item label="客户编号">
                  {taskInfo.extNum}<Button type="link" onClick={() => copyValue(taskInfo.extNum)}>复制</Button>
                </Descriptions.Item>
                <Descriptions.Item label="中智团号" >
                  {taskInfo.groupNum} <Button type="link" onClick={() => copyValue(taskInfo.groupNum)}>复制</Button>
                </Descriptions.Item>
                <Descriptions.Item label="会议城市">
                  {taskInfo.city}<Button type="link" onClick={() => copyValue(taskInfo.city)}>复制</Button>
                </Descriptions.Item>
                <Descriptions.Item label="是否审批">
                  {taskInfo.hasApproved}
                </Descriptions.Item>
                <Descriptions.Item label="人数">
                  {taskInfo.qtyAttender}
                </Descriptions.Item>
                <Descriptions.Item label="会议日期">
                  {taskInfo.eventDate}
                </Descriptions.Item>
                <Descriptions.Item label="办会人">
                  {taskInfo.tuName} <Button type="link" onClick={() => copyValue(taskInfo.tuName, 'tuName', taskInfo.tuPhoneNumber)}>复制</Button>
                </Descriptions.Item>
                <Descriptions.Item label="办会人电话">
                  {taskInfo.tuPhoneNumber} <Button type="link" onClick={() => copyValue(taskInfo.tuPhoneNumber)}>复制</Button>
                </Descriptions.Item>
                <Descriptions.Item label="办会人邮箱">
                  {taskInfo.tuEmail}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={1}>
                <Descriptions.Item label="协助者信息">
                  {taskInfo.assistantInfo}
                </Descriptions.Item>
                <Descriptions.Item label="备注">
                  {taskInfo.descr}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default InfoCard;
