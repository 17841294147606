import React, { useEffect, useState } from 'react';
import { Button, Alert, Spin, Modal } from 'antd';
import { FullscreenOutlined } from "@ant-design/icons";

function KKfileViewerModal(props) {
    const {
        isModalOpen,
        closeModal,
        iframeUrl
    } = props;
    const [loding, setLoding] = useState(false);

    useEffect(() => {

        if (isModalOpen) {
            setTimeout(() => {
                const ele = document.getElementById('myIframe')
                const iframeEle = ele instanceof HTMLIFrameElement ? ele : null;
                if (iframeEle.contentWindow && iframeEle.contentWindow.location) {
                    iframeEle.contentWindow.location.href = iframeUrl;
                }
                if (ele.contentDocument && ele.contentDocument.readyState === 'complete') {
                    console.log('iframe加载完成')
                    setLoding(false)
                }
            }, 100)

        }
    }, [isModalOpen]);
    const toggleFullScreen = () => {
        const element = document.querySelector('#myIframe');
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
        }
    }
    return (
        <>
            <Modal
                title="预览"
                width="90%"
                className="modalPreview"
                open={isModalOpen}
                onCancel={() => { closeModal() }}
                footer={[
                ]}>
                {
                    loding && <div className="loadMore"><Spin tip="加载中...">
                        <Alert
                            message="请稍等"
                            description="文件加载中，请稍等"
                            type="info"
                        />
                    </Spin></div>
                }
                <Button type="text" className="fullScreenBtn" onClick={() => { toggleFullScreen() }}>{<FullscreenOutlined />}</Button>
                <iframe id="myIframe" onLoad={() => { setLoding(false) }} width='100%' height='800px' url={iframeUrl} />
            </Modal>
        </>
    );
}

export default KKfileViewerModal;
