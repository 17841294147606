/* eslint-disable */

import React from 'react';
import ReactDom from 'react-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'antd/dist/antd.less';
import './assets/css/reset.less';
import './assets/css/common.less';
import store from './redux/store.js';
import { Provider } from 'react-redux';
import RootRouter from './router/root-router.jsx';
import { getCookie } from 'tiny-cookie';
import './utils/alife-logger.js';
import publicMethod from './utils/public.js';
// micro-app 微应用
import microApp from '@micro-zoe/micro-app';
import moment from 'moment';
import 'moment/locale/zh-cn';
// 引入乾坤
import '@/config/index';
moment.locale('zh-cn');
// 为angular微应用引入
if (!window.__MICRO_APP_BASE_ROUTE__) {
    require('zone.js');
}

const consoleError = console.error;

console.error = function (...args) {
    const message = args.join(' ');
    if (
        typeof message === 'string' &&
        message.startsWith('Warning: ') &&
        !message.includes('Warning: React does not recognize the `Component`')
    ) {
        return;
    }
    consoleError.apply(console, args);
};

microApp.start({
    destroy: true,
});
// import OAuth2Refresh from "./controllers/oauth2Refresh";
try {
    // 测试账号到DevToken.js中修改
    console.log('====运行环境====', devToken);
    if (devToken) {
        document.cookie = `token=${
            JSON.parse(devToken).content.access_token
        }; path=/`;
        document.cookie = `role_codes=${
            JSON.parse(devToken).content.role_codes
        }; path=/`;
        document.cookie = `userId=${
            JSON.parse(devToken).content.userId
        }; path=/`;
    }
} catch (error) {}

const token = getCookie('x_token') || getCookie('token');

if (!token && window.location.search.indexOf('sessionId') === -1) {
    publicMethod.deleteLocalData();
}
publicMethod.appendSession();
// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

ReactDom.render(
    <>
        {
            // token ?
            <ConfigProvider locale={zhCN}>
                <Provider store={store}>
                    <RootRouter />
                </Provider>
            </ConfigProvider>
            //  : <div style={{padding: "20px"}}><Spin indicator={antIcon} /></div>
        }
    </>,
    document.getElementById('root'),
);
