import React, {useEffect, useRef, useState} from "react";
import commonService from "@/service/common";
import "../../MopModules/modules.less";
import SupplierItem from "./SupplierItem";
import QuoteTerms from "@/components/DetailDrawer/compments/MopModules/HoteDemand/QuoteTerms";
import {Spin, Alert} from "antd";

const HoteDemandItem = (props) => {
    const [supplierInfo, setSupplierInfo] = useState([]);
    const [isQuote, setIsQuote] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const quoteTerms = useRef(null);
    useEffect(() => {
        setIsQuote(props.listItem.canEditOrder)
        GetF1QuotedPriceTaskList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const GetF1QuotedPriceTaskList = () => {
        let params = {
            miceId: props.requestVal.processId,
            orderId: props.listItem.orderId,
        }
        commonService.GetF1QuotedPriceTaskList(params).then(res => {
            if (res && res.success) {
                QueryVenueItem(res.content)
                quoteTerms.current && quoteTerms.current.queryClauses()
            }
        })
    }
    const QueryVenueItem = (supplierInfo) => {
        let params = {
            miceId: props.requestVal.processId,
            orderId: props.listItem.orderId,
        }
        setIsLoading(true)
        commonService.QueryVenueItem(params).then(res => {
            if (res && res.success) {
                const dataArr = ['venues', 'meals', 'rooms', 'others'];
                let supplier = res.content;
                supplier.forEach(ele => {
                    ele.supplierInfo = supplierInfo.filter((li) => {
                        if (props.listItem.orderId) {
                            return li.supplierId === ele.supplierId && props.listItem.orderId === li.orderId
                        }
                        return li.supplierId === ele.supplierId
                    })[0];
                    ele.processId = props.requestVal.processId;
                    ele.orderId = props.listItem.orderId;
                    ele.items.forEach(li => {
                        li.expand = true;
                        ele[dataArr[li.itemType - 1]] = ele[dataArr[li.itemType - 1]] || [];
                        ele[dataArr[li.itemType - 1]].push(li);
                    });
                    ele.isEdit = !(ele.supplierStatus >= 30 || [11, 12].includes(ele.supplierStatus))
                });
                setSupplierInfo(supplier)
                setIsLoading(false)
            }
        })
    }
    return (
        <>
            <div className="boderRow">
                {isLoading && (<Spin tip="Loading...">
                    <Alert type="info" />
                </Spin>)}
                {supplierInfo.length > 0 && supplierInfo.map((item, index) => (
                    <SupplierItem key={index}  listItem={item} taskId={props.requestVal.taskId} isQuote={isQuote && item.isEdit} GetF1QuotedPriceTaskList={GetF1QuotedPriceTaskList}/>
                ))}
            </div>
            <div className="boderRow">
                <QuoteTerms ref={quoteTerms} requestVal={props.requestVal} listItem={props.listItem} isQuote={isQuote}></QuoteTerms>
            </div>
        </>
    );
};

export default HoteDemandItem;
