import { UPDATEURL, UPDATEMENUTITLE } from './actionsType';

const updateMenuTitle = (data) => ({ type: UPDATEMENUTITLE, data });
const updateUrl = (data) => ({ type: UPDATEURL, data });

const asyncUpdateMenuTitle = (data) => {
    return (dispatch, getState) => {
        setTimeout(()=>{
            dispatch(updateMenuTitle(data))
        },2000)
    }
}

export {
    asyncUpdateMenuTitle,
    updateMenuTitle,
    updateUrl
};

