import React from "react";
import styles from "./meetingOpsCenter.module.less";

const MeetingOperation = () => {
  const microUrl = process.env.REACT_APP_MOP_URL;
  return (
    <div className={styles.orderContainer}>
      <micro-app
        name="meetingOperation"
        url={microUrl}
        baseroute="/micro-apps"
      ></micro-app>
    </div>
  );
};

export default MeetingOperation;
