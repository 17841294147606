import React, { useState, useEffect } from 'react';
import SmartQueryReact from 'smart-iquery-react';
import { useGeneric } from '@/controllers/useGeneric';
import styles from './channel.module.less';

const ChannelQuery = (props) => {
    const { moduleItem, requestVal } = props;
    const iSignalr = process.env.REACT_APP_SIGNALER;
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [queryData, setQueryData] = useState(null);
    const { replaceEvalObject } = useGeneric();

    const getqueryData = (params) => {
        let replaceParams = replaceEvalObject(moduleItem, params);
        console.log(replaceParams.queryParams, 'replaceParams');

        setQueryData([replaceParams['queryParams']]);
    };
    const returnSelectedRows = (rowsId, rows) => {};
    const rowMenuHandleClick = (e, record, item) => {};
    const headerBtnHandleClick = (btn, item, items) => {
        console.log('headerBtnHandleClick');
    };
    useEffect(() => {
        getqueryData(requestVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {queryData && (
                <div>
                    <div className={styles.title}>
                        {props.moduleItem?.title}
                    </div>
                    <SmartQueryReact
                        gatewayPath={gatewayPath}
                        iSignalr={iSignalr}
                        getQueryData={queryData}
                        returnSelectedRows={returnSelectedRows}
                        rowMenuHandleClick={rowMenuHandleClick}
                        headerBtnHandleClick={headerBtnHandleClick}
                    ></SmartQueryReact>
                </div>
            )}
        </>
    );
};

export default ChannelQuery;
