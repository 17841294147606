import React, { useEffect, useState } from "react";
import "./index.less";
import iSignalr from "../../controllers/smart-signalr";
var GMV_Amount = 0;
const GMVNotification = () => {
  const [amount, setAmount] = useState(0);
  const [count, setCount] = useState(0);
  const [clock, setClock] = useState(0);
  var refreshHub = new iSignalr({
    hubName: "SmartHub",
    queryParams: "group=EventWorld-X-Notification",
    listeners: [
      {
        methodName: "GMV-Notification",
        method: function (data) {
          console.log(data);
          if (GMV_Amount < data.GMV_Amount) {
            numFun(GMV_Amount, Number(data.GMV_Amount));
            GMV_Amount = Number(data.GMV_Amount);
          }
          setCount(data.Total_Event);
          let arr1 = data.clock.split(" ");
          let time = arr1[1].split(":");
          let mou = arr1[0].split("-")[1];
          let day = arr1[0].split("-")[2];
          time.unshift(day);
          time.unshift(mou);
          time.pop();
          setClock(time);
        },
      },
    ],
    host: process.env.REACT_APP_SIGNALER,
    // host: 'https://signalr.smartmice.cn'
  });
  const componentWillUnmount = () => {
    refreshHub.uninstall();
  };
  useEffect(() => {
    refreshHub.install();
    return componentWillUnmount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numFun = (startNum, maxNum) => {
    let numText = startNum;
    let golb; // 为了清除requestAnimationFrame
    function numSlideFun() {
      // 数字动画
      numText += 5; // 速度的计算可以为小数 。数字越大，滚动越快
      if (startNum === 0) {
        numText += 200000;
      } else {
        numText += 10;
      }
      if (numText >= maxNum) {
        numText = maxNum;
        cancelAnimationFrame(golb);
      } else {
        golb = requestAnimationFrame(numSlideFun);
      }
      setAmount(numText.toLocaleString("en-US"));
    }
    numSlideFun(); // 调用数字动画
  };
  useEffect(() => {
    let obj = "2023-03-30 16:55:05";
    let arr1 = obj.split(" ");
    let time = arr1[1].split(":");
    let mou = arr1[0].split("-")[1];
    let day = arr1[0].split("-")[2];
    time.unshift(day);
    time.unshift(mou);
    time.pop();
    console.log(time);
    setClock(time);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="GMVNotificationBox">
      {/* <img className="bgImg" src="https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/gmv2.jpg"></img> */}
      <img
        className="bgImg"
        src="https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/GMVbg.jpg"
        alt=""
      ></img>
      <div className="countBox gmvAmountBox">
        <span>¥</span>
        {amount}
      </div>
      <div className="countBox gmvCountBox">
        {count}
        <span>场</span>
      </div>
      <div className="countBox clockBox">
        {clock &&
          clock.map((item, index) => (
            <div>
              {item && [...item].map((font, index1) => <span>{font}</span>)}
              <p>
                {index === 0
                  ? "月"
                  : index === 1
                  ? "日"
                  : index === 2
                  ? "时"
                  : "分"}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};
export default GMVNotification;
