import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import {Form, Button, Modal, Cascader, message} from 'antd'
import commonService from "@/service/common";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/**
 * 编辑酒店审核关键词
 * author: Lt.Ethan Cho, on Mar.14 2024
 */
const CityAuditEdit = forwardRef((props, ref) => {
    // 省市区
    const [regionList, setRegionList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [RowData, setRowData] = useState({});
    const [formInstance] = Form.useForm();
    useEffect(() => {
        getRegions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onCancel = () => {
        setVisible(false)
    }
    const onOk = () => {
        formInstance.validateFields().then(values => {
            let cityData = []
            values.address.forEach(element => {
                cityData.push({
                    province: element[0],
                    city: element[1] || '',
                    region: element[2] || '',
                    remark: ""
                })
            });
            let params = {
                customerId: RowData.customerId,
                customerName: RowData.displayName,
                rows: cityData
            }
            commonService.BathSaveCityAcr(params).then(res => {
                if (res && res.success) {
                    message.success("保存成功");
                    props.reloadData();
                    onCancel();
                }
            })
        })
    }
    // 获取省市
    const getRegions = () => {
        let params = {
            'keycode': 'CPC-Province,CPC-City,CPC-Region'
        };
        commonService.queryTreeAsync(params).then(res => {
            if (res && res.success) {
                setRegionList(res.content)
            }
        })
    }
    const QueryCityAcrByCustomerId = (val) => {
        let params = {
            customerId: val.customerId
        }
        commonService.QueryCityAcrByCustomerId(params).then(res => {
            if(res.success) {
                const address = res.content.map(city => [city.province, city.city, city.region]);
                formInstance.setFieldsValue({ address });
            }
        })
    }
    // expose to parent component
    useImperativeHandle(ref, () => ({
        handle: (btn, rowKeys, selectedRows, data) => {
            formInstance.resetFields();
            QueryCityAcrByCustomerId(selectedRows)
            setRowData(selectedRows);
            setVisible(true);
        }
    }))
    return (
        <Modal title="编辑" destroyOnClose visible={visible} width="35%" onOk={onOk} onCancel={onCancel}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <p>
                    客户： {RowData.displayName}
                </p>
                <p>配置名称： {RowData.catalog}</p>
            </div>
            <Form name="basic" layout="vertical" form={formInstance}>
            <Form.List name="address" rules={[{required: true,message: '请选择',},]}>
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                        <Form.Item required={false} key={field.key}>
                            <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[{required: true,message: '请选择',},]}
                            noStyle
                            >
                                <Cascader options={regionList}  placeholder="请选择" style={{ width: '60%' }} changeOnSelect/>
                            </Form.Item>
                            {fields.length > 1 ? (
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                            />
                            ) : null}
                        </Form.Item>
                        ))}
                        <Form.Item>
                        <Button type="dashed" onClick={() => add()} style={{ width: '60%' }} icon={<PlusOutlined />}>
                            添加城市
                        </Button>
                        <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
})
export default CityAuditEdit;
