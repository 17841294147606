import { enterSearch } from "./actionsType";

const changeStatus=(state)=>{
  return {
    type: enterSearch,
    payload: state,
  };
}

export { changeStatus };
