import React, {
    forwardRef,
    useImperativeHandle, useRef,
    useState
} from "react";
import {Modal, Form, Input, InputNumber, Row, Col, message} from "antd";
import './index.less'
import smartService from "@/service";
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
export default forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [formInstance] = Form.useForm();
    const [lineData, setLineData] = useState({});
    // expose to parent component
    useImperativeHandle(ref, () => ({
        handle: (btn, rowKeys, selectedRows, data) => {
            console.log(btn, rowKeys, selectedRows, data);
            formInstance.setFieldsValue(selectedRows);
            setLineData(selectedRows);
            setVisible(true);
        }
    }))
    const onCancel = () => {
        setVisible(false)
    }
    const onOk = () => {
        console.log(formInstance.getFieldsValue());
        smartService.EditCityHistoryPrice({id: lineData.id, ...formInstance.getFieldsValue()}).then(res => {
            if(res.success){
                message.success('成功');
                onCancel();
                props.reloadData();
            }
        })
        // onCancel();
    }
    return (<Modal
        title="编辑历史均价"
        destroyOnClose
        visible={visible}
        width="30%"
        onOk={onOk}
        onCancel={onCancel}
    >
        <Form
            name="basic"
            {...layout}
            form={formInstance}
        >
            <Form.Item
                label="城市级别"
                name="level">
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="酒店分类"
                name="venueCatalog">
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="酒店星级"
                name="venueStar">
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="资源类型"
                name="resourceType">
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="规格"
                name="specifications">
                <Input disabled/>
            </Form.Item>
            {
                lineData.resourceType === '会场' ? (
                    <>
                        <Form.Item
                            label="历史均价区间"
                            className="priceArrange">
                            <Form.Item
                                name="lowPrice">
                                <InputNumber min={0} precision={2} controls={false} placeholder="请输入"/>
                            </Form.Item>
                            <span style={{margin: '0 10px'}}>-</span>
                            <Form.Item name="hightPrice">
                                <InputNumber min={0} precision={2} controls={false} placeholder="请输入"/>
                            </Form.Item>
                            <span style={{marginLeft: '10px'}}>半天价</span>
                        </Form.Item>
                        <Form.Item
                            label="历史均价区间"
                            className="priceArrange">
                            <Form.Item
                                name="lowPriceExt">
                                <InputNumber min={0} precision={2} controls={false} placeholder="请输入"/>
                            </Form.Item>
                            <span style={{margin: '0 10px'}}>-</span>
                            <Form.Item name="hightPriceExt">
                                <InputNumber min={0} precision={2} controls={false} placeholder="请输入"/>
                            </Form.Item>
                            <span style={{marginLeft: '10px'}}>全天价</span>
                        </Form.Item>
                    </>
                ) : (
                    <Form.Item
                        label="历史均价区间"
                        className="priceArrange">
                        <Form.Item
                            name="lowPrice">
                            <InputNumber min={0} precision={2} controls={false} placeholder="请输入"/>
                        </Form.Item>
                        <span style={{margin: '0 10px'}}>-</span>
                        <Form.Item name="hightPrice">
                            <InputNumber min={0} precision={2} controls={false} placeholder="请输入"/>
                        </Form.Item>
                        <span style={{marginLeft: '10px'}}>间/晚</span>
                    </Form.Item>
                )
            }
        </Form>
    </Modal>)
})
