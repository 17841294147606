import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  message,
  Modal,
  Button,
  Checkbox,
  Col,
  Row,
  Divider,
  Radio,
} from "antd";
import commonService from "@/service/common";
import "../index.less";
const GenericBatch = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [btnData, setBtnData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [ruleTypeValue, setRuleTypeValue] = useState(2);
  const [isOffLine, setIsOffLine] = useState(1);
  const [enabled, setEnabled] = useState(true);
  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      console.log(btn, rowKeys, selectedRows, data);
      setBtnData(btn);
      setSelectedRows(selectedRows || data);
      // eslint-disable-next-line no-eval
      eval(btn.val)(btn, rowKeys, selectedRows, data);
    },
  }));
  // eslint-disable-next-line no-unused-vars
  const batchSetAllPage = async (btn, selectedRowKeys, selectedRows, data) => {
    getTenants();
  };
  const getTenants = async () => {
    let params = {
      UsePaging: false,
      QueryId: props.getQueryData[0].params.queryId,
      parameters: {},
      cluster: "default",
    };
    let res = await commonService.getQueryData(params);
    if (res && res.success) {
      setTenantOptions(res.content.rows);
      setModalVisible(true);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const batchSetRow = (btn, selectedRowKeys, selectedRows, data) => {
    if (selectedRowKeys.length < 1 && !data) {
      message.info("请至少选择一条数据！");
      return;
    }
    getTenants();
  };

  const commonHandle = async (url, params, btn) => {
    let method = "post";
    let res = await commonService.common(method, url, params);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      cancelModal();
      props.reloadData();
    }
  };
  const saveBatch = () => {
    console.log(btnData);
    let url =
      btnData.parameters.pai ||
      props.queryObject.variables.api4BatchSetQueryData;
    let params = {};
    // let params = {
    //   DataMap: btnData.parameters.DataMap,
    //   // Rows: btnData.val == "batchSetAllPage" ? [] : selectedRows,
    //   Updates: {
    //     CustomerIds: checkedValues,
    //     Enabled: enabled,
    //     RuleType: ruleTypeValue,
    //     IsOffLine: isOffLine,
    //   },
    // };
    if (btnData.parameters.Request) {
      params = Object.assign(btnData.parameters || {}, {
        Request: props.dataParame,
      });
    } else {
      params = Object.assign(btnData.parameters || {}, { Rows: selectedRows });
    }
    params.Updates = {
      CustomerIds: checkedValues,
      Enabled: enabled,
      RuleType: ruleTypeValue,
      IsOffLine: isOffLine,
    };
    console.log(url, params);
    commonHandle(url, params);
  };
  const cancelModal = () => {
    setCheckedValues([]);
    setEnabled(true);
    setRuleTypeValue(2);
    setIsOffLine(1);
    setModalVisible(false);
  };
  return (
    <div>
      <Modal
        title="关联客户"
        centered
        visible={modalVisible}
        maskClosable
        onCancel={cancelModal}
        width={1200}
        footer={[
          <Button key="back" onClick={saveBatch}>
            确定
          </Button>,
        ]}
      >
        <p className="titleP">目标客户</p>
        <Checkbox.Group
          style={{
            width: "100%",
          }}
          value={checkedValues}
          onChange={(e) => setCheckedValues(e)}
        >
          <Row>
            {tenantOptions?.map((item, oindex) => {
              return (
                <Col span={4}>
                  <Checkbox value={item.value}>{item.name}</Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
        <Divider />
        <p className="titleP">关联模式</p>
        <Radio.Group
          value={enabled}
          onChange={(e) => setEnabled(e.target?.value)}
        >
          <Radio value={false}>移除</Radio>
          <Radio value={true}>绑定</Radio>
        </Radio.Group>
        <Divider />
        <p className="titleP">访问控制</p>
        <Radio.Group
          value={ruleTypeValue}
          onChange={(e) => setRuleTypeValue(e.target?.value)}
        >
          <Radio value={0}>黑名单</Radio>
          <Radio value={1}>白名单</Radio>
          <Radio value={2}>未标记</Radio>
        </Radio.Group>
        <Divider />
        <p className="titleP">酒店类型</p>
        <Radio.Group
          value={isOffLine}
          onChange={(e) => setIsOffLine(e.target?.value)}
        >
          <Radio value={0}>高频酒店（线上）</Radio>
          <Radio value={1}>低频酒店（线下）</Radio>
        </Radio.Group>

        {/* <Checkbox value={enabled} onChange={(e) => setEnabled(e.target?.checked)}>启用访问类型</Checkbox> */}
      </Modal>
    </div>
  );
});

export default GenericBatch;
