import React from "react";
import { SmartStorage } from "@/utils";
import SmartQueryReact from "smart-iquery-react";
const BillingWorkbench = (props) => {
    const iSignalr = process.env.REACT_APP_SIGNALER;
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const UrlParams= SmartStorage.get("menuParams")?.UrlParams
    const getQueryDataDetail = [{ "queryId": UrlParams[0].queryId4Detail, additional: {}, "cluster": "default", queryId4Count:UrlParams[0].queryId4DetailCount }]
    // 行数据选中回调
  const returnSelectedRows = (rowsId, rows) => {
    console.log(rowsId);
    // setSelectedRowKeys(rowsId);
    // setSelectedRows(rows);
  };
    return (
        <div>
            <SmartQueryReact
                gatewayPath={gatewayPath}
                iSignalr={iSignalr}
                getQueryData={getQueryDataDetail}
                returnSelectedRows={returnSelectedRows}
            ></SmartQueryReact>
            <div>
            </div>
            
        </div>
    )
}

export default BillingWorkbench;