import { request } from "./request";

const smartService = {
  /**
   * 公共post
   */
  common: (method = "post", url, params, conf) => {
    if (method.toLocaleLowerCase() === "post") {
      return request.post(url, params || {}, conf || {});
    } else {
      return request.get(url, { params: params } || { params: {} }, conf || {});
    }
  },
  // TPM GetSettings
  GetSettings: (params) => {
    return request.post("/tpm/api/cfg/getsettings", params);
  },
  // TPM QuerySettings
  QuerySettings: (params) => {
    return request.post("/api/tpm/cfg/querysettings", params);
  },
  // TPM QuerySettings
  AddSettings: (params) => {
    return request.post("/api/tpm/cfg/AddSettings", params);
  },
  // TPM QuerySettings
  EditSettings: (params) => {
    return request.post("/api/tpm/cfg/EditSettings", params);
  },

  getMe: (params) => {
    return request.get("/foundation/passport/IUserService/GetMe", {params: params});
  },

  /*  -----------------------------------------------------divider----------------------------------------------------------------  */

  // 获取省-城市树
  GetCityTree: () => {
    return request.post(
      "/foundation/dict/IDictService/QueryTreeAsync",
      {"keyCode":"CPC-Province,CPC-City"}
    );
  },
  // 获取组织-部门树
  GetOrgTree: (params) => {
    return request.post(
      "/api/foundation/organization/Org/MyOrg_Get_OrgTree",
      params
    );
  },

  // 获取部门树
  GetDeptTree: (params) => {
    return request.post(
      "/api/foundation/organization/Org/MyOrg_Get_DeptTree",
      params
    );
  },

  // 获取子部门列表
  GetSubDepts: (params) => {
    return request.post(
      "/api/foundation/organization/Org/MyOrg_Get_SubDepts",
      params
    );
  },

  // 获取部门的员工列表
  GetStaffsByDept: (params) => {
    return request.post(
      "/api/foundation/organization/Org/MyOrg_Get_Staffs_By_DeptId",
      params
    );
  },
  // 获取会议对账信息
  GetMiceSettleInfo(params){
    return request.post('/api/category/car/MiceSettle/GetMiceSettleInfo', params)
  },
  // 保存会议对账信息
  SaveMiceSettleInfo(params){
    return request.post('/api/category/car/MiceSettle/SaveMiceReduceFee', params)
  },
  // gcar会议结算中发送邮件
  SendEmail(params){
    return request.post('/api/category/car/MiceSettle/SendMiceSettleEmail', params)
  },
  // 获取场地库图片
  GetSupplierImageInfos(params){
    return request.post('/api/venue/BaseInfo/GetSupplierImageInfo', params)
  },
  // 场地库旧 - 图片维护 - 上传图片
  SaveImages(params){
    return request.post('/api/venue/BaseInfo/SaveFile', params)
  },
  // 场地库旧 - 图片维护 - 上传图片
  DeleteImages(params){
    return request.post('/api/venue/BaseInfo/DeleteFile', params)
  },
  // 场地库旧 - 城市历史均价 - 编辑
  EditCityHistoryPrice(params){
    return request.post('/api/venue/CityLevel/UpdateCityLevelAvgPrices', params)
  },
  // gcar客服台-设置乘车人无效
  NullifiedPassenger(params){
    return request.post('/api/category/car/mergecar/TripsFee/ChangePassengerValid', params)
  },
  // gcar-客服改价
  ChangeConfirmFee(params){
    return request.post('/api/category/car/mergecar/TripsFee/ChangeConfirmFee', params)
  },
};

export default smartService;
