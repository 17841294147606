import { createFromIconfontCN } from '@ant-design/icons';
import './index.less'

const IconFont = createFromIconfontCN({
	// prev  //at.alicdn.com/t/font_2816487_4tc4qfnk024.js
	// next  //at.alicdn.com/t/font_2816487_rh5pr513y1.js
  scriptUrl: '//at.alicdn.com/t/c/font_2816487_ooz6exd8xu.js',
});

const XIconFont = (props) => {
	return(
		<IconFont  className={"x-icon-font " + props.className || ""} rotate={props.rotate} spin={props.spin} style={props.style} type={props.type} />
	)
}

export default XIconFont