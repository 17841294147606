import React, { useEffect, useState } from "react";
import { Card, Descriptions } from "antd";
import { getMeetingInfo } from "@/service/OrderManagement";

const InfoDetial = (props) => {
  const { dataInfo, requestVal, defultCol, modelInfo } = props;
  const [detialInfos, setDetialInfos] = useState({});
  //数据格式化处理
  const regFnc = (params) => {
    console.log(params)
    let tempData = {};
    tempData.mice = [...params.infoLabels.miceLabels];
    tempData.consumeInfo = [...params.infoLabels.consumeLabels];
    tempData.voucherInfo = [...params.infoLabels.voucherLabels];
    return tempData
  };
  // 请求数据接口
  const getDeltialInfo = async (val) => {
    const res = await getMeetingInfo({ orderId: val.orderId||val.OrderId });
    if (res.success) {
      setDetialInfos({ ...res.content });
    }
  };
  useEffect(() => {
    console.log(requestVal)
    getDeltialInfo(requestVal);
  }, [requestVal]);
  return (
    <>
      {JSON.stringify(detialInfos) !== "{}" &&
        modelInfo.map((item, index) => {
          return item.value.map((item2, i) => {
            return (
              <Card
                className="cardItem"
                title={item2.name}
                key={i}
                style={{ marginBottom: 10 }}
              >
                {
                  <Descriptions column={defultCol}>
                    {regFnc(dataInfo)[item2.comName].map((ritem, k) => {
                      return (
                        <Descriptions.Item label={ritem.label} key={k}>
                          {detialInfos.order[item2.comName][ritem.render]}
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                }
              </Card>
            );
          });
        })}
    </>
  );
};

export default InfoDetial;
