import React, { useState, useEffect } from 'react';
import financeManageService from '@/service/financeManage';
import { Descriptions, Form, Button, message, Modal } from 'antd';
import XFormItem from '@/components/FormItem';
import itemList from '@/assets/mock/finance/refundAndRefundReview.json';
import moment from 'moment';
import styles from './finance.module.less';

const { confirm } = Modal;
//财务详情卡片组件
const FinanceInfoCard = (props) => {
    console.log(props, 'props');

    const { requestVal, pageType, moduleItem } = props;
    const [form] = Form.useForm();
    const [baseInfo, setBaseInfo] = useState(null);
    const [taskInfo, setTaskInfo] = useState(null);
    const [formList, setFormList] = useState(null);
    const [bankInfo, setBankInfo] = useState(null);
    /**
     * 获取基础详情
     */
    const getBaseDetail = () => {
        financeManageService
            .GetBaseDetail({ payId: requestVal.payId })
            .then((res) => {
                if (res.success && res.content) {
                    setBaseInfo(res.content);
                }
            });
    };

    const getTask = () => {
        // 深拷贝元数据
        let deepCloneList = structuredClone(itemList);
        financeManageService
            .GetTask({ taskId: requestVal.taskId })
            .then((res) => {
                if (res.success && res.content) {
                    setTaskInfo(res.content);
                    deepCloneList = deepCloneList.map((item) => {
                        if (res.content.nodeCode === 'RefundPaymentAudit') {
                            item.label = `收款${item.label}`;
                        } else {
                            item.label = `付款${item.label}`;
                        }
                        return item;
                    });
                    setFormList(deepCloneList);
                    form.setFieldsValue({
                        PayBank: baseInfo?.payBank || null,
                        DtPay: baseInfo?.dtPay || null,
                        PayAmount: baseInfo?.amount || null,
                    });
                }
            });
    };
    /**
     * 保存
     */
    const submit = () => {
        let params = {
            payId: requestVal.payId,
        };
        form.validateFields().then((values) => {
            financeManageService.CheckPay(params).then((res) => {
                if (res.success) {
                    if (res.content.isError) {
                        message.error('保存成功');
                    } else {
                        save(values, '');
                    }
                }
            });
        });
    };
    /**
     * 付款
     */
    const payment = () => {
        let params = {
            payId: requestVal.payId,
        };
        form.validateFields().then((values) => {
            financeManageService.CheckPay(params).then((res) => {
                if (res.success) {
                    if (res.content.isError) {
                        showConfirm(res.msg, values);
                    } else {
                        showPaymentModal(values);
                    }
                }
            });
        });
    };

    const showConfirm = (content, values) => {
        confirm({
            title: '提醒',
            content: content,
            onOk() {
                showPaymentModal(values);
            },
        });
    };
    const showPaymentModal = (values) => {
        confirm({
            title: '付款',
            content: '是否确认付款',
            onOk() {
                // 判断银行是否为交行或交通银行
                const regex = /(交通|交通银行|交行)/g;
                if (bankInfo.children.match(regex)) {
                    // 如果匹配成功，则执行相应的操作
                    paymentAPI(values);
                }
            },
        });
    };
    const paymentAPI = (values) => {
        let params = {
            payId: requestVal.payId,
            Amount: values.PayAmount,
            PayBankAccountName: bankInfo.children,
            PayBankAccountNumber: values.PayBank,
            ReceiveBankName: `${requestVal?.accountBank}${requestVal?.bankBranch}`,
            ReceiveBankAccountNumber: requestVal?.accountNum,
            ReceiveBankAccountName: requestVal?.accountName,
        };
        financeManageService.TransferAccount(params).then((res) => {
            if (res.success) {
                message.success('保存成功');
            }
        });
    };
    const save = (values, extNum) => {
        let params = {
            PayId: JSON.parse(this.listItem.taskData).payId, //主键
            PayBank: bankInfo.children, //付款银行
            PayBankAccount: values.PayBank, //付款银行账户
            DtPay: moment(values.DtPay).format('YYYY-MM-DD'), //付款时间
            PayAmount: values.PayAmount, // 金额
            ExtNum: extNum, //交易号
        };
        financeManageService.Payoff(params).then((res) => {
            if (res.success) {
                message.success('保存成功');
            }
        });
    };

    const callback = (e, item, optionsData) => {
        setBankInfo(optionsData);
    };
    useEffect(() => {
        getBaseDetail();
        getTask();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestVal]);
    return (
        <div className={styles.module_content}>
            <Descriptions title="基本信息" column={2}>
                {moduleItem?.infoLabels?.labels.map((labelItem, labelIndex) => {
                    return (
                        <Descriptions.Item
                            label={labelItem.label}
                            key={labelIndex}
                        >
                            {baseInfo?.[labelItem.render] || '暂无'}
                        </Descriptions.Item>
                    );
                })}
            </Descriptions>
            {formList && ['Fin-RefundAndRefundReview'].includes(pageType) && (
                <Form form={form}>
                    <XFormItem itemList={formList} callBack={callback} />
                </Form>
            )}
            {['Fin-RefundAndRefundReview'].includes(pageType) && (
                <>
                    {taskInfo?.nodeCode === 'RefundPaymentAudit' ? (
                        <Button type="primary" onClick={submit}>
                            保存(网银支付)
                        </Button>
                    ) : (
                        <Button type="primary" onClick={payment}>
                            付款(银企支付)
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

export default FinanceInfoCard;
