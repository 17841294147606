import React, { useEffect, useState } from "react";
import commonService from "@/service/common";
import "../MopModules/modules.less";
import {Button, Table, Form, Input, Tabs, message, Modal} from "antd";
import moment from "moment/moment";

const MopLog = (props) => {
    const [form] = Form.useForm();
    const [dataSource,setDataSource] = useState([]);
    const [tableData,setTableData] = useState([]);
    const [selectData,setSelectData] = useState([]);
    const [defaultActiveKey,setDefaultActiveKey] = useState('');
    const [loading,setLoading] = useState(false);
    const [remarkModal,setRemarkModal] = useState(false);
    const [btn,setBtn] = useState(false);
    const { TextArea } = Input;
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        QueryAccessTaskLogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mopModalData]);

    const setMopModalVisible = async () => {
        props.mopModalVisibleCallback({ type: "cancel" });
    };
    const QueryAccessTaskLogs = () => {
        let params = {
            businessId: props.mopModalData.businessId,
            processId: props.mopModalData.processId
        };
        setLoading(true)
        commonService.queryProcessTaskLogs(params).then(res => {
            if (res && res.success && res.content.length) {
                setDataSource(res.content)
                setDefaultActiveKey(res.content[0].supplierName)
                setTableData(res.content[0].logs)
            }
            setLoading(false)
        })
    }
    const onChangeTabs = (val) => {
        dataSource.forEach(ele => {
            if ([val].includes(ele.supplierName)) {
                setTableData(ele.logs)
            }
        })
    }
    const editInfo = (btn, val) => {
        setBtn(btn)
        setSelectData(val)
        setRemarkModal(true)
    }
    const DealTask = () => {
        form.validateFields().then((values) => {
            let params = {
                taskId: props.mopModalData.taskId,
                processId: props.mopModalData.processId,
                businessId: props.mopModalData.businessId,
                supplierId: selectData.supplierId,
                nodeCode: props.mopModalData.nodeCode,
                actCode: btn.code,
                descr: values.descr
            };
            commonService.DealTask(params).then(res => {
                if (res && res.success) {
                    message.success('操作成功')
                    props.mopModalVisibleCallback({ type: "cancel" });
                }
            })
        })
    }
    const columns = [
        { title: '任务节点', dataIndex: 'nodeName', key: 'nodeName' },
        { title: '创建时间', dataIndex: 'dtCreated', key: 'dtCreated' ,
            render: (children) => (
                (moment(children).format("YYYY-MM-DD HH:mm:ss"))
            )},
        { title: '创建者', dataIndex: 'creatorDspName', key: 'creatorDspName' },
        { title: '执行时间', dataIndex: 'dtDone', key: 'dtDone' ,
            render: (children) => (
                children && (moment(children).format("YYYY-MM-DD HH:mm:ss"))
            )},
        { title: '执行者', dataIndex: 'executorDspName', key: 'executorDspName' },
        { title: '状态', dataIndex: 'taskStatus', key: 'taskStatus' },
        { title: '执行意见', dataIndex: 'actDescr', key: 'actDescr' },
        { title: '操作', dataIndex: 'buttons', key: 'buttons' ,
            render: (children, record) => (
                <>
                    {children.length && (children.map((ele, index) => (
                            <Button key={index} type="primary" onClick={() => editInfo(ele, record)}>{ele.name}</Button>
                        ))
                    )}
                </>
            )},
    ];
    return (
        <Modal title='任务日志' centered destroyOnClose={true} visible={props.mopModalVisible}
            maskClosable
            onCancel={() => {
                props.mopModalVisibleCallback();
            }}
            footer={
                [
                    <Button key="back" type="primary" onClick={setMopModalVisible}>
                        确定
                    </Button>,
                ]
            }
            width={1200}
        >
            <Tabs defaultActiveKey={defaultActiveKey} onChange={onChangeTabs}>
                {dataSource.length && (dataSource.map((ele,index)=> (
                        <Tabs.TabPane tab={ele.supplierName} key={ele.supplierName}>
                        </Tabs.TabPane>
                    ))
                )}
            </Tabs>
            <Table loading={loading} columns={columns} bordered dataSource={tableData} pagination={false}/>
            <Modal title={btn.name} centered destroyOnClose={true} visible={remarkModal} maskClosable
                   onCancel={() => {
                       setRemarkModal(false)
                   }}
                   footer={
                       [
                           <Button key="back" type="primary" onClick={DealTask}>
                               确定
                           </Button>,
                       ]
                   }>
                <Form form={form} component={false} >
                    <Form.Item name='descr' rules={[ { required: true, message: '请填写备注'}]}>
                        <TextArea rows={4} placeholder="请填写备注"/>
                    </Form.Item>
                </Form>
            </Modal>
        </Modal>
    );
};

export default MopLog;
