import React, { useState } from 'react';
import { Modal } from 'antd';
import MeetingList from '../MeetingList/MeetingList';
import OrderList from '../OrderList/OrderList';

const AddMeeting = (props) => {
    const { meetingModalVisible, fieldsValue, drawerData, activeData } = props;

    const [selectIds, setSelectIds] = useState(null);
    const [amount, setAmount] = useState(0);

    const handleMeetingCancle = () => {
        props.handleMeetingCancle({
            amount,
            activeMiceIds:
                selectIds && selectIds.length > 0 ? selectIds.join(',') : '',
        });
    };
    const callBackSelectData = (ids, data) => {
        let total = 0;
        data.forEach((element) => {
            if (fieldsValue.feeType === '20') {
                total += parseFloat(element.insuranceAmount);
            } else {
                total += parseFloat(element.amount);
            }
        });
        setAmount(total);
        setSelectIds(ids);
    };
    return (
        <>
            <Modal
                title="会议"
                destroyOnClose
                maskClosable={false}
                open={meetingModalVisible}
                width={1200}
                onCancel={handleMeetingCancle}
                footer={null}
            >
                <MeetingList
                    drawerData={drawerData}
                    activeData={activeData}
                    fieldsValue={fieldsValue}
                    callBackSelectData={callBackSelectData}
                    handleCancelMeetingModal={handleMeetingCancle}
                />
                <OrderList
                    drawerData={drawerData}
                    activeData={activeData}
                    fieldsValue={fieldsValue}
                    selectIds={selectIds}
                />
            </Modal>
        </>
    );
};

export default AddMeeting;
