import React from "react";
import { Descriptions } from "antd";

const EasyMeetingPayment = (props) => {
    const { queryObject, requestVal } = props;
    console.log(requestVal)
    // useEffect(() => {
    //     const deleteData = delete requestVal.buttons
    //     console.log(deleteData)
    //     console.log(Object.keys(requestVal))
    //     setDetialInfos(requestVal)
    // }, [requestVal]);
    return (<div>
        <Descriptions column={2}>
        {queryObject.columns?.map((item, oindex) => {
                return (
                    <Descriptions.Item key={oindex} label={item.name}>{requestVal[item.title]}</Descriptions.Item>
                );
              })}
        </Descriptions>
    </div>
    );
};

export default EasyMeetingPayment;
