import React, { useState, useEffect } from "react";
import { SmartStorage } from "@/utils";
import commonService from "@/service/common";
import InfoCard from "./compments/MopModules/InfoCard";
import QueryCard from "./compments/MopModules/QueryCard";
import FooterBtnsCard from "./compments/MopModules/FooterBtnsCard";
import HotelDetails from "./compments/MopModules/HotelDetails";
import HoteDemand from "./compments/MopModules/HoteDemand/HoteDemand";
import AuditModule from "./compments/MopModules/AuditModule";
import "./index.less";

const components = {
  InfoCard,
  QueryCard,
  FooterBtnsCard,
  HotelDetails,
  HoteDemand,
  AuditModule
};

const DetailPage = () => {
  // 模块数组
  const [modules, setModules] = useState([]);
  const getDetailMoudles = () => {
    let moudleParams = {
      collection: "cfg-web-modular",
      filter: {
        Application: "XCENTER",
        tenantId: "*",
        type: SmartStorage.get("radioQueryData").pageType,
        abbrCode: {
          $in: [SmartStorage.get("radioQueryData").pageType],
        },
      },
    };
    commonService.GetSettings(moudleParams).then((res) => {
      if (res && res.success) {
        setModules(res.content.modules);
      }
    });
  };
  useEffect(() => {
    getDetailMoudles();
  }, []);
  return (
    <div className="detailPage">
      {modules?.map((item, index) => {
        const TagName = components[item.comName];
        return (
          <TagName
            key={index}
            requestVal={SmartStorage.get("detailRow")}
            moduleItem={item}
            pageType={SmartStorage.get("radioQueryData").pageType || ""}
          ></TagName>
        );
      })}
    </div>
  );
};

export default DetailPage;
