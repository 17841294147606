
const column1 = [
    {
        title: '',
        dataIndex: 'code',
        colSpan: 3,
        render: (value, row, index) => {
            const obj = {
                children: value,
                props: {},
            };
            if (index === 1) {
                obj.props.rowSpan = 6;
            }
            if([2, 3, 4, 5, 6].includes(index)){
                obj.props.rowSpan = 0;
            }
            return obj
        }
    },
    {
        title: '',
        dataIndex: 'status',
        colSpan: 0,
        render: (value, row, index) => {
            const obj = {
                children: value,
                props: {},
            };
            if (index === 0) {
                obj.props.rowSpan = 7;
            }
            if([1, 2, 3, 4, 5, 6].includes(index)){
                obj.props.rowSpan = 0;
            }
            return obj
        }
    },
    {
        title: '',
        dataIndex: 'status1',
        colSpan: 0,
        dataSets: ['total', 'tripFinish', 'tripCancel', 'tripCancelHaveFee', 'tripInvalid', 'tripNoOrder', 'tripNoTrain']
    },
    {
        title: '行程数',
        dataIndex: 'tripCount',
        render: (value, row, index) => {
            return (<h1>{value}</h1>)
        }
    },
    {
        title: '车费（含税）',
        dataIndex: 'tripHaveTaxFee',
        render: (value, row, index) => {
            return (<h1>{value}</h1>)
        }
    },
    {
        title: '服务费（不含税）',
        dataIndex: 'tripServiceFee',
        render: (value, row, index) => {
            return (<h1>{value}</h1>)
        }
    },
    {
        title: '总价',
        dataIndex: 'tripSumFee',
        render: (value, row, index) => {
            return (<h1>{value}</h1>)
        }
    }
]

const column2 = [
    {
        title: '',
        dataIndex: 'code',
        render: (value, row, index) => {
            const obj = {
                children: value,
                props: {},
            };
            if (index === 0) {
                obj.props.rowSpan = 5;
            }
            if (index === 5) {
                obj.props.rowSpan = 3;
            }
            if([1, 2, 3, 4, 6, 7, 8].includes(index)){
                obj.props.rowSpan = 0;
            }
            return obj
        }
    },
    {
        title: '调整原因',
        dataIndex: 'status'
    },
    {
        title: '调整原则',
        dataIndex: 'status1',
        render: (value, row, index) => {
            const obj = {
                children: value,
                props: {},
            };
            if(index === 0){
                obj.props.rowSpan = 8;
            }
            if([1, 2, 3, 4, 5, 6, 7, 8].includes(index)){
                obj.props.rowSpan = 0;
            }
            return obj
        }
    },
    {
        title: '行程数',
        dataIndex: 'tripCount',
        editable: true
    },
    {
        title: '车费调整金额（含税）',
        dataIndex: 'tripHaveTaxFee',
        editable: true
    },
    {
        title: '服务费调整金额（不含税）',
        dataIndex: 'tripServiceFee',
        editable: true
    },
]

const column3 = [
    {
        title: '',
        dataIndex: 'code',
        colSpan: 3,
        render: (value, row, index) => {
            const obj = {
                children: value,
                props: {},
            };
            if (index === 1) {
                obj.props.rowSpan = 6;
            }
            if([2, 3, 4, 5, 6].includes(index)){
                obj.props.rowSpan = 0;
            }
            return obj
        }
    },
    {
        title: '',
        dataIndex: 'status',
        colSpan: 0,
        render: (value, row, index) => {
            const obj = {
                children: value,
                props: {},
            };
            if (index === 0) {
                obj.props.rowSpan = 7;
            }
            if([1, 2, 3, 4, 5, 6].includes(index)){
                obj.props.rowSpan = 0;
            }
            return obj
        }
    },
    {
        title: '',
        dataIndex: 'status1',
        colSpan: 0,
        dataSets: ['total', 'tripFinish', 'tripCancel', 'tripCancelHaveFee', 'tripInvalid', 'tripNoOrder', 'tripNoTrain']
    },
    {
        title: '行程数',
        dataIndex: 'tripCount',
        render: (value, row, index) => {
            return (<h1>{value}</h1>)
        }
    },
    {
        title: '车费（含税）',
        dataIndex: 'tripHaveTaxFee',
        render: (value, row, index) => {
            return (<h1>{value}</h1>)
        }
    },
    {
        title: '服务费（不含税）',
        dataIndex: 'tripServiceFee',
        render: (value, row, index) => {
            return (<h1>{value}</h1>)
        }
    },
    {
        title: '结算总价',
        dataIndex: 'tripSumFee',
        render: (value, row, index) => {
            return (<h1>{value}</h1>)
        }
    }
]
const mockData1 = [
    {
        code: 'meeting-code',
        status: '行程状态',
        status1: 'total',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: '0'
    },
    {
        code: 'RESP-FPS-S23-987',
        status: '行程状态',
        status1: '行程已完成',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: '0'
    },
    {
        code: 'RESP-FPS-S23-987',
        status: '行程状态',
        status1: '已取消',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: '0'
    },
    {
        code: 'RESP-FPS-S23-987',
        status: '行程状态',
        status1: '行程已取消有损失',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: '0'
    },
    {
        code: 'RESP-FPS-S23-987',
        status: '行程状态',
        status1: '行程无效（取消）',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: '0'
    },
    {
        code: 'RESP-FPS-S23-987',
        status: '行程状态',
        status1: '行程未下单（取消）',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: '0'
    },
    {
        code: 'RESP-FPS-S23-987',
        status: '行程状态',
        status1: '行程已完成（未上车）',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: '0'
    }
]

const mockData2 = [
    {
        code: '严重投诉',
        status: '1.1未送达（已乘坐未送达目的地）',
        status1: '扣除本行程车费和服务费',
        tripCount: '1',
        tripHaveTaxFee: '129.9',
        tripServiceFee: '112.09',
        tripSumFee: '22'
    },
    {
        code: '严重投诉',
        status: '1.2私自修改目的地',
        status1: '扣除本行程车费和服务费',
        tripCount: '1',
        tripHaveTaxFee: '129.9',
        tripServiceFee: '112.09',
        tripSumFee: '22'
    },
    {
        code: '严重投诉',
        status: '1.3司机服务',
        status1: '扣除本行程车费和服务费',
        tripCount: '1',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: 0
    },
    {
        code: '严重投诉',
        status: '1.4客户服务',
        status1: '扣除本行程车费和服务费',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: 0
    },
    {
        code: '严重投诉',
        status: '1.5其他特殊情况',
        status1: '扣除本行程车费和服务费',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: 0
    },
    {
        code: '对账服务',
        status: '2.1超过预估车费1.2倍',
        status1: '扣除本行程车费和服务费',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: 0
    },
    {
        code: '对账服务',
        status: '2.2行程已开始实际未上车',
        status1: '扣除本行程车费和服务费',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: 0
    },
    {
        code: '对账服务',
        status: '2.3其他情况',
        status1: '扣除本行程车费和服务费',
        tripCount: '0',
        tripHaveTaxFee: '0',
        tripServiceFee: '0',
        tripSumFee: 0
    }
]
const handleInput = (e, value, row, index, key) => {
    console.log(e);
    mockData2[index][key] = e.target.value;
    console.log(mockData2);
}
export {column1, mockData1, column2, mockData2, column3}
