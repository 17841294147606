// eslint-disable-next-line no-unused-vars
import React from "react";
import styles from "./FinanceWorkPanel.module.less";
import { useHistory } from "react-router";
import { SmartStorage } from "@/utils";
/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import jsxCustomEvent from "@micro-zoe/micro-app/polyfill/jsx-custom-event";

const FinanceWorkPanelDetails = (props) => {
  const microUrl = process.env.REACT_APP_FINANCE_WORK_PANEL_URL;
  const history = useHistory();
  // 返回上一级
  // eslint-disable-next-line no-unused-vars
  const goback = () => {
    props.history.goBack();
  };
  // 获取来自子应用的信号,返回之前的路由地址
  const handleSignalFromNGAPP = (e) => {
    console.log("REACT_APP_ENV:", process.env);
    const rnd = new Date().getTime();
    const url = SmartStorage.get("previousPath");
    history.push(url + `?rnd=${rnd}`);
  };
  return (
    <div className={styles.finance_micro__div}>
      {/*<div className={styles.btns}>*/}
      {/*  <Affix>*/}
      {/*    <Button type="primary" icon={<LeftOutlined />} onClick={goback}>*/}
      {/*      返回上一级*/}
      {/*    </Button>*/}
      {/*  </Affix>*/}
      {/*</div>*/}
      <micro-app
        name="FinanceWorkPanelDetails"
        url={microUrl}
        baseroute="/micro-apps"
        onDataChange={(e) => handleSignalFromNGAPP(e)}
      ></micro-app>
    </div>
  );
};

export default FinanceWorkPanelDetails;
