import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "antd";
import styles from "./thirdPartApp.module.less";
import "./index.less";
import commonService from "@/service/common";
import { CloseCircleOutlined } from "@ant-design/icons";
const ThirdPartApp = forwardRef((props, ref) => {
  const [appVisable, setAppVisable] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      getAccessToken(btn, data,rowKeys);
    },
  }));
  const getAccessToken = (btnInfo, record,rowKeys) => {
    console.log(record)
    let eventData = {
      provinceDictVal: record.province || "",
      province: record.province || "",
      cityDictVal: record.city || "",
      city: record.city || "",
      cityId: record.pingRegionId || "",
      regionId: record.regionId || "",
      region: record?.extData?.region || "",
      latitude: record.latitude || "",
      longitude: record.longitude || "",
      tenantId: record.tenantId,
      tenant_code: record.tenantCode,
      typeDictVal: record.typeDictVal,
      status: record.status,
      statusTxt: record.statusTxt,
      eventOrderId: record.orderId,
      // isFromMore: appData.isFromMore,
      miceId: record.miceId,
      proposalId: record.miceId || record.proposalId,
      dtStart: record.dtStart, //外出用餐时间
      dtEnd: record.dtStart, //
      extData: record?.extData || {},
    };
    let params = {
      client_id: "internal.passportless",
      client_secret: "smartx",
      grant_type: "passwordless",
      userName: ['emMudid', 'eoMudid', 'loginAccount'].includes(btnInfo.actionCode) ? record[btnInfo.actionCode] : record.loginAccount,
    };
    commonService.getToken(params).then((res) => {
      if (res && res.success) {
        let concatObj = {
          eventData,
          "X-EventWorld-User": window.btoa(
            JSON.stringify({ userId: res.content.userId })
          ),
          supplierId: record.supplierId || "",
          userId: res.content.userId,
          role_codes: res.content.role_codes,
          directory_id: res.content.directory_id,
          tenant_code: res.content.tenant_code,
          token: res.content.access_token,
          container: "h5",
          tenant_id: res.content.tenant_id,
          platform: "app",
          name:res.content.name,
          isFrom: "x-center",
        };
        getDynamicSessionId(btnInfo, concatObj,rowKeys);
      }
    });
  };
  const getDynamicSessionId = (btnInfo, data,rowKeys) => {
    console.log();
    let params = {
      Data: data,
      Strategy:'Session'
      // CacheEntryOptions: {
      //   AbsoluteExpirationRelativeToNow: "08:00:00", //从现在开始计时(时:分:秒）过期
      // },
      // Prefix: "jwts_",
    };
    commonService.saveCacheData(params).then((res) => {
      let url = "";
      if (res && res.success) {
        switch (btnInfo.actionCode) {
          case "platformCar":
          case "cityCar":
          case "emMudid":
          case "eoMudid":
          case "loginAccount":
            if(rowKeys.reason=="staffcar"){
              url = `${btnInfo.actionUrl}?route=staffcar&sessionId=${res.content}`;
            }else{
              url = `${btnInfo.actionUrl}?proposalId=${data.eventData.proposalId}&sessionId=${res.content}`;
            }
            
            break;
          default:
            url = `${btnInfo.actionUrl}&proposalId=${data.eventData.proposalId}&sessionId=${res.content}`;
            break;
        }
        console.log(url);
        setAppUrl(url);
        setAppVisable(true);
      }
    });
  };
  const handleCancel = () => {
    setAppVisable(false);
    setAppUrl("");
  };
  return (
    <>
      {appVisable && (
        <div className="phoneBox">
          <div className="maskBox"></div>
          <div id="phone">
            <div id="camera"></div>
            <div id="receiver"></div>
            <div id="screen">
              <iframe
                width="100%"
                height="576px"
                id="app-iframe"
                title="app"
                src={appUrl}
              ></iframe>
            </div>
            <div id="btn" onClick={(e)=>{handleCancel()}}></div>
          </div>
        </div>
        // <Modal
        //   className={styles.modalContent}
        //   title=""
        //   visible={appVisable}
        //   onCancel={handleCancel}
        //   footer={null}
        //   width={375}
        //   closeIcon={<CloseCircleOutlined />}
        //   maskClosable
        //   centered
        // >
        // </Modal>
      )}
    </>
  );
});

export default ThirdPartApp;
