import React, {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {Form, Input, Select, Button, Row, Col, Modal, Radio, DatePicker, Upload, Cascader, notification, Tooltip, message} from 'antd';
import MapEditor from "@/components/DetailDrawer/compments/CarSupplierModules/MapEditor";
import RiskHint from './RiskHint'
import './modules.less';
import {PlusOutlined} from "@ant-design/icons";
import commonService from "@/service/common";
import SmartQueryReact from "smart-iquery-react";
import {SmartStorage} from "@/utils";
import moment from "moment";
import _ from "lodash";

const uploadButton = (
    <div>
        <PlusOutlined />
        <div
            style={{
                marginTop: 8,
            }}
        >
            上传
        </div>
    </div>
);
const {AMap} = window;
const {Option} = Select;

const AuditModule = (props) => {
    console.log(props);
    const iSignalr = process.env.REACT_APP_SIGNALER;
    const gatewayPath = process.env.REACT_APP_GATEWAY;
    const [formInstance] = Form.useForm();
    const [queryData, setQueryData] = useState([]);
    // 地图选点功能
    const mapEditorRef = useRef(null);
    // 省市区
    const [regionList, setRegionList] = useState([]);
    const [fileList, setFileList] = useState([]);
    // 场地库弹窗
    const [PlaceVisible, setPlaceVisible] = useState(false);
    // 选择的场地
    const [selectRow, setSelectRow] = useState(null);
    // 当前是哪个表单item
    const [currentField, setCurrentField] = useState();
    // 下拉列表
    const [OperationMode,setOperationMode] = useState([]);
    const [BusinessStatus, setBusinessStatus] = useState([]);
    const [EnterpriseEquityType, setEnterpriseEquityType] = useState([]);
    const [HotelBrands, setHotelBrands] = useState([]);
    const mapper = new Map();
    const [selectFields, setSelectFields] = useState(mapper);
    const [auditData, setAuditData] = useState({});
    const saveForm = () => {
        formInstance.validateFields().then(values => {
            console.log(values);
            const [province, city] = values.location.province;
            const {brand, brandType} = values.brand;
            // const {_SupplierId} = SmartStorage.get("detailRow");
            // const _SupplierId =
            const params = {
                auditId: auditData.auditId,
                taskId: SmartStorage.get("detailRow").taskId,
                AuditData: {
                    supplierId: SmartStorage.get("SupplierId") || SmartStorage.get("detailRow")._SupplierId,
                    ...values,
                    city,
                    province,
                    address: values.location.address,
                    brand,
                    brandType,
                    otherRegistrationTime: values.otherRegistrationTime && moment(values.otherRegistrationTime).format("YYYY-MM-DD"),
                    otherSpecialCircumstancesFiles: values.otherSpecialCircumstances && values.otherSpecialCircumstances.map(x => {
                        if(x.response){
                            return x.response.content;
                        }else {
                            return x
                        }
                    })
                }
            }
            delete params.location;
            delete params.brand;
            delete params.AuditData.otherSpecialCircumstances;
            commonService.saveAudit(params).then(res => {
                if(res.success) {
                    message.success('保存成功');
                    getAuditData();
                    // history.goBack();
                }
            })
        })
    }
    // 重置表单
    const resetForm = () => {
        formInstance.resetFields();
    }
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        if(e.status === 'done') {
            setFileList(e?.fileList);
        }
        return e?.fileList.map(i => {
            const type = i.type || i.fileType;
            if(type.indexOf('pdf') !== -1) {
                i.thumbUrl = 'https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/public/PDF.png'
            }
            return i
        })
    }
    // 选择地图的模态框
    const handleOpenMapModal = (key) => {
        if(!auditData.canEdit) return;
        console.log(key);
        setCurrentField(key);
        let searchKey;
        if(key === 'name') {
            searchKey = formInstance.getFieldValue('name');
            mapEditorRef.current.show({});
        }else {
            if(formInstance.getFieldValue('location') && formInstance.getFieldValue('location').province){
                searchKey = formInstance.getFieldValue('location')?.address;
                mapEditorRef.current.show({});
            }else {
                notification.error({
                    message: "提示",
                    description: '请先选择省市',
                    duration: 2,
                });
            }
        }
        mapEditorRef.current.setKeyword(searchKey);
    }
    // 获取省市
    const getRegions = () => {
        let params = {
            'keycode': 'CPC-Province,CPC-City'
        };
        commonService.queryTreeAsync(params).then(res => {
            if (res && res.success) {
                setRegionList(res.content.map(i => {
                    return {
                        ...i,
                        children: i.children.map(x => {
                            delete x.children;
                            return x
                        })
                    }
                }))
            }
        })
    }
    // 场地库检索
    const showPlacesSearch = () => {
        if(!auditData.canEdit) return
        setQueryData([{ queryId: "c5e5703a-d630-43dc-bafa-3a49d8a833ad", queryId4Count: "d69b696e-c065-431c-a6eb-442e46654dcb", cluster: "default" , additional: {_tenantId: auditData.tenantId}},]);
        setPlaceVisible(true);
    }
    // 场地库检索-选择行
    const returnSelectedRows = (id, rows) => {
        console.log(rows);
        setSelectRow(rows[0]);
    }
    // 不管是选择了场地库还是地图选址完成，都把省市给带出来，赋值给省市字段
    // 选择了场地
    const onOkPlace = async () => {
        console.log(selectRow);
        selectRow.id = selectRow.supplierId;
        const flag = await beforeSubmit(selectRow);
        if(flag.type === 1) {
            message.error("该地址在黑名单中，请重新选择");
            return;
        }
        setPlaceVisible(false);
        const pNameArr = selectRow.cityData.split(',').slice(0, 2);
        console.log(pNameArr);
        formInstance.setFieldsValue({name: selectRow.name, location: {province: [...pNameArr], address: selectRow.address}});
        saveRiskHint('name',await queryBlacklist(selectRow.name, 0));
        saveRiskHint('address',await queryBlacklist(selectRow.address, 1));
        SmartStorage.set('SupplierId', selectRow.supplierId);
    }
    // 地图选址回调
    const mapDataCallback = async (data) => {
        console.log(data);
        if(currentField === 'name') { // 省市和地址都联动赋值进去
            let pNameArr;
            const specialDistrictFlag = ["北京", "重庆", "上海", "天津"].some(i => {
                return data.district ? data.district.indexOf(i) !== -1 : data.pname.indexOf(i) !== -1;
            })
            // 这个比照的标准是城市的级联菜单选择的结果进行匹配
            if(specialDistrictFlag) {
                pNameArr = Array(2).fill(data.district.substr(0, 3));
            }else {
                const target = data.adcode.substr(0, 4) + '00';
                regionList.forEach(i => {
                    if(i.valId === target) {
                        pNameArr = [i.txt];
                    }else {
                        if(i.children) {
                            i.children.forEach(x => {
                                if(x.valId === target) {
                                    pNameArr = [i.txt, x.txt];
                                }
                            })
                        }
                    }
                })
            }
            formInstance.setFieldsValue({name: data.name, location: {province: [...pNameArr], address: data.address}});
            saveRiskHint('name',await queryBlacklist(data.name, 0));
            saveRiskHint('address',await queryBlacklist(data.address, 1));
            SmartStorage.set('SupplierId', data.id);
        }else {
            let loc = formInstance.getFieldValue('location');
            formInstance.setFieldsValue({location: {address: data.address, province: loc.province}});
            saveRiskHint('address',await queryBlacklist(data.address, 1));
        }
    }
    // 黑名单查询
    const queryBlacklist = (keyword, Catalog) => {
        const params = {
            "CustomerId": SmartStorage.get("detailRow").tenantId,
            "Keyword": keyword,
            "Catalog": Catalog
        }
        return new Promise(resolve => {
            commonService.queryBlackList(params).then(res => {
                if(res.success) {
                    resolve(res.content);
                }
            })
        })
    }
    // 取消选择场地库
    const onCancelPlace = () => {
        setPlaceVisible(false);
    }
    // 获取基础下拉框菜单（经营范围、企业状态等信息）
    const getBasicOptions = async () => {
        const kyeCodes = ["setOperationMode", "setBusinessStatus", "setBusinessScope", "setEnterpriseEquityType"];
        let results = await Promise.all(kyeCodes.map(async (i) => {
            return {
                name: i,
                options: await new Promise(resolve => {
                    commonService.queryAsync({keycode: i.split('set')[1]}).then(res => {
                    if (res.success) {
                        resolve(res.content);
                    }
                })
            })
        }
        }));
        // 赋值
        results.forEach(i => {
            try {
                const str = `${i.name}(i.options)`;
                eval(str)
            }catch (e) {
                console.log(e);
            }
        })
    }
    // 获取酒店品牌
    const getHotelBrandList = () => {
        commonService.getHotelBrands().then(res => {
            if (res.success) {
                setHotelBrands(res.content);
            }
        })
    }
    // 获取审核内容
    const getAuditData = () => {
        const SupplierId = SmartStorage.get('SupplierId');
        const {_OrderId, _SupplierId, processId, actionType} = SmartStorage.get("detailRow");
        const params = {
            "MiceId":  processId,
            "SupplierId": SupplierId || _SupplierId,
            "OrderId": _OrderId
        }
        commonService.getAuditRecord(params).then(res => {
            if (res.success) {
                setAuditData(res.content);
                const {province, city, address, brandType, brand, otherRegistrationTime, otherSpecialCircumstancesFiles
                } = res.content.auditData;
                const formData = {
                    ...res.content.auditData,
                    brand: {
                        brandType,
                        brand
                    },
                    location: {
                        province: province ? [province,city] : [],
                        address: address
                    },
                    otherRegistrationTime: otherRegistrationTime ? moment(otherRegistrationTime, 'YYYY-MM-DD') : null,
                    otherSpecialCircumstances: otherSpecialCircumstancesFiles && otherSpecialCircumstancesFiles.map(i => {
                        if(i.fileType.indexOf('pdf') !== -1) {
                            i.urlThumbnail = 'https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/public/PDF.png';
                            i.thumbUrl = 'https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/public/PDF.png';
                        }
                        return i
                    })
                }
                console.log(formData);
                formInstance.setFieldsValue(formData);
            }
        })
    }
    const saveRiskHint = (key, obj) => {
        setSelectFields(new Map(selectFields.set(key, obj)))
    }
    // 下拉框选择内容后是否进行风险提示
    const selectOnChange = async (val, key) => {
        switch (key) {
            case 'businessStatus':
                saveRiskHint(key, await queryBlacklist(val, 2));
                break;
            case 'businessScope':
                saveRiskHint(key, await queryBlacklist(val, 3));
                break;
            case 'enterpriseEquityType':
                saveRiskHint(key, await queryBlacklist(val, 4));
                break;
            default:
                break;
        }
    }
    // 输入框内容是否有黑名单关键字的检查
    const handleRiskAddressChange = _.debounce(async (e) => {
        saveRiskHint('address', await queryBlacklist(e.target.value, 1));
    }, 1000);
    // 提交之前的黑名单检测
    const beforeSubmit = (val) => {
        console.log(val);
        const params = {
            "amapId": val.id,
            tenantId: auditData.tenantId,
            ...val,
        }
        delete params.city;
    return new Promise(resolve => {
            commonService.blackListCheck(params).then(res => {
                if(res.success) {
                    resolve(res.content);
                }
            })
        })
    }

    // 上传配置
    const uploadConfig = {
        showUploadList: {
            showDownloadIcon: false,
            showPreviewIcon: true,
            showRemoveIcon: true
        },
        beforeUpload: (file) => {
            return new Promise((resolve, reject) => {
                const extension = file.type.split('/')[1];
                if(/(webp|svg|png|gif|jpg|jpeg|jfif|bmp|dpg|ico|pdf)$/i.test(extension)){
                    resolve()
                }else {
                   message.error('仅支持图片和pdf文件');
                }
            })
        }
    }
    useEffect(() => {
        getRegions();
        getBasicOptions();
        getHotelBrandList();
        getAuditData();
    }, []);
    return (
        <>
            <div className="audit_module">
                <div className="module_name">基本信息和经营情况</div>
                <Form disabled={!auditData.canEdit} name="formInstance" form={formInstance} labelCol={{span: 4}} wrapperCol={{span: 12}}>
                    <Form.Item label="酒店名称" required>
                        <Row>
                            <Col span={20}>
                                <Input.Group compact className="inline_compact">
                                    <Form.Item noStyle name="name" rules={[
                                        {
                                            required: true,
                                            message: '请输入',
                                        },
                                    ]}>
                                        <Input
                                            plceholder="请使用地图或者场地库检索"
                                            disabled
                                            addonBefore={<span className="place_icon" onClick={showPlacesSearch}>场地库检索</span>}
                                            addonAfter={<i title="地图选址" onClick={() => handleOpenMapModal("name")}  className="iconfont icon-changdi place_icon"></i>}
                                        />
                                    </Form.Item>
                                </Input.Group>
                                {auditData.auditData ? <div>客户录入：{auditData.auditData.custName}</div> : null}
                            </Col>
                            <RiskHint mapFileds={selectFields} name="name"/>
                        </Row>
                    </Form.Item>
                    <Form.Item label="地址" required>
                        <Input.Group compact>
                            <Form.Item noStyle name={['location', 'province']} rules={[
                                {
                                    required: true,
                                    message: '请选择',
                                },
                            ]}>
                                <Cascader options={regionList}  placeholder="请选择"/>
                            </Form.Item>
                            <Row style={{display: 'flex'}}>
                                <Col span={12}>
                                    <Form.Item
                                        name={['location', 'address']}
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={handleRiskAddressChange}
                                            addonAfter={<i title="地图选址" onClick={() => handleOpenMapModal("address")}  className="iconfont icon-changdi place_icon"></i>}
                                            placeholder="请输入地址"
                                        />

                                    </Form.Item>
                                </Col>
                                <RiskHint mapFileds={selectFields} name="address"/>
                            </Row>
                            {auditData.auditData ? <div>客户录入：{auditData.auditData.custAddress}</div> : null}
                        </Input.Group>

                    </Form.Item>
                    <Form.Item label="酒店联系人" name="concatName">
                        <Input placeholder="请输入"/>
                    </Form.Item>
                    <Form.Item label="酒店联系人手机" name="concatMobile">
                        <Input maxLength={11} placeholder="请输入"/>
                    </Form.Item>
                    <Form.Item label="酒店联系人邮箱" name="concatEmail" >
                        <Input placeholder="请输入"/>
                    </Form.Item>
                    <Form.Item label="酒店品牌">
                        <Row>
                            <Col span={10} >
                                <Form.Item noStyle name={['brand', 'brandType']}>
                                    <Select fieldNames={{label: 'txt', value: 'val'}} options={OperationMode} placeholder="请选择类型">
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10} offset={4}>
                                <Form.Item noStyle name={['brand', 'brand']}>
                                    <Select options={HotelBrands} fieldNames={{label: 'name', value: 'resourceId'}} placeholder="请选择品牌"></Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label="法律名称" name="legalName">
                        <Input placeholder="请输入"/>
                    </Form.Item>
                    <Form.Item label="统一社会信用代码" name="unifiedSocialCreditCodeOrganizationalCode">
                        <Input placeholder="请输入"/>
                    </Form.Item>
                    <Form.Item label="经营状态">
                        <Row>
                            <Col span={12}>
                                <Form.Item noStyle name="businessStatus">
                                    <Select onChange={(val) => selectOnChange(val, 'businessStatus')} fieldNames={{label: 'txt', value: 'val'}} options={BusinessStatus} placeholder="请选择状态"></Select>
                                </Form.Item>
                            </Col>
                            <RiskHint mapFileds={selectFields} name="businessStatus"/>
                        </Row>
                    </Form.Item>
                    <Form.Item label="经营范围">
                        <Row>
                            <Col span={12}>
                                <Form.Item noStyle name="businessScope">
                                    <Input placeholder="请输入"  onChange={_.debounce((e) => selectOnChange(e.target.value, 'businessScope'), 500)}/>
                                    {/*<Select onChange={(val) => selectOnChange(val, 'businessScope')}  fieldNames={{label: 'txt', value: 'val'}} options={BusinessScope} placeholder="请选择范围"></Select>*/}
                                </Form.Item>
                            </Col>
                            <RiskHint mapFileds={selectFields} name="businessScope"/>
                        </Row>
                    </Form.Item>
                    <Form.Item label="酒店信息公示" name="hotelInformationPublicity">
                        <Input placeholder="请输入"/>
                    </Form.Item>
                    {/*<Form.Item label="是否收到工商处罚" name="isReceivedSanctionsUUEC">*/}
                    {/*    <Radio.Group>*/}
                    {/*        <Radio value="1">是</Radio>*/}
                    {/*        <Radio value="0">否</Radio>*/}
                    {/*    </Radio.Group>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="企业股权类型">
                        <Row>
                            <Col span={12}>
                                <Form.Item noStyle name="enterpriseEquityType">
                                    <Select onChange={(val) => selectOnChange(val, 'enterpriseEquityType')} fieldNames={{label: 'txt', value: 'val'}} options={EnterpriseEquityType} placeholder="请选择范围"></Select>
                                </Form.Item>
                            </Col>
                            <RiskHint mapFileds={selectFields} name="enterpriseEquityType"/>
                        </Row>
                    </Form.Item>
                </Form>
                {/*<Button type="primary" onClick={saveForm}>保存</Button>*/}
                <div className="module_name">合规筛选</div>
                <Form disabled={!auditData.canEdit} name="form2" form={formInstance} labelCol={{span: 4}} wrapperCol={{span: 8}}>
                    <Form.Item label="是否为度假酒店">
                        <Form.Item name="isVacationHotel" noStyle>
                            <Radio.Group style={{width: '100%'}}>
                                <Radio value="1">是</Radio>
                                <Radio value="0">否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle name="vacationHotel">
                            <Input/>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="是否为奢华品牌" >
                        <Form.Item noStyle name="isLuxuryHotel">
                            <Radio.Group style={{width: '100%'}}>
                                <Radio value="1">是</Radio>
                                <Radio value="0">否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle name="luxuryHotel">
                            <Input/>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="黑名单筛查" >
                        <Form.Item noStyle name="isBlacklistScreening">
                            <Radio.Group style={{width: '100%'}}>
                                <Radio value="1">是</Radio>
                                <Radio value="0">否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle name="blacklistScreening">
                            <Input/>
                        </Form.Item>
                    </Form.Item>
                </Form>
                <div className="module_name">支持文件</div>
                <Form disabled={!auditData.canEdit} name="form3" className="label_wrap__form" form={formInstance} labelCol={{span: 6}} wrapperCol={{span: 8}}>
                    <Form.Item label="可开具酒店增值税发票" name="canIssueHotelAddTaxInvoices">
                        <Radio.Group style={{width: '100%'}}>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="签订的年框架协议上加盖公章或合规章" name="sealContractOrAgreement" >
                        <Radio.Group style={{width: '100%'}}>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="公章或合同章与发票上的名称一致" name="sealContractOrAgreementMatchesInvoiceName" >
                        <Radio.Group style={{width: '100%'}}>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="酒店提供机打账单" name="hotelProvidesMachinePrintedBills">
                        <Radio.Group style={{width: '100%'}}>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="酒店结算文件采集" name="collectionHotelSettlementDocuments" >
                        <Radio.Group style={{width: '100%'}}>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
                <div className="module_name">合规经营及承诺</div>
                <Form disabled={!auditData.canEdit} name="form4" className="label_wrap__form" form={formInstance} labelCol={{span: 6}} wrapperCol={{span: 8}}>
                    <Form.Item label="该酒店是否可以接受反贪污受贿条款" name="isAcceptAntiCorruption">
                        <Radio.Group style={{width: '100%'}}>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="是否配合客户发起审计或调查" name="isInitiateAuditsOrInvestigations">
                        <Radio.Group style={{width: '100%'}}>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="是否受到美英欧或中国政府制裁">
                        <Form.Item noStyle name="isReceivedICPunishment">
                            <Radio.Group style={{width: '100%'}}>
                                <Radio value="1">是</Radio>
                                <Radio value="0">否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle name="receivedICPunishment">
                            <Input/>
                        </Form.Item>
                    </Form.Item>
                </Form>
                <div className="module_name">会议资源</div>
                <Form disabled={!auditData.canEdit} name="form5" className="label_wrap__form" form={formInstance} labelCol={{span: 9}} wrapperCol={{span: 8}}>
                    <Form.Item label="具备可承接会议的基本资源（房间、会议室、餐厅数量）">
                        <Form.Item noStyle name="isBasicResourcesToUndertakeConferences">
                            <Radio.Group style={{width: '100%'}}>
                                <Radio value="1">是</Radio>
                                <Radio value="0">否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle name="basicResourcesToUndertakeConferences" >
                            <Input/>
                        </Form.Item>
                    </Form.Item>
                    <div>少于10间、没有会议室不能合作，外包餐厅需要提示客户</div>
                </Form>
                <div className="module_name">其他供应商尽调信息</div>
                <Form disabled={!auditData.canEdit} name="form6" form={formInstance} labelCol={{span: 5}} wrapperCol={{span: 8}}>
                    <Form.Item label="对外名称" name="otherExternalName">
                        <Input placeholder="请输入"/>
                    </Form.Item>
                    {/*<Form.Item label="法律名称" name="otherLegalName" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: '请输入',*/}
                    {/*}]}>*/}
                    {/*    <Input placeholder="请输入"/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="统一社会信用代码" name="otherUnifiedSocialCreditCode" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: '请输入',*/}
                    {/*}]}>*/}
                    {/*    <Input placeholder="请输入"/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="经营状态" name="otherBusinessStatus" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: '请选择',*/}
                    {/*}]}>*/}
                    {/*    <Select options={[{value: '1', label: '酒店类型'}]} placeholder="请选择经营状态"></Select>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="经营范围" name="otherBusinessScope" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: '请输入',*/}
                    {/*}]}>*/}
                    {/*    <Input placeholder="请输入"/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="注册时间" name="otherRegistrationTime">
                        <DatePicker
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="公司规模" name="otherCompanySize">
                        <Input placeholder="请输入"/>
                    </Form.Item>
                    <Form.Item label="该供应商是否受到过工商处罚？" >
                        <Form.Item noStyle name="otherisReceivedSanctionsUUEC">
                            <Radio.Group style={{width: '100%'}}>
                                <Radio value="1">是</Radio>
                                <Radio value="0">否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle name="otherReceivedSanctionsUUEC">
                            <Input/>
                        </Form.Item>
                    </Form.Item>
                    {/*<Form.Item label="黑名单筛查" required name="otherBlacklistScreening" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: '请选择',*/}
                    {/*}]}>*/}
                    {/*    <Radio.Group style={{width: '100%'}}>*/}
                    {/*        <Radio value="1">是</Radio>*/}
                    {/*        <Radio value="0">否</Radio>*/}
                    {/*    </Radio.Group>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="签订协议" name="otherSignAgreement">
                        <Radio.Group style={{width: '100%'}}>
                            <Radio value="1">是</Radio>
                            <Radio value="0">否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {/*<Form.Item label="供应商是否可以接受反贪污贿赂条款?" required name="otherIsAcceptAntiCorruption" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: '请选择',*/}
                    {/*}]}>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="是否配合客户发起审计或调查?" required name="otherInitiateAuditOrInvestigation" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: '请选择',*/}
                    {/*}]}>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="该供应商是否收到过工商处罚?" required name="otherReceivedSanctionsUUECExt" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: '请选择',*/}
                    {/*}]}>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="酒店特殊情况说明" name="otherSpecialCircumstances" valuePropName="fileList"
                               getValueFromEvent={normFile}>
                        <Upload
                            {...uploadConfig}
                            action={process.env.REACT_APP_GATEWAY + '/foundation/aliyun/api/oss/upload'}
                            listType="picture-card"
                            fileList={fileList}
                            accept="image/jpeg, image/png, image/jpg, .pdf"
                        >
                            { uploadButton}
                        </Upload>
                    </Form.Item>
                </Form>
                <Button disabled={!auditData.canEdit} type="primary" onClick={saveForm}>保存</Button>
                <Button disabled={!auditData.canEdit} onClick={resetForm} style={{marginLeft: '30px'}}>重置</Button>
            </div>
            <MapEditor ref={mapEditorRef} currentField={currentField} mapDataCallback={mapDataCallback} beforeSubmit={beforeSubmit}></MapEditor>
            <Modal
                title="场地库检索"
                destroyOnClose
                visible={PlaceVisible}
                width="80%"
                onOk={onOkPlace}
                okText="确定"
                onCancel={onCancelPlace}
            >
                <SmartQueryReact
                    gatewayPath={gatewayPath}
                    iSignalr={iSignalr}
                    getQueryData={queryData}
                    returnSelectedRows={returnSelectedRows}
                ></SmartQueryReact>
            </Modal>
        </>
    )
}
export default AuditModule;
