import React, {useEffect, useRef, useState} from "react";
import { getCookie } from "tiny-cookie";
import './CarInfoCard.less'
import {Button, message, Popconfirm, Table, Typography} from "antd";
import {SmartStorage} from "@/utils";
import CarBillingDetailModal from "@/components/DetailDrawer/compments/CarSupplierModules/CarBillingDetailModal";
import CarBillingEditor from "@/components/DetailDrawer/compments/CarSupplierModules/CarBillingEditor";
import moment from "moment";
import commonService from "@/service/common";
const CarBillingDetail = (props) => {
    const carBillingDetailModal = useRef(null);
    const carBillingEditor = useRef(null);
    const [taskInfo, setTaskInfo] = useState({});
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        setTaskInfo(props?.miceInfo)
        setDataList(props?.miceInfo?.orderExpensePieces)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.miceInfo]);
    const columns = [
        { title: '用车形式', dataIndex: ['itsExpenseData','carModus'], key: 'carModus' },
        { title: '车牌号', dataIndex: ['itsExpenseData','licensePlate'], key: 'licensePlate' },
        { title: '车型', dataIndex: ['itsExpenseData', 'carType'], key: 'carType' },
        { title: '公里数', dataIndex: ['itsExpenseData', 'kilometers'], key: 'kilometers',
            render: (children) => (<> {children} km </> )},
        { title: '上车时间', dataIndex: ['itsExpenseData', 'sDateTime'], key: 'sDateTime' ,
            render: (children) => (<>{moment(children).format('YYYY-MM-DD HH:mm')}</>)
        },
        { title: '小计', dataIndex: ['itsExpenseData','money'], key: 'money' },
        { title: '操作', dataIndex: 'action', key: 'action',
            render: (_, record) => (<> {props.miceInfo.status !== 100 ? (
                <>
                    <Typography.Link onClick={() => editInfo(record)} style={{ marginRight: 8 }}>
                        编辑
                    </Typography.Link>
                    <Popconfirm title="确定删除该明细?" onConfirm={() => remove(record)}>
                        <span style={{color: 'red', cursor: 'pointer'}}>删除</span>
                    </Popconfirm>
                </>
            ) : (
                <>
                    <Button type="primary" onClick={() => showDetail(record)}>详情 {props.hasAddCarBillingBtn}</Button>
                </>
            )} </> )
        },
    ];
    const editInfo = (record) => {
        carBillingEditor.current.showModal(record)
    }
    const remove = (record) => {
        let params = {
            pieceId: record.itsExpenseData.pieceId,
            expenseId: record.expenseId
        }
        commonService.RemovePiece(params).then((res) => {
            if (res && res.success) {
                message.success("删除成功")
                props.updateChild()
            }
        });
    }
    const showDetail = (record) => {
        carBillingDetailModal.current.showModal(record)
    }
    const exportFiles = () => {
        let params = {
            orderId: SmartStorage.get("detailRow").orderId
        }
        commonService.ExportCarDetailToExcel(params).then((res) => {
            if (res && res.success) {
               window.open(res.content)
            }
        });
    }
    const addBilling = () => {
        carBillingEditor.current.showModal({})
    }
    const updateChild = () => {
        props.updateChild()
    }
    return (<div className="boderRow carDetails">
        <div className="carTitle">
            <div className="card_title">{props.moduleItem?.queryName}</div>
            <div className="BtnList">
                <Button type="primary" onClick={exportFiles}>导出</Button>
                {props.hasAddCarBillingBtn && (<Button type="primary" style={{marginLeft: '20px'}} onClick={addBilling}>添加明细</Button>)}
            </div>
        </div>
        <Table
            columns={columns}
            bordered
            dataSource={dataList}
            pagination={false}
        />
        <h2 className="amount">结算总金额：<span>{taskInfo.totalAmount}</span></h2>
        <CarBillingDetailModal ref={carBillingDetailModal} miceInfo={taskInfo}></CarBillingDetailModal>
        <CarBillingEditor ref={carBillingEditor} miceInfo={taskInfo} updateChild={updateChild}></CarBillingEditor>
    </div>)
}

export default CarBillingDetail;
