import React, { forwardRef, useImperativeHandle, useState } from "react";
import { message, Modal, Button, Form, DatePicker } from "antd";
import dayjs from "dayjs";
import commonService from "@/service/common";
import moment from "moment";
import "../index.less";
const FundLogDetails = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [btnData, setBtnData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      console.log(btn, rowKeys, selectedRows, data);
      setBtnData(btn);
      setSelectedRows(selectedRows || data);
      // eslint-disable-next-line no-eval
      eval(btn.val)(btn, rowKeys, selectedRows, data);
    },
  }));
  // eslint-disable-next-line no-unused-vars
  const batchCopy = (btn, selectedRowKeys, selectedRows, data) => {
    setModalVisible(true);
  };

  const commonHandle = async (url, params, btn) => {
    let method = "post";
    let res = await commonService.common(method, url, params);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      cancelModal();
      props.reloadData();
    }
  };
  const saveBatch = () => {
    form.validateFields().then((values) => {
      console.log(values);
      let url =
        btnData.parameters.pai ||
        props.queryObject.variables.api4BatchSetQueryData;
      let params = {};
      if (btnData.parameters.Request) {
        params = Object.assign(btnData.parameters || {}, {
          Request: props.dataParame,
        });
      } else {
        params = Object.assign(btnData.parameters || {}, {
          Rows: selectedRows,
        });
      }
      // params.Updates = {
      //     FillingDate: moment(values.FillingDate).format("YYYY-MM-DD")
      // }
      let obj = {};
      Object.keys(params.Updates).forEach(function (key) {
        obj[key] = moment(values.FillingDate).format("YYYY-MM-DD");
      });
      params.Updates = obj;
      params.Request.parameters = {
        Category: ["?"],
        Company: ["?"],
        dtCreated: {
          start: moment(values.dtCreated).format("YYYY-MM-DD"),
          end: moment(values.dtCreated).format("YYYY-MM-DD"),
        },
        _pageSize: 10,
        _pageIndex: 1,
      };
      console.log(url, params);
      commonHandle(url, params);
    });
  };
  const cancelModal = () => {
    setModalVisible(false);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= dayjs().subtract(1, "day").endOf("day");
  };
  return (
    <div>
      <Modal
        title="批量复制数据"
        centered
        visible={modalVisible}
        maskClosable
        onCancel={cancelModal}
        footer={[
          <Button key="back" onClick={saveBatch}>
            确定
          </Button>,
        ]}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="源数据日期"
            name="dtCreated"
            rules={[{ required: true, message: "请选择日期!" }]}
          >
            <DatePicker
              format={"YYYY-MM-DD"}
              style={{
                width: "200px",
              }}
            />
          </Form.Item>
          <Form.Item
            label="填报日期"
            name="FillingDate"
            rules={[{ required: true, message: "请选择复制到哪天!" }]}
          >
            <DatePicker
              disabledDate={disabledDate}
              format={"YYYY-MM-DD"}
              style={{
                width: "200px",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default FundLogDetails;
