import {getAllCookies, removeCookie} from "tiny-cookie";
import {message} from "antd";
import {SmartStorage} from "@/utils";

let publicMethod = {
    setiDomain() {
        let domain = document.domain;
        var re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/; //正则表达式
        let isIP = re.test(domain);
        let isLocalhost = domain === "localhost";
        console.log("document domain:", domain);
        domain =
            isIP || isLocalhost ? domain : domain.substring(domain.indexOf(".") + 1);
        window.idomain = domain;
    },
    appendSession() {
        let cookieList = getAllCookies();
        for (var i in cookieList) {
            const itemValue = cookieList[i];
            if (!itemValue) continue;
            SmartStorage.set(i, cookieList[i]);
        }
    },
    // 指定删除cookie，cookieList是一个数组，如果不传就删除所有cookie
    async removeAllCookies(cookieList = []) {
        if (!cookieList.length) {
            cookieList = Object.keys(getAllCookies());
        }
        Object.keys(cookieList).forEach(function (key) {
            removeCookie(cookieList[key], {
                domain: window.idomain,
            });
            removeCookie(cookieList[key], {
                domain: document.domain,
            });
            removeCookie(cookieList[key], {
                domain: ".eventworld.cn",
            });
            removeCookie(cookieList[key], {
                domain: ".smartmice.cn",
            });
        });
    },
    // 清除缓存session/cookie 并返回到登录站点
    async deleteLocalData() {
        if (
            window.location.pathname === "/micro-apps/publicQuery" ||
            window.location.pathname === "/gmvNotification"
        ) {
            console.log(window.location.pathname);
        } else {
            let returnUrl = encodeURIComponent(window.location.href);
            // let returnUrl = process.env.REACT_APP_Path;
            let loginUrl = process.env.REACT_APP_LOGIN_URL;
            let loginMiceUrl = process.env.REACT_APP_LOGIN_MICE_URL;
            if (!(loginUrl && loginMiceUrl)) {
                message.error("系统异常，请刷新页面后重试！");
                return;
            }
            // console.log(loginUrl);
            await this.removeAllCookies();
            localStorage.clear();
            sessionStorage.clear();
            console.log("cookie: ", document.cookie);
            // var returnUrl = window.location.href;
            window.location.href = (returnUrl.indexOf('smartmice') !== -1 ? loginMiceUrl : loginUrl) + "?returnUrl=" + returnUrl;
            // window.location.href = `${loginUrl}&returnUrl=${returnUrl}`;
            // window.location.href = loginUrl;
        }
    },
};

export default publicMethod;
